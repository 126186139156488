import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Menu, Select } from 'antd';
import { getFilterCustomerList } from '../../../../API-Interaction/api-functions/customer.api';
import { useDispatch } from 'react-redux';
import { getProductCategoryFilterList } from '../../../../API-Interaction/api-functions/product.api';

const FilterProductCategory = ({ passData, getData }) => {
    const [filterStatus, setFilterStatus] = useState({ label: 'All', value: 'All' });
    const [filteredCategory, setFilteredCategory] = useState([]);
    const token = localStorage.getItem('authTokenHaasil');
    const dispatch = useDispatch()

    const fetchData = async (value, data) => {

        setFilterStatus(data)
        if (value == 'All')
            return getData()

        try {
            const response = await getProductCategoryFilterList(token, dispatch, value);
            console.log({ response })
            passData(response)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div>
            <Select
                placeholder="Filter By Status"
                optionFilterProp="children"
                onChange={fetchData}
                value={filterStatus.value}
                style={{ width: '200px', height: '40px' }} 

                options={[


                    {
                        value: 'L1',
                        label: 'Category',
                    },
                    {
                        value: 'L2',
                        label: 'DEEP Category',
                    },
                    {
                        value: 'L3',
                        label: 'Storable',
                    },
                    {
                        value: 'L4',
                        label: 'Product Type',
                    },
                    {
                        value: 'L5',
                        label: 'Manufacturer',
                    },
                    {
                        value: 'L6',
                        label: 'Brand',
                    },
                    {
                        value: 'L7',
                        label: 'Variant',
                    },
                    {
                        value: 'L8',
                        label: 'Sub Variant',
                    },
                    {
                        value: 'L9',
                        label: 'Unit Of Measure',
                    },
                    {
                        value: 'L10',
                        label: 'Tier',
                    },
                ]}

            />
            <div>
                {filteredCategory?.map(item => (
                    <div key={item.id} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                        <h3>{item.name}</h3>
                        <p>Status: {item.level}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FilterProductCategory;
