export const pageRoutes = {
    public: {
        login: "/",
        signup: "/signup",
        forgetPassword: "/forget-password",
    },
    private: {
        dashboard: "/dashboard",
        dailyEssentails: "/daily-essentials",
        rfq: "/request-for-quotation",
        checkout: "/checkout",
        profile: "/profile",
        manageAddresses: "/manage-address",
        addToCart: "/add-to-cart",
        verification: "/verification",
        newPassword: "/new-password",
        invoice: "/invoice",
        ProductCategory:'/ProductCategory',
        AdminCreateOrder:'/AdminCreateOrder',
        UserCreated:'/userCreated'
        
        
        

    }
}