import React, { useEffect, useState } from "react";
import { Button, Col, ConfigProvider, DatePicker, Divider, Input, Modal, Row, Spin, Upload } from "antd";
import Layout from "../../../Layout/Layout";
import Appbar from '../../../Components/Appbar/Appbar'
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "../../../Assets/Colors";
import Quotestable from "../../../Components/Admin/Table/Quotestable";
import { CloudDownloadOutlined, CloudUploadOutlined, DeleteOutlined, LinkOutlined, LoadingOutlined, LockOutlined, UploadOutlined } from "@ant-design/icons";
import { ChangeQuotationStatusAdmin, rejectClientQuotation } from "../../../API-Interaction/api-functions/quotation.api";
import { useDispatch, useSelector } from "react-redux";
import ApprovedQuoteModal from "../../../Components/Admin/Modals/ApprovedQuoteModal";
import { CSVLink, CSVDownload } from "react-csv";
import { FileExcelOutlined } from '@ant-design/icons';
import { DataForCSV } from "../../../helpers/DataForCSV";
import { UpdateStatusOrder, onOrderDelivered } from "../../../API-Interaction/api-functions/order.api";
import { success } from "../../../helpers/notifications";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import locale from 'antd/locale/zh_CN';
import moment from "moment";
import { checkbeforeUpload, debounceImage } from "../../../helpers/helper";
import { uploadAdminDocument, uploadOrderImage } from "../../../API-Interaction/api-functions/upload.api";
import { Form, Select } from 'antd';
import InvoiceGenerator from "../../../Components/InvoiceGenerator/InvoiceGenerator";
import DeliveryChallanContent from "../../../Components/InvoiceGenerator/DeliveryChallanContent";
import DeliveryChallanGenerator from "../../../Components/InvoiceGenerator/DeliveryChallanGenerator";
import ParkingSlipGenerator from "../../../Components/InvoiceGenerator/PakingSlipGenerator";
import InvoiceGeneratorOrder from "../../../Components/InvoiceGenerator/InvoiceGeneratorOrder";




const Quotes = () => {
    const { Option } = Select;
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = localStorage.getItem('authTokenHaasil')
    const { id } = useParams()
    const [view, setView] = useState("Grid")
    const [Data, setData] = useState()
    const [preferredDate, setPreferredDate] = useState('')
    const [clientRemarks, setclientRemarks] = useState('')
    const [open, setOpen] = useState(false)
    const [adminRemarks, setAdminRemarks] = useState("")
    const [QuotationStatus, setQuotationStatus] = useState('')
    const [orderRemarks, setorderRemarks] = useState('')
    const [Document, setDocument] = useState('')
    const [AddressDetail, setAddressDetail] = useState('')
    const [CompanyDetails, setCompanyDetails] = useState()
    const [ExcelHeader, setExcelHeader] = useState([])
    const [ExcelData, setExcelData] = useState([])
    const [callMainAPI, setcallMainAPI] = useState(false)
    const [deliveryDate, setDeliveryDate] = useState(null)
    const [expiryDate, setexpiryDate] = useState(null)
    const [loading, setloading] = useState(false)
    const [adminDocument, setAdminDocument] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [result, setResult] = useState(null); // Define 'result' state
    const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);
    const [isParkingModalVisible, setIsParkingModalVisible] = useState(false);
    const [isDeliveryModalVisible, setIsDeliveryModalVisible] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState({})



    //invoive //
    const openInvoiceModal = () => {
        setIsInvoiceModalVisible(true);
    };


    const HandleModalInvoice = () => {
        setIsInvoiceModalVisible(!isInvoiceModalVisible)
    }
    //Delivery
    const openDeliveryModal = () => {
        setIsDeliveryModalVisible(true);
    };


    const HandleModalDelivery = () => {
        setIsDeliveryModalVisible(!isDeliveryModalVisible)
    }
    //parking
    const openParkingModal = () => {
        setIsParkingModalVisible(true);
    };

    const HandleModalParking = () => {
        setIsParkingModalVisible(!isParkingModalVisible)
    }

    const showModalStatus = () => {
        setIsChangeStatusModalOpen(true);
    };


    const fetchInvoice = async (type = 'invoice') => {
        setInvoiceDetails({ Order: { ...Data } })
        if (type == 'invoice')
            setIsInvoiceModalVisible(true);
        if (type == 'parking')
            setIsParkingModalVisible(true);
        if (type == 'delivery')
            setIsDeliveryModalVisible(true);

    }

    const handleStatusOk = () => {
        form.validateFields()
            .then((values) => {
                // Make an API request using the fetch function
                UpdateStatusOrder(dispatch, token, id, values) // Pass 'id' as the orderId
                    .then((response) => {
                        // Handle the API response as needed
                        console.log('API Response:', response);
                        setIsChangeStatusModalOpen(false);
                        window.location.reload()

                    })
                    .catch((error) => {
                        console.error('API Request Error:', error);
                    });
            })
            .catch((errorInfo) => {
                console.log('Validation Failed:', errorInfo);
            });
    };

    const handleStatusCancel = () => {
        setIsChangeStatusModalOpen(false);
    };
    const SubmitQuotation = async () => {
        let result = await ChangeQuotationStatusAdmin(dispatch, token, id,
            {
                remarks: adminRemarks,
                status: "QP",
                // deliveryDate: moment(deliveryDate ? deliveryDate?.$d : new Date()).format('YYYY-MM-DD'),
                // expiryDate: moment(expiryDate ? expiryDate?.$d : new Date()).format('YYYY-MM-DD'),
                deliveryDate: deliveryDate ? moment(deliveryDate?.$d).format('YYYY-MM-DD') : null,
                expiryDate: expiryDate ? moment(expiryDate.$d).format('YYYY-MM-DD') : null,

                adminDocuments: adminDocument
            })
        if (result.success) {
            HandleModal()
        }
    }

    const HandleModal = () => {
        if (open) return navigate('/admin/approved-quotations')
        setOpen(!open)
    }

    const RejectQuotation = async () => {
        const result = await rejectClientQuotation(dispatch, token, id)
        if (result.success) {
            navigate('/admin/quotations')
        }
    }


    const SortDataForCSV = () => {
        if (Data) {
            let item = DataForCSV(Data)
            setExcelHeader(item.header.headers)
            let cell = item.Cell()
            setExcelData(cell)
        }

    }


    const OrderDelivered = async () => {

        if (Data.status !== 'OR') {
            const result = await onOrderDelivered(dispatch, token, id)
            if (result.success) {
                success('Order Delivered.')
                setcallMainAPI(!callMainAPI)
            }
        }
    }


    const FomatDate = () => {
        console.log("DATA", Data?.deliveryDate)

        const formattedDate = moment(Data?.preferredDeliveryDate).format('DD-MM-YYYY');
        setPreferredDate(formattedDate)
        console.log("DATA", Data?.preferredDeliveryDate)
        if (Data?.status == 'OC' || Data?.status == 'OR') {
            const formattedDeliveryDate = moment(Data?.deliveryDate).format('DD-MM-YYYY');

            setDeliveryDate(formattedDeliveryDate)
        }
    }


    const onChange = (date, dateString) => {
        setDeliveryDate(dateString)
    };



    const disabledDate = current => {
        // Get today's date
        const today = new Date();

        // Calculate the minimum allowed date
        const minDate = new Date();
        minDate.setDate(today.getDate() + 2); // Minimum two days after today

        // Disable dates before the minimum allowed date
        return current && current < minDate;
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        RejectQuotation();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // const handleUploadDocument = debounceImage(async (info) => {
    //     setloading(true)
    //     const { file, fileList } = info
    //     const check = checkbeforeUpload(info)
    //     if (!check) {
    //         setloading(false)
    //         return
    //     }
    //     let response = await uploadAdminDocument(dispatch, file.originFileObj, token, id)
    //     setAdminDocument([...adminDocument, response])
    //     setloading(false)
    // })
    const handleUploadDocument = debounceImage(async (info) => {
        setloading(true);
        const { fileList } = info;

        try {
            const uploadPromises = fileList.map((file) => {
                return uploadAdminDocument(dispatch, file.originFileObj, token, id);
            });

            const responses = await Promise.all(uploadPromises);

            console.log('Responses from file uploads:', responses);

            setAdminDocument([...adminDocument, ...responses]);
            setloading(false);
        } catch (error) {
            console.error('Error during file upload:', error);
            setloading(false);
        }
    });
    // const handleUploadDocument = debounceImage(async (info) => {
    //     setloading(true);
    //     const { fileList } = info;

    //     try {
    //         const allowedExtensions = ['.xlsx']; // Specify allowed file extensions here

    //         // Filter files by extension
    //         const validFiles = fileList.filter((file) => {
    //             const fileExtension = file.name.split('.').pop().toLowerCase();
    //             return allowedExtensions.includes(fileExtension);
    //         });

    //         const uploadPromises = validFiles.map((file) => {
    //             return uploadAdminDocument(dispatch, file.originFileObj, token, id);
    //         });

    //         const responses = await Promise.all(uploadPromises);

    //         console.log('Responses from file uploads:', responses);

    //         setAdminDocument([...adminDocument, ...responses]);
    //         setloading(false);
    //     } catch (error) {
    //         console.error('Error during file upload:', error);
    //         setloading(false);
    //         message.error('File upload failed. Please check the file format.');
    //     }
    // });



    const manageAdminDocument = (item) => {
        const newList = adminDocument.filter(dataItem => {
            if (dataItem !== item) {
                return dataItem
            }
        })
        setAdminDocument(newList)
    }
    useEffect(() => {
        SortDataForCSV()
    }, [Data])
    useEffect(() => {
        FomatDate()
    }, [Data])

    return (
        <Layout>
            <Spin spinning={pageLoader?.changeOrderStatus || false}>
                <div className=" mb-10">
                    <Row >
                        <Col md={20}>
                            <Appbar Heading={` ${QuotationStatus !== 'OC' ? `Quotation # ${id}` : `Order # ${id}`}`} view={view} setView={setView} ExtraDetail={true} />
                        </Col>
                        {/* <Col md={4}>
                            <p style={{fontSize:'20px',fontWeight:'bold',marginTop:'8px'}}>Quotation Id: {Data?.orderId}</p>
                        </Col> */}
                        <Divider className="m-[10px]" />
                    </Row>



                    <Row className="flex justify-between w-full flex-col h-fit">
                        <p
                            className={`mt-[4px]  support-heading bg-[${Colors.grayTxt}] text-[13px] font-bold`}
                        >
                            {QuotationStatus !== 'OC' ? "Quotation Detail" : `Order Detail`}
                        </p>

                        {CompanyDetails &&
                            <Row className="w-full justify-start gap-0 h-fit items-center">
                                <Col lg={7} xs={12} className="flex justify-end  p-1 relative overflow-hidden">
                                    <div className={`p-3 flex flex-col gap-3 w-full bg-[white] rounded-[5px] text-[black] text-[12px] h-fit
                                            overflow-hidden
                                            shadow-[rgba(149, 157, 165, 0.2) 0px 8px 24px] text-[${Colors.darkBlue}]
                                            `} style={{
                                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
                                        }}
                                    >
                                        <div className="flex justify-between">
                                            <p className="flex gap-2 ">
                                                <span className="flex justify-between font-[800] ">Location Title </span> {AddressDetail?.addressTitle}
                                            </p>
                                            <p className="flex gap-2">
                                                <span className="flex justify-between font-[800] ">Name: </span> {AddressDetail?.fullname}
                                            </p>
                                        </div>
                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800] ">City: </span>{AddressDetail?.city}
                                        </p>
                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800] ">Address </span> {AddressDetail?.address}
                                        </p>
                                        <div className="flex justify-between" >
                                            <p className="flex gap-2">
                                                <span className="flex justify-between font-[800] ">Phone:</span> {AddressDetail?.number}
                                            </p>
                                            {
                                                AddressDetail?.zipcode &&
                                                <p className="flex gap-2">
                                                    <span className="flex justify-between font-[800] ">ZipCode</span> {AddressDetail?.zipcode}
                                                </p>

                                            }
                                        </div>

                                    </div>


                                </Col>
                                <Col lg={7} xs={12} className="flex justify-end    relative overflow-hidden " style={{ boxShadow: " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}>
                                    <div className={` bg-[${''}] 
                                        text-[${Colors.darkBlue}]
                                         h-fit 
                                         p-3 flex flex-col
                                          gap-3 w-full rounded-[5px] 
                                          text-[black]
                                        text-[12px]
                                            shadow-[box-shadow rgba(0, 0, 0, 0.1) 0px 4px 12px]`} >
                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800]">Company Name: </span> {CompanyDetails?.Company?.name}
                                        </p>
                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800]">Address:</span> {CompanyDetails?.Company?.address}
                                        </p>
                                        <div className="flex justify-between">
                                            <p className="flex gap-2">
                                                <span className="flex justify-between font-[800]">City: </span> {CompanyDetails?.Company?.city}
                                            </p>

                                            <p className="flex gap-2">
                                                <span className="flex justify-between font-[800]">NTN </span>{CompanyDetails?.Company?.ntn}
                                            </p>
                                        </div>
                                        <div className="flex justify-between">

                                            <p className="flex gap-2">
                                                <span className="flex justify-between font-[800]">STN </span>{CompanyDetails?.Company?.stn}
                                            </p>
                                            <p className="flex gap-2">
                                                <span className="flex justify-between font-[800]">PO number: </span>{Data?.purchaseOrder ? Data?.purchaseOrder : "N-A"}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                {
                                    preferredDate &&
                                    <Col lg={9} xs={24} className="flex ml-1 flex-col py-1 gap-3  px-3 " style={{ boxShadow: " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", height: '132px' }}>


                                        {
                                            Data.status === 'RFQ' &&
                                            <p className="flex gap-2 items-center px-2 font-semibold justify-between " style={{ borderRight: "1px solid lightgray" }}>
                                                Set Quotation Expiry:
                                                <DatePicker
                                                    disabledDate={disabledDate}
                                                    onChange={(date, dateString) => setexpiryDate(date)}
                                                    format="DD-MM-YYYY"
                                                />
                                            </p>
                                        }
                                        <p className="flex gap-2 items-center px-2 font-semibold justify-between " style={{ borderRight: "1px solid lightgray" }}>
                                            Client Prefered Delivery Date:
                                            <DatePicker
                                                value={moment(Data?.preferredDeliveryDate, 'YYYY-MM-DD')} // Use 'value' instead of 'defaultValue' to control the displayed value
                                                format="DD-MM-YYYY" // Set the format explicitly
                                                disabled
                                            />

                                        </p>
                                        {/* <p className="flex gap-2 items-center px-2 font-semibold justify-between " style={{ borderRight: "1px solid lightgray" }}>
                                            Delivery Date:
                                            {
                                                Data?.status == 'RFQ' ?
                                                    <DatePicker
                                                        onChange={(date, inString) => setDeliveryDate(date)}
                                                        format="DD-MM-YYYY"
                                                        placeholder="Enter Date" />
                                                    : Data?.status == 'OC' || Data?.status == 'OR' || Data?.status == 'OR' ? <DatePicker value={dayjs(deliveryDate, 'DD-MM-YYYY')}
                                                        disabled={true} format="DD-MM-YYYY" />
                                                        : ""
                                            }
                                        </p> */}

                                        <p className="flex gap-2 items-center px-2 font-semibold justify-between " style={{ borderRight: "1px solid lightgray" }}>
                                            Delivery Date:
                                            {
                                                Data?.status == 'RFQ' ? (
                                                    <DatePicker
                                                        onChange={(date, inString) => setDeliveryDate(date)}
                                                        format="DD-MM-YYYY"
                                                        placeholder="Enter Date"
                                                    />
                                                ) : Data?.status == 'OC' || Data?.status == 'OR' ? (
                                                    <DatePicker
                                                        value={dayjs(deliveryDate, 'DD-MM-YYYY')}
                                                        disabled={true}
                                                        format="DD-MM-YYYY"
                                                    />
                                                ) : "N/A"
                                            }
                                        </p>

                                    </Col>
                                }
                            </Row>
                        }
                        <Divider className="m-[10px]" />
                    </Row>
                    <div className="w-full bg-[#f4f6fc]" >
                        <Quotestable
                            callApiCheck={callMainAPI}
                            allOrderData={setData}
                            setorderRemarks={setorderRemarks}
                            setOrderStatus={setQuotationStatus}
                            setClientRemarks={setclientRemarks}
                            setDocument={setDocument}
                            setAddressDetail={setAddressDetail}
                            setCompanyDetails={setCompanyDetails} />
                    </div>
                    {Data?.status === 'OC' ? (

                        // Display the orderPrice value below the table
                        <div className='py-3 px-2'>

                            <p className="text-right font-bold" >

                                Total Price : <span className='text-blue'>{Data?.orderPrice}</span>
                            </p>
                        </div>
                    ) : null}


                    {/* Client Uploaded Documents  */}
                    {
                        Data?.adminDocuments &&
                        <div className="mt-2 flex  flex-col">
                            <p className={`font-bold text-[16px] text-[${Colors.darkBlue}]`} >
                                Admin Uploaded Documents:
                            </p>
                            <div className="flex  gap-3" >
                                {
                                    Data?.adminDocuments?.map((item, index) => {
                                        return (
                                            <a href={item} style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} className="h-[100px] cursor-pointer w-[100px] bg-[#f6f6f6] flex justify-center items-center  flex-col">
                                                <CloudDownloadOutlined className={`text-[30px] text-[#061E3A] `} />
                                                <p className="text-[10px]">Download File</p>

                                            </a>
                                        )
                                    })

                                }
                            </div>

                        </div>
                    }

                    {/* Client Uploaded Documents  */}


                    <Row>
                        <Col md={20}>
                            {
                                Data?.orderDocuments &&
                                <div className="mt-2 flex  flex-col">
                                    <p className={`font-bold text-[16px] text-[${Colors.darkBlue}]`} >
                                        Client Order Documents: {Data?.orderDocuments?.length > 0 ? '' : 'N/A'}
                                    </p>
                                    <div className="flex  gap-3" >
                                        {
                                            Data?.orderDocuments?.map((item, index) => {
                                                return (
                                                    <a href={item} style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} className="h-[100px] cursor-pointer w-[100px] bg-[#f6f6f6] flex justify-center items-center  flex-col">
                                                        <CloudDownloadOutlined className={`text-[30px] text-[#061E3A] `} />
                                                        <p className="text-[10px]">Download File</p>

                                                    </a>
                                                )
                                            })

                                        }
                                    </div>

                                </div>
                            }
                        </Col>
                        <Col md={4}>
                            <div>
                                {QuotationStatus === 'QP' || QuotationStatus === 'RFQ' ? (
                                    <Button
                                        className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[2px] font-[600] text-[13px] py-[0px] px-[35px] mt-[6px]`}
                                        onClick={showModalStatus}
                                    >
                                        Change Status
                                    </Button>
                                ) : null}

                                <Modal title="Change Status" visible={isChangeStatusModalOpen} onOk={handleStatusOk} onCancel={handleStatusCancel}
                                    okButtonProps={{ style: { background: 'black', borderColor: 'black', color: 'white' } }} // Set button styles here
                                >
                                    <Form form={form}>
                                        <Form.Item
                                            name="status"
                                            label="Select Status"
                                            className="mb-[40px]"

                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a status',
                                                }
                                            ]}
                                        >
                                            <Select>
                                                <Option value="OC"> Order Confirmed</Option>
                                                <Option value="OR">Order Recieved</Option>
                                            </Select>

                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </div>
                        </Col>
                    </Row>
                    {/* Client Remarks */}
                    <div className="mt-2 flex items-center">
                        <p className={`font-bold text-[16px] text-[${Colors.darkBlue}]`} >
                            Client Remarks:
                        </p>
                        <p className={`border-none text-[12px] py-[20px] px-[15px] text-[${Colors.darkBlue}]`}>
                            {QuotationStatus === 'OC' && orderRemarks
                                ? orderRemarks
                                : QuotationStatus !== 'OC' && clientRemarks
                                    ? clientRemarks
                                    : 'N/A'}
                        </p>
                    </div>
                    <Divider className="m-[2px]" />
                    {
                        QuotationStatus !== 'OC' && QuotationStatus !== 'OR' &&
                        <div className="mt-5">
                            <Input.TextArea
                                value={adminRemarks}
                                onChange={(event) => { setAdminRemarks(event.target.value) }}
                                className="border-none bg-[#E9EFF6] text-[12px] py-[20px] px-[15px]"
                                maxLength={3000}

                                rows={2}
                                placeholder="Add Remarks Here..."
                            />
                        </div>
                    }


                    <div className="flex justify-between mt-3">

                        <div>
                            {QuotationStatus === 'OC' || QuotationStatus === 'OR' ? Document &&


                                <div>
                                    <p className={`font-bold text-[20px] mb-[10px] text-[${Colors.darkBlue}]`} >
                                        Client PO Document:
                                    </p>
                                    <Button className={`"flex justify-center items-center bg-[${Colors.darkBlue}] text-[white]`}
                                        href={`${Document}`} color="transparent"
                                        target="_blank"
                                        download onClick={() => { }}><CloudDownloadOutlined /> Download Document</Button>
                                </div>

                                :
                                QuotationStatus === 'RFQ' &&
                                <Upload showUploadList={false} onChange={handleUploadDocument} disabled={loading} multiple>

                                    {/* // <Upload showUploadList={false} onChange={handleUploadDocument} disabled={loading} multiple> */}
                                    <Button icon={!loading && <UploadOutlined />} > {loading ? <LoadingOutlined /> : `Click to Upload `}</Button>
                                </Upload>
                            }

                        </div>


                        <Spin spinning={pageLoader?.getQuotesById || false}>
                            {
                                QuotationStatus !== 'OR' & QuotationStatus !== 'OC' ?
                                    <div className="flex gap-3">
                                        {
                                            <Button
                                                loading={pageLoader.RejectQuotation || false}
                                                onClick={showModal}
                                                className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[2px] font-[600] text-[13px] py-[0px] px-[35px]`}
                                            >
                                                Reject Quote
                                            </Button>

                                        }
                                        <Modal
                                            title="Confirm"
                                            visible={isModalVisible}
                                            onOk={handleOk}
                                            onCancel={handleCancel}
                                            okText="Yes"
                                            cancelText="No"
                                            okButtonProps={{ type: "default" }}
                                        >
                                            <p>Are you sure you want to reject the quote?</p>
                                        </Modal>
                                        <Button loading={pageLoader.changeOrderStatus || false} onClick={() => { SubmitQuotation() }}
                                            className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[2px] font-[600] text-[13px] py-[0px] px-[35px]`}>
                                            Send Quote
                                        </Button>
                                    </div>

                                    :
                                    <div className="flex gap-2">

                                        <Button onClick={() => { }} className={`border-none flex items-center text-[${Colors.darkBlue}] font-[600] py-[0px] px-[35px] rounded-[10px]`}>
                                            <CSVLink data={ExcelData} headers={ExcelHeader} filename='HaasilInvoice.csv' className="flex items-center gap-1 "> Download CSV <FileExcelOutlined className="text-[green] text-[15px]" /></CSVLink>
                                        </Button>
                                        <Button loading={pageLoader?.orderDelivered || false} onClick={() => { OrderDelivered() }}
                                            className={`border-none ${Data?.status !== 'OR' ? `bg-[${Colors.darkBlue}] text-[white] ` : " text-[lightgreen] border-[green] border-2"}  rounded-[4px] font-[600] text-[13px] py-[0px] px-[35px]`}>
                                            {
                                                Data?.status === 'OC' ? "Mark Order as Delivered" : "Delivered"
                                            }
                                        </Button>
                                    </div>
                            }

                        </Spin>
                    </div>
                    <div>
                        <Row>
                            <Col md={24}>
                                {Data?.status === 'OR' || Data?.status === 'OC' ? (
                                    <div>
                                        <Button
                                            onClick={() => fetchInvoice('delivery')}
                                            className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[5px] font-[600] text-[13px] py-[0px] px-[35px] mt-[6px]`}
                                        >
                                            Delivery Slip
                                        </Button>
                                        {/* <InvoiceGenerator open={isInvoiceModalVisible} close={HandleModal} data={InvoiceData} /> */}
                                        <DeliveryChallanGenerator open={isDeliveryModalVisible} close={HandleModalDelivery} data={invoiceDetails} />
                                    </div>
                                ) : null}
                            </Col>
                            <Col md={24}>
                                {Data?.status === 'OR' || Data?.status === 'OC' ? (
                                    <div>
                                        <Button
                                            onClick={() => fetchInvoice('parking')}
                                            className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[5px] font-[600] text-[13px] py-[0px] px-[35px] mt-[6px]`}
                                        >
                                            Packing Slip
                                        </Button>
                                        <ParkingSlipGenerator open={isParkingModalVisible} close={HandleModalParking} data={invoiceDetails} />
                                    </div>
                                ) : null}
                            </Col>
                            <Col md={24}>
                                {Data?.status === 'OR' || Data?.status === 'OC' ? (
                                    <div>
                                        <Button
                                            onClick={() => fetchInvoice('invoice')}
                                            className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[5px] font-[600] text-[13px] py-[0px] px-[35px] mt-[6px]`}
                                        >
                                            Invoice
                                        </Button>
                                        <InvoiceGeneratorOrder open={isInvoiceModalVisible} close={HandleModalInvoice} data={invoiceDetails} />
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                    {/* Render Uploaded Documents Lists  */}
                    {adminDocument.length !== 0 &&
                        <div className="mt-[10px]">
                            {adminDocument.map((item, index) => {
                                return (
                                    <div className="flex justify-between ">
                                        <div className="flex items-center gap-2">
                                            <LinkOutlined className="text-[#1677ff]" />
                                            <a className="text-[#1677ff] cursor-pointer" href={item} >{item}</a>
                                        </div>
                                        <div>
                                            <Button className="shadow-none border-none" onClick={() => { manageAdminDocument(item) }}>
                                                <DeleteOutlined className="text-[red] " />
                                            </Button>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>

                    }
                </div>
            </Spin>
            <ApprovedQuoteModal open={open} close={HandleModal} />
        </Layout >
    )
}

export default Quotes