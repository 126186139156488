import React, { useEffect, useState } from "react";
import { Divider, Row, Spin } from "antd";
import Layout from "../../Layout/Layout";
import InvoicesHeader from '../../Components/Invoices/invoicesHeader'
import InvoicesTable from '../../Components/Invoices/InvoicesTable'
import { getAllCustomerInvoices } from "../../API-Interaction/api-functions/invoice.api";
import { useDispatch, useSelector } from "react-redux";
import InvoiceGenerator from "../../Components/InvoiceGenerator/InvoiceGenerator";


const Invoices = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [InvoiceData, setInvoiceData] = useState('')
    const token = localStorage?.getItem('authTokenHaasil')
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const getAllInvoice = async () => {
        let response = await getAllCustomerInvoices(dispatch, token)
        if (response?.success) {
            setData(response?.response?.order)
        }
    }

    useEffect(() => {
        getAllInvoice()
    }, [])


    const HandleModal = () => {
        setOpen(!open)
    }
    return (
        <>
            <Layout id='invoicePage' className='Invoice-Page'>
                <Row className="">
                    <InvoicesHeader />
                </Row>
                <Spin spinning={pageLoader?.getAllInvoice || pageLoader?.getInvoiceById || false}>
                    <Divider className="mt-1 w-[full]" />
                    <Row className="w-[full] ">
                        <InvoicesTable data={data} setOpen={setOpen} open={open} setInvoiceData={setInvoiceData} HandleModal={HandleModal}  />
                    </Row>
                </Spin>
            </Layout>
            <InvoiceGenerator open={open} close={HandleModal} data={InvoiceData} />

        </>
    );
};

export default Invoices;
