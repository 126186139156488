import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { error } from "../helpers/notifications";
import { pageRoutes } from "./pageRoutes";

const NonProtectedRoutes = () => {

    let token = localStorage?.getItem('authTokenHaasil')
    let Role = localStorage.getItem('Role')
   


    return (
        !token?.length ? <Outlet /> : <Navigate to={Role == '1' ? pageRoutes?.private?.dailyEssentails : '/admin/customers-list'} />
    )
}

export default NonProtectedRoutes