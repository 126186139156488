import { Content } from "antd/es/layout/layout";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Colors } from "../Assets/Colors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiForCart } from "../API-Interaction/api-functions/cart.api";
import { Spin } from "antd";

const Layout = ({ children, sideKey }) => {
  const [Hamburger, setHamburger] = useState(false)
  const dispatch = useDispatch()
  const token = localStorage.getItem('authTokenHaasil')

  const cart = useSelector((state) => state?.cart?.cartItems);

  const updateCart = async () => {
    const ArrayOfCartItem = Object.values(cart)
    const response = await callApiForCart(dispatch, token, cart)
  }
  useEffect(() => {
    updateCart()
  }, [cart])

  return <div className="w-full overflow-hidden " >
    <div
      className="w-full text-[#fff] fixed top-0 h-[70px] px-[10px] py-[20px] z-50 "
      style={{
        zIndex: '999',
        background: Colors.darkBlue,
      }}
    >
      {/* Header COntent */}
      <Header setHamburger={setHamburger} Hamburger={Hamburger} />
    </div>
    {/* Side Bar */}
    <div style={{}}>
      <div
        className="Layout-Sidebar"
        style={
          {
            position: 'fixed',
            height: `calc(100vh - 70px)`,
            display: Hamburger && ' block',
            width: Hamburger && "270px",
            transition: '0.2s ease-in-out'
          }
        }

      >
        <Sidebar sideKey={sideKey} Hamburger={Hamburger} style={{ zIndex: "9999999" }} />


      </div>



      {/* Main Content Section */}
      <Content onClick={() => { setHamburger(false) }} className="mt-[70px] Layout-MainContent" style={{
        marginLeft: Hamburger ? `270px` : '0px',
        transition: '0.2s ease-in'
      }} >
        <div className="Layout-Content-Padding"  >
          {children}
        </div>
      </Content>
    </div>

    {/* <div className="Sidebar-Support" onClick={() => { setHamburger(false) }} style={{
      position: 'fixed',
      width: Hamburger ? `calc(100vw - 270px)` : '0px',
      // background: "#000000d1",
      top: "0px",
      left: `270px`,
      height: "100vh",
      zIndex: '0',
      // filter: "blur(20px)"

    }}>

    </div> */}
  </div >
};
export default Layout;
