import { setPageLoader } from "../../Redux/features/utilsSlice";
import { error } from "../../helpers/notifications";
import { getRequest, postRequest, putRequest } from "../apiMethods";
import { apiRoutes } from "../apiRoutes";

export const getAllDashboardCount = async (dispatch, accessToken,) => {
    dispatch(setPageLoader({ page: 'getAllDashboardCount', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.dashboard.getDashboardOrderStatusAndItems, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getAllDashboardCount', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }

}

export const getProductRecomdentaionProduct = async (dispatch, accessToken,) => {
    dispatch(setPageLoader({ page: 'getProductRecomdentaionProduct', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.dashboard.onProductRecomdentaionProduct, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getProductRecomdentaionProduct', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }

}

export const getRecurringProduct = async (dispatch, accessToken,) => {
    dispatch(setPageLoader({ page: 'getRecurringProduct', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.dashboard.recurringProduct, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getRecurringProduct', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }

}


export const getDashboardBarChartData = async(dispatch, accessToken,) => {
    dispatch(setPageLoader({ page: 'getDashboardBarChartData', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes?.dashboard?.getDashboardData, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getDashboardBarChartData', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }

}

