import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, Modal, Row, Select } from "antd";
import Layout from "../../Layout/Layout";
import Appbar from '../../Components/Appbar/Appbar'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Colors } from "../../Assets/Colors";
import Upload from "../../Components/Upload/Upload";
import { CloudDownloadOutlined, DeleteOutlined, LinkOutlined, LockOutlined } from "@ant-design/icons";
import ClientQuotesTable from "../../Components/Tables/Clientquotes";
import { SubmitOrder, UpdateStatusOrder, deleteBulkOrderItem } from "../../API-Interaction/api-functions/order.api";
import { useDispatch, useSelector } from "react-redux";
import { error, success } from "../../helpers/notifications";
import { getDateInProperFormat } from "../../helpers/helper";
import InvoiceGenerator from "../../Components/InvoiceGenerator/InvoiceGenerator";
import { getInvoiceById } from "../../API-Interaction/api-functions/invoice.api";
import dayjs from 'dayjs';
import locale from 'antd/locale/zh_CN';
import moment from "moment";
import ConfirmationModal from "../../Components/Modals/Confirmation";
import DeliveryChallanGenerator from "../../Components/InvoiceGenerator/DeliveryChallanGenerator";
import ParkingSlipGenerator from "../../Components/InvoiceGenerator/PakingSlipGenerator";
import { getAllInvoices } from "../../API-Interaction/api-functions/invoice.api";
import InvoiceGeneratorOrder from "../../Components/InvoiceGenerator/InvoiceGeneratorOrder";


const ClientQuote = () => {
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const { id } = useParams()
    const location = useLocation();
    let token = localStorage.getItem('authTokenHaasil')
    let navigate = useNavigate()
    let dispatch = useDispatch()
    const [view, setView] = useState("Grid")
    const [ViewState, setViewState] = useState('')
    const [adminRemarks, setAdminRemarks] = useState('')
    const [Data, setData] = useState([])
    const [InvoiceData, setInvoiceData] = useState()
    const [allData, setAllData] = useState("")
    console.log("🚀 ~ allData:", allData)
    const [additonalRemarks, setAdditonalRemarks] = useState('')
    const [selectedProduct, setselectedProduct] = useState([])
    const [Documents, setDocuments] = useState([])
    const [PoDate, setPoDate] = useState()
    const [AddressDetail, setAddressDetail] = useState('')
    const [CompanyDetails, setCompanyDetails] = useState([])
    const [Open, setOpen] = useState(false)
    const [preferredDate, setPreferredDate] = useState('')
    const [invoiceDetails, setInvoiceDetails] = useState({})
    const [orderDetails, setOrderDetails] = useState({})
    const [setAllItems, setsetAllItems] = useState([])


    const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
    const [form] = Form.useForm();
    const { Option } = Select;
    const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);
    const [isParkingModalVisible, setIsParkingModalVisible] = useState(false);
    const [isDeliveryModalVisible, setIsDeliveryModalVisible] = useState(false);


    const showModalStatus = () => {
        setIsChangeStatusModalOpen(true);
    };


    //invoive
    const openInvoiceModal = () => {
        setIsInvoiceModalVisible(true);
    };


    const HandleModalInvoice = () => {
        setIsInvoiceModalVisible(!isInvoiceModalVisible)
    }
    //Delivery
    const openDeliveryModal = () => {
        setIsDeliveryModalVisible(true);
    };

    const fetchInvoice = async (type = 'invoice') => {
        // const response = await getInvoiceById(dispatch, token, id)
        setInvoiceDetails({
            Order: { ...allData }
        })

        if (type == 'invoice')
            setIsInvoiceModalVisible(true);
        if (type == 'parking')
            setIsParkingModalVisible(true);
        if (type == 'delivery')
            setIsDeliveryModalVisible(true);

        console.log({ allData })
        // if (response.success) {
        //     setInvoiceDetails(response?.allData)
        // }

    }


    const HandleModalDelivery = () => {
        setIsDeliveryModalVisible(!isDeliveryModalVisible)
    }
    //parking
    const openParkingModal = () => {
        setIsParkingModalVisible(true);
    };

    const HandleModalParking = () => {
        setIsParkingModalVisible(!isParkingModalVisible)
    }

    const handleStatusOk = () => {
        form.validateFields()
            .then((values) => {
                // Make an API request using the fetch function
                UpdateStatusOrder(dispatch, token, id, values) // Pass 'id' as the orderId
                    .then((response) => {
                        // Handle the API response as needed
                        console.log('API Response:', response);
                        setIsChangeStatusModalOpen(false);
                        window.location.reload()
                    })
                    .catch((error) => {
                        console.error('API Request Error:', error);
                    });
            })
            .catch((errorInfo) => {
                console.log('Validation Failed:', errorInfo);
            });
    };

    const handleStatusCancel = () => {
        setIsChangeStatusModalOpen(false);
    };
    const HandleConfirmOrder = async (values) => {
        let date = PoDate
        let result = await SubmitOrder(dispatch, token, id, {
            orderRemarks: additonalRemarks,
            status: 'OC',
            documents: Documents,
            purchaseOrder: values?.poNumber,
            purchaseDate: date
        })
        if (result?.success) {
            HandlePrintInvoice(result?.response?.invoice?.invoiceId)
            // setModal(true)

        }
        if (result?.success) {
            HandlePrintInvoice(result?.response?.invoice?.invoiceId)
        }




    }
    const trackRFIDGeneration = (rfidValue) => {
        console.log("Tracking RFID Generation Event:", rfidValue);

        // Initialize gtag function
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }

        // Track the RFID generation event
        gtag('event', 'rfid_generation', {
            'event_category': 'rfid',
            'event_label': rfidValue,
        });
    };
    const HandleDate = (date, dateString, type) => {
        setPoDate(dateString)
    };

    const HandleModall = () => {
        if (Open) return navigate('/quotes')
        setOpen(!Open)
    }

    const HandlePrintInvoice = async (id) => {
        const response = await getInvoiceById(dispatch, token, id)
        if (response.success) {
            setInvoiceData(response?.response?.order[0])
            HandleModall()
        }
    }

    const FomatDate = () => {
        if (allData) {
            if (allData?.deliveryDate) {
                const formattedDate = moment(allData?.deliveryDate).format('DD-MM-YYYY');
            }
        }
    }


    const bulkDelete = async () => {
        const result = await deleteBulkOrderItem(dispatch, token, id, selectedProduct)
        if (result.success) {
            success('Item successfully deleted!')
            setselectedProduct([])

        }
    }
    useEffect(() => {
        const getQueryFromUrl = () => {
            const params = new URLSearchParams(location.search);
            return params.get('type') || '';
        };
        const urlQuery = getQueryFromUrl();
        setViewState(urlQuery)
    }, [])

    const HandleConfirmationModal = () => {
        if (Open) return navigate('/quotes')
        setOpen(!Open)
    }
    useEffect(() => {

        FomatDate()
    }, [allData])



    return (
        <Layout>
            <Form className=" mb-10"
                name="ConfirmOrder"
                onFinish={HandleConfirmOrder}
            >
                <Row className="w-full">
                    <Col md={20}>
                        <Appbar Heading={"Order Details"} view={view} setView={setView} ExtraDetail={true} />
                    </Col>
                    <Col md={4}>
                        <h1 style={{ fontWeight: 'bold', marginTop: '10px', fontSize: '20px' }}>Quotation Id: {Data[0]?.orderId}</h1>

                    </Col>
                    <Divider className="mt-[10px]" />
                </Row>
                <Row className="flex justify-between w-full">
                    <Col lg={5} xs={24}>
                        <p
                            className={`  text-[${Colors.grayTxt}] text-[11px] font-bold `}
                        >
                            Your Order Details
                        </p>

                    </Col>
                </Row>

                <Row className="w-full">
                    {CompanyDetails &&
                        <Row className="w-full">
                            <Col lg={7} xs={12} className="flex justify-end  p-1 relative overflow-hidden">
                                <div className={`p-3 flex flex-col gap-3 w-full bg-[white] rounded-[5px] text-[black] text-[12px] h-fit
                                            overflow-hidden
                                            shadow-[rgba(149, 157, 165, 0.2) 0px 8px 24px] text-[${Colors.darkBlue}]
                                            `} style={{
                                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
                                    }}

                                >
                                    {/* <div className="flex justify-between items-center"> */}
                                    <p>
                                        <span className="font-[800]  ">Title: </span> {AddressDetail?.addressTitle}
                                    </p>
                                    <div className="flex justify-between">
                                        <Col>
                                            <p>
                                                <span className="font-[800]  ">Name:</span>{AddressDetail?.fullname}
                                            </p>
                                        </Col>
                                        <Col>
                                            {/* </div> */}
                                            <p>
                                                <span className="font-[800]  ">City: </span>{AddressDetail?.city}
                                            </p>
                                        </Col>
                                    </div>

                                    <p>
                                        <span className="font-[800]  ">Address </span> {AddressDetail?.address}
                                    </p>
                                    <div className="flex justify-between">
                                        {/* </div> */}
                                        <Col>
                                            <p>
                                                <span className="font-[800]  ">Phone:</span> {AddressDetail?.number}
                                            </p></Col>
                                        <Col>
                                            {AddressDetail?.zipcode &&

                                                <p>
                                                    <span className="font-[800]  ">ZipCode:</span> {AddressDetail?.zipcode}
                                                </p>
                                            }
                                        </Col>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={7} xs={12} className="flex justify-end relative overflow-hidden mb-[6px] " style={{ boxShadow: " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}>

                                <div className={` bg-[${''}] 
                                        text-[${Colors.darkBlue}]
                                         
                                         p-3 flex flex-col
                                          gap-3 w-full rounded-[5px] 
                                          text-[black]
                                        text-[12px]
                                            shadow-[ rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`} >
                                    <p>
                                        <span className="font-[800]  ">Company Name: </span> {CompanyDetails?.Company?.name}
                                    </p>
                                    <p>
                                        <span className="font-[800]  ">Address:</span> {CompanyDetails?.Company?.address}
                                    </p>
                                    {/* <div className="flex justify-between"> */}
                                    <p>
                                        <span className="font-[800]  ">City: </span> {CompanyDetails?.Company?.city}
                                    </p>
                                    <div className="flex justify-between">
                                        <Col>
                                            <p>
                                                <span className="font-[800]  ">NTN : </span>{CompanyDetails?.Company?.ntn || 'N/A'}
                                            </p>
                                        </Col>
                                        {/* </div> */}
                                        <Col>
                                            <p>
                                                <span className="font-[800]  ">STN : </span>{CompanyDetails?.Company?.stn || 'N/A'}
                                            </p>
                                        </Col>
                                    </div>
                                </div>
                            </Col>

                            {
                                preferredDate &&
                                <Col lg={9} xs={24} className="flex ml-1 flex-col py-1 gap-3  px-3 mb-[6px]" style={{ boxShadow: " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", height: '145px' }}>
                                    <div className="flex flex-col gap-3">
                                        <p className="flex gap-[30px] items-center px-2 font-semibold text-[16px] justify-between" style={{ borderRight: "1px solid lightgray" }}>
                                            Preferred Delivery Date:
                                            <DatePicker defaultValue={preferredDate ? dayjs(preferredDate, 'YYYY-MM-DD') : ""} format="DD-MM-YYYY" disabled={true} />
                                        </p>
                                        <p className="flex gap-[30px] items-center px-2 font-semibold text-[16px] justify-between" style={{ borderRight: "1px solid lightgray" }}>
                                            Delivery Date:
                                            <DatePicker
                                                defaultValue={allData?.deliveryDate ? dayjs(allData?.deliveryDate, 'YYYY-MM-DD') : ""} format="DD-MM-YYYY" disabled={true} />
                                        </p>

                                    </div>
                                </Col>
                            }
                        </Row>
                    }
                    {/* Admin Delivery Date */}
                </Row>



                {/* Quotes Table */}
                <div className="w-full bg-[#f4f6fc]" >
                    <ClientQuotesTable
                        setsetAllItems={setsetAllItems}
                        selectedProduct={selectedProduct}
                        setPreferredDate={setPreferredDate}
                        setAllData={setAllData}
                        viewType={ViewState}
                        setAdminRemarks={setAdminRemarks}
                        HandleSaveData={setData}
                        selectedOrderId={setselectedProduct}
                        setAddressDetail={setAddressDetail}
                        setCompanyDetails={setCompanyDetails}
                        bulkDelete={bulkDelete}
                        setOrder={setOrderDetails}
                    />
                </div>








                {/* Admin Comments */}
                {
                    adminRemarks && <div className="mt-2 flex items-center justify-between">
                        <div >
                            <p className={`font-bold text-[20px] text-[${Colors.darkBlue}] `}
                            >
                                Admin Remarks:
                            </p>
                            <p
                                className={`border-none text-[12px] py-[3px] text-[${Colors.grayTxt}] `}
                            >{adminRemarks}</p>
                        </div>

                    </div>
                }
                {/* Admin Attached Documents  */}

                <Row>
                    <Col md={20}>     {
                        allData.adminDocuments &&
                        <div className="my-5 flex  flex-col">
                            <p className={`font-bold mb-3 text-[20px] text-[${Colors.darkBlue}]`} >
                                Admin  Attached Documents:
                            </p>
                            <div className="flex  gap-3" >
                                {
                                    allData.adminDocuments?.map((item, index) => {
                                        return (
                                            <a href={item} style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} className="h-[100px] cursor-pointer w-[100px] bg-[#f6f6f6] flex justify-center items-center  flex-col">
                                                <CloudDownloadOutlined className={`text-[30px] text-[#4ed4ff] `} />
                                                <p className="text-[10px]">Download File</p>

                                            </a>
                                        )
                                    })

                                }
                            </div>

                        </div>
                    }
                    </Col>
                    <Col>
                        <div>
                            {/* {QuotationStatus === 'QP' || QuotationStatus === 'RFQ' ? ( */}
                            {allData?.status === 'QP' && `${allData?.User?.role}` !== '1' && (
                                <Button
                                    className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[2px] font-[600] text-[13px] py-[0px] px-[35px] mt-4`}
                                    onClick={showModalStatus}
                                >
                                    Change Status
                                </Button>
                            )}

                        </div>
                    </Col>
                </Row>

                {/* Client Attached Documents  */}
                {
                    allData?.orderDocuments &&
                    <div className="my-5 flex  flex-col">
                        <p className={`font-bold mb-3 text-[20px] text-[${Colors.darkBlue}]`} >
                            Your Attached Documents:
                        </p>
                        <div className="flex  gap-3" >
                            {
                                allData?.orderDocuments?.map((item, index) => {
                                    return (
                                        <a href={item} style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} className="h-[100px] cursor-pointer w-[100px] bg-[#f6f6f6] flex justify-center items-center  flex-col">
                                            <CloudDownloadOutlined className={`text-[30px] text-[#4ed4ff] `} />
                                            <p className="text-[10px]">Download File</p>

                                        </a>
                                    )
                                })

                            }
                        </div>

                    </div>
                }

                {
                    allData?.status === 'OC' &&
                    <>
                        <div>
                            <Button
                                onClick={() => fetchInvoice('delivery')}
                                className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[2px] font-[600] text-[13px] py-[0px] px-[35px] mt-[6px]`}
                            >
                                Delivery Slip
                            </Button>
                            <DeliveryChallanGenerator open={isDeliveryModalVisible} close={HandleModalDelivery} data={invoiceDetails} />
                        </div>
                        <div>
                            <Button
                                onClick={() => fetchInvoice('parking')}
                                className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[2px] font-[600] text-[13px] py-[0px] px-[35px] mt-[6px]`}
                            >
                                Packing Slip
                            </Button>
                            <ParkingSlipGenerator open={isParkingModalVisible} close={HandleModalParking} data={invoiceDetails} />
                        </div>
                        <div>
                            <Button
                                // onClick={openInvoiceModal}
                                onClick={() => fetchInvoice('invoice')}
                                className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[2px] font-[600] text-[13px] py-[0px] px-[35px] mt-[6px]`}
                            >
                                Invoice
                            </Button>
                            <InvoiceGeneratorOrder open={isInvoiceModalVisible} close={HandleModalInvoice} data={invoiceDetails} />
                        </div>
                    </>
                }


                {/*Po Details  */}
                {ViewState !== 'view' && <div>
                    <div>
                        <p className="bold-heading">
                            Enter PO Number:
                        </p>
                        <Row >
                            <Col span={3}>
                                <Form.Item name='poNumber'>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <p className="bold-heading" >
                        Enter PO Date:
                    </p>
                    <Row >
                        <Col span={3}>
                            <Form.Item className="w-full" name="PoDate">
                                <DatePicker className="w-full" onChange={(date, dateString) => HandleDate(date, dateString, 'Po date')} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider className="m-[2px]" />
                </div>}

                {/* Client text Area */}
                {
                    ViewState !== 'view' &&
                    <div className="mt-5">
                        <Input.TextArea
                            onChange={(event) => { setAdditonalRemarks(event.target.value) }}
                            className="border-none text-[12px] py-[20px] px-[15px] bg-[#e9eff6]"
                            rows={1}
                            placeholder="Add Notes Here.."
                        />
                    </div>
                }

                {
                    ViewState !== 'view' &&
                    <div className="flex justify-between mt-3 items-center">
                        <div className="flex flex-col">
                            <Upload setDocuments={setDocuments} id={id} />
                            {Documents.length !== 0 &&
                                <div>
                                    {Documents.map((item, index) => {
                                        return (
                                            <div className="flex justify-between ">
                                                <div className="flex items-center gap-2">
                                                    <LinkOutlined className="text-[#1677ff]" />
                                                    <a className="text-[#1677ff] cursor-pointer" href={item} >{item}</a>
                                                </div>
                                                <div>
                                                    <Button className="shadow-none border-none" onClick={() => { setDocuments([]) }}>
                                                        <DeleteOutlined className="text-[red] " />
                                                    </Button>
                                                </div>
                                            </div>

                                        )
                                    })}
                                </div>
                            }
                        </div>
                        <div className="flex gap-3">

                            {/* <Button htmlType="submit"
                                disabled={pageLoader.uploadDocument || false} loading={pageLoader?.SubmitOrder}
                                className={`border-none  bg-[${Colors.darkBlue}] text-[white] rounded-[5px] font-[600] text-[13px]`}>
                                Confirm
                            </Button> */}
                            <Button
                                htmlType="submit"
                                disabled={pageLoader.uploadDocument || false}
                                loading={pageLoader.SubmitOrder}
                                onClick={() => {
                                    trackRFIDGeneration('GeneratedRFID123'); // Track RFID generation here
                                }}
                                className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[5px] font-[600] text-[13px]`}
                            >
                                Confirm
                            </Button>

                        </div>
                    </div>
                }

            </Form>
            {/* <ConfirmationModal open={Modal} close={HandleConfirmationModal} /> */}
            <InvoiceGenerator open={Open} close={HandleModall} data={InvoiceData} />

            <Modal title="Change Status" visible={isChangeStatusModalOpen} onOk={handleStatusOk} onCancel={handleStatusCancel}
                okButtonProps={{ style: { background: 'black', borderColor: 'black', color: 'white' } }} // Set button styles here
            >
                <Form form={form}>
                    <Form.Item
                        name="status"
                        label="Select Status"
                        className="mb-[40px]"

                        rules={[
                            {
                                required: true,
                                message: 'Please select a status',
                            }
                        ]}
                    >
                        <Select>
                            <Option value="OC"> Order Confirmed</Option>
                            <Option value="OR">Order Recieved</Option>
                        </Select>

                    </Form.Item>
                </Form>
            </Modal>
        </Layout >
    )
}

export default ClientQuote