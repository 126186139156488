import React from 'react'
import { Button, Col, Dropdown, Image } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import { Images } from '../../Assets/Images/Images';
import { Colors } from '../../Assets/Colors';

const items = [
    {
        key: '1',
        label: (
            <a> Option one</a>
        ),
    },
    {
        key: '2',
        label: (
            <a> Option Two</a>
        ),
    },
    {
        key: '3',
        label: (
            <a> Option Three</a>

        ),
    },
];
const Appbar = ({ Heading, view, setView, ExtraDetail }) => {

    return (
        <>
            <div className="flex justify-between w-full items-center">
                <p className="font-bold header-heading">
                    {Heading}
                </p>
            
            </div>

        </>
    )
}

export default Appbar