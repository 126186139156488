import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Dropdown, Image, Row, Space, Spin, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Colors } from "../../Assets/Colors";
import { Images } from "../../Assets/Images/Images";
import { getProductCategory } from "../../API-Interaction/api-functions/category.api";
import { useDispatch, useSelector } from "react-redux";


const ProductSectionHeader = ({ setCurrentPage, view, setView, setcategoryID, Heading, parentCategoryId, count }) => {
  const [items, setitems] = useState([])
  const { pageLoader } = useSelector(state => state.utils.loaders)
  const [allCategory, setallCategory] = useState([])
  const [SelectedCategory, setSelectedCategory] = useState('All Products')

  const dispatch = useDispatch()
  const token = localStorage.getItem('authTokenHaasil')
  const getAllProductCategory = async () => {
    const result = await getProductCategory(dispatch, token, parentCategoryId)
    if (result.success) {
      setallCategory(result?.response?.productCategory)
      setCategories(result?.response?.productCategory)
    }
  }

  const setCategories = (data) => {
    let items = data.map((item, index) => {
      return {
        key: item?.categoryId,
        index: index,
        label: item?.name
      }
    })
    setitems([{ index: -1, key: "", label: "All Products" }, ...items])
  }


  const onClick = (key) => {
    setCurrentPage(1)
    setcategoryID(key?.key)
    const CategoryName = allCategory.filter((category, index) => { if (category.categoryId == key?.key) { return category } })
    setSelectedCategory(CategoryName.length !== 0 ? CategoryName[0]?.name : 'All Products')
  };
  useEffect(() => {
    getAllProductCategory()
    setSelectedCategory('All Products')
    setcategoryID('')
  }, [parentCategoryId])
  return (
    <>
      <Row className="flex justify-between w-full items-center gap-2">
        <Col xs={24} lg={10} className=" font-bold  header-heading"  >
          {Heading}
        </Col>

        <Col xs={24} lg={12} className="flex gap-2 items-center justify-end">

          <p className="support-heading whitespace-nowrap" style={{ color: Colors.grayTxt }}>Filter By:</p>
          <Spin spinning={pageLoader.getProductCategory || false}>
            <Dropdown
              className="flex items-center gap-3 z-0 whitespace-nowrap"
              style={{
                height: "200px",
                overFlow: 'hidden',

              }}

              menu={
                { items, onClick, selectable: true }
              }

              placement="bottomRight"
              trigger={["click"]}
            >

              <button
                style={{
                  fontSize: "12px",
                  border: "1px solid lightgray",
                  padding: "3px 9px",
                }}
                className="text-gray-500   "
              >
                Category: {SelectedCategory}
                <DownOutlined style={{ fontSize: "12px", padding: "0px" }} />
              </button>
            </Dropdown>
          </Spin>

          <Image
            onClick={() => { setView('Table') }}
            className="cursor-pointer"
            src={view === 'Table' ? Images.TableViewActive : Images.TableViewNonActive}
            preview={false}
            width="25px"
          />
          <Image
            onClick={() => { setView('Grid') }}
            src={view === 'Grid' ? Images.GridViewActive : Images.GridViewNonActive}
            className="cursor-pointer"
            preview={false}
            width="25px"
          />
          <Image
            onClick={() => { setView('AmazaonGridView') }}
            src={view === 'AmazaonGridView' ? Images.ThumbIconSelected : Images.ThumbIcon}
            className="cursor-pointer"
            preview={false}
            width="25px"
          />
        </Col>

      </ Row>
    </>
  );
};

export default ProductSectionHeader;
