import React, { useEffect, useState } from "react";
import { Images } from "../Assets/Images/Images";
import { Colors } from "../Assets/Colors";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Drawer,
  Popover,
  Row,
  Spin,
  notification,
} from "antd";
import { HiOutlineBell } from "react-icons/hi"
import {
  CloseOutlined,
  EyeOutlined,
  MenuOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Link, json, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import { Link, useNavigate } from "react-router-dom";
import { pageRoutes } from "../Routes/pageRoutes";
import { success } from "../helpers/notifications";
import { resetCart } from "../Redux/features/cartSlice";
import { getCountNotifications, getNotifications } from "../API-Interaction/api-functions/Notification.api";

function Header({ setHamburger, Hamburger }) {
  const ProfileImage = localStorage.getItem('HaasilUserImage')
  const CompanyDetails = localStorage?.getItem('CompanyDetails')
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  let Role = localStorage.getItem('Role')
  const cart = useSelector((state) => state?.cart?.cartItems);
  const [loading, setLoading] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const dispatch = useDispatch()
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  const token = localStorage.getItem('authTokenHaasil')

  const fetchCount = async (id) => {

    setLoading(true);

    try {

      const count = await getCountNotifications(id, token)
      setNotificationCount(count)

    }
    catch (error) {
      console.error('Error fetching notification count:', error);
    }
    finally {
      setLoading(false);
    }
  };

  const fetchNotificationCount = async (id) => {

    setLoading(true);

    try {
      const apis = [getNotifications(id, token), getCountNotifications(id, token)]
      const [notificationData, count] = await Promise.all(apis)
      setNotificationCount(count)
      setNotifications(notificationData)
    }
    catch (error) {
      console.error('Error fetching notification count:', error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleNotificationClick = async (notification) => {
    const { orderId, page, notificationStatus } = notification;

    onClose();

    try {
      if (notificationStatus && notificationStatus.toLowerCase() === 'rfq') {
        console.log('route to qutations')
        navigate(`/admin/quotations/${orderId}`);
      } else if (notificationStatus && notificationStatus.toLowerCase() === 'qp') {
        console.log('route to quotes')

        navigate(`/quotes/${orderId}`);
      } else {
        console.log('route nhi hioga')

      }
    } catch (error) {
      console.error('Error handling notification click:', error);
      // Handle the error as needed
    }
  };
  useEffect(() => {
    fetchCount()
  }, []);

  const showDrawer = () => {
    setOpenDrawer(true);
    fetchNotificationCount()
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div
      className="w-full flex justify-center items-center  h-[100%] px-3"
      style={{ background: Colors.darkBlue, }}
    >

      {/* Hamburger */}
      <div className="flex items-center justify-center  h-full Hamburger ">
        <Button onClick={() => { setHamburger(!Hamburger) }} style={{ display: "flex" }} className="h-full p-0 border-none text-white text-[20px] flex justify-center items-center">
          {
            Hamburger ?
              <CloseOutlined />
              :
              <MenuOutlined className="HeaderItems" />
          }
        </Button>
      </div>




      <div className="flex w-full justify-between items-center gap-10 px-1 ml-5">
        <img
          onClick={() => {
            navigate("/dashboard");
          }}
          className="cursor-pointer  object-contain HeaderLogo"
          src={Images.Logo}
          alt=""

        />
        <div
          // onClick={HandleModal}
          className=" flex-col hidden text-[10px] md:flex xs:hidden"
        >
          <p
            className="p-0 whitespace-nowrap text-[14px] "
            style={{ letterSpacing: "2px" }}
          >
            {Role == 2 ? " Admin" : CompanyDetails}
          </p>
          <p className="p-0 whitespace-nowrap  text-[9px]">
            {" "}
            Welcome to Haasil Dashboard
          </p>
        </div>




        <div className="flex items-center gap-4 w-full justify-end">
          {/* Cart Icon */}
          {
            Role == '1' &&
            <Button
              className="p-0 border-none"
              onClick={() => {
                navigate("/add-to-cart");
              }}
            >
              <Link to={"/add-to-cart"}>
                <Badge className="h-full flex justify-center items-center mt-1" count={Object.keys(cart).length || "0"}>
                  <ShoppingCartOutlined
                    className="cursor-pointer HeaderItems "
                    style={{ color: "white" }}
                  />
                </Badge>
              </Link>
            </Button>


          }

          {/* User Profile */}
          <Button
            className="flex items-center h-full cursor-pointer bg-white rounded-full p-[5px]"
            onClick={showDrawer}
          >
            <Badge count={notificationCount} color="" size="default">
              <HiOutlineBell color="#939393" size={20} />
            </Badge>

          </Button>
          <div
            className="flex items-center h-full cursor-pointer"
            onClick={() => {
              // navigate("/viewprofile");
            }}
          >
            <Popover
              placement="bottomLeft"
              style={{ width: "90px" }}
              content={
                <div
                  className="flex flex-col  gap-1 w-full "
                  style={{ fontSize: "12px" }}
                >
                  <Button
                    onClick={() => {
                      navigate("/profile");
                    }}
                    className="p-1 flex items-center w-full cursor-pointer border-none shadow-none justify-center rounded-md text-gray-500 text-xs transition-colors duration-300 hover:bg-gray-100 hover:text-black active:bg-gray-400 active:text-white"
                    style={{
                      borderRadius: "5px",
                      color: Colors.grayTxt,
                      fontSize: "12px",
                      width: "100px"
                    }}
                  >

                    Profile
                  </Button>
                  <Divider style={{ margin: "0px" }} />
                  <Button
                    onClick={() => {
                      navigate("/manage-address");
                    }}
                    className="p-1 flex items-center w-full cursor-pointer border-none shadow-none justify-center rounded-md text-gray-500 text-xs transition-colors duration-300 hover:bg-gray-100 hover:text-black active:bg-gray-400 active:text-white"
                    style={{
                      borderRadius: "5px",
                      color: Colors.grayTxt,
                      fontSize: "12px",
                      width: "100px",
                    }}
                  >

                    Address
                  </Button>
                  <Divider style={{ margin: "0px" }} />
                  <Button
                    onClick={() => {

                      localStorage.clear()
                      dispatch(resetCart())
                      navigate(pageRoutes?.public?.login)
                      success("Logged Out")
                    }}
                    className="p-1 flex items-center w-full cursor-pointer border-none shadow-none justify-center rounded-md text-gray-500 text-xs transition-colors duration-300 hover:bg-gray-100 hover:text-black active:bg-gray-400 active:text-white"
                    style={{
                      borderRadius: "5px",
                      color: Colors.grayTxt,
                      fontSize: "12px",
                      width: "100px",
                    }}
                  >

                    Logout
                  </Button>
                </div>
              }
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              {/* <Button type="primary">Click me</Button> */}
              <Avatar src={<img src={ProfileImage ? ProfileImage : Images.DisplayPic} alt="avatar" />} />
            </Popover>
          </div>
          {/* Notification */}

         




          <Drawer title="Notification" placement="right" onClose={onClose} open={openDrawer}>
            <div style={{ width: '300px', padding: '' }}>
              {loading ? (
                <div className="text-center mt-2">
                  <Spin size="large" />
                </div>
              ) : (
                // Render notifications when data is loaded
                notifications?.map(({ text, orderId, page }, index) => (
                  <div key={index}>
                    <Row>
                      <Col md={3}>
                        <p>
                          <HiOutlineBell color="#939393" size={22} />
                        </p>
                      </Col>
                      <Col md={20}>
                        <p>{text}</p>
                      </Col>
                      <Col md={1}>
                        {(notifications?.Order?.status === "rfq" || notifications?.Order?.status === "qp") ? (
                          <EyeOutlined onClick={() => handleNotificationClick({ orderId, page, notificationStatus: notifications?.Order?.status })} />
                        ) : null}
                      </Col>
                    </Row>
                    <Divider />
                  </div>
                ))
              )}
            </div>
          </Drawer>





        </div>
      </div>
    </div >
  );
}

export default Header;
