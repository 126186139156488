// import { error, success } from "@/helpers/notifications";
import { error, success } from "../../helpers/notifications"
import { login, verification } from "../../Redux/features/authSlice";
import { setPageLoader } from "../../Redux/features/utilsSlice";

import { deleteRequest, getRequest, postRequest, putRequest } from "../apiMethods";
import { apiRoutes } from "../apiRoutes";
const { address } = apiRoutes;




export const getNotifications = async (id, accessToken, type = null) => {

  try {

    let response = await getRequest({ endPoint: apiRoutes?.notification?.get, loggedInToken: accessToken ,
      params: {
        limit: 100
      }})

    if (!response?.success)
      throw new Error(response?.response);

    return response?.response?.notifications

  } catch (error) {
    console.error("Error fetching notification count:", error);
    throw error; // Re-throw the error to handle it outside this function
  }
};

export const getCountNotifications = async (id, accessToken, type = null) => {

  try {

    let response = await getRequest({ endPoint: apiRoutes.notification.countNotification, loggedInToken: accessToken,
      params: {
        limit: 100
      }} )


    if (!response?.success)
      throw new Error(response?.response);

    return response?.response?.count || 0;

  } catch (error) {
    console.error("Error fetching notification count:", error);
    throw error; // Re-throw the error to handle it outside this function
  }
};



const readNotificationId = async (dispatch) => {
  dispatch(setPageLoader({ page: "markAsRead", flag: true }))

  let response = await putRequest({
    endPoint: apiRoutes.notification.readNotificationId,
    loggedInToken: localStorage.getItem("authToken-RTAP")
  })
  dispatch(setPageLoader({ page: "", flag: false }))

  if (response?.success === true) return
  error(response.response)
}

export { }

const onMarkAsRead = async (dispatch) => {
  dispatch(setPageLoader({ page: "markAsRead", flag: true }))

  let response = await putRequest({
    endPoint: apiRoutes.notification.readallNotification,
    loggedInToken: localStorage.getItem("authToken-RTAP")
  })
  dispatch(setPageLoader({ page: "", flag: false }))

  if (response?.success === true) return
  error(response.response)
}

export { }




export { }