export const Colors = {
  darkBlue: "#061E3A",
  brightBlue: "#0085FF",
  textBg: "#E9EFF6",
  sideTxt: "#051D39",
  statusBox: "#F7F7F7",
  tableBg: "#F6F6F6",
  grayBg: "#F7F7F7",
  grayTxt: "#939393",
  Lightblue: "#0A789A",
  inputBg: "#E9EFF6",
  borderColor: "#26045D",
  highlightedColor: "#D5F6FC",
  highlightedBorderColor: "#4ED4FF",
  blueLink: "#0E7A9E",
  CoolLightBlue: "#ecf2ff",
  CoolDarkBlue: '#0c1830',
  InvoiceBg: "#e5e7e8"

};
