import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Input, Modal, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, } from 'antd';
import { formErrors } from '../../../constant';
import { createCategory } from '../../API-Interaction/api-functions/category.api';
import { useDispatch, useSelector } from 'react-redux';
import { error, success } from '../../helpers/notifications';



const ManageCategoriesModal = ({ open, close }) => {
    const dispatch = useDispatch()
    const [SelectedCategory, setSelectedCategory] = useState('')
    const [ParentCategory, setParentCategory] = useState('')
    const [reloadPage, setReloadPage] = useState(false); 

    const token = localStorage.getItem('authTokenHaasil')
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const categories = [

        {
            label: <a onClick={() => setSelectedCategory({
                level: "L2", name: "Deep Category"
            },)}>Deep Category</a>,
            key: '1',
        },
        {
            label: <a onClick={() => setSelectedCategory({
                level: "L3", name: "Storable"
            },)}>Storable</a>,
            key: '2',
        },
        {
            label: <a onClick={() => setSelectedCategory({
                level: "L4", name: "Product Type"
            },)}>Product Type</a>,
            key: '3',
        },
        {
            label: <a onClick={() => setSelectedCategory({
                level: "L5", name: "Manufacturer"
            },)}>Manufacturer</a>,
            key: '4',
        },
        {
            label: <a onClick={() => setSelectedCategory({
                level: "L6", name: "Brand"
            },)}>Brand</a>,
            key: '5',
        },
        {
            label: <a onClick={() => setSelectedCategory({
                level: "L7", name: "Variant"
            },)}>Variant</a>,
            key: '6',
        },
        {
            label: <a onClick={() => setSelectedCategory({
                level: "L8", name: "Sub Variant"
            },)}>Sub Variant</a>,
            key: '7',
        },

        {
            label: <a onClick={() => setSelectedCategory({
                level: "L9", name: "Unit of MeasureF"
            },)}>Unit of Measure</a>,
            key: '9',
        },
        {
            label: <a onClick={() => setSelectedCategory({
                level: "L10", name: "Tier"
            },)}>Tier</a>,
            key: '10',
        },

    ];
    const ParentCategories = [
        {
            label: <a onClick={() => setParentCategory({
                id: "1", name: "Daily Essentials"
            },)}>Daily Essentials</a>,
            key: '1',
        },
        {
            label: <a onClick={() => setParentCategory({
                id: "3", name: "Ad-Hoc"
            },)}>Ad-Hoc</a>,
            key: '2',
        },
        {
            label: <a onClick={() => setParentCategory({
                id: "2", name: "Customise Goods"
            },)}>Customise Goods</a>,
            key: '3',
        },

    ];



    const HanleClose = () => {
        setSelectedCategory('')
        close()
    }
    useEffect(() => {
        setParentCategory('')
    }, [SelectedCategory])



    const onFinish = async (values) => {
        if (SelectedCategory) {
            const Data = {
                name: values.name,
                code: values.code ? values.code : null,
                level: SelectedCategory.level
            }
            if (SelectedCategory.name == 'Deep Category' & !ParentCategory) {
                return error('Kindly Select Parent Category!')
            }
            Data.parentCategoryId = ParentCategory.id ? ParentCategory.id : null
            const result = await createCategory(dispatch, token, Data)
            if (result.success) {
                success('Successfully Created!')
                setSelectedCategory('')
                setParentCategory('')
                setReloadPage(true); 
                close();
                
            }
        }
        else {
            return error('Kindly Select Category!')
        }

    }
    useEffect(() => {
        if (reloadPage) {
          window.location.reload();
        }
      }, [reloadPage]);
    
    return (
        <>

            <Modal title="Manage Categories" open={open} footer={null} onCancel={HanleClose}>
                <Form className='flex justify-center mt-[20px] flex-col gap-3'
                    name="login"
                    onFinish={onFinish}
                >
                    {
                        SelectedCategory ?
                            <>
                                <p className='flex justify-center w-full text-[20px] font-semibold'>
                                    {

                                        SelectedCategory ? SelectedCategory.name : ""
                                    }
                                </p>
                                <Divider className='mt-[5px] mb-[5px] ' />
                            </>
                            : " "
                    }

                    <Dropdown
                        className='flex justify-center bg-[#0c18301a] text-[#0c1830] p-[10px] rounded-[10px] w-full text-[12px] font-[700]  Categories-center'
                        menu={{
                            items: categories,
                        }}
                        trigger={['click']}
                    >
                        <p className='cursor-pointer'>
                            <Space>
                                Select Category
                                <DownOutlined />
                            </Space>
                        </p>
                    </Dropdown>
                    {/* If Selected Category is Deep Category */}

                    {
                        SelectedCategory.name == 'Deep Category' &&
                        <>
                            <p className='text-[gray]'>{ParentCategory ? `Parent Category: ${ParentCategory.name}` : "Select Parent Category*"}</p>
                            <Dropdown
                                className='flex justify-center bg-[white] text-[#0c1830] p-[10px] rounded-[10px] w-full text-[12px] font-[700] items-center border-[#0c1830] border-2'
                                menu={{
                                    items: ParentCategories,
                                }}
                                trigger={['click']}
                            >
                                <p className='cursor-pointer'>
                                    <Space>
                                        Select Parent Category
                                        <DownOutlined />
                                    </Space>
                                </p>
                            </Dropdown>
                        </>
                    }



                    <Form.Item className="w-full"
                        name={"name"}
                        rules={[
                            {
                                required: true,
                                message: formErrors?.emptyField,
                            },
                        ]}>

                        <Input size='large' className='w-full' placeholder="Enter name" />
                    </Form.Item>
                    <p className='text-[gray]'>Optional Field*</p>
                    <Form.Item className="w-full"
                        name={"code"}
                    >

                        <Input size='large' className='w-full' placeholder="Enter Code" />
                    </Form.Item>

                    <Divider className='mt-[5px] mb-[5px] ' />
                    <div className='flex justify-end w-full'>
                        <Button loading={pageLoader?.createCategory || false} htmlType='submit' className='bg-[#0c1830] text-[white] '>
                            Create
                        </Button>
                    </div>
                </Form>

            </Modal >
        </>
    );
};
export default ManageCategoriesModal;