import React, { useDebugValue, useEffect, useState } from "react";
import { Col, Divider, Row, Spin } from "antd";
import Layout from "../../../Layout/Layout";
import Appbar from '../../../Components/Appbar/Appbar'
import { useDispatch, useSelector } from "react-redux";
import FilterableData from "./Button/FilterButton";
import ProductCategoryTable from "../../../Components/Admin/Table/ProductCategoryTable";
import { getAllProductCategoryList } from "../../../API-Interaction/api-functions/product.api";
import FilterProductCategory from "./Button/FilterProductCategory";


const ProductCategory = () => {
    const dispatch = useDispatch()
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const [Data, setData] = useState([])
    const token = localStorage.getItem('authTokenHaasil')
    const [total, settotal] = useState(0)


    const getAllProductCategories = async () => {
        let response = await getAllProductCategoryList(dispatch, token)
        if (response.success) {
            settleResponse(response)
        }
    }


    const settleResponse = (response) => {
        settotal(response?.response?.totalCount)
        setData(response?.response?.productCategories)
    }

    useEffect(() => {
        getAllProductCategories()
    }, [pageLoader.Category, pageLoader.FilterCategory])

    return (
        <Layout>
            <Spin spinning={pageLoader?.ProductCategoryTable|| false}>

                <Row className="">
                    <Col span={13} className='flex  flex-col justify-end gap-3 '>
                        <Appbar Heading={'Product Category'} />
                    </Col>
                    <Col span={11} className='flex  flex-col  gap-4 '>
                        <div className='flex gap-3 justify-end'>
                            <Spin spinning={pageLoader?.MainLoading || false}>
                                {/* <FilterableData passData={settleResponse} getData={getAllProductCategories} /> */}
                                <FilterProductCategory passData={settleResponse} getData={getAllProductCategories} />
                            </Spin>
                        </div>
                    </Col>
                </Row>
                <Spin spinning={pageLoader?.FilterProductCategory || pageLoader?.level || false}>
                    <Divider className="mt-1 w-[full]" />
                    <div className="w-full bg-[#f4f6fc]" >
                        <ProductCategoryTable Data={Data} callProducts={getAllProductCategories} total={total} />
                    </div>
                </Spin>
                </Spin>
        </Layout >
    )
}

export default ProductCategory