import { Button, Table } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../Assets/Colors';
import { EyeOutlined, FieldTimeOutlined, CheckOutlined, StopOutlined, StarOutlined, StarFilled, ShoppingCartOutlined, SyncOutlined } from '@ant-design/icons';
import { getFormatedDate } from '../../helpers/getTimeFromDate'
import { getDateInProperFormat, getRemainingTimeInHours } from '../../helpers/helper';
import ReviewModal from '../Modals/ReviewsModal';
import { getQuotationById } from '../../API-Interaction/api-functions/quotation.api';
import { useDispatch, useSelector } from 'react-redux';
import { onAddToCart } from '../../API-Interaction/api-functions/cart.api';
import { success } from '../../helpers/notifications';


const QuotesTable = ({ data }) => {
    const [open, setOpen] = useState(false)
    const [id, setId] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [reorderItems, setReorderItems] = useState([])
    const token = localStorage.getItem('authTokenHaasil')
    const cart = useSelector((state) => state?.cart?.cartItems);

    const getOrderItem = async (id) => {
        const result = await getQuotationById(dispatch, id, token)
        if (result?.response.success) {
            let orderItems = result?.response?.order[0].OrderItems
            orderItems.forEach((item, index) => {
                onAddToCart(item?.Product, item?.quantity, dispatch, cart);

            })
            success('Order Items successfully added to cart')
        }
    }


    const columns = [
        {
            title: 'Order ID',
            dataIndex: 'orderId',
            align: 'center',
            render: (OrderID, data) => {
                let time;
                if (data?.endDate && data?.status !== 'OC') {
                    time = getRemainingTimeInHours(data?.startDate, data?.endDate)
                }
                return time !== 'Quote Expired' ?
                    < Button className='w-[90px]' onClick={() => data.status === 'QP' ? navigate(`/customer-quote/${OrderID} `) : navigate(`/customer-quote/${OrderID}?type=view`)} >
                        {data?.status === 'OC' ? "O-00" : data?.status === "QP" ? "AP-00" : "Q-00"}{OrderID}
                    </Button > : < Button  className='w-[90px]' onClick={() => navigate(`/customer-quote/${OrderID}?type=view`)} >
                        {data?.status === 'OC' ? "O-00" : data?.status === "QP" ? "AP-00" : "Q-00"}{OrderID}
                    </Button >
            }
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            align: 'center',
            render: (Date) => {
                let date = getDateInProperFormat(Date)
                return <p className='font-[800] w-full justify-center' >
                    {date}
                </p>

            }


        },

        {
            title: 'Status',
            key: 'actions',
            dataIndex: 'TotalStatus',
            align: "center",
            render: (text, data, index) => {
                return <div className=' gap-2'>

                    <Button
                        className=' w-[200px] font-[800] rounded-[14px] h-[32px] '
                        style={{
                            border: data?.status === 'OR' && `2px solid ${Colors.darkBlue}`,
                            background: data?.status === 'QP' ? 'darkgreen' : data?.status === 'RFQ' ? "white" : data?.status === 'QRR' ? "red" : data?.status === 'OC' ? Colors.darkBlue : "",
                            color: data?.status === 'QP' ? 'white' : data?.status === 'RFQ' ? ' gray' : data?.status === 'QRR' ? "white" : data?.status === 'OC' ? "white" : Colors.darkBlue
                        }}
                    >{data?.status === 'QP' ? 'Quotation Received' : data?.status === 'RFQ' ? "Requested For Quotation" : data?.status === 'QRR' ? "Quote Rejected" : data?.status === 'OC' ? 'Order Confirmed' : "Order Delivered"}   </Button>

                </div>
            }
        },
        {
            title: 'Expiry Date',
            key: 'actions',
            dataIndex: 'Expiry Date',
            align: "center",
            render: (text, data, index) => {
                let time;
                if (data?.endDate && data?.status !== 'OC') {
                    time = getRemainingTimeInHours(data?.startDate, data?.endDate)
                }
                return <div className='w-full '>
                    {
                        <p className='font-[900] flex justify-center items-center' >{data?.endDate && data?.status !== 'OC' & data?.status !== 'OR' ? time : 'N/A'}</p>
                    }

                </div>
            }
        },
        {
            title: 'Action',
            key: 'actions',
            dataIndex: 'TotalStatus',
            align: "center",
            render: (text, data, index) => {
                let time;
                if (data?.endDate && data?.status !== 'OC') {
                    time = getRemainingTimeInHours(data?.startDate, data?.endDate)
                }
                return <div className='flex justify-center items-center gap-2'>
                    {

                        time !== 'Quote Expired' ?
                            data?.status === 'QP' ? <EyeOutlined className='text-[20px] p-[0px] cursor-pointer ' onClick={() => navigate('/customer-quote/' + data.orderId)}
                                style={{ color: Colors.brightBlue }} />
                                : data?.status === 'RFQ' ? <FieldTimeOutlined className='text-[20px] p-[0px !important] text-[orange]'
                                />
                                    : <CheckOutlined className='text-[20px] p-[0px] text-[limegreen]' />
                            : data?.status == 'QP' ? <StopOutlined className='text-[red] text-[20px]' /> : <CheckOutlined className='text-[20px] p-[0px] text-[limegreen]' />


                    }
                    {/* {
                        data?.status === 'OR' &&
                        <Button onClick={() => { HandleModal(); setId(data?.orderId) }} className='flex justify-center gap-1 items-center font-bold '>
                            Remarks <StarFilled className='text-[orange]' />
                        </Button>
                    } */}
                    <Button onClick={() => { getOrderItem(data?.orderId) }} className='flex items-center ' style={{ background: Colors.darkBlue, color: "white" }} loading={false}>Reorder  <ShoppingCartOutlined /></Button>

                </div>
            }
        }


    ];

    const HandleModal = () => {
        setOpen(!open)
    }
    return (
        <>
            <Table columns={columns} dataSource={data} className='w-[100%] overflow-x-scroll' scroll={{y:360}}/>
            <ReviewModal open={open} close={HandleModal} id={id} />
        </>
    )
}

export default QuotesTable