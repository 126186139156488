export const BarChartData = [
    {
        name: "Furniture",
        color: "8DDFF5"
    },
    {
        name: "Electronics",
        color: "pink"
    },
    {
        name: "Seasonal Fruits",
        color: "#BEEB15"
    },
    {
        name: "General Products",
        color: "#CB4AB8"
    },
    {
        name: "Packaging",
        color: "#853AF8"
    },
    {
        name: "Medicine",
        color: "#3FC577"
    },
    {
        name: "Stationary",
        color: "#C83F0B"
    },
    {
        name: "Janitorial",
        color: "#0085FF"
    },
    {
        name: "Pantry",
        color: "#44A2A2"
    },
    {
        name: "Customized Services",
        color: "#FF0000"
    },
    {
        name: "Office Supplies",
        color: "#21F6AC"
    },
    {
        name: "Hardware",
        color: "green"
    },
    {
        name: "Freshner",
        color: "orange"
    },
    {
        name: "Seasonal Products",
        color: "lightblue"
    },
] 