import { setPageLoader } from "../../Redux/features/utilsSlice"
import { error } from "../../helpers/notifications"
import { postRequest, uploadRequest } from "../apiMethods"
import { apiRoutes } from "../apiRoutes"

export const uploadDocument = async (dispatch, body, token, id) => {
    dispatch(setPageLoader({ page: 'uploadDocument', flag: true }))
    let Form = new FormData()
    Form.append('documents', body)
    let response = await uploadRequest({
        endPoint: apiRoutes.uploadDocument.uploadDocument + `/${id}`,
        loggedInToken: token,
        body: Form,
    })
    dispatch(setPageLoader({ page: 'uploadDocument', flag: false }))
    if (response.status === 413) return error('File Too Large...')
    return response?.data[0].Location

}
export const uploadImage = async (dispatch, body, token,) => {
    dispatch(setPageLoader({ page: 'uploadImage', flag: true }))
    let Form = new FormData()
    Form.append('images', body)
    let response = await uploadRequest({
        endPoint: apiRoutes.uploadProfilePicture.uploadImage,
        loggedInToken: token,
        body: Form,
    })
    dispatch(setPageLoader({ page: 'uploadImage', flag: false }))
    if (response.status === 413) return error('File Too Large...')
    return response?.data[0].Location
}

export const uploadOrderImage = async (dispatch, body, token,) => {
    dispatch(setPageLoader({ page: 'uploadOrderImage ', flag: true }))
    let Form = new FormData()
    Form.append('documents', body)
    let response = await uploadRequest({
        endPoint: apiRoutes.uploadOrderImage.uploadOrder,
        loggedInToken: token,
        body: Form,
    })
    dispatch(setPageLoader({ page: 'uploadOrderImage ', flag: false }))
    if (response.status === 413) return error('File Too Large...')
    if (response.status === 411) return error(response?.message)
    return response?.data[0].Location

}


export const uploadAdminDocument = async (dispatch, body, token, id) => {
    dispatch(setPageLoader({ page: 'uploadAdminDocument ', flag: true }))
    let Form = new FormData()
    Form.append('documents', body)
    let response = await uploadRequest({
        endPoint: apiRoutes.uploadAdminDocument.uploadDocument + `/${id}`,
        loggedInToken: token,
        body: Form,
    })
    dispatch(setPageLoader({ page: 'uploadAdminDocument ', flag: false }))
    if (response.status === 413) return error('File Too Large...')
    return response?.data[0].Location

}