import { Button, Divider, Image, Menu } from "antd";
import React, { useEffect } from "react";
import { Colors } from "../Assets/Colors";
import { SideNavigation } from "./Sidebar.Routes";
import { HomeOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { success } from "../helpers/notifications";
import { pageRoutes } from "../Routes/pageRoutes";
import { MailOutlined, SettingOutlined, AppstoreOutlined } from '@ant-design/icons';
import { useState } from "react";
import SubMenu from "antd/es/menu/SubMenu";
import { useDispatch } from "react-redux";
import { resetCart } from "../Redux/features/cartSlice";


function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Sidebar = ({ sideKey, Hamburger }) => {
  const location = useLocation();
  const [openKeys, setopenKeys] = useState([])
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const Role = localStorage.getItem('Role')

  const onClick = (SidebarRoutes) => {
    navigate(SidebarRoutes.key);

  };

  return (


    <div className="h-full relative" >

      <div className="flex flex-col justify-between h-full px-2" style={{ zIndex: "9999999" }}>
        {
          Role == 1 || Role == 3?
            <>
              <div>
                {SideNavigation.CustomerRoutes.slice(0, 4).map((text, index) => (
                  <div>

                    <Button
                      onClick={() => {
                        navigate(text.path);
                      }}
                      className="flex items-center gap-5 justify-start shadow-none  mt-2 disabled:border-none w-full"
                      key={index}
                      disabled={text?.isDisabled}
                      type="primary"
                      icon={
                        <img
                          src={location.pathname === text.path ? text?.SelectedImage : text?.NonSelectedImage}
                          style={{
                            height: "20px",
                            width: "20px",
                            objectFitl: "contain",
                          }}
                          alt=""
                        />
                      }
                      style={{
                        background: location.pathname === text.path ? Colors.darkBlue : "white",
                        color: location.pathname === text.path ? "white" : Colors.sideTxt,
                        fontSize: "14px",
                        paddingLeft: "10px",
                        padding: "18px 10px",
                        borderRadius: "12px",
                        // borderBottom: "1px solid lightgray"
                      }}
                    >
                      {text.name}
                    </Button>
                    <Divider className="my-[0px] mx-[0px] " />

                  </div>
                ))}
              </div>
              {/* <div>
                {SideNavigation.CustomerRoutes.slice(4).map((text, index) => (
                  <div>
                    <Divider className="my-[0px] mx-[0px] " />

                    <Button
                      onClick={() => {
                        navigate(text.path);
                      }}
                      className="flex items-center gap-5 justify-start shadow-none  mt-2 disabled:border-none w-full"
                      key={index}
                      disabled={text?.isDisabled}
                      type="primary"
                      icon={
                        <img
                          src={location.pathname === text.path ? text?.SelectedImage : text?.NonSelectedImage}
                          style={{
                            height: "20px",
                            width: "20px",
                            objectFitl: "contain",
                          }}
                          alt=""
                        />
                      }
                      style={{
                        background:
                          location.pathname === text.path ? Colors.darkBlue : "white",

                        color:
                          location.pathname === text.path ? "white" : Colors.sideTxt,
                        fontSize: "14px",
                        paddingLeft: "10px",
                        padding: "18px 10px",
                        borderRadius: "12px",
                        // borderBottom: "1px solid lightgray"
                      }}
                    >
                      {text.name}
                    </Button>
                  </div>
                ))}

              </div> */}
               <div>
            {SideNavigation.CustomerRoutes.slice(4).filter((item, name) => {
              if (Role == 1) { return item } else {
                if (item.name !== "User") { console.log('hello world i sm here ', item); return item }
              }
            }).map((text, index) => {

              return <div key={index}>
                <Divider className="my-[0px] mx-[0px]" />
                <Button
                  onClick={() => navigate(text.path)}
                  className="flex items-center gap-5 justify-start shadow-none mt-2 disabled:border-none w-full"
                  disabled={text.isDisabled}
                  type="primary"
                  icon={
                    <img
                      src={location.pathname === text.path ? text.SelectedImage : text.NonSelectedImage}
                      style={{
                        height: "20px",
                        width: "20px",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  }
                  style={{
                    background: location.pathname === text.path ? Colors.darkBlue : "white",
                    color: location.pathname === text.path ? "white" : Colors.sideTxt,
                    fontSize: "14px",
                    paddingLeft: "10px",
                    padding: "18px 10px",
                    borderRadius: "12px",
                  }}
                >
                  {text.name}
                </Button>
              </div>
            })}
          </div>
            </>
            :
            <div>

              {SideNavigation.AdminRoutes.map((text, index) => {
                return (text?.child ?


                  <Menu
                    onClick={onClick}
                    style={{
                      color: Colors.sideTxt,
                      fontSize: "14px",
                      paddingLeft: "10px",
                      padding: "0px ",
                      borderRadius: "12px",
                      marginTop: "4px"
                    }}
                    defaultSelectedKeys={[sideKey]}
                    defaultOpenKeys={[sideKey]}
                    defaultValue={[sideKey]}
                    mode="inline"
                    // Here we will render our Childrens---->

                    items={[{
                      label: text?.name,
                      icon: text?.Avatar,
                      children: text?.child
                    }]}
                  />
                  :
                  <Button
                    onClick={() => {
                      navigate(text.path);
                    }}
                    className="flex items-center gap-5 justify-start shadow-none  mt-2 disabled:border-none w-full"
                    key={index}
                    disabled={text?.isDisabled}
                    type="primary"
                    icon={
                      text.Avatar
                    }
                    style={{
                      background:
                        location.pathname === text.path ? Colors.darkBlue : "white",

                      color:
                        location.pathname === text.path ? "white" : Colors.sideTxt,
                      fontSize: "14px",
                      paddingLeft: "10px",
                      padding: "18px 10px",
                      borderRadius: "12px",
                    }}
                  >
                    {text.name}
                  </Button>
                )
              }

              )}
            </div>

        }


        {/* Logout Button */}
        <Button
          className="flex items-center gap-3 justify-start shadow-none"
          type="primary"
          icon={<SettingOutlined style={{ fontSize: "20px" }} />}
          style={{
            background: "white",
            color: Colors.sideTxt,
            fontSize: "14px",
            paddingLeft: "10px",
          }}
          onClick={() => {
            localStorage.clear()
            dispatch(resetCart())
            navigate(pageRoutes?.public?.login)
            success("Logged Out")
          }}
        >
          Log out
        </Button>
      </div>



    </div >
  );
};

export default Sidebar;
