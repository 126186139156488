
import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Row, Spin } from "antd";
import Layout from "../../../../Layout/Layout";
import InvoicesTable from '../../../../Components/Invoices/InvoicesTable'
import { getAllCustomerInvoices } from "../../../../API-Interaction/api-functions/invoice.api";
import { useDispatch, useSelector } from "react-redux";
import UserForm from "./UserForm";
import { Colors } from "../../../../Assets/Colors";
import UserTable from "./userTable";
import { CreateUserBySuperAdmin, getAllUsers } from "../../../../API-Interaction/api-functions/customer.api";

const UserCreated = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false); // State variable to control Spinner visibility

  const token = localStorage?.getItem('authTokenHaasil');
  const { pageLoader } = useSelector(state => state.utils.loaders);



  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCreate = async (values) => {
    
    const requestBody = {
      ...values,
      // If you want to include a company name, you can add it here
      // companyName: 'YourCompany'
    };
    setLoading(true);
    let response = await CreateUserBySuperAdmin(dispatch, token, requestBody);
    if (response?.success) {
      console.log('Received values:', values);
      setData(response?.response?.order);
      window.location.reload();
      setVisible(false);
    }
    setLoading(false);


  };

  return (
    <>
      <Layout id='invoicePage' className='Invoice-Page'>
        <Row className="">
          <Col md={21}>
            <p className=" font-semibold ml-[10px] header-heading">Users </p>
          </Col>

          <Spin size="small" spinning={loading}>
            <Col md={3}>
              <p className=" font-semibold ml-[10px] ">
                <Button onClick={showModal} style={{ background: Colors.darkBlue, color: "white" }}>
                  Create Users
                </Button>
                <UserForm
                  visible={visible}
                  onCreate={handleCreate}
                  onCancel={handleCancel}
                  loading={loading} // Pass the loading prop here

                />
              </p>
            </Col>
          </Spin>
        </Row>
        <Divider className="mt-1 w-[full]" />
        <Row className="w-[full] ">
          <UserTable />
        </Row>
      </Layout>
    </>
  );
};

export default UserCreated;
