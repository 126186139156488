import React, { useEffect, useState } from 'react';
import { Button, Divider, Image, Modal, Rate, Spin } from 'antd';
import { getQuotationById } from '../../API-Interaction/api-functions/quotation.api';
import { useDispatch, useSelector } from 'react-redux';
import { Images } from '../../Assets/Images/Images';
import { onPostReview } from '../../API-Interaction/api-functions/product.api';
import { error, success } from '../../helpers/notifications';
const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];


const ReviewModal = ({ open, close, id }) => {
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const { pageLoader } = useSelector((state) => state.utils.loaders);
    const [Data, setData] = useState([])
    const [value, setValue] = useState([]);
    const getOrderById = async (id) => {
        const respone = await getQuotationById(dispatch, id, token)
        if (respone.success) {
            setData(respone?.response?.order[0].OrderItems)
        }
    }
    const postReview = async (orderItemId) => {
        const findValue = value.findIndex(item => item?.id === orderItemId)
        if (findValue !== -1) {
            const body = { orderItemId: value[findValue].id, rating: value[findValue].value }
            const result = await onPostReview(dispatch, token, body)
            if (result.success) {
                success('Review Updated!')

            }
        }
        else {
            error('Kindly select value first')
        }

    }
    useEffect(() => {
        if (id) {
            getOrderById(id)
        }
    }, [id])
    return (
        <>

            <Modal title='Order Remarks' open={open} onCancel={close} footer={null} width={600} style={{
                top: 20,
            }} >
                <Spin spinning={pageLoader?.getQuotesById || false}>
                    <div className='flex justify-center relative overflow-hidden py-[20px] mt-[20px]' >
                        <div className='absolute left-[-20px] h-[150px] w-[150px] rounded-[50%] top-[0px] bg-[#0a789a69]'>

                        </div>
                        <Image preview={false}
                            width={150}
                            src={Images.HasilInvoiceLogo}
                        />
                    </div>
                    {Data.length !== 0 && Data.map((item, index) => {
                        return (
                            // { index: index, value: e, id: item?.orderItemId }
                            <div className='mt-[10px]'>
                                <div className='flex justify-between items-center shadow-sm p-[10px] '>
                                    <div className='flex gap-3 items-center'>

                                        <Image preview={false}
                                            width={70}
                                            src={item?.Product.imageURLs ? item?.Product.imageURLs[0] : Images.HasilInvoiceLogo}
                                        />
                                        <div className='text-[gray]'>
                                            <p className='text-[13px] font-bold'>{item?.Product.name}</p>
                                            <p className='text-[12px] font-black'>{item?.Product.internalReference}</p>
                                            <p className='text-[10px]'>lorem ipsum dipour lorem ipsun dipur</p>
                                            <p className='text-[10px] font-black text-[#0a789a]'>Manufacturer </p>

                                        </div>
                                    </div>
                                    <div className='flex justify-end items-end flex-col gap-2'>
                                        <span>
                                            <Rate tooltips={desc} onChange={(e) => {
                                                setValue((prev) => {
                                                    if (prev.length !== 0) {
                                                        let newItem = prev.findIndex(prevItems => prevItems?.id === item.orderItemId)
                                                        if (newItem !== -1) {
                                                            let newValue = prev;
                                                            newValue.splice(newItem, 1, { value: e, id: item?.orderItemId })
                                                            return newValue
                                                        } else {
                                                            return [...prev, { value: e, id: item?.orderItemId }]
                                                        }
                                                    } else {
                                                        return [{ value: e, id: item?.orderItemId }]

                                                    }
                                                })
                                            }} defaultValue={item?.Review ? item?.Review?.rating : 0} className='text-[orange]' />
                                            {/* {value ? <span className="ant-rate-text font-semibold">{desc[value - 1]}</span> : ''} */}
                                        </span>
                                        <Button onClick={() => { postReview(item?.orderItemId) }} loading={pageLoader?.postReview || false}>Submit review</Button>
                                    </div>


                                </div>
                                <Divider className='mt-[10px] mb-[3px]' />
                            </div>
                        )

                    })


                    }




                </Spin>

            </Modal>
        </>
    );
};
export default ReviewModal;