import React, { useEffect, useRef } from 'react';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { useState } from 'react';
import { uploadOrderImage } from '../../API-Interaction/api-functions/upload.api';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, debounceImage } from '../../helpers/helper';
import { setUploadDocument } from '../../Redux/features/cartSlice';
import { setLoader } from '../../Redux/features/utilsSlice';
import { error } from '../../helpers/notifications';
const { Dragger } = Upload;




const DraggerButton = ({ setUploadItems }) => {
    const ref = useRef()
    const [loading, setloading] = useState(false)
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const [documentArray, setDocumentArray] = useState([])


    useEffect(() => {
        setUploadItems(documentArray)
    }, [documentArray])

    const checkbeforeUpload = (info) => {
        let { file } = info
        const fileListTypes = ["image/jpeg", "image/png", "image/Jpeg",
            "image/PNG", "image/JPEG", "image/Png", 'application/pdf',
            'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            '.xlsx', 'text/csv', '.docs', '.docx', '.doc',
            'text/plain', 'text/docs', 'text/docx',
            'text/word', 'text/xlsx', 'text/CSV'];
        const checkFile = fileListTypes.includes(file.type)

        if (!checkFile) {
            error('You can only upload JPG/PNG/Excel/Pdf/word file!')
            return false
        }
        if (file.size > 2000000) {
            error('File must be smaller than 2MB!')
            return false
        }
        return true
    }

    const HandleChange = debounceImage(async (info) => {
        console.log('Hello world i am call ---- >')
        setloading(true);
        const { fileList } = info;
        console.log("🚀 ~ file: DraggerButton.js:68 ~ HandleChange ~ fileList:", fileList.length)
        // Upload each file separately
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            const check = checkbeforeUpload({ file });

            if (!check) {
                setloading(false);
                return;
            }

            let response = await uploadOrderImage(dispatch, file.originFileObj, token);
            dispatch(setUploadDocument(response));
        }
        setloading(false);
    });






    return (<Dragger fileList={[]}  action={() => { }} multiple={true} disabled={loading} showUploadList={false} onChange={HandleChange} >
        <p className="ant-upload-drag-icon">
            <InboxOutlined />
        </p>
        {!loading ?
            <div>

                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                    banned files.
                </p>
            </div> :
            <LoadingOutlined />
        }
    </ Dragger>
    )

}

export default DraggerButton;