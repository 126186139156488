import React, { useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {  ProductDownloadApi } from '../../../API-Interaction/api-functions/product.api';

const FileDownloadComponent = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const handleDownload = async () => {
        try {
            setLoading(true);
            let response = await ProductDownloadApi(dispatch, token);

            console.log(response)
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'products.csv'; // Set the desired filename
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error downloading the file.');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setLoading(false);
        }
    };

    return (

        <Button className='text-[black]'
            loading={loading}
            onClick={handleDownload}
            icon={<DownloadOutlined />}
        >
            Download File
        </Button>
    );
};

export default FileDownloadComponent;
