import InputBg from "./InputBg.png";
import UpdatePassBg from "./updatePassBg.png";
import LoginBg from "./LoginBg.png";
import Logo from "./Logo.png";
import Check from "./Check.png";
import Tick from "./Tick.png";
import Draft from "./Draft.png";
import Mockup from "./Mockup.png";
import ConfirmCheckout from "./ConfirmModal.png";
import DisplayPic from "./Display.png";
import Notification from "./Notification.png";
import CameraUpload from "./CameraUpload.png";
import AddItem from "./AddItems.png";
import DailyEssentialsBlue from "./Dailyessentialsblue.png";
import DailyEssentialsWhite from "./Dailyessentialswhite.png";
import Dashboardblueicon from "./DashboardBlueIcon.png";
import DashboardWhiteIcon from "./DashboardWhiteIcon.png";
import CustomiseGoodsBlue from "./CustomiseGoodsBlue.png";
import CustomiseGoodsWhite from './CustomiseGoodswhite.png'
import AdhocBlue from "./AdhocBlue.png";
import AdhocWhite from './AdhocWhite.png'
import InvoicesBlue from "./Invoices.png";
import InvoiceWhite from './InvoiceWhite.png';
import GridViewActive from './GridViewActive.png';
import TableViewActive from './TableViewActive.png';
import GridViewNonActive from './GridViewNonActive.png';
import TableViewNonActive from './TableViewNonActive.png';
import QuotationsBlue from './QuotationsBlue.png'
import QuotationsWhite from './QuotationsWhite.png'
import ChartPic from './ChartPic.png'
import EditPen from './EditPen.png'
import HasilInvoiceDesign from './HassilInvoiceDesign.png';
import HasilInvoiceLogo from './Haasil-LogoBlack.png'
import ThumbIcon from './ThumbIcon.png'
import ThumbIconSelected from './ThumbIconSelected.png'
import RfqConfirmation from './RfqConfirmation.png'
import BannerAdImage from './BannerAdImage.PNG'
import HaasilVideo from './HaasilVideo.mp4'
import AdBg from './adBg.PNG'
import banner2 from './banner2.png'
import userWhite from './userWhite.png'
import userBlue from './userBlue.png'


export const Images = {
  userBlue:userBlue,
  userWhite:userWhite,
  banner2: banner2,
  AdBg: AdBg,
  RfqConfirmation: RfqConfirmation,
  LoginButtonBG: InputBg,
  BannerAdImage: BannerAdImage,
  LoginBg: LoginBg,
  UpdatePassBg: UpdatePassBg,
  Logo: Logo,
  Check: Check,
  Tick: Tick,
  Draft: Draft,
  Mockup: Mockup,
  ConfirmCheckout: ConfirmCheckout,
  DisplayPic: DisplayPic,
  Notification: Notification,
  CameraUpload: CameraUpload,
  AddItem: AddItem,
  DailyEssentialsWhite: DailyEssentialsWhite,
  DashboardWhiteIcon: DashboardWhiteIcon,
  DailyEssentialsBlue: DailyEssentialsBlue,
  Dashboardblueicon: Dashboardblueicon,
  CustomisegoodsBlue: CustomiseGoodsBlue,
  CustomiseGoodsWhite: CustomiseGoodsWhite,
  AdhocBlue: AdhocBlue,
  AdhocWhite: AdhocWhite,
  InvoicesBlue: InvoicesBlue,
  InvoiceWhite: InvoiceWhite,
  GridViewActive: GridViewActive,
  TableViewActive: TableViewActive,
  GridViewNonActive: GridViewNonActive,
  TableViewNonActive: TableViewNonActive,
  QuotationsBlue: QuotationsBlue,
  QuotationsWhite: QuotationsWhite,
  ChartPic: ChartPic,
  EditPen: EditPen,
  HasilInvoiceDesign: HasilInvoiceDesign,
  HasilInvoiceLogo: HasilInvoiceLogo,
  ThumbIcon: ThumbIcon,
  ThumbIconSelected: ThumbIconSelected,
  HaasilVideo: HaasilVideo
};
