import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loaders: {
        mainLoader: false,
        pageLoader: {},
        componentLoader: {}
    },
    reloadFlags: {
        page: {},
        component: {}
    },
}

export const utils = createSlice({
    name: "utils",
    initialState,
    reducers: {
        setLoader: (state, { payload }) => {
            state.loaders.mainLoader = payload
        },
        setPageLoader: (state, { payload }) => {
            let { page, flag } = payload
            state.loaders.pageLoader[page] = flag
        },
        setComponentLoader: (state, { payload }) => {
            let { component, flag } = payload
            state.loaders.componentLoader[component] = flag
        },
        setPageReloader: (state, { payload }) => {
            let { page } = payload
            let currentFlag = state.reloadFlags.page[page]
            state.reloadFlags.page[page] = !currentFlag
        },
        setComponentReloader: (state, { payload }) => {
            let { component } = payload
            let currentFlag = state.reloadFlags.component[component] || false
            state.reloadFlags.component[component] = !currentFlag
        },


    }
})


export const { setLoader, setPageLoader, setComponentLoader, setPageReloader, setComponentReloader } = utils.actions

export default utils.reducer