import { CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Image, Radio, Row, Table } from 'antd';
import { useDebugValue, useEffect, useState } from 'react';
import { Colors } from '../../../Assets/Colors';
import ViewCustomerModal from '../../Modals/ViewcustomerModal';
import { Images } from '../../../Assets/Images/Images';
import { getAllCustomerList, getCustomerById } from '../../../API-Interaction/api-functions/customer.api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from '../../Pagination/PaginationComponent';




const CustomerTable = ({ Data, CallAllCustomers }) => {
    const token = localStorage.getItem('authTokenHaasil')
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [userData, setuserData] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const total = Data.length; // Use the length of your data array as the total count.

    const totalPages = Math.ceil(total / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = Data.slice(startIndex, endIndex);

    const [count, setCount] = useState(total)
    const [Search, setSearch] = useState('')
    const navigate = useNavigate()
    const [Type, setType] = useState('View')

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
          setCurrentPage(newPage);
        }
      };



    const columns = [


        {
            title: 'Name',
            dataIndex: 'firstname',
            align: "center",

            render: (item) => {
                return <p>{item ? item : 'User name'}</p>
            }
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
            align: "center",

            
            render: (text) => (
                <div style={{ whiteSpace: 'normal' }}>{text}</div>
              ),
        },

        {
            title: 'Phone No.',
            dataIndex: 'phoneNumber',
            align: "center",

        },
        // {
        //     title: 'Company Name',
        //     dataIndex: 'company?.name',
        //     align: "center",

        // },
        {
            title: 'Company Name',
            dataIndex: 'Company', // Remove ?.name
            align: 'center',
            render: (company) => {
              const companyName = company?.name || 'N/A'; // Handle null or undefined
              return <p  style={{ whiteSpace: 'normal' }}>{companyName}</p>;
            },
          },
        {
            title: "Status",
            dataIndex: 'status',
            key: 'status',
            align: "center",
            render: (item) => {
                return <div className='flex items-center justify-center'>


                    <div className='' style={{ background: item == 2 ? "rgb(0 255 0 / 7%)" : item === 1 ? '#0000ff1c' : '#80000030 ', padding: "5px 20px", borderRadius: "10px", boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", fontWeight: "700", color: item == 2 ? "green" : item === 1 ? 'blue' : 'maroon  ' }}>
                        {item == 2 ? "Verified" : item === 1 ? 'Not Verified' : 'Rejected'}
                    </div>

                </div>
            }

        },
        

        {
            title: 'Actions',
            key: 'address',
            dataIndex: 'address',
            render: (_, record) => {
                return <div className='flex justify-center items-center gap-3'>
                    <Button onClick={() => { getUserById(record?.userId, 'View') }} className='p-0 border-none  flex items-center justify-center ' >
                        <EyeOutlined className={`text-[20px] text-[${Colors.darkBlue}] shadow-none`} />
                    </Button>
                    <Button onClick={() => { getUserById(record?.userId, 'Edit') }} className='p-0 border-none  flex items-center justify-center ' >
                        <EditOutlined className={`text-[20px] text-[${Colors.darkBlue}] shadow-none`} />

                    </Button>

                </div>
            },
            align: "center",
        },
    ];

    const getUserById = async (id, type) => {
        let respone = await getCustomerById(dispatch, id, token)
        if (respone.success) {
            setuserData(respone)
            setOpen(!open)
            setType(type)
        }
    }

    const HandleModal = () => {
        setOpen(!open)
    }
    useEffect(() => {
      setCurrentPage(1); // Reset to the first page when Data or Search changes
  }, [Data, Search]);
    return (
 
        <div className="w-full">
        {/* Render your table using `currentData` */}
      
        {Data.length > 0 ? (  <Table
          pagination={false}
          columns={columns}
          dataSource={currentData}
          className="admin-customer-table overflow-x-scroll"
          scroll={{ y: 400 }}
        />
        ) : (
          <p>No data available</p>
        )}
  
        {/* Render pagination controls */}
        {totalPages > 1 && Data.length > 0 && (

        <div className="pagination-controls flex justify-end">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{marginRight:'3px'}}
          >
            -
          </Button>
          <span className='mt-1'>
             Page {currentPage} of {totalPages} 
          </span>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{marginLeft:'3px'}}
          >
            +
          </Button>
        </div>
              )}

        <ViewCustomerModal open={open} close={HandleModal} data={userData} Type={Type} />

      </div>
    );
};
export default CustomerTable;