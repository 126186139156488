import React, { PureComponent, useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getDashboardBarChartData } from '../../API-Interaction/api-functions/dashboard.api';
import { useDispatch } from 'react-redux';



const BarChartDashboard = () => {
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const [Data, setData] = useState([])
    const [categoryTypes, setcategoryTypes] = useState([])



    // Get Month Name Function 
    function getMonthName(monthNumber) {
        if (monthNumber < 1 || monthNumber > 12) {
            return "Invalid month number";
        }

        const months = [
            "January", "February", "March", "April",
            "May", "June", "July", "August",
            "September", "October", "November", "December"
        ];

        return months[monthNumber - 1];
    }

    const filterData = (graphData) => {
        if (graphData.length !== 0) {
            let filterAray = []
            for (let i = 0; graphData.length > i; i++) {
                let { name, productCount } = graphData[i]
                let m_name = getMonthName(graphData[i]?.month)
                if (i == 0) { filterAray.push({ [name]: Number(productCount), name: m_name }) }
                else {
                    let item = filterAray.findIndex((item, index) => (m_name == item.name))
                    if (item === -1) {
                        { filterAray.push({ [name]: Number(productCount), name: m_name }) }
                    } else {
                        filterAray.splice(item, 1, { ...filterAray[item], [name]: Number(productCount) })
                    }

                }
            }

            setData(filterAray.reverse())
        }
    }

    const callDashboardData = async () => {
        const result = await getDashboardBarChartData(dispatch, token)
        if (result?.success) {
            // setData(result?.response?.results)
            setcategoryTypes(result?.response?.levelsData)
            filterData(result?.response?.results)
        }
    }


    useEffect(() => {
        callDashboardData()
    }, [])
    return (
        <ResponsiveContainer width="100%" height="110%">
            <BarChart
                data={Data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 20,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey='Furnitture' stackId="a" fill="#8DDFF5" />
                <Bar dataKey="Electornics" stackId="a" fill="pink" />
                <Bar dataKey="Seasonal Fruits" stackId="a" fill="#BEEB15" />
                <Bar dataKey="General Products" stackId="a" fill="#CB4AB8" />
                <Bar dataKey="Packaging" stackId="a" fill="#853AF8" />
                <Bar dataKey="Medicine" stackId="a" fill="#3FC577" />
                <Bar dataKey="Stationary" stackId="a" fill="#C83F0B" />
                <Bar dataKey="Janitorial" stackId="a" fill="#0085FF" />
                <Bar dataKey="Pantry" stackId="a" fill="#44A2A2" />
                <Bar dataKey="Customized Services" stackId="a" fill="#FF0000" />
                <Bar dataKey="Office Supplies" stackId="a" fill="#21F6AC" />
                <Bar dataKey="Hardware" stackId="a" fill="green" />
                <Bar dataKey="Freshner" stackId="a" fill="orange" />
                <Bar dataKey="Seasonal Products" stackId="a" fill="lightblue"  style={{marginBottom:'20px'}}/>
            </BarChart>
        </ResponsiveContainer>

    );

}
export default BarChartDashboard