import AddtoCart from "../Views/Dashboard/AddtoCart";
import Rfq from "../Views/Dashboard/Rfq";
import Checkout from "../Views/Dashboard/Checkout";
import ClientProducts from "../Views/Dashboard/Client-Products";
import Home from "../Views/Dashboard/Home";
import Invoices from "../Views/Dashboard/Invoices";
import Manageaddress from "../Views/Dashboard/Manageaddress";
import Quotes from "../Views/Dashboard/Quotes";
import Viewprofile from "../Views/Profile/Viewprofile";
import NewPassword from "../Views/Session/NewPassword";
import Verification from "../Views/Session/Verification";
import Customerslist from "../Views/Dashboard/Admin/Customerslist";
import ClientQuotations from "../Views/Dashboard/Admin/Admin-Client-quotations";
import ClientQuotes from '../Views/Dashboard/Admin/Client-Order-Detail'
import ClientQuoteDetail from "../Views/Dashboard/ClientQuoteDetail";
import ApprovedQuotation from "../Views/Dashboard/Admin/Approved-quotation";
import Product from "../Views/Dashboard/Admin/Product";
import Orders from "../Views/Dashboard/Admin/Admin-All-Orders";
import AdminInvoices from "../Views/Dashboard/Admin/Admin-Client-Invoices";
import ProductCategory from "../Views/Dashboard/Admin/Admin-Product-Category";
import AdminCreateOrder from "../Views/Dashboard/Admin/Admin-Create-Order";
import UserCreated from "../Views/Dashboard/Admin/CreateUser/User";


export const ClientPrivateRoutes = [
  {
    path: "/dashboard",
    component: <Home />,
  },

  {
    path: "/daily-essentials",
    component: <ClientProducts type={'Daily Essentials'} />
  },
  {
    path: "/adhoc",
    component: <ClientProducts type={'Adhoc-Supplies'} />
  },
  {
    path: "/customisegoods",
    component: <ClientProducts type={'Customise Goods'} />
  },

  {
    path: "/request-for-quotation",
    component: <Rfq />,
  },
  {
    path: "/checkout",
    component: <Checkout />,
  },
  {
    path: "/add-to-cart",
    component: <AddtoCart />,
  },
  {
    path: "/invoice",
    component: <Invoices />
  },
  {
    path: '/quotes',
    component: <Quotes />
  },
  {
    path:'/userCreated',
    component:<UserCreated/>
  }

];
export const AdminPrivateRoutes = [


  {
    path: '/admin/customers-list',
    component: <Customerslist />
  },
  {
    path:'/admin/ProductCategory',
    component:<ProductCategory/>
  },

  {
    path: '/admin/quotations',
    component: <ClientQuotations sideKey={'/admin/quotations'} />
  },
  {
    path: "/admin/quotation/:id",
    component: <ClientQuotes />
  },
  {
    path: "/admin/approved-quotations",
    component: <ApprovedQuotation sideKey={'/admin/approved-quotations'} />
  },
  {
    path: "/admin/products",
    component: <Product />
  }, {
    path: "/admin/orders",
    component: <Orders sideKey={'/admin/orders'} />
  },
  {
    path: '/admin/invoice',
    component: <AdminInvoices sideKey={'/admin/invoice'} />
  },
  {
    path:'/admin/AdminCreateOrder',
    component:<AdminCreateOrder sideKey={'/admin/AdminCreateOrder'}/>
  }

]
export const UniverSalProtectedRoutes = [
  {
    path: "/profile",
    component: <Viewprofile />,
  },
  {
    path: "/manage-address",
    component: <Manageaddress />
  },
  {
    path: '/customer-quote/:id',
    component: <ClientQuoteDetail />
  },
]
export const authPrivateRoutes = [
  {
    path: "/verification",
    component: <Verification />,
  },
  {
    path: "/new-password",
    component: <NewPassword />,
  },

]