import { CheckOutlined, DeleteOutlined, EyeOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Image, Input, Radio, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Colors } from '../../Assets/Colors';
import { Images } from '../../Assets/Images/Images';
import ProductImage from '../../../Assets/productNestle.png'
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrderByOrderId, getQuotationById } from '../../API-Interaction/api-functions/quotation.api';
import { onUpdateAllCustomerOrder, onUpdateCustomerOrderByOrderId } from '../../API-Interaction/api-functions/order.api';
import { success } from '../../helpers/notifications';



const ClientQuotesTable = ({ selectedProduct, bulkDelete, setPreferredDate, setAllData, setAdminRemarks, HandleSaveData, viewType, selectedOrderId, setAddressDetail, setCompanyDetails }) => {
    const { id } = useParams()
    const [type, setType] = useState('')
    const dispatch = useDispatch()
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const token = localStorage.getItem('authTokenHaasil')
    const [Data, setData] = useState()
    const [editMode, setEditMode] = useState(false)
    const [SelectedDel, setSelectedDel] = useState([])


    const getQuoteById = async (id) => {
        const result = await getQuotationById(dispatch, id, token)
        if (result.success) {
            let item = result.response.order[0].OrderItems.map((e, index) => {
                e.key = index
                    ; return e
            })
            setPreferredDate(result?.response?.order[0]?.preferredDeliveryDate)
            setAllData(result?.response?.order[0])
            HandleSaveData(result?.response?.order[0].OrderItems,)
            setData(item)
            setCompanyDetails(result?.response?.order[0]?.User)
            setAddressDetail(result?.response?.order[0].Address)
            setAdminRemarks(result?.response?.order[0].remarks)
            setType(result?.response?.order[0].status)
        }

    }
    const [result, setResult] = useState(null); // Define 'result' state

    useEffect(() => {
        const fetchResultData = async () => {
            try {
                const response = await getQuotationById(dispatch, id, token);
                if (response.success) {
                    setResult(response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchResultData();
    }, [id, token]);
    const UpdateClientOrder = async () => {
        const response = await onUpdateAllCustomerOrder(dispatch, token, id, Data)
        if (response.success) {
            success('Order Successfully Updated!')
            setEditMode(false)
            getQuoteById(id)
        }

    }
    const columns = [
        {
            align: 'start ',
            title: 'PRODUCTS ',
            key: '1',
            dataIndex: 'Sno',
            render: (_, record) => {
                return <div className='flex justify-start items-center '>
                    <div className='flex justify-s items-center gap-3 w-[350px]'>
                        <Avatar style={{ background: "white", boxShadow: '  rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px ' }} size="large" icon={
                            <Image src={record?.Product.imageURLs ? record?.Product.imageURLs[0] : Images.CameraUpload} style={{ color: Colors.darkBlue }} />

                        }
                        />
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>{record?.Product?.name}</p>
                    </div>
                </div>
            },


        },
        {
            title: 'SKU',
            dataIndex: 'Sku',
            align: "center",
            render: (_, record, index) => {
                return <div className='flex justify-center items-center gap-3 '>
                    <div>
                        <p style={{ fontSize: "12px" }}> {record?.Product?.internalReference}</p>
                    </div>
                </div>
            },
        },
        {
            title: 'Variant',
            dataIndex: 'Variant',
            align: "center",
            render: (_, record, index) => {
                return <div className='flex justify-center items-center gap-3 '>
                    <div>
                        <p style={{ fontSize: "12px" }}> {record?.Product?.productCategoryL8?.name ? record?.Product?.productCategoryL8?.name : "N/A"}</p>
                    </div>


                </div>
            },
        },

        {
            key: "2",
            title: 'QTY',
            dataIndex: 'quantity',
            align: "center",
            render: (_, record, index) => {
                return <div className='flex justify-center items-center gap-3 '>
                    <div>
                        {editMode ?

                            <Button
                                className=" flex gap-2 items-center w-[150px] "
                                style={{
                                    background: Colors.darkBlue,
                                    color: "white",
                                    borderRadius: "20px",
                                    fontSize: "10px",
                                    padding: "0px",
                                }}
                            >
                                <p
                                    onClick={() => HandleQuantity('Decrement', index)}
                                    className="h-full w-full flex items-center justify-center  "
                                >
                                    -
                                </p>
                                <Input
                                    type='Number'
                                    onChange={(event) => {
                                        HandleQuantity('Input', index, event.target.value)
                                    }}
                                    id="quantityInput"
                                    value={

                                        record?.quantity
                                    }
                                    style={{
                                        background: Colors.darkBlue,
                                        color: "white",
                                        textAlign: "center",
                                        border: "none",
                                    }}
                                />
                                <p
                                    onClick={() => HandleQuantity('Increment', index)}
                                    className="h-full w-full flex items-center justify-center"
                                >
                                    +
                                </p>
                            </Button> : record?.quantity
                        }

                    </div>


                </div>
            },
        },
        {
            title: 'UNIT RATE',
            dataIndex: 'price',
            key: '3',
            align: "center",

        },

        {
            title: 'GST %',
            key: '4   ',
            align: "center",
            dataIndex: 'GST',

        },
        {
            title: "Total",
            key: '5',
            dataIndex: 'totalPrice',
            align: "center",
        },


        ...viewType !== 'view' ? [
            {
                title: 'EDIT',
                key: '6',
                dataIndex: 'edit',
                render: (_, record, index) => {
                    return <div className='flex justify-center items-center gap-3'>
                        {
                            editMode ? <div className='flex items-center gap-2'>

                                <Button onClick={() => { HandleMode('delete', index, record) }} className='p-0 flex items-center border-none shadow-none'>

                                    <DeleteOutlined style={{ color: "red" }} />
                                </Button>
                                {/* <Button onClick={() => { HandleMode('submit', index, record) }} className='p-0 flex items-center border-none shadow-none'>

                                    <CheckOutlined style={{ color: "green" }} />
                                </Button> */}

                            </div> : <Button onClick={() => { setEditMode(true) }} className='p-0 border-none  flex items-center justify-center ' >
                                <img src={Images.EditPen} style={{ color: Colors.darkBlue, height: "20px" }} />
                            </Button>

                        }

                    </div>
                },
                align: "center",
            },] : []
    ];
    // Handle Selected Checkboxes
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            selectedOrderId(selectedRows)
            setSelectedDel(selectedRows)
        },

    };
    // UseEffect
    useEffect(() => {
        getQuoteById(id)
        setSelectedDel([])
    }, [pageLoader?.deleteOrderByOrderId, pageLoader?.onUpdateCustomerOrderByOrderId, pageLoader?.deleteBulkOrderItem])
    useEffect(() => {
        setSelectedDel(selectedProduct)
    }, [selectedProduct])
    // Handle Quantity
    const HandleQuantity = (type, index, value) => {
        let DataQuantity;
        if (type === 'Increment') {
            DataQuantity = Data.filter((data, dataindex) => { if (dataindex == index) { data.quantity = data.quantity + 1 } return data })

        } else if (type === 'Decrement') {
            DataQuantity = Data.filter((data, dataindex) => { if (dataindex == index) { if (data.quantity > 1) { data.quantity = data.quantity - 1 } } return data })

        } else if (type === 'Input') {
            // if (Data[index].qty > 1) {
            DataQuantity = Data.filter((data, dataindex) => { if (dataindex == index) { if (value > 1) { data.quantity = Number(value) } else { data.quantity = 1 } } return data })

            // }
        }
        setData(DataQuantity)
    }
    // Handle Edit Mode Functionality
    const HandleMode = async (mode, index, data) => {
        let NewData;
        if (mode === 'edit') {
            NewData = Data.filter((item, key) => { if (key === index) { item.Mode = true } return item })
        }
        else if (mode === 'submit') {
            let result = await onUpdateCustomerOrderByOrderId(dispatch, token, data?.orderItemId, { quantity: data?.quantity })
            NewData = Data.filter((item, key) => { if (key === index) { item.Mode = false } return item })
        }
        else if (mode === 'delete') {
            const result = await deleteOrderByOrderId(dispatch, token, data?.orderItemId, data)
            NewData = Data.filter((item, key) => { if (key === index) { item.Mode = false } return item })
        }
        setData(NewData)
    }


    return (
        <Spin spinning={pageLoader?.getQuotesById || false}>
            <div className='w-full'>

                <Table
                    style={{ overflowX: "scroll" }}
                    rowSelection={viewType !== 'view' && {
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    pagination={false}
                    columns={columns}
                    dataSource={Data}
                    className=' admin-customer-quotes-table'
                />
            </div>
            {
                // Display the orderPrice value below the table
                <div className='py-3 px-2'>
                    {result && (
                        <p className="text-right font-bold" >
                            Total Price : <span className='text-blue'>{result?.response?.order[0]?.orderPrice}</span>
                        </p>
                    )}
                </div>
            }
            {

            }
            <div className='flex justify-between py-3 px-2'>
                {
                    SelectedDel.length !== 0 &&
                    <Button onClick={() => { bulkDelete() }} loading={pageLoader?.deleteBulkOrderItem || false} className='bg-[white] text-[red] flex items-center' style={{ border: "2px solid red" }}>Delete Selected Items <DeleteOutlined /></Button>
                }

                {
                    editMode &&
                    <div className='flex justify-end   gap-3'>
                        <Button onClick={() => { setEditMode(false) }} className='bg-[white] text-[#061E3A] border-none'>
                            Cancel
                        </Button>
                        <Button loading={pageLoader?.onUpdateAllCustomerOrder || false} onClick={UpdateClientOrder} className='bg-[#061E3A] text-[white]'>
                            Submit
                        </Button>

                    </div>
                }
            </div>


        </Spin>
    );
};
export default ClientQuotesTable;