import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { getDateInProperFormat } from '../../helpers/helper'
import { Colors } from '../../Assets/Colors'
import { Images } from '../../Assets/Images/Images'

const InvoiceContentOrder = ({ data }) => {
    const [gstValue, setgstValue] = useState('')
    const [valueWithOutGst, setvalueWithOutGst] = useState('')
    const calculateGstTotal = () => {
        if (data) {
            let g_Value = 0;
            let o_Value = 0;
            data?.Order?.OrderItems.forEach((item, index) => {
                o_Value = (Number(o_Value) + Number(item.quantity) * Number(item.price))
                g_Value = Number(g_Value) + ((Number(item.quantity)) * (Number(item.price) * (Number(item.GST) / 100)))
            })
            setgstValue(Math.floor(g_Value))
            setvalueWithOutGst(Math.floor(o_Value))
        }
    }
    useEffect(() => {
        calculateGstTotal()
    }, [data])
    console.log(data?.Order?.Invoice?.amount)
    return (
        <div className='p-1' >
            <div>
                <div className='flex justify-between items-center' style={{ background: "" }}>
                    <div style={{}}>

                        <img src={Images.HasilInvoiceLogo} style={{ width: "100px" }} />
                    </div>
                    <div style={{ position: "relative", paddingRight: "30px" }}>
                        <p style={{ color: "rgb(10 120 154)", fontSize: "30px", }}>INVOICE</p>
                        <div style={{ position: "absolute", top: "-48px", right: "-38px", }}>
                            <img src={Images.HasilInvoiceDesign} style={{ width: "120px" }} />
                        </div>
                    </div>

                </div>
                {/* Invoice Detail */}
                <Row className='flex justify-between'>
                    <Col span={6} className='mt-3'>
                        <p style={{ fontSize: "12px", fontWeight: "700" }}>Seller Information</p>
                        <div className='flex flex-col w-full ' style={{ color: "gray" }}>


                            <p style={{ fontSize: "10px" }}>Haasil</p>
                            <p style={{ fontSize: "10px" }}>4th Floor, TTI Tower، 1-E 9th Zamzama Ln, Karachi</p>
                            <p style={{ fontSize: "10px" }}>4th Floor, TTI Tower، 1-E 9th Zamzama Ln, Karachi</p>
                            <p style={{ fontSize: "10px" }}>Karachi</p>
                            <p style={{ fontSize: "10px" }}>0901</p>

                        </div>
                    </Col>
                    {/* Customer Information */}
                    <Col span={5} className='mt-3'>
                        <p style={{ fontSize: "12px", fontWeight: "700" }}>Bill to</p>
                        <div className='flex flex-col w-full ' style={{ color: "gray" }}>

                            <p style={{ fontSize: "10px" }}>{data?.Order?.User?.firstname + " " + data?.Order?.User?.lastname}</p>
                            <p style={{ fontSize: "10px" }}>{data?.Order?.User?.Company.address}</p>
                            {/* <p style={{ fontSize: "10px" }}>Address 2</p> */}
                            <p style={{ fontSize: "10px" }}>{data?.Order?.User?.Company.city}</p>
                            <p style={{ fontSize: "10px" }}>{data?.Order?.User?.phoneNumber
                            }</p>

                        </div>
                    </Col>
                    <Col span={6} className='mt-3'>
                        <p style={{ fontSize: "12px", fontWeight: "700" }}>Deliver to</p>
                        <div className='flex flex-col  w-full' style={{ color: "gray" }}>

                            <p style={{ fontSize: "10px" }}>{data?.Order?.Address?.fullname}</p>
                            <p style={{ fontSize: "10px" }}>{data?.Order?.Address?.address}</p>
                            <p style={{ fontSize: "10px" }}>{data?.Order?.Address?.city}</p>
                            <p style={{ fontSize: "10px" }}>{data?.Order?.Address?.number}</p>

                        </div>
                    </Col>
                    <Col span={4} className='mt-3'>
                        <p style={{ fontSize: "12px", fontWeight: "700" }}>Invoice#: I-00{ data?.Order?.Invoice?.invoiceId}</p>
                        <div className='flex flex-col  ' style={{ color: "gray" }}>

                            <p style={{ fontSize: "10px" }}>{getDateInProperFormat(data?.createdAt)}</p>
                            <p style={{ fontSize: "10px" }}>PO#:{data?.Order?.purchaseOrder}</p>
                            <p style={{ fontSize: "10px" }}>PO Date:{data?.Order?.purchaseOrder ? getDateInProperFormat(data?.Order?.purchaseDate) : ''}</p>
                            <p style={{ fontSize: "10px" }}>DC </p>

                        </div>
                    </Col>
                </Row>
            </div>
            {/* Table Where Item will render */}
            <Row className='flex ' style={{ fontSize: "9px", marginTop: "20px" }}>
                <Col style={{ border: `1px solid ${Colors.darkBlue}` }} className='flex flex-col items-center ' span={5}>
                    <p style={{ borderBottom: `1px solid ${Colors.darkBlue}`, fontSize: "10px", background: Colors.darkBlue, color: "white", padding: "10px 3px", width: "100%", }} className='flex justify-center'>

                        SKU
                    </p>
                    <div className='flex flex-col items-center ' style={{ minHeight: "300px", backgroundColor: Colors.InvoiceBg, width: "100%", fontSize: "9px" }}>
                        {data && data?.Order?.OrderItems.map((item, index) => <p className='w-full' style={{ marginBottom: "10px", height: "30px", marginLeft: '10px' }}>{item?.Product?.internalReference}</p>
                        )
                        }
                    </div>
                </Col>
                <Col style={{ border: `1px solid ${Colors.darkBlue}` }} className='flex flex-col items-center ' span={5}>
                    <p style={{ borderBottom: `1px solid ${Colors.darkBlue}`, fontSize: "10px", background: Colors.darkBlue, color: "white", padding: "10px 3px", width: "100%" }} className='flex justify-center'>

                        PRODUCT NAME
                    </p>
                    <div className='flex flex-col items-center ' style={{ minHeight: "300px", backgroundColor: Colors.InvoiceBg, width: "100%", fontSize: "9px" }}>
                        {data && data?.Order?.OrderItems.map((item, index) => <p className='w-full' style={{ textAlign: 'left', marginBottom: "10px", height: "30px", marginLeft: '10px' }}>{item?.Product?.name}</p>
                        )
                        }
                    </div>
                </Col>
                <Col style={{ border: `1px solid ${Colors.darkBlue}` }} className='flex flex-col items-center ' span={3}>
                    <p style={{ borderBottom: `1px solid ${Colors.darkBlue}`, fontSize: "10px", background: Colors.darkBlue, color: "white", padding: "10px 3px", width: "100%" }} className='flex justify-center'>

                        PROD.CAT
                    </p>
                    <div className='flex flex-col items-center ' style={{ minHeight: "300px", backgroundColor: Colors.InvoiceBg, width: "100%", fontSize: "9px" }}>
                        {data && data?.Order?.OrderItems.map((item, index) => <p className='w-full' style={{ textAlign: 'center', marginBottom: "10px", height: "30px", }}>{item?.Product?.categoryId
                        }</p>
                        )
                        }
                    </div>
                </Col>
                <Col style={{ border: `1px solid ${Colors.darkBlue}` }} className='flex flex-col items-center ' span={2}>
                    <p style={{ borderBottom: `1px solid ${Colors.darkBlue}`, fontSize: "10px", background: Colors.darkBlue, color: "white", padding: "10px 3px", width: "100%" }} className='flex justify-center'>

                        QTY
                    </p>
                    <div className='flex flex-col items-center ' style={{ minHeight: "300px", backgroundColor: Colors.InvoiceBg, width: "100%", fontSize: "9px" }}>
                        {data && data?.Order?.OrderItems.map((item, index) => <p className='w-full' style={{ textAlign: 'center', marginBottom: "10px", height: "30px", }}>{item?.quantity
                        }</p>
                        )
                        }
                    </div>
                </Col>
                <Col style={{ border: `1px solid ${Colors.darkBlue}` }} className='flex flex-col items-center ' span={3}>
                    <p style={{ borderBottom: `1px solid ${Colors.darkBlue}`, fontSize: "10px", background: Colors.darkBlue, color: "white", padding: "10px 3px", width: "100%" }} className='flex justify-center'>

                        UNIT RATE 
                    </p>
                    <div className='flex flex-col items-center ' style={{ minHeight: "300px", backgroundColor: Colors.InvoiceBg, width: "100%", fontSize: "9px" }}>
                        {data && data?.Order?.OrderItems.map((item, index) => <p className='w-full' style={{ textAlign: 'center', marginBottom: "10px", height: "30px", }}>{item?.price
                        }</p>
                        )
                        }
                    </div>
                </Col>
                <Col style={{ border: `1px solid ${Colors.darkBlue}` }} className='flex flex-col items-center ' span={2}>
                    <p style={{ borderBottom: `1px solid ${Colors.darkBlue}`, fontSize: "10px", background: Colors.darkBlue, color: "white", padding: "10px 3px", width: "100%" }} className='flex justify-center'>

                        GST%
                    </p>
                    <div className='flex flex-col items-center ' style={{ minHeight: "300px", backgroundColor: Colors.InvoiceBg, width: "100%", fontSize: "9px" }}>
                        {data && data?.Order?.OrderItems.map((item, index) => <p className='w-full' style={{ textAlign: 'center', marginBottom: "10px", height: "30px", }}>{item?.GST
                        }</p>
                        )
                        }
                    </div>
                </Col>
                <Col style={{ border: `1px solid ${Colors.darkBlue}` }} className='flex flex-col items-center ' span={4}>
                    <p style={{ borderBottom: `1px solid ${Colors.darkBlue}`, fontSize: "10px", background: Colors.darkBlue, color: "white", padding: "10px 3px", width: "100%" }} className='flex justify-center'>

                        VALUE INCL.GST
                    </p>
                    <div className='flex flex-col items-center ' style={{ minHeight: "300px", backgroundColor: Colors.InvoiceBg, width: "100%", fontSize: "9px" }}>
                        {data && data?.Order?.OrderItems.map((item, index) => <p className='w-full' style={{ textAlign: 'center', marginBottom: "10px", height: "30px", }}>{item?.totalPrice
                        }</p>
                        )
                        }
                    </div>
                </Col>
            </Row >
            {/* Discount and GST Section */}
            < Row className='flex justify-end items-center' >
                <Col span={3}>
                    <div style={{ height: "40px", }} className='flex justify-start items-center'>
                        <p style={{ fontSize: "12px" }}>GST.VALUE</p>
                    </div>
                    <div style={{ height: "40px", }} className='flex justify-start items-center'>
                        <p style={{ fontSize: "12px" }}>DISCOUNT</p>
                    </div>
                    <div style={{ height: "40px", }} className='flex justify-start items-center'>
                        <p style={{ fontSize: "12px" }}>TOTAL INCL.GST</p>
                    </div>
                </Col>
                <Col span={4}>
                    <div className='flex justify-center items-center font-black' style={{ height: "40px", border: `1px solid ${Colors.darkBlue}` }}>
                        <p>{gstValue}</p>
                    </div>
                    <div style={{ height: "40px", border: `1px solid ${Colors.darkBlue}` }}>

                    </div>
                    <div className='flex justify-center items-center' style={{ height: "40px", border: `1px solid ${Colors.darkBlue}` }}>
                        {/* <p style={{ fontSize: '14px', fontWeight: '800' }}>{data?.amount}</p> */}
                        <p style={{ fontSize: '14px', fontWeight: '800' }}>{ data?.Order?.Invoice?.amount}</p>

                    </div>
                </Col>

            </ Row>
            
            {/* Bank Details */}
            < div >
                <p className='mb-[10px]'>BANK A/C DETAILS</p>
                <Row>
                    <Col span={9}>
                        <div style={{ border: `1px solid ${Colors.darkBlue}`, width: "100%", padding: '5px' }}>
                            <p style={{ fontWeight: "800" }}>Bank Alfalah</p>
                            <p className='support-heading flex flex-col '>
                                Account Title: HSL Technologies (Private) Limited
                                <p>
                                    IBAN: <span className='cursor-pointer' style={{ fontWeight: "800", }}>PK89ALFH0030001008231998</span>
                                </p>

                            </p>

                        </div>
                    </Col>
                    <Col span={9}>
                        <div style={{ border: `1px solid ${Colors.darkBlue}`, width: "100%", padding: '5px' }}>
                            <p style={{ fontWeight: "900" }}>Meezan Bank</p>
                            <p className='support-heading flex flex-col'>
                                Account Title: HSL Technologies (Private) Limited
                                <p>

                                    IBAN: <span className='cursor-pointer' style={{ fontWeight: "800" }}>PK91MEZN0099090107514685</span>

                                </p>

                            </p>
                        </div>
                    </Col>
                </Row>
            </ div>
            {/* Remarks */}
            <div div style={{ width: "100%" }}>
                <p>Remarks</p>
                <div style={{ width: "100%", height: "90px", border: `1px solid ${Colors.darkBlue}` }}>

                </div>
            </div >
        </div>
    )
}

export default InvoiceContentOrder