import { Modal, Table, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CreateUserBySuperAdmin, DeleteUser, UpdateUser, getAllUsers } from '../../../../API-Interaction/api-functions/customer.api';
import { setPageLoader } from '../../../../Redux/features/utilsSlice';
import UpdateForm from './UpdateForm';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Colors } from '../../../../Assets/Colors';
const Role = localStorage.getItem('Role')

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [Data, setData] = useState()
  const [formVisible, setFormVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  const navigate = useNavigate()
  let dispatch = useDispatch()
  let token = localStorage.getItem('authTokenHaasil')
  const cart = useSelector((state) => state?.cart?.cartItems);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const showDeleteConfirmationModal = (record) => {
    setUserToDelete(record);
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
    setUserToDelete(null);
  };

  const confirmDelete = async () => {
    if (userToDelete) {
      // Execute the delete action here
      await handleDelete(userToDelete);
      closeConfirmationModal();
    }
  };
  const handleCancel = () => {
    setFormVisible(false);
  };

  const handleUpdate = async (values) => {
    const requestBody = {
      ...values,
      // If you want to include a company name, you can add it here
      // companyName: 'YourCompany'
    };
    let response = await UpdateUser(dispatch, token, requestBody);
    if (response?.success) {
      console.log('Received values:', values);
      setData(response?.response?.order);
      window.location.reload();
    }
    // Handle user creation logic here, e.g., sending a POST request to your API.
    setFormVisible(false);
  };


  const handleEditClick = (record) => {
    setModalData(record);
    setFormVisible(true);
  };



  const handleDelete = async (record) => {
    try {
      const userId = record.userId; // Adjust this based on your data structure
      const companyId = record.companyId; // Adjust this based on your data structure
      const data = {}; // If you need to send data with the request

      const response = await DeleteUser(dispatch, token, userId, companyId, data);

      if (response.success) {
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.userId !== userId)
        );
        message.success('User deleted successfully');
      } else {
        message.error('Error deleting user: ' + response.error);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      message.error('An error occurred while deleting the user');
    }
  };





  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setPageLoader({ page: 'userTable', flag: true }));

        const token = localStorage?.getItem('authTokenHaasil');
        const response = await getAllUsers(token, dispatch);

        dispatch(setPageLoader({ page: 'userTable', flag: false }));

        if (response?.success) {
          const fetchedUsers = response?.response?.users;
          setUsers(fetchedUsers);
        } else {
          setError(response?.response);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        setError(error.message);
      }
    };

    fetchData();
  }, [dispatch]);
  if (error) {
    return <div>Error: {error}</div>;
  }




  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstname',
      align: 'center',
      render: (text, record) => (
        <span>{`${record.firstname || 'N/A'} ${record.lastname || 'N/A'}`}</span>
      ),
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
      align: 'center',
      render: (text, record) => (
        <span>{record.emailAddress || 'N/A'}</span>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      align: 'center',
      render: (text, record) => (
        <span>{record.phoneNumber || 'N/A'}</span>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      align: 'center',
      render: (text, record) => (
        <span>
          {record.role === 1 ? 'Super Admin' : record.role === 3 ? 'Admin' : 'N/A'}
        </span>
      ),
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      render: (text, record) => (
        <span>
          <a onClick={() => handleEditClick(record)}><EditOutlined /></a>
          {/* <a onClick={() => handleDelete(record)}> <DeleteOutlined /> </a> */}

          {/* { Role == '3' ?
          <a onClick={() => showDeleteConfirmationModal(record)}><DeleteOutlined /></a>
        }:null */}
          {record.role === 3 && (
            <a onClick={() => showDeleteConfirmationModal(record)}>
              | <DeleteOutlined />
            </a>
          )}

        </span>
      ),
    },
  ];


  return (
    <>

      <Table columns={columns} dataSource={users} className='w-[100%] overflow-x-scroll' scroll={{ y: 360 }} />
      {formVisible && (
        <UpdateForm
          visible={formVisible}
          data={modalData} // Pass the data to populate the form fields
          onCreate={handleUpdate}
          onCancel={handleCancel}

        />


      )}

      <Modal
        okButtonProps={{ style: { backgroundColor: Colors.darkBlue } }}

        title="Confirm Deletion"
        visible={showConfirmationModal}
        onOk={confirmDelete}
        onCancel={closeConfirmationModal}

      >
        Are you sure you want to delete this user?
      </Modal>
    </>
  )
}

export default UserTable