import React from "react";
import Layout from "../../Layout/Layout.js";
import Typography from "antd/es/typography/Typography.js";
import { Button, Col, Divider, Image, Row, Spin } from "antd";
import { Images } from "../../Assets/Images/Images.js";
import { Colors } from "../../Assets/Colors.js";
import AddressModal from "../../Components/Modals/Addressmodal.js";
import { useState } from "react";
import { useEffect } from "react";
import { onDeleteAddress, onGetAllAddresses } from "../../API-Interaction/api-functions/address.api.js";
import { useDispatch, useSelector } from "react-redux";

const Manageaddress = () => {
  const token = localStorage.getItem('authTokenHaasil')
  const { pageLoader } = useSelector(state => state.utils.loaders)

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [Address, setAddress] = useState()
  const [Data, setData] = useState('')
  const HandleModal = () => {
    if (open) {
      setOpen(!open);
      setData('')
    } else {
      setOpen(!open)
    }
  };

  // Fetch All Address------------------->
  const fetchAllAddress = async () => {
    const response = await onGetAllAddresses(dispatch, token)
    if (response.success) {
      setAddress(response.response.addresses)
    }
  }

  // Delete Address 
  const delteAddress = async (addressID) => {
    const response = await onDeleteAddress(dispatch, token, addressID)
    if (response.success) {
      fetchAllAddress()
    }
  }

  useEffect(() => {
    window.scroll(0, 0);
    fetchAllAddress()
  }, [pageLoader.createAddress, pageLoader?.deletePage]);
  return (
    <Layout>
      <Spin spinning={pageLoader?.rfq || false}>
        <Typography.Text className="bold-heading header-heading" >
          Your Address
        </Typography.Text>
        <Divider className="my-[10px] mx-[0px]" />
        <Row className="gap-3">
          <Col
            onClick={HandleModal}
            lg={7}
            xs={24}
            className="p-10 flex justify-center items-center cursor-pointer flex-col h-[200px]"
            style={{ border: "1px solid lightgray " }}
          >
            <Image
              width={"70px"}
              className="object-contain"
              preview={false}
              src={Images.AddItem}
            />
            <Typography.Text
              className="bold-heading mt-1 text-[15px]"
            >
              Add Address
            </Typography.Text>
          </Col>
          {/* Previous Address */}
          {
            Address?.length > 0 && Address.map(e =>
              <Col
                lg={7}
                xs={24} className="p-3 flex flex-col justify-between h-[200px]"
                style={{
                  border: "1px solid lightgray ",
                }}
              >
                <div className="flex  flex-col" style={{ color: Colors.grayTxt }}>
                  <p className="bold-heading text-[black]"
                  >
                    {e.addressTitle}
                  </p>
                  <p className="">
                    {e.address}
                  </p>
                  <p className="w-full">

                    {
                      `${e.city}/${e.state}/${e.zipcode}/Pakistan`
                    }</p>
                  <p className="">Phone number: {e.number}</p>
                </div>
                <div className="flex gap-2 cursor-pointer items-center ">
                  <Button className={`text-[${Colors.blueLink}] `} onClick={() => { setData(e); HandleModal() }}>Edit</Button>
                  |
                  <Button className="border-none shadow-none" loading={pageLoader?.deletePage || false} onClick={() => { delteAddress(e.addressId) }} style={{ color: Colors.blueLink }}>Remove</Button>
                </div>
              </Col>
            )

          }

        </Row>
        <AddressModal open={open} close={HandleModal} data={Data} fetchAddress={fetchAllAddress} />
      </Spin>
    </Layout >
  );
};

export default Manageaddress;
