import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { PublicRoutes } from "./PublicRoutes";
import { AdminPrivateRoutes, authPrivateRoutes, ClientPrivateRoutes, UniverSalProtectedRoutes } from "./PrivateRoutes";
import ProtectedRoutes from "./ClientProtectedRoutes";
import NonProtectedRoutes from "./NonProtectedRoutes";
import AdminProtectedRoutes from "./AdminProtectedRoutes";
import UniversalRoutes from "./UniversalProtectedRoutes";
import { useEffect } from "react";
import { routeHistoryMaintainer } from "../helpers/helper";

const RouteHandler = () => {

  const location = useLocation()
  routeHistoryMaintainer(location?.pathname)

  return (
    <Routes>
      <Route element={<NonProtectedRoutes />}>
        {PublicRoutes.map((items, index) => { return (<Route key={index} path={items.path} element={items.component} />) })}
      </Route>
      {/* Client Routes -----------------------------> */}
      <Route element={<ProtectedRoutes isAuth={false} />}>
        {ClientPrivateRoutes.map((items, index) => { return (<Route key={index} path={items.path} element={items.component} />) })}
      </Route>
      {/* Admin Routes ------------------------------> */}
      <Route element={<AdminProtectedRoutes isAuth={false} />}>
        {AdminPrivateRoutes.map((items, index) => { return (<Route key={index} path={items.path} element={items.component} />) })}
      </Route>
      {/* Universal Private Routes ------------------------------> */}
      <Route element={<UniversalRoutes isAuth={false} />}>
        {UniverSalProtectedRoutes.map((items, index) => { return (<Route key={index} path={items.path} element={items.component} />) })}
      </Route>

      {/* Verification Page */}
      <Route element={<ProtectedRoutes isAuth={true} />}>
        {authPrivateRoutes?.map((items, index) => { return (<Route key={index} path={items.path} element={items.component} />) })}
      </Route>
    </Routes>
  );
};

export default RouteHandler;
