import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Row, Spin } from "antd";
import Layout from "../../../Layout/Layout";
import Appbar from '../../../Components/Appbar/Appbar'
import CustomerTable from "../../../Components/Admin/Table/Customertable";
import ViewCustomerModal from "../../../Components/Modals/ViewcustomerModal";
import QuotationsTable from "../../../Components/Admin/Table/Quotationstable";
import { getAllQuotesAdmin } from "../../../API-Interaction/api-functions/quotation.api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Colors } from "../../../Assets/Colors";

const CustomerQuotations = ({ sideKey }) => {
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const [data, setData] = useState([])
    const [total, settotal] = useState()
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const getClientQuotation = async (offset) => {
        const respone = await getAllQuotesAdmin(dispatch, token, offset ? offset - 1 : 0)
        if (respone.success) {
            settotal(respone?.response?.orderCount)
            setData(respone?.response?.order)
        }
    }
    useEffect(() => {
        getClientQuotation()
    }, [])

    const navigate = useNavigate();

    const handlePageRoute = () => {
        navigate('/admin/AdminCreateOrder');
    };
    return (
        <Layout sideKey={sideKey}>
            <Row className="">
                <Col md={20}>
                    <Appbar Heading={"Pending RFQ's "} />
                </Col>
                <Col md={4}>
                    <Button onClick={handlePageRoute} className="mt-2 w-[100%]" style={{ background: Colors.darkBlue, color: "white" }}>
                        Create a new order
                    </Button>
                </Col>
            </Row>
            <Divider className="mt-1 w-[full]" />
            <Spin spinning={pageLoader?.getAllQuotes || false}>
                <div className="w-full bg-[#f4f6fc]" >
                    <QuotationsTable data={data} total={total} getClientQuotation={getClientQuotation} />
                </div>
            </Spin>
        </Layout>
    )
}

export default CustomerQuotations