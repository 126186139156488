// API endpoints ( All API endpoints to be defined here )
// Below aredummy endpoints which are to be set accordingly
export const apiRoutes = {
  auth: {
    login: "/users/login",
    logout: "/users/logout",
    forgotPassword: {
      receiveOTP: "/users/forgotPassword",
      verifyOTP: "/users/verifyForgotPasswordOTP",
      changePassword: "/users/resetPassword",
      resendOTP: "/users/resend/otp"
    },
    signup: "/users/signup",
  },

  dashboard: {
    getDashboardOrderStatusAndItems: "/dashboard/getDashboardCount",
    // onProductRecomdentaionProduct: "/dashboard/prodcuctRecommendations",
    onProductRecomdentaionProduct: "/dashboard/productRecommendations",
    recurringProduct: "/dashboard/recurringOrders",
    getDashboardData: "/dashboard/graphData"
  },
  profile: {
    get: "/users/getUserProfile",
    update: "/users/update"
  },
  products: {
    get: "/products/getAllProducts",
    getProductByName: '/products/search',
    createProduct: '/products/create',
    deleteProductById: '/products/delete/',
    updateProductById: "/products/update",
    giveReview: "/Reviews/create",
    Category:'/products/allProductCategories',
    FilterCategory:'/products/allProductCategories',
    UpdateCategory:'/products/editProductCategory/',
    UpdateCategorylevel:'/products/getAllProductCategoriesLevelWise/'


  },
  Categories: {
    getAllProductCategory: "/products/getAllProductCategories",
    getProductByCategoryId: '/products/filterByProductCategories',
    getAllProductCategoriesWithLevel: '/products/getAllProductCategoriesLevel',
    createCategory: "/products/createProductCategory"
  },
  order: {
    create: "/orders/create",
  },
  address: {
    create: "/address/create",
    getAll: "/address/getAllAddresses",
    getById: "/address",
    deleteByID: '/address',
    updateAddress: "/address"
  },
  company:{
    getAllCompany:'/users/allCompanies'
  },
  customer: {
    getAll: '/users/allUsers',
    getUserById: '/users/getUserProfile',
    changeUserStatus: '/users/changeUserStatus',
    updateCustomer: "/users/updateAdmin",
    createCustomer: "/users/signup",
    createUser: "/users/signupCompanyUser",
    UpdateUser: "/users/updateCompanyAdmin",
    getusers:"users/allCompanyUsers",
    deleteUser:"users/deleteCompanyUser",


  },
  Quotation: {
    getAllQuotation: "/orders/getRFQs",
    getQuoteById: "/orders/getOrder",
    CreateOrderAdmin:'/orders/createOrderAdmin',
    changeOrderStatus: '/orders/changeOrderStatus',
    getUserQuotation: '/orders/getAllOrders',
    getApprovedQuotes: "/orders/getQps",
    rejectClientQuotation: "/orders/rejectQuotationRequest",

  },
  Order: {
    updateOrderByOrderId: '/orders/updateOrderItem',
    // updatePendingRfqOrderByOrderId:'/provideQuotation',
    deleteOrderByOrderId: "/orders/deleteOrderItem",
    updateCustomerOrderByOrderId: '/orders/updateOrderItemCust',
    updateCompleteOrderCust: "/orders/updateOrderCust",
    submitOrder: '/orders/submitorder',
    getAllOrders: "/orders/getOCs",
    updateOrder: "/orders/deliverOrder",
    updateCompleteOrder: "/orders/updateOrder",
    updateCompleteOrderRfq: "/orders/provideQuotation",
    NewupadteCompleteRfqOrder:'/orders/updateOrder',

    bulkOrderItemDelete:"/orders/deleteOrderItems",
    updateStatus:'orders/jumpToOrderStatus'


  },
  Invoices: {
    getAllCustomerInvoices: "/invoice/getAllInvoiceCust",
    getAllInvoice: '/invoice/getAllInvoice',
    getInvoiceById: "/invoice/getInvoice",
    getPayment:'/invoice/paymentReceived',

  },
  uploadOrderImage: {
    uploadOrder: '/uploads/uploadDocuments',

  },
  uploadAdminDocument: {
    uploadDocument: "/uploads/uploadAdminDocuments"
  },
  uploadDocument: {
    uploadDocument: "/uploads/uploadDocuments"
  },
  uploadProfilePicture: {
    uploadImage: "/uploads/uploadProfilePicture"
  },
  cartItems: {
    addToCart: "/cart/addCartItem",
    deleteCartItem: 'cart/deleteCartItem',
  },
  AdminProduct:{
    downloadFile:'/utils/exportProducts',
    uploadFile:'/utils/importProducts',
  

  },
  notification:{
    get:'/notifications',
    countNotification:'/notifications/count',
    readNotificationId:'notification/read/:notificationId',
    readallNotification:'notification/read/all'


  },
  status:{
    getStatus:'/orders/changeOrderStatus'
  }
};
