import { Button, Col, Divider, Form, Image, Input, Modal, Row, } from 'antd';
import { useEffect, useState } from 'react';
import { Colors } from '../../Assets/Colors';
import { Images } from '../../Assets/Images/Images';
import { CheckOutlined } from '@ant-design/icons';
import { changeUserStatus, updateCustomerAdmin } from '../../API-Interaction/api-functions/customer.api';
import { useDispatch, useSelector } from 'react-redux';
import { formErrors } from '../../../constant';
import './CustomerModal.css'

const ViewCustomerModal = ({ open, close, data, Type }) => {
    const dispatch = useDispatch()
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const [form] = Form.useForm()
    const token = localStorage.getItem('authTokenHaasil')
    const HanldeVerification = async (id, status,) => {
        let response = await changeUserStatus(dispatch, id, token, status)
        if (response.success) {
            close()
        }
    }
    const UpdateUser = async (values) => {
        values.userId = data?.response?.user?.[0]?.userId
        const response = await updateCustomerAdmin(dispatch, token, values)
        if (response.success) {
            close('Create')
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            "firstname": data?.response?.user?.[0]?.firstname,
            'lastname': data?.response?.user[0]?.lastname,
            'phoneNumber': data?.response?.user[0]?.phoneNumber,
            'name': data?.response?.user[0]?.Company?.name,
            'address': data?.response?.user[0]?.Company?.address,
            'city': data?.response?.user[0]?.Company?.city,
            'ntn': data?.response?.user[0]?.Company.ntn,
            'stn': data?.response?.user[0]?.Company.stn,
            'emailaddress': data?.response?.user[0]?.emailAddress,
        })
    }, [open, data])

    return (
        <div className=''>
            <Modal centered className='p-[0px] mt-[30px] mb-[0px] ' style={{
                '.css-dev-only-do-not-override-ed5zg0': {
                    marginBottom: '0px !important'
                }
            }} footer={null} head open={open} onCancel={close}>
                <div
                >

                    <div className='flex justify-between  items-center mt-3'>
                        <div className='flex items-center gap-5 '>
                            <Image preview={false} src={Images.DisplayPic} style={{ width: "70px" }} />
                            <div>
                                <p className={`large-font  text-[${Colors.Lightblue}] `} style={{ fontSize: "18px" }} >{data?.response?.user[0]?.firstname}</p>
                                <p className='bold-heading flex items-center text-[10px]'>
                                    {data?.response?.user[0]?.phoneNumber}</p>
                            </div>
                        </div>
                        <div>
                            {data?.response?.user[0]?.status === 1 ?
                                <div className='flex gap-1'>
                                    <Button className='rounded-[10px] text-[green] font-[700] border-none bg-[#0080001a]' onClick={() => { HanldeVerification(data?.response?.user[0]?.userId, 2) }}

                                    >
                                        Accept
                                    </Button>
                                    <Button onClick={() => { HanldeVerification(data?.response?.user[0]?.userId, 3) }}
                                        className='rounded-[10px] text-[maroon] font-[700] border-none bg-[#80000014] text-light'
                                    >
                                        Reject
                                    </Button>

                                </div> : data?.response?.user[0]?.status === 2 ?
                                    <div className='flex gap-1'>

                                        <Button
                                            className='rounded-[10px] text-[limegreen] font-[700] border-none bg-[#0080001a] cursor-default flex items-center'
                                        >
                                            Verified User <CheckOutlined />
                                        </Button>


                                    </div> :
                                    <Button disabled
                                        className='rounded-[10px] text-[maroon] font-[700] border-none  cursor-default flex items-center'

                                        style={{
                                            background: "rgba(128, 0, 0, 0.19)", cursor: "default"
                                        }}>Blocked
                                    </Button>
                            }
                        </div>
                    </div>
                    <Divider className='my-[10px] mx-[0px]' />

                    <Form name="customerModal"
                        className="w-full customerModal"
                        onFinish={UpdateUser}
                        form={form}
                    >
                        <Row className='flex justify-between mt-5 gap-2 items-center' >

                            <Col span={24} className='flex items-center gap-1 justify-between rounded-[10px] p-[10px] bg-[#f4f6fc]'

                                style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                <p className='bold-heading font-[800]' >
                                    First Name
                                </p>
                                <Form.Item className='' style={{}} name={'firstname'} rules={
                                    [
                                        {
                                            required: true,
                                            message: formErrors.emptyField
                                        }
                                    ]
                                }>

                                    <Input disabled={Type === 'View' ? true : false} style={{
                                        background: "#f4f6fc", fontSize: "12px", width: "fit-content", textAlign: 'end', border: Type === 'View' ? 'none' : ``
                                    }}
                                    />
                                </Form.Item>

                            </Col>
                            <Col span={24} className='flex items-center gap-1 justify-between' style={{ background: "#f4f6fc", padding: ' 10px', borderRadius: '10px', boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                <p className='bold-heading'>
                                    Last Name
                                </p>
                                <Form.Item name={'lastname'} rules={
                                    [
                                        {
                                            required: true,
                                            message: formErrors.emptyField
                                        }
                                    ]
                                }>

                                    <Input disabled={Type === 'View' ? true : false} style={{ background: "#f4f6fc", fontSize: "12px", width: "fit-content", textAlign: 'end', border: Type === 'View' ? 'none' : `` }}
                                    />
                                </Form.Item>

                            </Col>
                            <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                <p className='bold-heading'>
                                    Email Address
                                </p>
                                <Form.Item name={'emailaddress'} rules={
                                    [
                                        {
                                            required: true,
                                            message: formErrors.emptyField
                                        }
                                    ]
                                }>

                                    <Input disabled={Type === 'View' ? true : true}
                                        style={{ background: "#f4f6fc", fontSize: "12px", width: "fit-content", textAlign: 'end', border: Type === 'View' ? 'none' : `none` }}
                                    />
                                </Form.Item>

                            </Col>
                            <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                <p className='bold-heading'>
                                    Company Name
                                </p>
                                <Form.Item name={'name'} rules={
                                    [
                                        {
                                            required: true,
                                            message: formErrors.emptyField
                                        }
                                    ]
                                }>

                                    <Input disabled={Type === 'View' ? true : false} style={{ background: "#f4f6fc", fontSize: "12px", width: "fit-content", textAlign: 'end', border: Type === 'View' ? 'none' : `` }}
                                    />
                                </Form.Item>

                            </Col>

                            <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                <p className='bold-heading'>
                                    Address
                                </p>
                                <Form.Item className='w-full' name={'address'} rules={
                                    [
                                        {
                                            required: true,
                                            message: formErrors.emptyField
                                        }
                                    ]
                                }>

                                    <Input disabled={Type === 'View' ? true : false}
                                        style={{ background: "#f4f6fc", fontSize: "12px", textAlign: 'end', border: Type === 'View' ? 'none' : `` }}

                                    />
                                </Form.Item>

                            </Col>
                            <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                <p className='bold-heading'>
                                    City
                                </p>
                                <Form.Item name={'city'} rules={
                                    [
                                        {
                                            required: true,
                                            message: formErrors.emptyField
                                        }
                                    ]
                                }>

                                    <Input disabled={Type === 'View' ? true : false} style={{ background: "#f4f6fc", fontSize: "12px", width: "fit-content", textAlign: 'end', border: Type === 'View' ? 'none' : `` }}

                                    />
                                </Form.Item>

                            </Col>
                            <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                <p className='bold-heading'>
                                    Phone
                                </p>
                                <Form.Item name={'phoneNumber'} rules={
                                    [
                                        {
                                            required: true,
                                            message: formErrors.emptyField
                                        }
                                    ]
                                }>

                                    <Input disabled={Type === 'View' ? true : false} style={{ background: "#f4f6fc", fontSize: "12px", width: "fit-content", textAlign: 'end', border: Type === 'View' ? 'none' : `` }}


                                    />
                                </Form.Item>

                            </Col>
                            <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                <p className='bold-heading'>
                                    NTN
                                </p>
                                <Form.Item name={'ntn'}
                                    rules={
                                        [
                                            // {
                                            //     required: true,
                                            //     message: formErrors.emptyField
                                            // }
                                        ]
                                    }
                                >

                                    <Input disabled={Type === 'View' ? true : false} style={{ background: "#f4f6fc", fontSize: "12px", width: "fit-content", textAlign: 'end', border: Type === 'View' ? 'none' : `` }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                <p className='bold-heading'>
                                    STN
                                </p>
                                <Form.Item name={'stn'} rules={
                                    [
                                        // {
                                        //     required: true,
                                        //     message: formErrors.emptyField
                                        // }
                                    ]
                                }>

                                    <Input disabled={Type === 'View' ? true : false} style={{ background: "#f4f6fc", fontSize: "12px", width: "fit-content", textAlign: 'end', border: Type === 'View' ? 'none' : `` }}
                                    />
                                </Form.Item>

                            </Col>



                        </Row>
                        {/* Submit Button */}
                        {
                            Type !== "View" &&
                            <Row className='flex justify-end'>
                                <Col span={10}>
                                    <Button loading={pageLoader?.updateCustomer || false} htmlType="submit" className='mt-2 w-full' style={{ background: Colors.darkBlue, color: 'white', border: "none" }}>Submit</Button>
                                </Col>
                            </Row>
                        }


                    </Form>
                </div>
            </Modal >
        </div >
    );
};
export default ViewCustomerModal;