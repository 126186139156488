import React, { useState } from 'react';
import { Modal, Form, Input, Button, Spin } from 'antd';
import { Colors } from '../../../../Assets/Colors';

const UserForm = ({ visible, onCreate, onCancel, resetForm ,loading}) => {
  const [form] = Form.useForm();

  const compareToPassword = (_, value) => {
    const passwordFieldValue = form.getFieldValue('password');
    if (value && passwordFieldValue !== value) {
      return Promise.reject('Passwords do not match');
    }
    return Promise.resolve();
  };

  return (
    <Modal
      visible={visible}
      title="Create User"
      okButtonProps={{ style: { background: Colors.darkBlue, color: "white" } }}
      okText="Create"
      cancelButtonProps={{ style: { background: Colors.darkBlue, color: "white" } }}
      cancelText="Cancel"
      // onCancel={() => {
      //   form.resetFields();
      //   onCancel();
      // }}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((errorInfo) => {
            console.log('Validation failed:', errorInfo);
          })
          .finally(() => {
            if (resetForm) {
              form.resetFields();
            }
          });
      }}
    // onOk={() => {
    //   form
    //     .validateFields()
    //     .then((values) => {
    //       form.resetFields();
    //       onCreate(values);
    //     })
    //     .catch((errorInfo) => {
    //       console.log('Validation failed:', errorInfo);
    //     });
    // }}
    >
      <Spin spinning={loading}> {/* Conditionally render the Spin component */}

      <Form form={form} layout="vertical">
        <Form.Item
          name="firstname"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Please enter the user's First Name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastname"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Please enter the user's Last Name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          rules={[
            {
              required: true,
              message: "Please enter the user's Address!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="emailAddress"
          label="Email Address"
          rules={[
            {
              required: true,
              message: "Please enter the user's Email Address!",
            },
            {
              pattern: new RegExp(/^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/),
              message: 'Please enter a valid EmailAddress'
            }
          ]}
        >
          <Input type='email' />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please enter the user's password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: "Please confirm the user's password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Passwords do not match');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
      
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: 'Only numeric values are allowed!',
            },
            {
              required: true,
              message: 'Please enter a phone number',
            },
            {
              max: 13,
              message: 'Phone number should not exceed 13 digits.',
            },
            {
              min:11,
              message: 'Phone number should minimum 11 digits.',
            }
          ]}
        >
          <Input minLength={11} maxLength={13} className="p-2" placeholder="03342344523" />
        </Form.Item>
      </Form>
      </Spin>

    </Modal>
  );
};

export default UserForm;
