import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Typography,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { message, Spin } from "antd";
import Layout from "../../Layout/Layout";
import { Images } from "../../Assets/Images/Images";
import { Colors } from "../../Assets/Colors";
import { useDispatch, useSelector } from "react-redux";
import {
  onFetchProfile,
  onProfileSubmit,
} from "../../API-Interaction/api-functions/profile.api";
import { uploadImage } from "../../API-Interaction/api-functions/upload.api";
import { error, success } from "../../helpers/notifications";
import { debounce, debounceImage } from "../../helpers/helper";
import { errorFactory } from "../../API-Interaction/errorHandling";


const beforeUpload = (data) => {
  let { file } = data
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/Jpeg" || file.type === "image/PNG" || file.type === "image/JPEG" || file.type === "image/Png" ||file.type === ".pdf";
  if (!isJpgOrPng) {
    error('You can only upload JPG/PNG file!')
    return false
  }
  if (file.size > 2000000) {
    error('File must be smaller than 2MB!')
    return false
  }
  return true
};

const Viewprofile = () => {

  let token = localStorage.getItem('authTokenHaasil')
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { pageLoader } = useSelector((state) => state.utils.loaders);
  const [profileData, setProfileData] = useState({});

  const dispatch = useDispatch();

  // Handle Image Change
  const handleChange = debounceImage(async (info) => {
    let ImageURL = imageUrl
    setImageUrl('')
    setLoading(true)
    let check = beforeUpload(info)
    if (!check) {
      setImageUrl(ImageURL)
      setLoading(false)
      return
    }
    let { file } = info;
    let response = await uploadImage(dispatch, file.originFileObj, token)
    setImageUrl(response ? response : ImageURL);
    setLoading(false)
  });


  // Custom Upload Button
  const uploadButton = (
    <div className='flex justify-center items-center h-full flex-col'>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  // Handle Form Submit
  const HandleSubmit = async (values) => {
    setIsLoading(true);
    // if (!imageUrl) {
    //   message.error('Please upload an image.');
    //   setIsLoading(false);
    //   return;
    // }

    let body = { ...values, profilePicURL: imageUrl }

    let response = await onProfileSubmit(dispatch, body, token);
    setProfileData(response.response.user); 
    setIsLoading(false);
    FetchProfileData()
  };

  const FetchProfileData = async () => {
    setIsLoading(true);
    let response = await onFetchProfile(dispatch, token);
    setImageUrl(response?.response?.user[0].profilePicURL ? response?.response?.user[0].profilePicURL : "")
    if (response?.response?.user[0]?.profilePicURL)
      localStorage.setItem('HaasilUserImage', response?.response?.user[0]?.profilePicURL)

    form.setFieldsValue(
      {
        ...response?.response?.user[0],
        ...response?.response?.user[0].Company,
      }
    )
    localStorage.setItem('CompanyDetails', response?.response?.user[0].Company?.name)
    setProfileData(response.response.user[0]);
    setIsLoading(false);
  };

  useEffect(() => {
    FetchProfileData()
  }, []);


  return (
    <Layout>
      <Form
        name="personalinfo"
        onFinish={HandleSubmit}
        form={form}
      >
        <Spin spinning={pageLoader?.profile || false}>
          <Row>
            <Col span={24}>
              <p level={3} className="header-heading bold-heading">Profile</p>
              <Divider className="my-[5px] mx-[0px]" />

              <Col md={10} xs={24}>
                <div className="flex flex-col gap-">
                  {/* Upload Image  */}
                  <div className="view-profile-cont">
                    <Upload
                      name="avatar"
                      listType="picture-circle"
                      className="avatar-uploader"
                      showUploadList={false}
                      onChange={handleChange}
                      style={{
                        overflow: "hidden",
                        height: "100px",
                      }}
                    >
                      <div
                        className="h-full w-full"
                        style={{ position: "relative" }}
                      >
                        {imageUrl ? (
                          <div
                            className="h-full "
                            style={{ position: "relative" }}
                            
                          >
                            <img
                              preview={false}
                              
                              src={imageUrl ? imageUrl : Images.DisplayPic}
                              alt="avatar"
                              style={{
                                objectFit: "fill",
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                                overflow: "hidden",
                                
                              }}
                            />
                          </div>
                        ) : (
                          uploadButton
                        )}

                        <div className="
                        absolute 
                        right-[10px] 
                        bottom-[10px] 
                        z-[10] h-[30px] w-[30px] p-[5px] rounded-[50%] bg-[#F3EBEB]"

                        >
                          {" "}
                          <img
                            preview={false}
                            src={Images.CameraUpload}
                            alt="avatar"
                            className="object-contain w-[100%] h-[100%] overflow-hidden"

                          />
                        </div>
                      </div>
                    </Upload>
                  </div>
                  {/* Personal Information  */}
                  <Typography.Text
                    className="text-base mb-3 font-[900]  flex-nowrap header-heading "
                    style={{
                      textShadow: "0.5px 0 #000000",
                    }}
                  >
                    Personal Information 
                  </Typography.Text>
                  <Divider className="mt-[5px] mb-[5px] " />

                  <div className="flex flex-col gap-1">
                    <p className="support-heading font-[900]"> Name</p>
                    <Form.Item
                      name={"firstname"}
                      rules={[
                        {
                          required: true,
                          message: "Name is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="support-heading font-[900]">Last Name</p>
                    <Form.Item
                      name={"lastname"}
                      rules={[
                        {
                          required: true,
                          message: "Last name is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="support-heading font-[900]" >Email</p>
                    <Form.Item
                      name={"emailAddress"}
                      rules={[
                        {
                          required: true,
                          message: "Email is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="support-heading font-[900]">Phone Number</p>
                    <Form.Item
                      name={"phoneNumber"}
                      rules={[
                        {
                          pattern: /^[0-9]+$/, 
                          message: 'Only numeric values are allowed!',
                        },
                        {
                          required: true,
                          message: 'Please enter a phone number',
                        },
                        {
                          max: 13,
                          message: 'Phone number should not exceed 13 digits.',
                        },
                      ]}
                    >
                      <Input maxLength={13} className="p-2" placeholder="03342344523" />

                      {/* <Input /> */}
                    </Form.Item>
                  </div>

                  {/* Personal Information  */}
                  <Typography.Text
                    className="text-base font-[900] text-[21px] flex-nowrap mb-3 header-heading "
                    style={{
                      textShadow: "0.5px 0 #000000",
                    }}
                  >
                    Company  Information
                  </Typography.Text>

                  <Divider className="mt-[5px] mb-[5px] " />


                  <div className="flex flex-col gap-1">
                    <p className="support-heading font-[900]">Comapany Name</p>
                    <Form.Item
                      name={"name"}
                      rules={[
                        {
                          required: true,
                          message: "Comapny name is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="support-heading font-[900]">Address</p>
                    <Form.Item
                      name={"address"}
                      rules={[
                        {
                          required: true,
                          message: "Address is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="support-heading font-[900]" >City</p>
                    <Form.Item
                      name={"city"}
                      rules={[
                        {
                          required: true,
                          message: "City is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="flex items-center gap-1 ">
                    <div className="flex-col gap-1 flex-1">
                      <p className="support-heading font-[900]">NTN</p>
                      <Form.Item
                        name={"ntn"}
                        rules={[
                          // {
                          //   required: true,
                          //   message: "Ntn number is required",
                          // },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="flex-col gap-1 flex-1">
                      <p className="support-heading font-[900]">STN</p>
                      <Form.Item
                        name={"stn"}
                        rules={[
                          // {
                          //   required: true,
                          //   message: "Stn number is required",
                          // },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>

                  </div>

                  <Row>
                    <Col span={4}>
                      <Button
                        htmlType="submit"
                        loading={pageLoader?.profile || false}
                        className={`bg-[${Colors?.darkBlue}] mt-2 w-[150px] text-[white] `}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Layout>
  );
};

export default Viewprofile;
