import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { Images } from '../Assets/Images/Images';
import { pageRoutes } from '../Routes/pageRoutes';

const Pagenotfound = () => {
  const navigate = useNavigate()
  const token = localStorage?.getItem("authTokenHaasil")
  useEffect(() => {
    setTimeout(() => {
      if (token?.length) return navigate(pageRoutes?.public?.login)
      navigate("/dashboard")
    }, 5000);
  }, [])

  return (
    <div className="flex flex-col" style={{ height: "100vh" }}>
      <div className='bg-[#061E3A] flex-[0.08] flex items-center p-2 w-full justify-between'>
        <img
          className="cursor-pointer h-[40px] object-contain"
          src={Images.Logo}
          alt=""
        />
        <div className="flex flex-col text-[10px] cursor-pointer text-white" >
          <p
            className="p-0 whitespace-nowrap"
            style={{ fontSize: "12px", letterSpacing: "2px" }}
          >
            Liquid Technologies
          </p>
          <p className="p-0 whitespace-nowrap " style={{ fontSize: "9px" }}>
            {" "}
            Haasil Dashbaord
          </p>
        </div>
      </div>
      <div className='flex flex-col flex-[0.92] items-center justify-center'>
        <span className='text-[30px] text-gray-600 font-bold'>Error 404!!</span>
        <span className='text-[11px]'>The page you are looking for doesn't exist.</span>
        <span className='text-[11px]'>You may have mistyped the address or the page may have moved.</span>
      </div>
    </div >
  )
}

export default Pagenotfound