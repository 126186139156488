import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Menu, Select } from 'antd';
import { getFilterCustomerList } from '../../../../API-Interaction/api-functions/customer.api';
import { useDispatch } from 'react-redux';

const FilterableData = ({ passData, getData }) => {
    const [filterStatus, setFilterStatus] = useState({ label: 'All', value: 'all' });
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const token = localStorage.getItem('authTokenHaasil');
    const dispatch = useDispatch()

    const fetchData = async (value, data) => {

        setFilterStatus(data)
        if (value == 'all')
            return getData()

        try {
            const statusMappings = {
                'NOT_VERIFIED': 1,
                'VERIFIED': 2,
                'BLOCKED': 3
            };
            const statusValue = statusMappings[value];
            const response = await getFilterCustomerList(token, dispatch, statusValue);
            passData(response)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div>
            <Select
                placeholder="Filter By Status"
                optionFilterProp="children"
                onChange={fetchData}
                style={{ width: '160px', height: '' }} 
                value={filterStatus}

                options={[
                    {
                        value: 'all',
                        label: 'All',
                    },
                    {
                        value: 'VERIFIED',
                        label: 'Verified',
                    },
                    {
                        value: 'NOT_VERIFIED',
                        label: 'Not Verified',
                    },
                    {
                        value: 'BLOCKED',
                        label: 'Rejected',
                    },
                ]}
            />
            <div>
                {filteredCustomers?.map(item => (
                    <div key={item.id} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                        <h3>{item.name}</h3>
                        <p>Status: {item.status}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FilterableData;
