import React, { useEffect, useState } from "react";
import { Button, Card, Col, ConfigProvider, DatePicker, Divider, Input, Modal, Row, Spin, Upload } from "antd";
import Layout from "../../../Layout/Layout";
import Appbar from '../../../Components/Appbar/Appbar'
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "../../../Assets/Colors";
import Quotestable from "../../../Components/Admin/Table/Quotestable";
import { CloudDownloadOutlined, CloudUploadOutlined, DeleteOutlined, LinkOutlined, LoadingOutlined, LockOutlined, UploadOutlined } from "@ant-design/icons";
import { AdminQuotationforCustomer, ChangeQuotationStatusAdmin, rejectClientQuotation } from "../../../API-Interaction/api-functions/quotation.api";
import { useDispatch, useSelector } from "react-redux";
import ApprovedQuoteModal from "../../../Components/Admin/Modals/ApprovedQuoteModal";
import { CSVLink, CSVDownload } from "react-csv";
import { FileExcelOutlined } from '@ant-design/icons';
import { DataForCSV } from "../../../helpers/DataForCSV";
import { onOrderDelivered } from "../../../API-Interaction/api-functions/order.api";
import { success } from "../../../helpers/notifications";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import locale from 'antd/locale/zh_CN';
import moment from "moment";
import { checkbeforeUpload, debounceImage } from "../../../helpers/helper";
import { uploadAdminDocument, uploadOrderImage } from "../../../API-Interaction/api-functions/upload.api";
import { Select } from 'antd';
// import AddProductTable from "../../../Components/Admin/Table/addPorductTable";
import NewTable from "../../../Components/Admin/Table/NewTable";
import { getCompanyList, getFilterProductCustomerList } from "../../../API-Interaction/api-functions/customer.api";


const AdminCreateOrder = () => {
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = localStorage.getItem('authTokenHaasil')
    const { id } = useParams()
    const [view, setView] = useState("Grid")
    const [Data, setData] = useState()
    const [preferredDate, setPreferredDate] = useState('')
    const [open, setOpen] = useState(false)
    const [adminRemarks, setAdminRemarks] = useState("")
    const [QuotationStatus, setQuotationStatus] = useState('')
    const [ExcelHeader, setExcelHeader] = useState([])
    const [ExcelData, setExcelData] = useState([])
    const [deliveryDate, setDeliveryDate] = useState(null)
    const [expiryDate, setexpiryDate] = useState(null)
    const [loading, setloading] = useState(false)
    const [adminDocument, setAdminDocument] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentFilterOption, setCurrentFilterOption] = useState({ label: 'All', value: 'all' });
    const [customers, setCustomers] = useState([]);
    const [rows, setRows] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [selectedAddress, setSelectedAddress] = useState({});
    const [company, setCompany] = useState([])
    const [selectedcompany, setSelectedCompany] = useState([])
    const [errorMessage, setErrorMessage] = useState([]);

    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

    { console.log(rows) }
    const SubmitQuotation = async () => {
        if (rows.some(row => row.quantity === 0 || row.unitrate === 0)) {
            // Set the error message
            setErrorMessage("Please add the Unit Price & Quantity of the quotation");
            setIsErrorModalVisible(true);

            return;
        }

        
        // return console.log({ rows })
        let result = await AdminQuotationforCustomer(dispatch, token, id,
            {
                remarks: adminRemarks,
                status: "QP",
                deliveryDate: moment(deliveryDate ? deliveryDate?.$d : new Date()).format('YYYY-MM-DD'),
                preferredDeliveryDate: moment(preferredDate ? preferredDate?.$d : new Date()).format('YYYY-MM-DD'),
                customerId: selectedcompany?.data?.Users[0]?.userId,
                companyId: selectedcompany?.data?.companyId,
                address: selectedcompany?.data?.Users[0]?.Addresses[0],
                products: rows?.map((row) => ({
                    quantity: row.quantity,
                    price: row.unitrate,
                    GST: row.gst,
                    totalPrice: Math.ceil((row?.quantity * (row?.unitrate ? row?.unitrate : 0)) + ((row?.quantity * (row?.unitrate ? row?.unitrate : 1)) * ((row?.gst ? row?.gst : 0) / 100))),
                    productId: row.id,
                }))
            })
        if (result.success) {
            HandleModal()
        }
    }


    const HandleModal = () => {
        if (open) return navigate('/admin/approved-quotations')
        setOpen(!open)
    }

    const RejectQuotation = async () => {
        const result = await rejectClientQuotation(dispatch, token, id)
        if (result.success) {
            navigate('/admin/quotations')
        }
    }



    const SortDataForCSV = () => {
        if (Data) {
            let item = DataForCSV(Data)
            setExcelHeader(item.header.headers)
            let cell = item.Cell()
            setExcelData(cell)
        }

    }





    const FomatDate = () => {
        // console.log("DATA", Data?.preferredDeliveryDate)

        const formattedDate = moment(Data?.preferredDeliveryDate).format('DD-MM-YYYY');
        setPreferredDate(formattedDate)
        // console.log("DATA", Data?.preferredDeliveryDate)
        if (Data?.status == 'OC' || Data?.status == 'OR') {
            const formattedDate = moment(Data?.deliveryDate).format('DD-MM-YYYY');
            setDeliveryDate(formattedDate)
        }
    }


    const onChange = (date, dateString) => {
        setDeliveryDate(dateString)
    };



    const disabledDate = current => {
        // Get today's date
        const today = new Date();

        // Calculate the minimum allowed date
        const minDate = new Date();
        minDate.setDate(today.getDate() + 2); // Minimum two days after today

        // Disable dates before the minimum allowed date
        return current && current < minDate;
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {

        RejectQuotation();

        // Close the modal after performing the action
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleUploadDocument = debounceImage(async (info) => {
        setloading(true)
        const { file, fileList } = info
        const check = checkbeforeUpload(info)
        if (!check) {
            setloading(false)
            return
        }
        let response = await uploadAdminDocument(dispatch, file.originFileObj, token, id)
        setAdminDocument([...adminDocument, response])
        setloading(false)
    })

    const manageAdminDocument = (item) => {
        const newList = adminDocument.filter(dataItem => {
            if (dataItem !== item) {
                return dataItem
            }
        })
        setAdminDocument(newList)
    }
    const fetchData = async (value, data) => {

        //     try {
        //         const response = await getFilterProductCustomerList(token, dispatch);
        //         const formattedCustomers = response
        //             ?.response
        //             ?.users
        //             ?.map((User) => ({ value: User?.userId, label: User?.firstname + ' ' + User?.lastname, data: User }))
        //         setCustomers(formattedCustomers);
        //         setSelectedCustomer(formattedCustomers[0])
        //         // setSelectedAddress({ value: formattedCustomers[0]?.data?.Addresses[0]?.address, label: formattedCustomers[0]?.data?.Addresses[0]?.address, data: formattedCustomers[0]?.data?.Addresses[0] })
        // //   setSelectedAddress(console.log({formattedCustomers}))
        //     } catch (error) {
        //         console.error('Error fetching data:', error);
        //     }

    };

    const fetchDataCompany = async (value, data) => {

        try {
            const response = await getCompanyList(token, dispatch);
            const formattedCompany = response?.response?.companies?.map((Company) => ({ value: Company?.companyId, label: Company?.name, data: Company }))
            setCompany(formattedCompany);
            setSelectedCompany(formattedCompany[0])
            setSelectedAddress({ value: formattedCompany[0]?.data?.Users[0]?.Addresses[0]?.address, label: formattedCompany[0]?.data?.Users[0]?.Addresses[0]?.address, data: formattedCompany[0]?.data?.Users[0]?.Addresses[0] })
            setSelectedCustomer({ value: formattedCompany[0]?.data?.Users[0]?.userId, label: formattedCompany[0]?.data?.Users[0]?.firstname + ' ' + formattedCompany[0]?.data?.Users[0]?.lastname, data: formattedCompany[0]?.data?.Users[0] })

        } catch (error) {
            console.error('Error fetching data:', error);
        }

    };

    useEffect(() => {
        SortDataForCSV()
    }, [Data])

    useEffect(() => {
        FomatDate()
    }, [Data])

    useEffect(() => {
        fetchData()
        fetchDataCompany()
    }, [])

    return (
        <Layout>
            <Spin spinning={pageLoader?.changeOrderStatus || false}>
                <div className=" mb-10">
                    <Row >
                        <Appbar Heading={`Admin Create Order`} view={view} setView={setView} ExtraDetail={true} />
                        <Divider className="m-[10px]" />
                    </Row>

                    <Row className="flex justify-between w-full flex-col h-fit">
                        <p className={`mt-[4px]  support-heading bg-[${Colors.grayTxt}] text-[13px] font-bold`}>
                            Customer Detail
                        </p>
                        <Row className="w-full justify-start gap-0 h-fit items-center">
                            <Col lg={7} xs={12} className="flex justify-end  p-1 relative overflow-hidden">
                                <div className={`p-3 flex flex-col gap-3 w-full bg-[white] rounded-[5px] text-[black] text-[12px] h-fit
                                            overflow-hidden
                                            shadow-[rgba(149, 157, 165, 0.2) 0px 8px 24px] text-[${Colors.darkBlue}]
                                            `} style={{
                                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
                                    }}
                                >
                                    <div className="flex justify-between">
                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800] ">Name: </span> {selectedcompany?.data?.Users[0]?.firstname + " " + selectedcompany?.data?.Users[0]?.lastname}

                                        </p>

                                    </div>
                                    <div className="flex justify-between">
                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800] ">Address: </span> {selectedcompany?.data?.Users[0]?.Addresses[0]?.address}
                                        </p>
                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800] ">City: </span>{selectedcompany?.data?.Users[0]?.Addresses[0]?.city}
                                        </p>
                                    </div>
                                    <p className="flex gap-2 ">
                                        <span className="flex justify-between font-[800] ">Location Title :</span>{selectedcompany?.data?.Users[0]?.Addresses[0]?.addressTitle}
                                    </p>
                                    <div className="flex justify-between" >
                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800] ">Phone:</span> {selectedcompany?.data?.Users[0]?.Addresses[0]?.number}
                                        </p>


                                    </div>
                                    <div className="flex justify-between" >

                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800] ">ZipCode:</span> {selectedcompany?.data?.Users[0]?.Addresses[0]?.zipcode || 'N/A'}
                                        </p>

                                    </div>

                                </div>


                            </Col>
                            <Col lg={7} xs={12} className="flex justify-end relative overflow-hidden " style={{ boxShadow: " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}>
                                <div className={` bg-[${''}] 
                                        text-[${Colors.darkBlue}]
                                         h-fit 
                                         p-3 flex flex-col
                                          gap-3 w-full rounded-[5px] 
                                          text-[black]
                                        text-[12px]
                                            shadow-[ rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`} >

                                    <p className="flex gap-2">
                                        <span className="flex justify-between font-[800] items-center ">Company Name: </span>

                                        <Select
                                            placeholder="Filter By Status"
                                            optionFilterProp="label"
                                            onChange={(_, value) => setSelectedCompany(value)}
                                            style={{ width: '200px' }}
                                            value={selectedcompany}
                                            options={company || []}
                                            showSearch={true}
                                        />
                                    </p>

                                    <p className="flex gap-14 font-[800] items-center">Address:
                                        <Select
                                            placeholder="Filter By Status"
                                            optionFilterProp="children"
                                            onChange={(_, value) => setSelectedAddress(value)}
                                            style={{ width: '200px' }}
                                            value={selectedAddress}
                                            options={selectedcompany?.data?.Users[0]?.Addresses?.map((address) => ({ value: address?.address, label: address?.address, data: address })) || []}
                                        />

                                    </p>
                                    <div className="flex justify-between">
                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800]">City: </span> {
                                                // selectedCustomer?.data?.Company?.city
                                                selectedcompany?.data?.city
                                            }
                                        </p>





                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800]">NTN: </span>{
                                                selectedcompany?.data?.ntn || 'N/A'}
                                        </p>
                                    </div>
                                    <div className="flex justify-between">

                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800]">STN: </span>{selectedcompany?.data?.stn || 'N/A'}
                                        </p>


                                        <p className="flex gap-2">
                                            <span className="flex justify-between font-[800]">PO number: </span>{Data?.purchaseOrder ? Data?.purchaseOrder : "N/A"}
                                        </p>
                                    </div>
                                </div>

                            </Col>
                            {
                                preferredDate &&
                                <Col lg={9} xs={24} className="flex ml-1 flex-col py-1 gap-3  px-3 " style={{ boxShadow: " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", height: '160px' }}>


                                    {
                                        Data?.status === 'RFQ' &&
                                        <p className="flex  items-center px-2 font-semibold justify-between " style={{ borderRight: "1px solid lightgray" }}>
                                            Set Quotation Expiry:
                                            <DatePicker
                                                // disabledDate={disabledDate}
                                                onChange={(date, dateString) => setexpiryDate(date)}
                                                format="DD-MM-YYYY"
                                            />
                                        </p>
                                    }
                                    {/* <p className="flex gap-2 items-center px-2 font-semibold justify-between " style={{ borderRight: "1px solid lightgray" }}>
                                        Client Prefered Delivery Date:
                                        <DatePicker
                                            defaultValue={dayjs(preferredDate, 'DD-MM-YYYY')} // Ensure preferredDate is in DD-MM-YYYY format
                                            // disabled
                                            format="DD-MM-YYYY" // Set the format explicitly
                                        />
                                    </p> */}

                                    <p className="flex gap-2 items-center px-2 font-semibold justify-between " style={{ borderRight: "1px solid lightgray" }}>
                                        Client Prefered Delivery Date:


                                        <DatePicker
                                            defaultValue={dayjs(preferredDate, 'DD-MM-YYYY')}
                                            // onChange={(date, inString) => setPreferredDate(date)}
                                            onChange={(date, inString) => {
                                                if (date === null) {
                                                    // If date is null, hide the "Client Prefered Delivery Date" block
                                                    document.getElementById('clientPreferredDeliveryDate').style.display = 'none';
                                                  } else {
                                                    // If date is selected, update the preferredDate
                                                    setPreferredDate(date);
                                                  }
                                                }}
                                            format="DD-MM-YYYY"
                                            placeholder="Enter Date" />
                                    </p>
                                    <p className="flex gap-2 items-center px-2 font-semibold justify-between " style={{ borderRight: "1px solid lightgray" }}>
                                        Delivery Date:
                                        {

                                            <DatePicker
                                                defaultValue={dayjs(preferredDate, 'DD-MM-YYYY')}
                                                onChange={(date, inString) => setDeliveryDate(date)}
                                                format="DD-MM-YYYY"
                                                placeholder="Enter Date" 
                                                required
                                                />

                                        }
                                    </p>


                                </Col>
                            }

                        </Row>

                        <Divider className="m-[10px]" />
                    </Row>
                    
                    {/* Quotes Table  */}
                    <div className="w-full bg-[#f4f6fc]" >
                        <NewTable
                            setRows={setRows}
                            rows={rows}
                        />
                    </div>

                    {/* Client Uploaded Documents  */}
                    {
                        Data?.adminDocuments &&
                        <div className="mt-2 flex  flex-col">
                            <p className={`font-bold text-[20px] text-[${Colors.darkBlue}]`} >
                                Admin Uploaded Documents:
                            </p>
                            <div className="flex  gap-3" >
                                {
                                    Data?.adminDocuments?.map((item, index) => {
                                        return (
                                            <a href={item} style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} className="h-[100px] cursor-pointer w-[100px] bg-[#f6f6f6] flex justify-center items-center  flex-col">
                                                <CloudDownloadOutlined className={`text-[30px] text-[#061E3A] `} />
                                                <p className="text-[10px]">Download File</p>

                                            </a>
                                        )
                                    })

                                }
                            </div>

                        </div>
                    }

                    {/* Client Uploaded Documents  */}
                    {
                        Data?.orderDocuments &&
                        <div className="mt-2 flex  flex-col">
                            <p className={`font-bold text-[20px] text-[${Colors.darkBlue}]`} >
                                Client Order Documents:
                            </p>
                            <div className="flex  gap-3" >
                                {
                                    Data?.orderDocuments?.map((item, index) => {
                                        return (
                                            <a href={item} style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} className="h-[100px] cursor-pointer w-[100px] bg-[#f6f6f6] flex justify-center items-center  flex-col">
                                                <CloudDownloadOutlined className={`text-[30px] text-[#061E3A] `} />
                                                <p className="text-[10px]">Download File</p>

                                            </a>
                                        )
                                    })

                                }
                            </div>

                        </div>
                    }




                    <div className="flex justify-between mt-3">



                        <Spin spinning={pageLoader?.getQuotesById || false}>
                            {
                                QuotationStatus !== 'OR' & QuotationStatus !== 'OC' ?
                                    <div className="flex gap-3">
                                        {
                                            // <Button
                                            //     loading={pageLoader.RejectQuotation || false}
                                            //     onClick={showModal}
                                            //     className={`border-none bg-[${Colors.darkBlue}] text-[white] rounded-[2px] font-[600] text-[13px] py-[0px] px-[35px]`}
                                            // >
                                            //     Reject Quote
                                            // </Button>

                                        }
                                        <Modal
                                            title="Confirm"
                                            visible={isModalVisible}
                                            onOk={handleOk}
                                            onCancel={handleCancel}
                                            okText="Yes"
                                            cancelText="No"
                                            okButtonProps={{ type: "default" }}
                                        >
                                            <p>Are you sure you want to reject the quote?</p>
                                        </Modal>
                                        <Button loading={pageLoader.changeOrderStatus || false} onClick={SubmitQuotation}
                                            style={{ background: Colors.darkBlue, color: "white", margin: '10px' }}>
                                            Create Order
                                        </Button>

                                        {/* 
                                        <Modal
                                            title="Error"
                                            visible={isErrorModalVisible}
                                            onCancel={() => setIsErrorModalVisible(false)}
                                            footer={null} 
                                        >
                                            {errorMessage}
                                        </Modal> */}
                                        <Modal
                                            title={
                                                <div className="text-center text-red-500 text-2xl font-bold">Error</div>
                                            }
                                            visible={isErrorModalVisible}
                                            onCancel={() => setIsErrorModalVisible(false)}
                                            footer={null}
                                        >
                                            <div className="text-center font-semibold text-sm">{errorMessage}</div>
                                        </Modal>

                                    </div>

                                    :
                                    <div className="flex gap-2">

                                        <Button onClick={() => { }} className={`border-none flex items-center text-[${Colors.darkBlue}] font-[600] py-[0px] px-[35px] rounded-[10px]`}>
                                            <CSVLink data={ExcelData} headers={ExcelHeader} filename='HaasilInvoice.csv' className="flex items-center gap-1 "> Download CSV <FileExcelOutlined className="text-[green] text-[15px]" /></CSVLink>
                                        </Button>
                                        <Button loading={pageLoader?.orderDelivered || false} onClick={() => { }}
                                            className={`border-none ${Data?.status !== 'OR' ? `bg-[${Colors.darkBlue}] text-[white] ` : " text-[lightgreen] border-[green] border-2"}  rounded-[4px] font-[600] text-[13px] py-[0px] px-[35px]`}>
                                            {
                                                Data?.status === 'OC' ? "Mark Order as Delivered" : "Delivered"
                                            }
                                        </Button>
                                    </div>
                            }
                        </Spin>



                    </div>

                    {/* Render Uploaded Documents Lists  */}
                    {adminDocument.length !== 0 &&
                        <div className="mt-[10px]">
                            {adminDocument.map((item, index) => {
                                return (
                                    <div className="flex justify-between ">
                                        <div className="flex items-center gap-2">
                                            <LinkOutlined className="text-[#1677ff]" />
                                            <a className="text-[#1677ff] cursor-pointer" href={item} >{item}</a>
                                        </div>
                                        <div>
                                            <Button className="shadow-none border-none" onClick={() => { manageAdminDocument(item) }}>
                                                <DeleteOutlined className="text-[red] " />
                                            </Button>

                                        </div>
                                    </div>

                                )
                            })}
                        </div>

                    }
                </div>
            </Spin>
            <ApprovedQuoteModal open={open} close={HandleModal} />
        </Layout >
    )
}

export default AdminCreateOrder