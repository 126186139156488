import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { Colors } from '../../Assets/Colors';
import { error, success } from '../../helpers/notifications';
import { uploadDocument, uploadOrderImage } from '../../API-Interaction/api-functions/upload.api';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, debounceImage } from '../../helpers/helper';
import { useState } from 'react';

const UploadButton = ({ setDocuments, id }) => {
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const [loading, setloading] = useState(false)

    let dispatch = useDispatch()
    let token = localStorage.getItem('authTokenHaasil')

    const checkbeforeUpload = (info) => {
        let { file } = info
        const fileListTypes = ["image/jpeg", "image/png", "image/Jpeg",
            "image/PNG", "image/JPEG", "image/Png", 'application/pdf',
            'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            '.xlsx', 'text/csv', '.docs', '.docx', '.doc',
            'text/plain', 'text/docs', 'text/docx',
            'text/word', 'text/xlsx', 'text/CSV'];
        const checkFile = fileListTypes.includes(file.type);
        if (!checkFile) {
            error('You can only upload JPG/PNG/Excel/Pdf/word file!')
            return false
        }
        if (file.size > 2000000) {
            error('File must be smaller than 2MB!')
            return false
        }
        return true
    }



    const HandleChange = debounceImage(async (info) => {
        setloading(true)
        const { file, fileList } = info
        const check = checkbeforeUpload(info)
        if (!check) {
            setloading(false);
            return
        }
        let response = await uploadOrderImage(dispatch, file.originFileObj, token)
        setDocuments([response])
        setloading(false)
    })

    return <Button loading={pageLoader.uploadDocument || false} className='flex items-center justify-center w-fit' style={{ border: "none", padding: "0px", margin: "0px" }} >
        <Upload showUploadList={false} onChange={HandleChange} maxCount={1} iconRender={!loading && <LoadingOutlined />} >
            <Button className='border-none shadow-none' disabled={loading} style={{ color: Colors.highlightedBorderColor }} >
                {loading ? <LoadingOutlined /> :
                    `Upload Document`

                }
            </Button>
        </Upload>
    </Button >
}
    ;
export default UploadButton;