export const DataForCSV = (data) => {
    let Data = {
        header: {
            headers: [
                { label: 'Customer Name', key: "customer_name" },
                { label: "POC", key: "poc" },
                { label: "Email", key: "email" },
                { label: "Phone", key: "phone" },
                { label: "SKU", key: "sku" },
                { label: "Product Name", key: "name" },
                { label: "Variant", key: "variant" },
                { label: "Unit", key: "unit" },
                { label: "Price", key: "price" },
                { label: "Total", key: "total" },
                { label: "Quantity", key: "quantity" },

            ]
        },
        Cell: () => {
            return data?.OrderItems.map((item, index) => {
                let sku = item?.Product?.internalReference;
                let price = item?.price;
                let quantity = item?.quantity;
                let total = item?.totalPrice;
                let name = item?.Product?.name

                return {
                    customer_name: index === 0 ? data?.User?.firstname : '',
                    poc: index === 0 ? data?.purchaseOrder : '',
                    email: index === 0 ? data?.User?.emailAddress : '',
                    phone: index === 0 ? data?.User?.phoneNumber : '',
                    sku: sku,
                    price: price,
                    total: total,
                    name: name,
                    quantity: quantity,
                    variant: '-',
                    unit: '-'
                }
            }
            )
        }
    }

    return Data;
}

