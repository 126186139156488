import { Button, Col, Modal, Row } from 'antd'
import React, { useRef, useState } from 'react'
import { Colors } from '../../Assets/Colors'
import { PrinterOutlined } from '@ant-design/icons'
import ReactToPrint from 'react-to-print'
import PakingSlipContent from './PakingSlipContent'
const ParkingSlipGenerator = ({ open, close, data }) => {
    let componentRef = useRef();
    const [Size, setSize] = useState(650)
    return (
        <Modal id='Invoice-Modal' width={Size} open={open} footer={null} onCancel={close} className='p-[0px] InvoiceModalCss' style={{ overflow: "hidden", position: 'relative', top: "10px" }}>
            {/*  Content to be printed*/}
            <div ref={(el) => (componentRef = el)} className=''>
                <div className='p-5'>
                    <PakingSlipContent data={data} />
                </div>
            </div>


            {/* React To Print Button */}
            <div className='flex justify-end w-full pt-2'>
                <ReactToPrint
                    trigger={() => <button id='InvoiceButton' className='flex items-center justify-center p-1 mr-3' style={{
                        backgroundColor: Colors.darkBlue, color: "white",
                        borderRadius: "5px",
                        width: "200px"
                    }}>
                        PRINT <PrinterOutlined />
                    </button>}
                    content={() => componentRef}
                />
            </div>
            {/* Bottom Red Color */}
            <div className='w-full mt-2 p-2' style={{ background: 'red', }}>
            </div>

        </Modal >
    )
}

export default ParkingSlipGenerator