
import React, { useEffect, useState } from 'react'
import { Space, Table, Tag, Button, Input } from 'antd';
import { Colors } from '../../Assets/Colors';
import { add_Product_Recommendation, onAddToCart, removeFromCart } from '../../API-Interaction/api-functions/cart.api';
import { useDispatch, useSelector } from 'react-redux';
import { removeItemFromCart } from '../../Redux/features/cartSlice';
import { DeleteOutlined, ShoppingCartOutlined } from '@ant-design/icons';



const ProductReccomendation = ({ productData }) => {
    const [data, setData] = useState([])
    const dispatch = useDispatch()
    const cart = useSelector((state) => state?.cart?.cartItems);

    useEffect(() => {
        setData(productData)
    }, [productData])



    const handleClick = (type, product, value) => {
        let quantity = cart[product?.productId]?.quantity;
        if (type === "increment")
            onAddToCart(product, quantity + 1, dispatch, cart);
        if (type === "decrement" && quantity > 1)
            onAddToCart(product, quantity - 1, dispatch, cart);
        if (type === "decrement" && quantity === 1)
            removeItemFromCart(dispatch, product?.productId);
        if (type === "UserInput") {
            // if (value == 0) {
            // } else {
            onAddToCart(product, Number(value), dispatch, cart);
            // }
        }
    };

    const handleAddToCart = async (product, existing_Pid) => {
        add_Product_Recommendation(product, existing_Pid, 1, dispatch, cart);
    };


    const removeItem = (product) => {
        removeFromCart(dispatch, product.productId)

    }

    const columns = [
        {
            title: 'Current',
            key: 'recommendationId',
            render: (_, data, index) => {
                return (
                    <p className='w-[140px]  overflow-hidden text-ellipsis'>{data?.Product?.name}</p>
                )
            }
        },
        // Suggested ----------->
        {
            title: 'Suggested',
            dataIndex: '1',
            key: '1',
            // align: "center",
            render: (_, data, index) => {
                return (
                    <p className='w-[140px]  overflow-hidden text-ellipsis'>{data?.RecommendedProduct?.name}</p>
                )
            }
        },
        {
            title: 'Saving %',
            dataIndex: '1',
            key: '1',
            align: "center",
            render: (_, data, index) => {
                return (
                    <p className='text-[red] font-bold'>{data?.saving}%</p>
                )
            }
        },

        {
            title: 'Update Cart',
            key: '2',
            dataIndex: '2',
            render: (item, data, index) => {
                return (

                    cart[data?.recommendedProductId]?.quantity > 0 ? (
                        <div className="flex items-center gap-2 w-[110px]" style={{
                            // boxShadow: " rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
                        }}>
                            <button
                                className="w-full flex  items-center"
                                style={{
                                    background: 'white',
                                    // border: "2px solid gray",
                                    color: "gray",
                                    padding: "3px",
                                    borderRadius: "7px",
                                    fontSize: "10px",
                                    boxShadow: " rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"


                                }}
                            >
                                <p
                                    onClick={() => handleClick("decrement", data?.RecommendedProduct)}
                                    className="h-full w-full flex items-center justify-center font-black text-[16px]"

                                >
                                    -
                                </p>
                                <input
                                    type="Number"
                                    className='text-[14px]'
                                    style={{
                                        // border: "2px solid gray",
                                        color: "gray",
                                        textAlign: "center",
                                        width: "30px",
                                        fontWeight: '800',
                                    }}
                                    onChange={(event) => {
                                        handleClick("UserInput", data, event.target.value);
                                    }}
                                    id="quantityInput"
                                    value={
                                        cart[data?.recommendedProductId]?.quantity
                                    }
                                />
                                <p
                                    onClick={() => handleClick("increment", data?.RecommendedProduct)}
                                    className="h-full w-full flex items-center justify-center font-black text-[16px]"
                                >
                                    +
                                </p>
                            </button>
                            {/* Delete Icon */}
                            <DeleteOutlined onClick={() => { removeItem(data?.RecommendedProduct) }} className="text-[red] text-[16px] cursor-pointer" />
                        </div>
                    ) : (
                        <Button
                            onClick={() => { handleAddToCart(data?.RecommendedProduct, data?.productId) }}
                            className="w-[100px] font-black flex items-center  justify-center"
                            style={{
                                background: 'white',
                                border: "2px solid gray",
                                color: "gray",
                                padding: "3px",
                                borderRadius: "7px",
                                fontSize: "11px",
                                fontWeight: '800'



                            }}
                        >
                            Add to cart <ShoppingCartOutlined className='text-[16px]' />
                        </Button>
                    )
                )
            }
        }
    ];
    return (
        <div className='item-Description  '>
            <Table pagination={false} columns={columns} dataSource={data} className=' h-[300px] overflow-y-scroll TableMain'    scroll={{
      y: 240,
    }}/>
        </div>

    )
}

export default ProductReccomendation