import { Form, Modal } from "antd";
import Typography from "antd/es/typography/Typography";
import { Button, Checkbox, Col, Divider, Input, Row, Select } from "antd";
import { Colors } from "../../Assets/Colors";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  onCreateAddress, onUpdateAddress
} from "../../API-Interaction/api-functions/address.api";
import { useEffect, useState } from "react";
import { formErrors } from "../../../constant";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const AddressModal = ({ open, close, fetchAddress, data }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const token = localStorage.getItem("authTokenHaasil")
  const handleChange = (value) => {
  };

  // Handle CheckBox
  const onChange = (e) => {
  };
  // Handle Form Submits
  const HandleForm = async (value) => {
    setLoading(true)
    let body = {
      ...value
    };

    if (data) {
      try {
        let response = await onUpdateAddress(dispatch, body, token, data?.addressId);
        if (response.success === true) {
          form.setFieldsValue(
            response.response.user[0]
          )
        }
      } catch (error) {
      } finally {
        close()
        setLoading(false)
        fetchAddress()
      }
    } else {
      try {
        let response = await onCreateAddress(dispatch, body, token);
        if (response.success === true) {

          form.setFieldsValue(
            response.response.user[0]
          )
          fetchAddress()
        }
      } catch (error) {
      } finally {
        close()
        setLoading(false)
      }
    }

  };

  if (data) {
    form.setFieldsValue(
      {
        addressTitle: data?.addressTitle,
        address: data?.address,
        fullname: data?.fullname,
        number: data?.number,
        city: data?.city,
        zipcode: data?.zipcode,
        state: data?.state,
      }
    )

  } else {
    form.setFieldsValue(
      {
        addressTitle: '',
        address: '',
        fullname: '',
        number: '',
        city: '',
        zipcode: '',
        state: '',
      }
    )
  }

  return (
    <div className="AddressModal p-5" style={{ zIndex: '999999999999999999999999999 !important' }}>
      <Modal
        centered
        open={open}
        onCancel={close}
        className="mt-[20px] mb-[3  0px]  "
        width={600}
        footer={null}
        style={{ zIndex: "9999999999999 !important" }}
      >
        <Form form={form} name="shippingform" onFinish={HandleForm}


        >
          <Row>
            <Col span={24}>
              <Typography.Text
                className="large-font header-heading"
              >
                Add  Address Hello world{" "}
              </Typography.Text>
              <Divider style={{ margin: "10px 0px" }} />
              {/* Form Section */}
              <div>
                <p className="support-heading bold-heading mt-[2px]">
                  Title  <strong style={{ color: 'red' }}>*</strong>
                </p>
                <Form.Item
                  name={"addressTitle"}
                  rules={[{
                    required: true, message:
                      formErrors.emptyField
                  }]}
                >
                  <Input size='middle' placeholder="Enter Title.." className="mt-1 support-heading" />
                </Form.Item>

                <p className="support-heading bold-heading mt-[7px]" >
                  Address  <strong style={{ color: 'red' }}>*</strong>
                </p>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message:
                        formErrors.emptyField
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Enter Address..." rows={3} size="middle" className="mt-1 support-heading " />
                </Form.Item>
                <p className="support-heading bold-heading mt-[2px]">
                  {"Full Name (First and Last name)"}  <strong style={{ color: 'red' }}>*</strong>
                </p>
                <Form.Item

                  name={"fullname"}

                  rules={[{
                    required: "true", message:
                      formErrors.emptyField
                  }]}
                >
                  <Input placeholder='Enter Name...' size="middle" className="mt-1 support-heading " />
                </Form.Item>
                <p className="support-heading bold-heading mt-[2px]">
                  Phone Number  <strong style={{ color: 'red' }}>*</strong>
                </p>
                <Form.Item
                  name={"number"}
                  rules={[
                    {
                      pattern: /^[0-9]+$/,
                      message: 'Only numeric values are allowed!',
                    },
                    {
                      required: true,
                      message: 'Please enter a phone number',
                    },
                    {
                      max: 13,
                      message: 'Phone number should not exceed 13 digits.',
                    },
                  ]}

                // rules={[{
                //   required: true, message:
                //     formErrors.emptyField
                // }]}

                >
                  <Input maxLength={13} placeholder='Enter Number...'
                    size="middle" className="mt-1 support-heading " />
                </Form.Item>
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="support-heading bold-heading mt-[2px]" >
                    City  <strong style={{ color: 'red' }}>*</strong>
                  </p>
                  <Form.Item
                    name="city"
                    rules={[{
                      required: true, message:
                        formErrors.emptyField
                    }]}
                  >
                    <Input placeholder="Enter City..." size="middle" className="mt-1 support-heading " />
                  </Form.Item>
                </div>
                <div>
                  <p className="support-heading bold-heading mt-[2px]" >
                    State
                  </p>
                  <Form.Item
                    name={"state"}
                  // rules={[{
                  //   required: true, message:
                  //     formErrors.emptyField
                  // }]}
                  >
                    <Select
                      placeholder='Select State here'
                      size="middle"
                      className="mt-1 support-heading  w-full"
                      defaultValue="state"
                      style={{ width: 100 }}
                      onCh ange={handleChange}
                      options={[
                        { value: "Sindh", label: "Sindh" },
                        { value: "Punjab", label: "Punjab" },
                        { value: "Balochistan", label: "Balochistan" },
                        { value: "NWFP", label: "NWFP", },
                      ]}
                    />
                  </Form.Item>
                </div>
                <div>
                  <p className="support-heading bold-heading mt-[2px]">
                    ZIP Code
                  </p>
                  <Form.Item
                    name={"zipcode"}
                  // rules={[
                  //   {
                  //     required: true, message:
                  //       formErrors.emptyField
                  //   },
                  // ]}
                  >
                    <Input type="Number" placeholder="Enter Zipcode..."
                      size="middle" className="mt-1 support-heading " />
                  </Form.Item>
                </div>
              </div>
              {/* <div className="">
                <Checkbox onChange={onChange}>
                  Make this my default address
                </Checkbox>
              </div> */}


              {
                data ?
                  <Button
                    loading={loading}
                    htmlType="submit"
                    className={`support-heading bold-heading cursor-pointer border-none font-light w-full  text-[white] h-[40px] mt-[10px] bg-[${Colors.darkBlue}]`}

                  >
                    Update
                  </Button>
                  :
                  <Button
                    loading={loading}
                    htmlType="submit"
                    className={`support-heading bold-heading cursor-pointer border-none font-light w-full  text-[white] h-[40px] mt-[10px] bg-[${Colors.darkBlue}]`}

                  >
                    Save
                  </Button>
              }
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default AddressModal;
