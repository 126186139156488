import { CheckOutlined, DeleteOutlined, EyeOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Image, Radio, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Colors } from '../../../Assets/Colors';
import { Images } from '../../../Assets/Images/Images';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from '../../Pagination/PaginationComponent';
import { getDateInProperFormat } from '../../../helpers/helper';




const ApprovedQuotationsTable = ({ data, getClientQuotation, total }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [count, setCount] = useState(total)
    const [Search, setSearch] = useState('')
    const navigate = useNavigate()

    const columns = [
        {
            title: 'Quotation No.',
            align: 'center',

            dataIndex: 'orderId',
            render: (item, Data, index) => {
                return <Button onClick={() => { navigate(`/admin/quotation/${Data.orderId}`) }} className='font-[800]' >
                    Q-00{item}
                </Button>

            }
        },
        {
            title: 'Name',
            align: 'center',

            dataIndex: 'firstname',
            render: (item, Data, index) => {
                return <p>{Data.User?.firstname}</p>
            }
        },
        {
            title: 'Company Name',
            align: 'center',

            dataIndex: 'Company Name',
            render: (item, Data, index) => {
                return <p>{Data.User?.Company?.name}</p>
            }
        },
        {
            title: 'Email Address',
            align: 'center',
            dataIndex: 'emailAddress',
            render: (item, Data, index) => {
                return <p>{Data.User?.emailAddress}</p>
            }
        },

        {
            title: 'Phone N.o',
            align: 'center',

            dataIndex: 'phone',
            render: (item, Data, index) => {
                return <p>{Data.User?.phoneNumber}</p>
            }
        },
        {
            title: 'Payment Type',
            align: 'center',

            dataIndex: 'paymentType',
            render: (item, Data, index) => {
                return <p>{Data?.paymentType}</p>
            }
        },
        {
            title: 'Prefered Delivery Date',
            align: 'center',

            dataIndex: 'deliveryDate',
            render: (item, Data, index) => {

                let date = getDateInProperFormat(Data?.preferredDeliveryDate)
                return <p>{date}</p>
            }
        },
        {
            title: 'Address Title',
            align: 'center',
            dataIndex: 'paymentType',
            render: (item, Data, index) => {
                return <p>{Data?.Address?.addressTitle}</p>
            }
        },

        {
            title: 'Actions',
            align: 'center',

            key: 'address',
            dataIndex: 'address',
            render: (_, record) => {
                return <div className='flex justify-center items-center gap-3'>
                    <Button onClick={() => { navigate(`/admin/quotation/${record.orderId}`) }} className='p-0 border-none  flex items-center justify-center ' >
                        <EyeOutlined className={`text-[20px] text-[${Colors.darkBlue}] shadow-none`} />
                    </Button>

                </div>
            },
            align: "center",
        },
    ];

    useEffect(() => {
        setCount(total)
    }, [total])


    return (
        <div className='w-full'>
            <Table
                style={{ overflowX: "scroll" }}
                pagination={false}
                columns={columns}
                dataSource={data}
                className='admin-customer-table'

            />
            <Row className="flex justify-end">
                <PaginationComponent search={Search} currentPage={currentPage} itemsPerPage={itemsPerPage} totalItems={count} setCurrentPage={setCurrentPage} callProducts={getClientQuotation} />
            </Row>

        </div>
    );
};
export default ApprovedQuotationsTable;



