import { apiRoutes } from "./apiRoutes";
import { errorFactory, ErrorHandling } from "./errorHandling";
import { appConfig } from "../config/config";
import axios from "axios";

export const getRequest = async (essentials) => {
    let { endPoint = '', loggedInToken = '', otherToken = '' } = essentials;
    try {
        let result = await fetch(`${appConfig?.apiUrl}${endPoint}`,
            {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': loggedInToken ? loggedInToken : otherToken,
                },
            });

        return await ErrorHandling(result, "get")
    }
    catch (error) {
        return errorFactory(error)
    }

}

export const postRequest = async (essentials) => {
    let { endPoint = '', loggedInToken = '', body = {}, otherToken = '', formData = false } = essentials;
    try {
        let result = await fetch(`${appConfig?.apiUrl}${endPoint}`,
            {
                method: 'POST',
                headers: {
                    'content-type': formData ? 'multipart/form-data' : 'application/json',
                    'Authorization': loggedInToken ? loggedInToken : otherToken,
                },
                body: formData ? body : JSON.stringify(body)
            });

        return await ErrorHandling(result, "post")
    }
    catch (error) {
        return errorFactory(error)
    }

}

export const uploadRequest = async (essentials) => {
    let { endPoint = '', loggedInToken = '', body = {} } = essentials;
    try {
        let result = await axios.post(
            `${appConfig?.apiUrl}${endPoint}`,
            body,
            {
                headers: {
                    'content-type': "multipart/form-data",
                    'Authorization': loggedInToken
                }
            }
        );
        return result
    }
    catch (error) {
        return errorFactory(error)
    }

}

export const putRequest = async (essentials) => {
    let { endPoint = '', loggedInToken = '', body = {}, otherToken = '' } = essentials;
    try {
        let result = await fetch(`${appConfig?.apiUrl}${endPoint}`,
            {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': loggedInToken ? loggedInToken : otherToken,
                },
                body: JSON.stringify(body)
            });

        return await ErrorHandling(result, "put")
    }
    catch (error) {
        return errorFactory(error)
    }

}

export const deleteRequest = async (essentials) => {
    let { endPoint = '', loggedInToken = '', body = {}, otherToken = '' } = essentials;
    try {
        let result = await fetch(`${appConfig?.apiUrl}${endPoint}`,
            {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': loggedInToken ? loggedInToken : otherToken,
                },
                body: JSON.stringify(body)
            });

        return await ErrorHandling(result, "delete")
    }
    catch (error) {
        return errorFactory(error)
    }

}