import { Button, Col, Divider, Form, Image, Input, Modal, Row, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Colors } from '../../Assets/Colors';
import { Images } from '../../Assets/Images/Images';
import { CheckOutlined } from '@ant-design/icons';
import { CreateCustomerAdmin, changeUserStatus, updateCustomerAdmin } from '../../API-Interaction/api-functions/customer.api';
import { useDispatch, useSelector } from 'react-redux';
import { formErrors } from '../../../constant';
import './CustomerModal.css'
import { inputValidations } from '../../helpers/helper';

const CreateCustomerModal = ({ open, close, data, Type, onCancel }) => {
    const dispatch = useDispatch()
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const [form] = Form.useForm()
    const token = localStorage.getItem('authTokenHaasil')
    const [loading, setLoading] = useState(false); // State to control loading spinner
    const passwordInputRef = useRef(null); // Create a ref for the password input field

    const HanldeVerification = async (id, status,) => {
        let response = await changeUserStatus(dispatch, id, token, status)
        if (response.success) {
            close()
        }
    }
    const UpdateCustomer = async (values) => {
        const User = JSON.parse(localStorage.getItem('User'))
        values.createdBy = User?.userId
        const response = await CreateCustomerAdmin(dispatch, token, values)
        console.log(values)
        if (response.success) {
            form.resetFields();

            close('Create')

        }
        setLoading(false)

    }


    return (
        <div className=''>
            <Modal centered className='p-[0px] mt-[30px] mb-[0px] ' style={{
                '.css-dev-only-do-not-override-ed5zg0': {
                    marginBottom: '0px !important'
                }
            }} footer={null} head open={open} onCancel={onCancel}>
                <div
                >
                    <Spin spinning={loading} size="large" tip="Loading..."> {/* Spin component covering the whole modal */}

                        <div className='flex justify-between  items-center mt-3'>
                            <div className='flex items-center gap-5 '>
                                {/* <Image preview={false} src={Images.DisplayPic} style={{ width: "70px" }} /> */}
                                <h1 style={{ fontWeight: 'bold', fontSize: '20px' }}>Create Customer</h1>
                                <div>
                                    <p className={`large-font  text-[${Colors.Lightblue}] `} style={{ fontSize: "18px" }} >{data?.response?.user[0]?.firstname}</p>
                                    <p className='bold-heading flex items-center text-[10px]'>
                                        {data?.response?.user[0]?.phoneNumber}</p>
                                </div>
                            </div>

                        </div>
                        <Divider className='my-[10px] mx-[0px]' />

                        <Form name="customerModal"
                            className="w-full customerModal"
                            onFinish={UpdateCustomer}
                            form={form}
                        >
                            <Row className='flex justify-between mt-5 gap-2 items-center' >

                                <Col span={24} className='flex items-center gap-1 justify-between rounded-[10px] p-[10px] bg-[#f4f6fc]'

                                    style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                    <p className='bold-heading font-[800]' >
                                        First Name
                                    </p>
                                    <Form.Item className='' style={{}} name={'firstname'} rules={
                                        [
                                            {
                                                required: true,
                                                message: formErrors.emptyField
                                            }
                                        ]
                                    }>

                                        <Input />

                                    </Form.Item>

                                </Col>
                                <Col span={24} className='flex items-center gap-1 justify-between' style={{ background: "#f4f6fc", padding: ' 10px', borderRadius: '10px', boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                    <p className='bold-heading'>
                                        Last Name
                                    </p>
                                    <Form.Item name={'lastname'} rules={
                                        [
                                            {
                                                required: true,
                                                message: formErrors.emptyField
                                            }
                                        ]
                                    }>


                                        <Input />
                                    </Form.Item>

                                </Col>
                                <Col span={24} className='flex items-center gap-1 justify-between' style={{ background: "#f4f6fc", padding: ' 10px', borderRadius: '10px', boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                    <p className='bold-heading'>
                                        Password
                                    </p>

                                    <Form.Item name={'password'}
                                        rules={inputValidations.password}
                                    >
                                        <Input.Password ref={passwordInputRef} autoComplete="new-password" />
                                    </Form.Item>


                                </Col>
                                <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                    <p className='bold-heading'>
                                        Email Address
                                    </p>

                                    <Form.Item name={'emailAddress'} rules={
                                        [
                                            {
                                                required: true,
                                                message: formErrors.emptyField
                                            }
                                        ]
                                    }>
                                        <Input autoComplete="off" />
                                    </Form.Item>

                                </Col>
                                <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                    <p className='bold-heading'>
                                        Company Name
                                    </p>
                                    <Form.Item name={'companyname'} rules={
                                        [
                                            {
                                                required: true,
                                                message: formErrors.emptyField
                                            }
                                        ]
                                    }>

                                        <Input />
                                    </Form.Item>

                                </Col>

                                <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                    <p className='bold-heading'>
                                        Address
                                    </p>
                                    <Form.Item className='w-full' name={'address'} rules={
                                        [
                                            {
                                                required: true,
                                                message: formErrors.emptyField
                                            }
                                        ]
                                    }>

                                        <Input />
                                    </Form.Item>

                                </Col>
                                <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                    <p className='bold-heading'>
                                        City
                                    </p>
                                    <Form.Item name={'city'} rules={
                                        [
                                            {
                                                required: true,
                                                message: formErrors.emptyField
                                            }
                                        ]
                                    }>

                                        <Input />
                                    </Form.Item>

                                </Col>
                                <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                    <p className='bold-heading'>
                                        Phone
                                    </p>
                                    {/* <Form.Item name={'phoneNumber'} rules={
                                        [
                                            {
                                                required: true,
                                                message: formErrors.emptyField
                                            }
                                        ]
                                    }>

                                        <Input />
                                    </Form.Item> */}
                                    <Form.Item
                                        name="phoneNumber"
                                        rules={[
                                            {
                                                pattern: /^[0-9]+$/,
                                                message: 'Only numeric values are allowed!'
                                            },
                                            {
                                                required: true,
                                                message: 'Please enter a phone number',
                                            },
                                            {
                                                max: 13,
                                                message: 'Phone number should not exceed 13 digits.',
                                            },
                                            {
                                                min: 11,
                                                message: 'Phone number should minimum 11 digits.',
                                            }
                                        ]}
                                    >
                                        <Input minLength={11} maxLength={13} className="p-2" placeholder="03342344523" />
                                    </Form.Item>

                                </Col>
                                <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                    <p className='bold-heading'>
                                        NTN
                                    </p>
                                    <Form.Item name={'ntn'}
                                        rules={
                                            [
                                                // {
                                                //     required: true,
                                                //     message: formErrors.emptyField
                                                // }
                                            ]
                                        }
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24} className='flex items-center gap-1 justify-between bg-[#f4f6fc] p-[10px] rounded-[10px] ' style={{ boxShadow: " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>

                                    <p className='bold-heading'>
                                        STN
                                    </p>
                                    <Form.Item name={'stn'} rules={
                                        [
                                            // {
                                            //     required: true,
                                            //     message: formErrors.emptyField
                                            // }
                                        ]
                                    }>

                                        <Input />
                                    </Form.Item>

                                </Col>



                            </Row>
                            {/* Submit Button */}
                            {
                                Type !== "View" &&
                                <Row className='flex justify-end'>
                                    <Col span={10}>
                                        <Button htmlType="submit" className='mt-2 w-full' style={{ background: Colors.darkBlue, color: 'white', border: "none" }}>Submit</Button>
                                    </Col>
                                </Row>
                            }


                        </Form>
                    </Spin>
                </div>
            </Modal >
        </div >
    );
};
export default CreateCustomerModal;