import {
  ADD_TO_CART,
  addProductRecomendation,
  removeItemFromCart,
  resetCart
} from "../../Redux/features/cartSlice";
import { setPageLoader } from "../../Redux/features/utilsSlice";
import { error, success } from "../../helpers/notifications";
import { postRequest } from "../apiMethods";
import { apiRoutes } from "../apiRoutes";

export const onAddToCart = async (product, quantity, dispatch, cart) => {
  dispatch(
    ADD_TO_CART({
      product,
      quantity,
    })

  );
  // localStorage.setItem("cartItems", JSON.stringify(cart));
};
export const removeFromCart = (dispatch, productId) => {
  dispatch(removeItemFromCart({ productId }));
}

export const onResetCart = (dispatch) => {
  dispatch(resetCart())
}

export const add_Product_Recommendation = async (product, Existing_Pid, quantity, dispatch, cart) => {
  dispatch(
    addProductRecomendation({
      product,
      quantity,
      Existing_Pid

    })

  );
}


export const callApiForCart = async (dispatch, token, data) => {
  dispatch(setPageLoader({ page: 'callApiForCart', flag: true }))
  let response = await postRequest({
    endPoint: apiRoutes.cartItems.addToCart,
    loggedInToken: token,
    body: { items: data }
  })
  dispatch(setPageLoader({ page: 'callApiForCart', flag: false }))
  if (response.success) {
    return response
  }
  else {
    error("Error Occur")
  }
}


export const setCartItems = async (dispatch, token, data) => {
  dispatch(setPageLoader({ page: 'callApiForCart', flag: true }))
  let response = await postRequest({
    endPoint: apiRoutes.cartItems.addToCart,
    loggedInToken: token,
    body: { items: data }
  })
  dispatch(setPageLoader({ page: 'callApiForCart', flag: false }))
  if (response.success) {
    return response
  }
  else {
    error("Error Occur")
  }
}