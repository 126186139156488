import { Button, Col, Divider, Image, Popover, Row } from 'antd'
import { DeleteOutlined, EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Colors } from '../../../Assets/Colors';
import { useState } from 'react';
import { Images } from '../../../Assets/Images/Images';
import { onDeleteProductById } from '../../../API-Interaction/api-functions/product.api';
import { useDispatch, useSelector } from 'react-redux';

const ProductListing = ({ name, imageUrl, data, HandleModal }) => {
    const [OpenPopOver, setOpenPopOver] = useState(false);
    const dispatch = useDispatch()
    const { pageLoader } = useSelector((state) => state.utils.loaders);

    const token = localStorage.getItem('authTokenHaasil')

    const hide = () => {
        setOpenPopOver(false);
    };
    const handleOpenChange = (newOpen) => {
        setOpenPopOver(!OpenPopOver);
    };

    // Delete Product

    const DeleteProduct = async (id) => {
        const result = await onDeleteProductById(dispatch, token, id)
        if (result.success) {
            hide()
        }

    }



    return (
        <Row className='mt-3 flex items-center justify-between w-full rounded-[10px] px-[20px] py-[10px]' style={{
            boxShadow: " rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
        }} >
            <Col span={10} className='flex gap-2 h-full items-center'>


                <div className="p-[1px] overflow-hidden rounded-[15px] h-[60px] w-[60px] flex justify-center items-center
                " style={{
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                    }}>
                    <Image
                        className="h-[30px] w-[30px] object-contain"
                        src={imageUrl ? imageUrl[0] : Images?.CameraUpload} />

                </div>
                <div className='flex flex-col h-full gap-1'>

                    <div className='flex flex-col text-[12px]' >

                        <p className='font-[700]' >
                            {name}
                        </p>
                    </div>

                    <p className={`p-[4px] bg-[#ecf2ff] text-[#8da7ff] text-[10px] rounded-[10px] w-[fit-content]`} >
                        {data?.productCategoryL1?.name}
                    </p>
                </div>
            </Col>
            <Col span={5}>
                <p className='text-[10px] font-[800x]' >
                    {data?.internalReference}
                </p>
            </Col>

            <Col span={5} className='h-full flex justify-end  gap-2'>
                <div className='flex gap-2 items-center justify-center text-[20px]' >

                    <span className={`rounded-[50%] w-[10px] h-[10px] `} style={{
                        background: data?.visibility ? 'limegreen' : 'red'
                    }}></span>

                    <EditOutlined className={`text-[${Colors.darkBlue}]`} onClick={() => { HandleModal('Update', data) }} />
                    <PlusSquareOutlined className={`text-[${Colors.darkBlue}]`} onClick={() => { HandleModal('Duplicate', data) }}/>
                    <Popover
                        placement="topLeft"
                        content={<div className='p-2 flex flex-col gap-3 items-center w-[200px]' > <p
                            className='font-[700]' >Are your sure you want to delete this product?</p>
                            <Divider className='m-[1px]' />
                            <div className='flex gap-3'>

                                <Button
                                    className='p-[4px] w-[fit-content] text-[green] font-[700]'
                                    loading={pageLoader?.deleteProductById || false} onClick={() => { DeleteProduct(data?.productId) }}>
                                    Yes
                                </Button>

                                <Button className='p-[4px] w-[fit-content] text-[maroon] font-[700]' onClick={() => { hide() }} >No
                                </Button>
                            </div>

                        </div>}
                        trigger="click"
                        open={OpenPopOver}
                        onOpenChange={handleOpenChange}
                    >
                        <DeleteOutlined className='text-[red]' />

                    </Popover>
                </div>
            </Col>
        </Row>)
}

export default ProductListing