import productNestle from './Assets/productNestle.png'

export const test = [

    {
        "id": 3,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21092&unique=1683187150000",
        "name": "Air Conditioner Gree Pular - 1 Ton",
        "description": "ADE-AIR-GRE-PULA-I1"
    },
    {
        "id": 4,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21093&unique=1683187150000",
        "name": "Air Conditioner Gree Pular - 2 Ton",
        "description": "ADE-AIR-GRE-PULA-I2"
    },
    {
        "id": 5,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21094&unique=1683187150000",
        "name": "Air Conditioner Haier Split - 1 Ton",
        "description": "ADE-AIR-HAI-SPLI-I1"
    },
    {
        "id": 6,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21101&unique=1683187150000",
        "name": "Air Freshener - Al Arabia Aseel",
        "description": "DEJ-AIR-AL-ASEE-0"
    },
    {
        "id": 7,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21102&unique=1683622438000",
        "name": "Air Freshener - Al Arabia Darahim",
        "description": "DEJ-AIR-AL-DARA-0"
    },
    {
        "id": 8,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21100&unique=1683187150000",
        "name": "Air Freshener - Al Arabia Silva",
        "description": "DEJ-AIR-AL-SILV-0"
    },
    {
        "id": 9,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21103&unique=1683187150000",
        "name": "Air Freshener - Deluxe Dispenser",
        "description": "DEJ-AIR-DEL-0000-D"
    },
    {
        "id": 10,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21104&unique=1683187150000",
        "name": "Air Freshener - Deluxe Refill",
        "description": "DEJ-AIR-DEL-0000-R"
    },
    {
        "id": 11,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21105&unique=1683187150000",
        "name": "Air Freshener - Fabreeze",
        "description": "DEJ-AIR-FAB-0000-0"
    },
    // {
    //     "id": 12,
    //     "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22502&unique=1683187150000",
    //     "name": "Air Freshener - Lotion Admirer",
    //     "description": "324.00 Rs."
    // },
    {
        "id": 13,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21106&unique=1683187150000",
        "name": "Air Freshener - Perfect Perfect",
        "description": "DEJ-AIR-PER-PERF-0"
    },
    {
        "id": 14,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21107&unique=1683187150000",
        "name": "Air Freshener - Roomi Hanger",
        "description": "DEJ-AIR-ROO-ROOM-H"
    },
    {
        "id": 15,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21108&unique=1683187150000",
        "name": "Air Freshener - Roomi Tablets",
        "description": "DEJ-AIR-ROO-ROOM-T"
    },
    {
        "id": 16,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22501&unique=1683187150000",
        "name": "Air Freshener - Standard Brands Fresco",
        "description": "384.00 Rs."
    },
    {
        "id": 17,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22700&unique=1683187150000",
        "name": "All Purpose Cleaner - Lemon Max",
        "description": "540.50 Rs."
    },
    {
        "id": 18,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22504&unique=1683187150000",
        "name": "Antiseptic - Dettol",
        "description": "DEJ-ANT-REC-DETT-0-L1"
    },
    {
        "id": 19,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21110&unique=1683187150000",
        "name": "Ash Tray",
        "description": "ADOS-ASH-GEN-0000-0"
    },
    {
        "id": 20,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21111&unique=1683187150000",
        "name": "Backpack",
        "description": "ADOS-BAC-GEN-0000-0"
    },
    {
        "id": 21,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21112&unique=1683187150000",
        "name": "Basket",
        "description": "ADOS-BAS-GEN-0000-0"
    },
    {
        "id": 22,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22758&unique=1683187150000",
        "name": "Basket - Decoration Basket",
        "description": "CGCS-BAS-GEN-DECO-0"
    },
    {
        "id": 23,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22507&unique=1683187150000",
        "name": "Bathroom Cleaner - Harpic Lemon",
        "description": "DEJ-BAT-REC-HARP-L-M500"
    },
    {
        "id": 24,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22701&unique=1683187150000",
        "name": "Bathroom Cleaner - Harpic Red",
        "description": "816.50 Rs."
    },
    {
        "id": 25,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22508&unique=1683187150000",
        "name": "Bathroom Cleaner - Tyfon",
        "description": "DEJ-BAT-TYF-STAN-0-M500"
    },
    {
        "id": 26,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21113&unique=1683187150000",
        "name": "Bathroom Mat",
        "description": "ADOS-BAT-GEN-RUBB-0"
    },
    {
        "id": 27,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21114&unique=1683187150000",
        "name": "Batteries - Energizer AA",
        "description": "DEJ-BAT-ENE-AA-2"
    },
    {
        "id": 28,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21115&unique=1683187150000",
        "name": "Batteries - Energizer AAA",
        "description": "DEJ-BAT-ENE-AAA-3"
    },
    {
        "id": 29,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21278&unique=1683187150000",
        "name": "Batteries - Generic AA Rechargable",
        "description": "DEJ-BAT-GEN-AA-R"
    },
    {
        "id": 30,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21116&unique=1683187150000",
        "name": "Batteries - Kodak AA",
        "description": "DEJ-BAT-KOD-AA-2"
    },
    {
        "id": 31,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21117&unique=1683187150000",
        "name": "Batteries - Toshiba AA",
        "description": "DEJ-BAT-TOS-AA-2"
    },
    {
        "id": 32,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21118&unique=1683187150000",
        "name": "Batteries - Toshiba AAA",
        "description": "DEJ-BAT-TOS-AAA-3"
    },
    {
        "id": 33,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21119&unique=1683187150000",
        "name": "Batteries - Toshiba CC",
        "description": "DEJ-BAT-TOS-CC-C"
    },
    {
        "id": 34,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21120&unique=1683187150000",
        "name": "Batteries - Toshiba DD",
        "description": "DEJ-BAT-TOS-DD-D"
    },
    {
        "id": 35,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21121&unique=1683187150000",
        "name": "Beard Mask",
        "description": "DEJ-BEA-GEN-0000-0"
    },
    {
        "id": 36,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22773&unique=1683187150000",
        "name": "Binder Clips - 32",
        "description": "DES-BIN-GEN-0000-3"
    },
    {
        "id": 37,
        "pic": "https: //haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22509&unique=1683187150000",
        "name": "Biscuit - Bisconni Chocollato",
        "description": "DEP-BIS-BIS-CHOC-0-BT"
    },
    {
        "id": 38,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22510&unique=1683187150000",
        "name": "Biscuit - LU Candi",
        "description": "240.00 Rs."
    },
    {
        "id": 39,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22511&unique=1683187150000",
        "name": "Biscuit - LU Click",
        "description": "DEP-BIS-LU-CLIC-0-BH"
    },
    {
        "id": 40,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22512&unique=1683187150000",
        "name": "Biscuit - LU Gala",
        "description": "DEP-BIS-LU-GALA-0-BH"
    },
    {
        "id": 41,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22513&unique=1683187150000",
        "name": "Biscuit - LU Oreo",
        "description": "240.00 Rs."
    },
    {
        "id": 42,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22514&unique=1683187150000",
        "name": "Biscuit - LU Tuc",
        "description": "DEP-BIS-LU-TUC-0-BH"
    },
    {
        "id": 43,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22515&unique=1683187150000",
        "name": "Biscuit - LU Wheatable",
        "description": "DEP-BIS-LU-WHEA-0-BH"
    },
    {
        "id": 44,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22516&unique=1683187150000",
        "name": "Biscuit - LU Wheatable Sugar Free",
        "description": "DEP-BIS-LU-WHEA-W-BH"
    },
    {
        "id": 45,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22517&unique=1683187150000",
        "name": "Biscuit - LU Zeera Plus",
        "description": "240.00 Rs."
    },
    {
        "id": 46,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22518&unique=1683187150000",
        "name": "Biscuit - Peak Freans Chocolate Sandwich",
        "description": "DEP-BIS-PEA-CHOC-0-BH"
    },
    {
        "id": 47,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22519&unique=1683622438000",
        "name": "Biscuit - Peak Freans Lemon Sandwich",
        "description": "240.00 Rs."
    },
    {
        "id": 48,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22520&unique=1683187150000",
        "name": "Biscuit - Peak Freans Party",
        "description": "DEP-BIS-PEA-PART-0-BH"
    },
    {
        "id": 49,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22521&unique=1683187150000",
        "name": "Biscuit - Peak Freans Salish",
        "description": "DEP-BIS-PEA-SALI-0-BH"
    },
    {
        "id": 50,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22522&unique=1683187150000",
        "name": "Biscuit - Peak Freans Sooper",
        "description": "DEP-BIS-PEA-SOOP-0-BH"
    },
    {
        "id": 51,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22523&unique=1683187150000",
        "name": "Black Loose Tea - Lipton Yellow Label",
        "description": "0.00 Rs."
    },
    {
        "id": 52,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22524&unique=1683187150000",
        "name": "Black Loose Tea - Tapal Danedar",
        "description": "1,506.75 Rs."
    },
    {
        "id": 53,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22525&unique=1683187150000",
        "name": "Black Loose Tea - Tapal Family Mixture",
        "description": "714.00 Rs."
    },
    {
        "id": 54,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22526&unique=1683187150000",
        "name": "Black Teabag - Lipton Yellow Label",
        "description": "630.00 Rs."
    },
    {
        "id": 55,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22527&unique=1683187150000",
        "name": "Black Teabag - Mezan Ultra",
        "description": "577.50 Rs."
    },
    {
        "id": 56,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22528&unique=1683187150000",
        "name": "Black Teabag - Tapal Danedar",
        "description": "551.20 Rs."
    },
    {
        "id": 57,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22529&unique=1683187150000",
        "name": "Black Teabag - Tapal Elaichi",
        "description": "DEP-BLA-TAP-ELAI-T-C50"
    },
    {
        "id": 58,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21143&unique=1683187150000",
        "name": "Blade - Cutter",
        "description": "ADH-BLA-GEN-0000-0"
    },
    {
        "id": 59,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22530&unique=1683187150000",
        "name": "Bleach - Black Betty",
        "description": "DEJ-BLE-BLA-0000-0-M500"
    },
    {
        "id": 60,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22532&unique=1683187150000",
        "name": "Bleach - Generic",
        "description": "DEJ-BLE-GEN-0000-0-L1"
    },
    {
        "id": 61,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22531&unique=1683187150000",
        "name": "Bleach - Hypo",
        "description": "DEJ-BLE-HYP-0000-0-L1"
    },
    {
        "id": 62,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22533&unique=1683187150000",
        "name": "Board Marker - Deer",
        "description": "0.00 Rs."
    },
    {
        "id": 63,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22534&unique=1683187150000",
        "name": "Board Marker - Dollar",
        "description": "630.00 Rs."
    },
    {
        "id": 64,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21122&unique=1683187150000",
        "name": "Board Marker - Dollar Refill",
        "description": "DES-BOA-DOL-REFI-0"
    },
    {
        "id": 65,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22535&unique=1683187150000",
        "name": "Board Marker - Picasso",
        "description": "0.00 Rs."
    },
    {
        "id": 66,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22536&unique=1683187150000",
        "name": "Body Spray - Mistelle",
        "description": "DEJ-BOD-MIS-0000-0-M200"
    },
    {
        "id": 67,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21123&unique=1683187150000",
        "name": "Bottle - Glass",
        "description": "CGGP-BOT-GEN-GLAS-0"
    },
    {
        "id": 68,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21124&unique=1683187150000",
        "name": "Bottle - Plastic",
        "description": "CGGP-BOT-GEN-PLAS-0"
    },
    {
        "id": 69,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21125&unique=1683187150000",
        "name": "Bottle - Temperature Flask",
        "description": "CGGP-BOT-GEN-TEMP-0"
    },
    {
        "id": 70,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22768&unique=1683187150000",
        "name": "Bowl - Generic",
        "description": "DEP-BOW-GEN-0000-0"
    },
    {
        "id": 71,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22562&unique=1683187150000",
        "name": "Box File - Large",
        "description": "367.50 Rs."
    },
    {
        "id": 72,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21170&unique=1683187150000",
        "name": "Box File - Stand",
        "description": "DES-FIL-GEN-BOX-S"
    },
    {
        "id": 73,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21126&unique=1683187150000",
        "name": "Broom - Generic Magic",
        "description": "DEJ-BRO-GEN-MAGI-0"
    },
    {
        "id": 74,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21127&unique=1683187150000",
        "name": "Broom - Generic Phool",
        "description": "DEJ-BRO-GEN-PHOO-0"
    },
    {
        "id": 75,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21128&unique=1683187150000",
        "name": "Broom - Generic Standup",
        "description": "DEJ-BRO-GEN-STAN-0"
    },
    {
        "id": 76,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21129&unique=1683187150000",
        "name": "Broom - Generic Stick Extension",
        "description": "DEJ-BRO-GEN-STIC-S"
    },
    {
        "id": 77,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21130&unique=1683187150000",
        "name": "Broom - Generic Tinka",
        "description": "DEJ-BRO-GEN-TINK-0"
    },
    {
        "id": 78,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21258&unique=1683187150000",
        "name": "Bubble Wrap",
        "description": "DEPK-BUB-GEN-0000-R"
    },
    {
        "id": 79,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21132&unique=1683187150000",
        "name": "Calcium Hydroxide (choona)",
        "description": "DEJ-CAL-GEN-0000-0"
    },
    {
        "id": 80,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21133&unique=1683187150000",
        "name": "Calculator - Casio",
        "description": "DES-CAL-CAS-0000-0"
    },
    {
        "id": 81,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22771&unique=1683187150000",
        "name": "Card File - Generic",
        "description": "DES-CAR-GEN-0000-0"
    },
    {
        "id": 82,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22764&unique=1683187150000",
        "name": "Carton Tape - Generic",
        "description": "DEPK-CAR-GEN-0000-0"
    },
    {
        "id": 83,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22537&unique=1683187150000",
        "name": "Chili Garlic Sauce - Knorr",
        "description": "DEP-CHI-KNO-0000-0-G800"
    },
    {
        "id": 84,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21187&unique=1683187150000",
        "name": "Clip Board",
        "description": "DES-CLI-GEN-0000-0"
    },
    {
        "id": 85,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22539&unique=1683187150000",
        "name": "Coffee - Nestle Gold",
        "description": "DEP-COF-NES-GOLD-0-G100"
    },
    {
        "id": 86,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22538&unique=1683187150000",
        "name": "Coffee - Nestle Nescafe",
        "description": "920.00 Rs."
    },
    {
        "id": 87,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22540&unique=1683187150000",
        "name": "Coffee Whitener - Coffe Mate - Vanilla",
        "description": "DEP-COF-COF-0000-V-G400"
    },
    {
        "id": 88,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21257&unique=1683187150000",
        "name": "Cups - Steel Glass",
        "description": "ADOS-CUP-GEN-STEE-0"
    },
    {
        "id": 89,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22654&unique=1683187150000",
        "name": "Cups / glasses",
        "description": "0.00 Rs."
    },
    {
        "id": 90,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22557&unique=1683187150000",
        "name": "Cups Disposable",
        "description": "4,380.00 Rs."
    },
    {
        "id": 91,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22547&unique=1683187150000",
        "name": "Dish Soap - Black Betty - Liquid",
        "description": "0.00 Rs."
    },
    {
        "id": 92,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22548&unique=1683187150000",
        "name": "Dish Soap - Lemon Max - Bar",
        "description": "33.75 Rs."
    },
    {
        "id": 93,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22549&unique=1683187150000",
        "name": "Dish Soap - Lemon Max - Liquid",
        "description": "168.75 Rs."
    },
    {
        "id": 94,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22550&unique=1683187150000",
        "name": "Dish Soap - Lemon Max - Paste",
        "description": "146.25 Rs."
    },
    {
        "id": 95,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22551&unique=1683187150000",
        "name": "Dish Soap - Vim - Bar",
        "description": "0.00 Rs."
    },
    {
        "id": 96,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22552&unique=1683187150000",
        "name": "Dish Soap - Vim - Liquid",
        "description": "219.38 Rs."
    },
    {
        "id": 97,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22553&unique=1683187150000",
        "name": "Dish Soap - Vim - Powder",
        "description": "84.38 Rs."
    },
    {
        "id": 98,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22554&unique=1683187150000",
        "name": "Dish Sponge - Black Betty",
        "description": "0.00 Rs."
    },
    {
        "id": 99,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22555&unique=1683187150000",
        "name": "Dish Sponge - Scotch Brite",
        "description": "114.75 Rs."
    },
    {
        "id": 100,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22556&unique=1683187150000",
        "name": "Dish Sponge - Spontex",
        "description": "0.00 Rs."
    },
    {
        "id": 101,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22752&unique=1683187150000",
        "name": "Dish Wash Spiral - Black Betty",
        "description": "DEJ-DIS-BLA-0000-S"
    },
    {
        "id": 102,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22748&unique=1683187150000",
        "name": "Dish Wash Spiral - Scotch Brite",
        "description": "DEJ-DIS-3M-SCOT-S"
    },
    {
        "id": 103,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22560&unique=1683187150000",
        "name": "Double Sided Tape",
        "description": "0.00 Rs."
    },
    {
        "id": 104,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22561&unique=1683187150000",
        "name": "Dry Mop",
        "description": "0.00 Rs."
    },
    {
        "id": 105,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21146&unique=1683187150000",
        "name": "Dry Mop - Refill",
        "description": "DEJ-DRY-GEN-REFI-0"
    },
    {
        "id": 106,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21147&unique=1683187150000",
        "name": "Dust Pan",
        "description": "DEJ-DUS-GEN-0000-P"
    },
    {
        "id": 107,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21151&unique=1683187150000",
        "name": "Duster - 12 x 12",
        "description": "DEJ-DUS-GEN-0000-1"
    },
    {
        "id": 108,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21148&unique=1683187150000",
        "name": "Duster Microfiber",
        "description": "DEJ-DUS-GEN-0000-M"
    },
    {
        "id": 109,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22749&unique=1683187150000",
        "name": "Duster Normal",
        "description": "DEJ-DUS-GEN-NORM-0"
    },
    {
        "id": 110,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21149&unique=1683187150000",
        "name": "Duster Rag",
        "description": "DEJ-DUS-GEN-RAG-0"
    },
    {
        "id": 111,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21150&unique=1683187150000",
        "name": "Dusterwood",
        "description": "DEJ-DUS-GEN-WOOD-0"
    },
    {
        "id": 112,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21152&unique=1683187150000",
        "name": "Ear Plugs",
        "description": "ADOS-EAR-GEN-0000-0"
    },
    {
        "id": 113,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22503&unique=1683187150000",
        "name": "Envelopes",
        "description": "1,092.50 Rs."
    },
    {
        "id": 114,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21154&unique=1683187150000",
        "name": "Eraser",
        "description": "DES-ERA-GEN-0000-0"
    },
    {
        "id": 115,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21144&unique=1683187150000",
        "name": "Face Mask Disposable",
        "description": "DEJ-FAC-GEN-0000-0"
    },
    {
        "id": 116,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21155&unique=1683187150000",
        "name": "Facial Tissue - Fay 100 Pulls",
        "description": "DEJ-TIS-FAY-100-F"
    },
    {
        "id": 117,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21156&unique=1683187150000",
        "name": "Facial Tissue - Fay Hithop",
        "description": "DEJ-TIS-FAY-HITH-F"
    },
    {
        "id": 118,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21157&unique=1683187150000",
        "name": "Facial Tissue - Fay Popup",
        "description": "DEJ-TIS-FAY-POPU-F"
    },
    {
        "id": 119,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21159&unique=1683187150000",
        "name": "Facial Tissue - Hankies Gold",
        "description": "DEJ-TIS-HAN-GOLD-F"
    },
    {
        "id": 120,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21161&unique=1683187150000",
        "name": "Facial Tissue - Rose Petal Luxury",
        "description": "DEJ-TIS-ROS-LUXU-F"
    },
    {
        "id": 121,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21160&unique=1683187150000",
        "name": "Facial Tissue - Rose Petal Menthol",
        "description": "DEJ-TIS-ROS-MENT-F"
    },
    {
        "id": 122,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21162&unique=1683187150000",
        "name": "Facial Tissue - Rose Petal Mini",
        "description": "DEJ-TIS-ROS-MINI-F"
    },
    {
        "id": 123,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21163&unique=1683187150000",
        "name": "Facial Tissue - Rose Petal Multi Colour",
        "description": "DEJ-TIS-ROS-MULT-F"
    },
    {
        "id": 124,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21165&unique=1683187150000",
        "name": "Facial Tissue - Rose Petal Popup",
        "description": "DEJ-TIS-ROS-POPU-F"
    },
    {
        "id": 125,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21164&unique=1683187150000",
        "name": "Facial Tissue - Rose Petal Smart Popup",
        "description": "DEJ-TIS-ROS-SMAR-F"
    },
    {
        "id": 126,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21158&unique=1683187150000",
        "name": "Facial Tissue - Standard Pvt Ltd Fresco - Luxury",
        "description": "DEJ-TIS-STA-FRES-F"
    },
    {
        "id": 127,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21166&unique=1683187150000",
        "name": "Facial Tissue - Ted n Marshal Hithop",
        "description": "DEJ-TIS-TED-HITH-F"
    },
    {
        "id": 128,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21167&unique=1683187150000",
        "name": "Facial Tissue - Ted n Marshal Luxury",
        "description": "DEJ-TIS-TED-LUXU-F"
    },
    {
        "id": 129,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22750&unique=1683187150000",
        "name": "Facial Tissue - Ted n Marshal Mini Popup",
        "description": "DEJ-TIS-TED-MINI-F"
    },
    {
        "id": 130,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21168&unique=1683187150000",
        "name": "Facial Tissue - Ted n Marshal Popup",
        "description": "DEJ-TIS-TED-POPU-F"
    },
    {
        "id": 131,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21169&unique=1683187150000",
        "name": "Facial Tissue - Tulip Popup",
        "description": "DEJ-TIS-TUL-POPU-F"
    },
    {
        "id": 132,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21171&unique=1683187150000",
        "name": "File - L-Shape",
        "description": "DES-FIL-GEN-L-SH-0"
    },
    {
        "id": 133,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21172&unique=1683187150000",
        "name": "File - Transparent",
        "description": "DES-FIL-GEN-TRAN-0"
    },
    {
        "id": 134,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22770&unique=1683187150000",
        "name": "File Seperator - Generic",
        "description": "DES-FIL-GEN-0000-0"
    },
    {
        "id": 135,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22563&unique=1683187150000",
        "name": "Floor Cleaner - Black Betty Sweep",
        "description": "DEJ-FLO-BLA-SWEE-0-M500"
    },
    {
        "id": 136,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22564&unique=1683187150000",
        "name": "Floor Cleaner - Crystal Sweep",
        "description": "DEJ-FLO-CRY-SWEE-0-M500"
    },
    {
        "id": 137,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22565&unique=1683187150000",
        "name": "Floor Wiper",
        "description": "253.00 Rs."
    },
    {
        "id": 138,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21145&unique=1683187150000",
        "name": "Forks Disposable",
        "description": "DEJ-FOR-GEN-0000-0"
    },
    {
        "id": 139,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22769&unique=1683187150000",
        "name": "Gauze Pad - Generic",
        "description": "DEM-GAU-GEN-0000-0"
    },
    {
        "id": 140,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21176&unique=1683187150000",
        "name": "Gloves - Cotton Knitted",
        "description": "DEJ-GLO-GEN-COTT-0"
    },
    {
        "id": 141,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21174&unique=1683187150000",
        "name": "Gloves - Disposable",
        "description": "DEJ-GLO-GEN-DISP-0"
    },
    {
        "id": 142,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21173&unique=1683187150000",
        "name": "Gloves - Medical",
        "description": "DEJ-GLO-GEN-MEDI-0"
    },
    {
        "id": 143,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21177&unique=1683187150000",
        "name": "Gloves - Rubber Long",
        "description": "DEJ-GLO-GEN-RUBB-0"
    },
    {
        "id": 144,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21175&unique=1683187150000",
        "name": "Gloves - Safety",
        "description": "DEJ-GLO-GEN-SAFE-0"
    },
    {
        "id": 145,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21153&unique=1683187150000",
        "name": "Glue - Elfy",
        "description": "DEOS-GLU-GEN-ELFY-0"
    },
    {
        "id": 146,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22566&unique=1683187150000",
        "name": "Glue Stick - UHU",
        "description": "115.00 Rs."
    },
    {
        "id": 147,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22567&unique=1683187150000",
        "name": "Green Tea - Lipton Lemon",
        "description": "167.40 Rs."
    },
    {
        "id": 148,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22569&unique=1683187150000",
        "name": "Green Tea - Tapal Elaichi",
        "description": "DEP-GRE-TAP-0000-E-C30"
    },
    {
        "id": 149,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22570&unique=1683187150000",
        "name": "Green Tea - Tapal Lemon",
        "description": "178.20 Rs."
    },
    {
        "id": 150,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22571&unique=1683187150000",
        "name": "Green Tea - Tapal Mango",
        "description": "DEP-GRE-TAP-0000-M-C30"
    },
    {
        "id": 151,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22568&unique=1683187150000",
        "name": "Green Tea - Tapal Original",
        "description": "DEP-GRE-TAP-0000-O-C30"
    },
    {
        "id": 152,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22572&unique=1683187150000",
        "name": "Green Tea - Tapal Peach",
        "description": "DEP-GRE-TAP-0000-P-C30"
    },
    {
        "id": 153,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22759&unique=1683187150000",
        "name": "Greeting Card -",
        "description": "CGCS-GRE-GEN-0000-0"
    },
    {
        "id": 154,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21178&unique=1683187150000",
        "name": "Gurr - Jaggery",
        "description": "DEP-GUR-GEN-0000-0"
    },
    {
        "id": 155,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21179&unique=1683187150000",
        "name": "HDMI Cable",
        "description": "ADE-HDM-GEN-0000-0"
    },
    {
        "id": 156,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21180&unique=1683187150000",
        "name": "Hand Dryer",
        "description": "ADE-HAN-GEN-0000-0"
    },
    {
        "id": 157,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21182&unique=1683187150000",
        "name": "Hand Soap Bar - Lifebouy - Original",
        "description": "DEJ-HSB-UNI-LIFE-O"
    },
    {
        "id": 158,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21183&unique=1683187150000",
        "name": "Hand Soap Bar - Lifebouy - Red",
        "description": "DEJ-HSB-UNI-LIFE-R"
    },
    {
        "id": 159,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21184&unique=1683187150000",
        "name": "Hand Soap Bar - Lux - Velvet Glow",
        "description": "DEJ-HSB-UNI-LUX-V"
    },
    {
        "id": 160,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21185&unique=1683187150000",
        "name": "Hand Soap Bar - Opal - Original",
        "description": "DEJ-HSB-OPA-0000-O"
    },
    {
        "id": 161,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21186&unique=1683187150000",
        "name": "Hand Soap Bar - Palmolive - Original",
        "description": "DEJ-HSB-COL-PALM-O"
    },
    {
        "id": 162,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22583&unique=1683187150000",
        "name": "Hand Soap Bar - Safeguard - Original",
        "description": "327.75 Rs."
    },
    {
        "id": 163,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21181&unique=1683187150000",
        "name": "Hand Soap Liquid - Black Betty",
        "description": "DEJ-HSL-BLA-0000-0"
    },
    {
        "id": 164,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22574&unique=1683187150000",
        "name": "Hand Soap Liquid - Dettol - Original",
        "description": "356.50 Rs."
    },
    {
        "id": 165,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22575&unique=1683187150000",
        "name": "Hand Soap Liquid - Dove - Cucumber & Green Tea",
        "description": "DEJ-HSL-UNI-DOVE-C-M250"
    },
    {
        "id": 166,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22576&unique=1683187150000",
        "name": "Hand Soap Liquid - Dupas - Caribbean Island",
        "description": "DEJ-HSL-DUP-0000-C-L17"
    },
    {
        "id": 167,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22577&unique=1683187150000",
        "name": "Hand Soap Liquid - Fresco - Original",
        "description": "DEJ-HSL-STA-FRES-O-L15"
    },
    {
        "id": 168,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22762&unique=1683187150000",
        "name": "Hand Soap Liquid - Fresco - Original",
        "description": "DEJ-HSL-STA-FRES-O-L5"
    },
    {
        "id": 169,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22579&unique=1683187150000",
        "name": "Hand Soap Liquid - Lifebouy - Original",
        "description": "DEJ-HSL-UNI-LIFE-O-M200"
    },
    {
        "id": 170,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22582&unique=1683187150000",
        "name": "Hand Soap Liquid - Pure Splash",
        "description": "DEJ-HSL-PUR-0000-0-M500"
    },
    {
        "id": 171,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22573&unique=1683187150000",
        "name": "Hand Soap Liquid Refill - Dettol - Original",
        "description": "747.50 Rs."
    },
    {
        "id": 172,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22578&unique=1683187150000",
        "name": "Hand Soap Liquid Refill - HiClean - Original",
        "description": "DEJ-HSR-HIC-0000-O-L15"
    },
    {
        "id": 173,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22702&unique=1683187150000",
        "name": "Hand Soap Liquid Refill - Lifebouy - Original",
        "description": "0.00 Rs."
    },
    {
        "id": 174,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22580&unique=1683187150000",
        "name": "Hand Soap Liquid Refill - Mystik - Green Apple",
        "description": "DEJ-HSR-MYS-0000-G-L25"
    },
    {
        "id": 175,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22581&unique=1683187150000",
        "name": "Hand Soap Liquid Refill - Mystik - Ocean Fresh",
        "description": "DEJ-HSR-MYS-0000-O-L25"
    },
    {
        "id": 176,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22584&unique=1683187150000",
        "name": "Hand Soap Liquid Refill - Safeguard - Original",
        "description": "DEJ-HSR-PNG-SAFE-O-L1"
    },
    {
        "id": 177,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22586&unique=1683187150000",
        "name": "Highlighter - Dollar",
        "description": "0.00 Rs."
    },
    {
        "id": 178,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22587&unique=1683187150000",
        "name": "Hygiene Tissue - Fay White",
        "description": "DEJ-TIS-FAY-WHIT-H-P200"
    },
    {
        "id": 179,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22589&unique=1683187150000",
        "name": "Hygiene Tissue - Hankies White",
        "description": "DEJ-TIS-HAN-WHIT-H-P200"
    },
    {
        "id": 180,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22590&unique=1683187150000",
        "name": "Hygiene Tissue - Rose Petal Blue",
        "description": "DEJ-TIS-ROS-BLUE-H-P100"
    },
    {
        "id": 181,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22591&unique=1683187150000",
        "name": "Hygiene Tissue - Rose Petal Orange",
        "description": "DEJ-TIS-ROS-ORAN-H-P100"
    },
    {
        "id": 182,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22592&unique=1683187150000",
        "name": "Hygiene Tissue - Rose Petal White",
        "description": "DEJ-TIS-ROS-WHIT-H-P100"
    },
    {
        "id": 183,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22588&unique=1683187150000",
        "name": "Hygiene Tissue - Standard Brands White",
        "description": "DEJ-TIS-STA-WHIT-H-P200"
    },
    {
        "id": 184,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22751&unique=1683187150000",
        "name": "Hygiene Tissue - Standard Brands White",
        "description": "DEJ-TIS-STA-WHIT-H-P100"
    },
    {
        "id": 185,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21190&unique=1683187150000",
        "name": "Insect Repellent - Baygon - Standard Insect Killer",
        "description": "DEJ-INS-SC -BAYG-S"
    },
    {
        "id": 186,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22593&unique=1683187150000",
        "name": "Insect Repellent - Black Betty - Liquid",
        "description": "DEJ-INS-BLA-0000-L-M500"
    },
    {
        "id": 187,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21191&unique=1683187150000",
        "name": "Insect Repellent - Black Betty - Machine w/refill",
        "description": "DEJ-INS-BLA-0000-M"
    },
    {
        "id": 188,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21193&unique=1683187150000",
        "name": "Insect Repellent - Black Betty - Powder",
        "description": "DEJ-INS-BLA-0000-P"
    },
    {
        "id": 189,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21192&unique=1683187150000",
        "name": "Insect Repellent - Black Betty - Refill",
        "description": "DEJ-INS-BLA-0000-R"
    },
    {
        "id": 190,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22594&unique=1683187150000",
        "name": "Insect Repellent - Kingtox - Standard Insect Killer",
        "description": "434.16 Rs."
    },
    {
        "id": 191,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22595&unique=1683187150000",
        "name": "Insect Repellent - Mortein - All Insect Killer",
        "description": "603.72 Rs."
    },
    {
        "id": 192,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22596&unique=1683187150000",
        "name": "Insect Repellent - Mortein - Coopex Powder",
        "description": "DEJ-INS-REC-MORT-C-G100"
    },
    {
        "id": 193,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22597&unique=1683187150000",
        "name": "Insect Repellent - Mortein - Crawling Insect Killer",
        "description": "1,188.00 Rs."
    },
    {
        "id": 194,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22598&unique=1683187150000",
        "name": "Insect Repellent - Mortein - Flying Insect Killer",
        "description": "529.20 Rs."
    },
    {
        "id": 195,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21194&unique=1683187150000",
        "name": "Insect Repellent - Mortein - Machine w/refill",
        "description": "DEJ-INS-REC-MORT-M"
    },
    {
        "id": 196,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22599&unique=1683187150000",
        "name": "Insect Repellent - Mortein - Peaceful Night",
        "description": "529.20 Rs."
    },
    {
        "id": 197,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21195&unique=1683187150000",
        "name": "Insect Repellent - Mortein - Refill",
        "description": "DEJ-INS-REC-MORT-R"
    },
    {
        "id": 198,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22600&unique=1683187150000",
        "name": "Insect Repellent - Mortein - Ultra Fast",
        "description": "DEJ-INS-REC-MORT-U-M550"
    },
    {
        "id": 199,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22601&unique=1683187150000",
        "name": "Insect Repellent - Power Plus - Super Flying",
        "description": "DEJ-INS-STA-POWE-S-M300"
    },
    {
        "id": 200,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22602&unique=1683187150000",
        "name": "Insect Repellent - Tyfon - Standard Insect Killer",
        "description": "DEJ-INS-TYF-0000-S-M375"
    },
    {
        "id": 201,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22603&unique=1683187150000",
        "name": "Jar",
        "description": "ADOS-JAR-GEN-0000-P-SL"
    },
    {
        "id": 202,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22757&unique=1683187150000",
        "name": "Juice - Fruitavitals - Chausa",
        "description": "DEP-JUI-NES-FRUI-C-L1"
    },
    {
        "id": 203,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22604&unique=1683187150000",
        "name": "Juice - Nestle Fruitavitals - Orange",
        "description": "DEP-JUI-NES-FRUI-O-M250"
    },
    {
        "id": 204,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22605&unique=1683187150000",
        "name": "Kitchen Knife",
        "description": "ADOS-KIT-GEN-0000-0-SS"
    },
    {
        "id": 205,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21200&unique=1683187150000",
        "name": "Kitchen Roll - Fay",
        "description": "DEJ-TIS-FAY-0000-K"
    },
    {
        "id": 206,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22606&unique=1683187150000",
        "name": "Kitchen Roll - Rose Petal",
        "description": "0.00 Rs."
    },
    {
        "id": 207,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22607&unique=1683187150000",
        "name": "Kitchen Roll - Ted n Marshal",
        "description": "DEJ-TIS-TED-0000-K-P1"
    },
    {
        "id": 208,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21201&unique=1683187150000",
        "name": "Kitchen Roll Stand",
        "description": "ADOS-KIT-GEN-0000-S"
    },
    {
        "id": 209,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22546&unique=1683187150000",
        "name": "Knife - Cutter",
        "description": "0.00 Rs."
    },
    {
        "id": 210,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22703&unique=1683187150000",
        "name": "Laundry Detergent - Ariel",
        "description": "564.00 Rs."
    },
    {
        "id": 211,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22704&unique=1683187150000",
        "name": "Laundry Detergent - Bonus",
        "description": "228.00 Rs."
    },
    {
        "id": 212,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22608&unique=1683187150000",
        "name": "Laundry Detergent - Brite",
        "description": "DEJ-LAU-COL-BRIT-0-G135"
    },
    {
        "id": 213,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22609&unique=1683187150000",
        "name": "Laundry Detergent - Sunlight",
        "description": "DEJ-LAU-UNI-SUNL-0-G230"
    },
    {
        "id": 214,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22705&unique=1683187150000",
        "name": "Laundry Detergent - Surf Excel",
        "description": "588.00 Rs."
    },
    {
        "id": 215,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21202&unique=1683187150000",
        "name": "Leather Cleaning Spray - Neutral",
        "description": "DEJ-LEA-GEN-0000-N"
    },
    {
        "id": 216,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22610&unique=1683187150000",
        "name": "Light (downlight)",
        "description": "ADE-LIG-OSA-0000-1-LW"
    },
    {
        "id": 217,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21205&unique=1683187150000",
        "name": "Lighter (kitchen / cigarette) - Cigarette",
        "description": "DEJ-LIG-GEN-CIGA-0"
    },
    {
        "id": 218,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21204&unique=1683187150000",
        "name": "Lighter (kitchen / cigarette) - Kitchen",
        "description": "DEJ-LIG-GEN-KITC-0"
    },
    {
        "id": 219,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22706&unique=1683205511000",
        "name": "Liquid Milk - Milkpak",
        "description": "2,808.00 Rs."
    },
    {
        "id": 220,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22611&unique=1683187150000",
        "name": "Liquid Milk - Nesvita",
        "description": "DEP-LIQ-NES-NESV-0-L1"
    },
    {
        "id": 221,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22707&unique=1683206822000",
        "name": "Liquid Milk - Nurpur",
        "description": "0.00 Rs."
    },
    {
        "id": 222,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22708&unique=1683187150000",
        "name": "Liquid Milk - Olpers",
        "description": "2,652.00 Rs."
    },
    {
        "id": 223,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21096&unique=1683187150000",
        "name": "MTO - Acrylic Board",
        "description": "CGGP-ACR-GEN-0000-W"
    },
    {
        "id": 224,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21098&unique=1683187150000",
        "name": "MTO - Acrylic Nameplate",
        "description": "CGGP-ACR-GEN-0000-0"
    },
    {
        "id": 225,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21099&unique=1683187150000",
        "name": "MTO - Acrylic Shield",
        "description": "CGGP-SHI-GEN-0000-A"
    },
    {
        "id": 226,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22543&unique=1683187150000",
        "name": "MTO - Box Customized",
        "description": "0.00 Rs."
    },
    {
        "id": 227,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21131&unique=1683187150000",
        "name": "MTO - Buntings",
        "description": "CGGP-BUN-GEN-0000-0"
    },
    {
        "id": 228,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21137&unique=1683187150000",
        "name": "MTO - Coaster Customized",
        "description": "CGGP-COA-GEN-0000-0"
    },
    {
        "id": 229,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21138&unique=1683187150000",
        "name": "MTO - Envelopes",
        "description": "CGGP-ENV-GEN-0000-0"
    },
    {
        "id": 230,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21188&unique=1683187150000",
        "name": "MTO - Hoodie Customizable Fleece",
        "description": "CGGP-HOO-GEN-FLEE-0"
    },
    {
        "id": 231,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21189&unique=1683187150000",
        "name": "MTO - Hoodie Customizable Kangroo",
        "description": "CGGP-HOO-GEN-KANG-0"
    },
    {
        "id": 232,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21198&unique=1683187150000",
        "name": "MTO - Keychain Customizable Acrylic",
        "description": "CGGP-KEY-GEN-ACRY-0"
    },
    {
        "id": 233,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21197&unique=1683187150000",
        "name": "MTO - Keychain Customizable Leather",
        "description": "CGGP-KEY-GEN-LEAT-0"
    },
    {
        "id": 234,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21199&unique=1683187150000",
        "name": "MTO - Keychain Customizable Metal",
        "description": "CGGP-KEY-GEN-META-0"
    },
    {
        "id": 235,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21141&unique=1683187150000",
        "name": "MTO - Letterhead",
        "description": "CGGP-LET-GEN-0000-0"
    },
    {
        "id": 236,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22617&unique=1683187150000",
        "name": "MTO - Mugs",
        "description": "0.00 Rs."
    },
    {
        "id": 237,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21262&unique=1683187150000",
        "name": "MTO - Mugs Sublimation",
        "description": "CGGP-MUG-GEN-SUBL-0"
    },
    {
        "id": 238,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21230&unique=1683187150000",
        "name": "MTO - Notebook Customizable Custom Cover",
        "description": "CGGP-NOT-GEN-CUST-0"
    },
    {
        "id": 239,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21231&unique=1683187150000",
        "name": "MTO - Notebook Customizable Custom Cover & Inside",
        "description": "CGGP-NOT-GEN-CUST-I"
    },
    {
        "id": 240,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21140&unique=1683187150000",
        "name": "MTO - Panaflex Customizable",
        "description": "CGCS-PAN-GEN-PANA-0"
    },
    {
        "id": 241,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22545&unique=1683187150000",
        "name": "MTO - Printing",
        "description": "0.00 Rs."
    },
    {
        "id": 242,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21244&unique=1683187150000",
        "name": "MTO - Safety Vest",
        "description": "CGGP-SAF-GEN-0000-0"
    },
    {
        "id": 243,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22632&unique=1683187150000",
        "name": "MTO - Shirt Polo",
        "description": "0.00 Rs."
    },
    {
        "id": 244,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21252&unique=1683187150000",
        "name": "MTO - Sign Board",
        "description": "CGGP-SIG-GEN-0000-0"
    },
    {
        "id": 245,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21139&unique=1683187150000",
        "name": "MTO - Stickers Customizable",
        "description": "CGCS-STI-GEN-STIC-0"
    },
    {
        "id": 246,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22665&unique=1683187150000",
        "name": "MTO - Tote Bag",
        "description": "CGGP-TOT-GEN-0000-0-SM"
    },
    {
        "id": 247,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22544&unique=1683187150000",
        "name": "MTO - Trophy Acrylic",
        "description": "0.00 Rs."
    },
    {
        "id": 248,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21225&unique=1683187150000",
        "name": "MTO - Visiting Cards",
        "description": "CGGP-VIS-GEN-0000-0"
    },
    {
        "id": 249,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22612&unique=1683187150000",
        "name": "Masking Tape",
        "description": "DES-MAS-GEN-0000-0-I1"
    },
    {
        "id": 250,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21206&unique=1683187150000",
        "name": "Match Box",
        "description": "DEJ-MAT-GEN-0000-0"
    },
    {
        "id": 251,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21213&unique=1683187150000",
        "name": "Medical Accessories - First Aid Box",
        "description": "DEM-MED-GEN-FIRS-0"
    },
    {
        "id": 252,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22613&unique=1683187150000",
        "name": "Medical Accessories - Saniplast",
        "description": "DEM-MED-UNI-SANI-0-SL"
    },
    {
        "id": 253,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21222&unique=1683187150000",
        "name": "Medical Accessories - Scissors",
        "description": "DEM-MED-GEN-SCIS-0"
    },
    {
        "id": 254,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21224&unique=1683187150000",
        "name": "Medical Accessories - Surgical Tape",
        "description": "DEM-MED-GEN-SURG-0"
    },
    {
        "id": 255,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22614&unique=1683187150000",
        "name": "Memory Card - MicroSD",
        "description": "ADE-MEM-LEX-MICR-0-M64"
    },
    {
        "id": 256,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22710&unique=1683187150000",
        "name": "Mineral Water - Culligan",
        "description": "0.00 Rs."
    },
    {
        "id": 257,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22709&unique=1683187150000",
        "name": "Mineral Water - Nestle",
        "description": "385.00 Rs."
    },
    {
        "id": 258,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22616&unique=1683187150000",
        "name": "Mineral Water - Nestle - Active",
        "description": "DEP-MIN-NES-ACTI-0-M330"
    },
    {
        "id": 259,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22615&unique=1683187150000",
        "name": "Mineral Water - Pepsi Co - Aquafina",
        "description": "DEP-MIN-PEP-AQUA-0-M500"
    },
    {
        "id": 260,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21226&unique=1683187150000",
        "name": "Mosquito Coil - Black Betty",
        "description": "DEJ-MOS-BLA-0000-0"
    },
    {
        "id": 261,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21227&unique=1683187150000",
        "name": "Mosquito Coil - Mortein",
        "description": "DEJ-MOS-REC-MORT-0"
    },
    {
        "id": 262,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21228&unique=1683187150000",
        "name": "Mouse Pad",
        "description": "ADOS-MOU-GEN-0000-0"
    },
    {
        "id": 263,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21229&unique=1683187150000",
        "name": "Networking Cable",
        "description": "ADOS-NET-D-L-0000-3"
    },
    {
        "id": 264,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21232&unique=1683187150000",
        "name": "Notebook - Spiral",
        "description": "DES-NOT-GEN-SPIR-0"
    },
    {
        "id": 265,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21233&unique=1683187150000",
        "name": "Notepad - Legal Pad",
        "description": "DES-NOT-GEN-LEGA-0"
    },
    {
        "id": 266,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21236&unique=1683187150000",
        "name": "Packing Shopper",
        "description": "DEJ-PAC-GEN-0000-1"
    },
    {
        "id": 267,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22655&unique=1683187150000",
        "name": "Paint Thinner",
        "description": "ADH-PAI-GEN-0000-0-L1"
    },
    {
        "id": 268,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21235&unique=1683187150000",
        "name": "Paper Tape",
        "description": "DES-PAP-GEN-0000-0"
    },
    {
        "id": 269,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21234&unique=1683187150000",
        "name": "Paper Tray",
        "description": "ADOS-PAP-GEN-0000-0"
    },
    {
        "id": 270,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21241&unique=1683187150000",
        "name": "Pen",
        "description": "ADOS-PEN-GEN-0000-0"
    },
    {
        "id": 271,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22618&unique=1683187150000",
        "name": "Pen - Clipper",
        "description": "0.00 Rs."
    },
    {
        "id": 272,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22619&unique=1683187150000",
        "name": "Pen - Deer",
        "description": "0.00 Rs."
    },
    {
        "id": 273,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21242&unique=1683187150000",
        "name": "Pen - Deer - Wood",
        "description": "DES-PEN-DEE-WOOD-0"
    },
    {
        "id": 274,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22620&unique=1683187150000",
        "name": "Pen - Dollar",
        "description": "0.00 Rs."
    },
    {
        "id": 275,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22505&unique=1683187150000",
        "name": "Pen - Mercury",
        "description": "DES-PEN-MER-BALL-0-CBLU"
    },
    {
        "id": 276,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22622&unique=1683187150000",
        "name": "Pen - Piano",
        "description": "0.00 Rs."
    },
    {
        "id": 277,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22621&unique=1683187150000",
        "name": "Pen - Piano - Gel Flow",
        "description": "DES-PEN-PIA-GEL-0-CBLK"
    },
    {
        "id": 278,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22623&unique=1683187150000",
        "name": "Pen - Picasso",
        "description": "0.00 Rs."
    },
    {
        "id": 279,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22763&unique=1683187150000",
        "name": "Pen - Signature Pen",
        "description": "DES-PEN-SIG-BALL-P"
    },
    {
        "id": 280,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22506&unique=1683187150000",
        "name": "Pen - Uniball",
        "description": "DES-PEN-UNI-BALL-0-CBLU"
    },
    {
        "id": 281,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22629&unique=1683187150000",
        "name": "Pen - Uniball - Eye Pen",
        "description": "DES-PEN-UNI-EYE-P-CBLK"
    },
    {
        "id": 282,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22772&unique=1683187150000",
        "name": "Pen Holder - Generic",
        "description": "DES-PEN-GEN-0000-0"
    },
    {
        "id": 283,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21243&unique=1683187150000",
        "name": "Percolator (Channi)",
        "description": "ADOS-PER-GEN-0000-0"
    },
    {
        "id": 284,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22625&unique=1683187150000",
        "name": "Permanent Marker - Dollar",
        "description": "593.25 Rs."
    },
    {
        "id": 285,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22624&unique=1683187150000",
        "name": "Permanent Marker - Dollar - Refill",
        "description": "DES-PER-DOL-REFI-0-CM"
    },
    {
        "id": 286,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22626&unique=1683187150000",
        "name": "Permanent Marker - Picasso",
        "description": "DES-PER-PIC-0000-P-CM"
    },
    {
        "id": 287,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22679&unique=1683187150000",
        "name": "Phenyl - Black Betty - Non-Concentrate",
        "description": "0.00 Rs."
    },
    {
        "id": 288,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22541&unique=1683187150000",
        "name": "Phenyl - Black Betty Concentrate Phenyl",
        "description": "0.00 Rs."
    },
    {
        "id": 289,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22542&unique=1683187150000",
        "name": "Phenyl - Caroline Concentrate Phenyl",
        "description": "360.00 Rs."
    },
    {
        "id": 290,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22680&unique=1683187150000",
        "name": "Phenyl - Finis - Non-Concentrate",
        "description": "0.00 Rs."
    },
    {
        "id": 291,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21134&unique=1683187150000",
        "name": "Phenyl - Finis Concentrate Phenyl",
        "description": "DEJ-PHE-FIN-CONC-0"
    },
    {
        "id": 292,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22681&unique=1683187150000",
        "name": "Phenyl - Generic - Non-Concentrate",
        "description": "89.25 Rs."
    },
    {
        "id": 293,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22683&unique=1683187150000",
        "name": "Phenyl - Generic - Tablets",
        "description": "DEJ-PHE-GEN-TABL-0-P108"
    },
    {
        "id": 294,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21135&unique=1683187150000",
        "name": "Phenyl - Generic Concentrate Phenyl",
        "description": "DEJ-PHE-GEN-CONC-0"
    },
    {
        "id": 295,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22682&unique=1683187150000",
        "name": "Phenyl - Standard Brands - Non-Concentrate",
        "description": "DEJ-PHE-STA-NONC-0-L275"
    },
    {
        "id": 296,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22684&unique=1683187150000",
        "name": "Phenyl - Tyfon - Non-Concentrate",
        "description": "143.75 Rs."
    },
    {
        "id": 297,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21136&unique=1683187150000",
        "name": "Phenyl - Tyfon Concentrate Phenyl",
        "description": "DEJ-PHE-TYF-CONC-0"
    },
    {
        "id": 298,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22628&unique=1683187150000",
        "name": "Plastic Plates",
        "description": "120.00 Rs."
    },
    {
        "id": 299,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22558&unique=1683187150000",
        "name": "Plates Disposable",
        "description": "1,380.00 Rs."
    },
    {
        "id": 300,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22630&unique=1683187150000",
        "name": "Polish - Kiwi - Leather Polish",
        "description": "DEJ-POL-KIW-LEAT-L-M75"
    },
    {
        "id": 301,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22631&unique=1683187150000",
        "name": "Polish - Kiwi - Wood Polish",
        "description": "DEJ-POL-KIW-WOOD-0-M250"
    },
    {
        "id": 302,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22633&unique=1683187150000",
        "name": "Polythene Bag",
        "description": "DEJ-POL-GEN-0000-0-SL"
    },
    {
        "id": 303,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22711&unique=1683187150000",
        "name": "Powder Milk - Everyday",
        "description": "2,693.25 Rs."
    },
    {
        "id": 304,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22634&unique=1683187150000",
        "name": "Powder Milk - Everyday",
        "description": "1,627.50 Rs."
    },
    {
        "id": 305,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22635&unique=1683187150000",
        "name": "Printer Paper - BLC",
        "description": "1,512.00 Rs."
    },
    {
        "id": 306,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22637&unique=1683187150000",
        "name": "Printer Paper - Copy Mate",
        "description": "DES-PRI-LOC-COPY-A-G7"
    },
    {
        "id": 307,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22639&unique=1683187150000",
        "name": "Printer Paper - HA480 (NV)",
        "description": "DES-PRI-LOC-HA48-A-G8"
    },
    {
        "id": 308,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21203&unique=1683187150000",
        "name": "Printer Paper - Legal",
        "description": "DES-PRI-LOC-GENE-L"
    },
    {
        "id": 309,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22636&unique=1683187150000",
        "name": "Printer Paper - PPC",
        "description": "DES-PRI-LOC-XPER-0-G7"
    },
    {
        "id": 310,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22638&unique=1683187150000",
        "name": "Printer Paper - Paper X (NV)",
        "description": "DES-PRI-LOC-PAPE-A-G7"
    },
    {
        "id": 311,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21279&unique=1683187150000",
        "name": "Printer Paper - Xpert",
        "description": "DES-PRI-LOC-XPER-0"
    },
    {
        "id": 312,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21196&unique=1683187150000",
        "name": "Qarshi - Johar Joshanda",
        "description": "DEP-JOS-QAR-JOHA-0"
    },
    {
        "id": 313,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21274&unique=1683187150000",
        "name": "Ramadan Package 1",
        "description": "CGSP-RAM-GEN-0000-1"
    },
    {
        "id": 314,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21275&unique=1683187150000",
        "name": "Ramadan Package 2",
        "description": "CGSP-RAM-GEN-0000-2"
    },
    {
        "id": 315,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21276&unique=1683187150000",
        "name": "Ramadan Package 3",
        "description": "CGSP-RAM-GEN-0000-3"
    },
    {
        "id": 316,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21277&unique=1683187150000",
        "name": "Ramadan Package 4",
        "description": "CGSP-RAM-GEN-0000-4"
    },
    {
        "id": 317,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22627&unique=1683187150000",
        "name": "Recycled PET bottle",
        "description": "0.00 Rs."
    },
    {
        "id": 318,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21245&unique=1683187150000",
        "name": "Register 200 pages",
        "description": "DES-REG-GEN-0000-2"
    },
    {
        "id": 319,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21237&unique=1683187150000",
        "name": "Rose Petal - Party Pack",
        "description": "DEJ-TIS-ROS-PART-3"
    },
    {
        "id": 320,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22640&unique=1683187150000",
        "name": "Ruler Metal",
        "description": "DES-RUL-GEN-0000-M-I12"
    },
    {
        "id": 321,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21207&unique=1683187150000",
        "name": "Sanitary Pads - Always Maxi Thicks - Extra Long",
        "description": "DEJ-SAN-ALW-MAXI-E"
    },
    {
        "id": 322,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22765&unique=1683187150000",
        "name": "Sanitizer - Khula",
        "description": "DEJ-SAN-GEN-KHUL-0-L1"
    },
    {
        "id": 323,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22641&unique=1683187150000",
        "name": "Sanitizer - Lifebouy",
        "description": "DEJ-SAN-UNI-LIFE-0-M60"
    },
    {
        "id": 324,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22642&unique=1683187150000",
        "name": "Sanitizer - Sanitell",
        "description": "DEJ-SAN-BRA-SANI-0-M400"
    },
    {
        "id": 325,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22766&unique=1683187150000",
        "name": "Sanitizer Dispenser - Generic",
        "description": "DEJ-SAN-GEN-0000-0"
    },
    {
        "id": 326,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21142&unique=1683187150000",
        "name": "Saw - Cutter",
        "description": "ADH-SAW-GEN-0000-0"
    },
    {
        "id": 327,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22643&unique=1683187150000",
        "name": "Scissors",
        "description": "0.00 Rs."
    },
    {
        "id": 328,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21246&unique=1683187150000",
        "name": "Self Adhesive Pads - 3M - Sticky Notes",
        "description": "DES-SEL-3M-STIC-0"
    },
    {
        "id": 329,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21247&unique=1683187150000",
        "name": "Self Adhesive Pads - Generic",
        "description": "DES-SEL-GEN-0000-0"
    },
    {
        "id": 330,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22767&unique=1683187150000",
        "name": "Serving Spoon - Generic",
        "description": "DEP-SER-GEN-0000-0"
    },
    {
        "id": 331,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21248&unique=1683187150000",
        "name": "Serving Tray",
        "description": "ADOS-SER-GEN-0000-0"
    },
    {
        "id": 332,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21250&unique=1683187150000",
        "name": "Sharpener - Metal",
        "description": "DES-SHA-GEN-0000-M"
    },
    {
        "id": 333,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21249&unique=1683187150000",
        "name": "Sharpener - Plastic",
        "description": "DES-SHA-GEN-0000-P"
    },
    {
        "id": 334,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21251&unique=1683187150000",
        "name": "Shoe Brush",
        "description": "DEJ-SHO-GEN-0000-0"
    },
    {
        "id": 335,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21253&unique=1683187150000",
        "name": "Silica Gel",
        "description": "ADOS-SIL-GEN-0000-0"
    },
    {
        "id": 336,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22647&unique=1683187150000",
        "name": "Soft Drink - 7up",
        "description": "0.00 Rs."
    },
    {
        "id": 337,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22645&unique=1683187150000",
        "name": "Soft Drink - Coca Cola",
        "description": "DEP-SOF-COC-COCA-0-M500"
    },
    {
        "id": 338,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22644&unique=1683187150000",
        "name": "Soft Drink - Diet Coke",
        "description": "DEP-SOF-COC-DIET-0-M300"
    },
    {
        "id": 339,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22646&unique=1683187150000",
        "name": "Soft Drink - Pakola",
        "description": "DEP-SOF-PAK-PAKO-0-M345"
    },
    {
        "id": 340,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22648&unique=1683187150000",
        "name": "Soft Drink - Pepsi",
        "description": "DEP-SOF-PEP-PEPS-0-M345"
    },
    {
        "id": 341,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22559&unique=1683187150000",
        "name": "Spoon Disposable",
        "description": "156.00 Rs."
    },
    {
        "id": 342,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22649&unique=1683187150000",
        "name": "Stapler",
        "description": "0.00 Rs."
    },
    {
        "id": 343,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21254&unique=1683187150000",
        "name": "Stapler - Pin Opener",
        "description": "DES-STA-GEN-0000-O"
    },
    {
        "id": 344,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21255&unique=1683187150000",
        "name": "Stapler Pin - Dollar",
        "description": "DES-STA-DOL-0000-0"
    },
    {
        "id": 345,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21256&unique=1683187150000",
        "name": "Stapler Pin - No. 10",
        "description": "DES-STA-GEN-NO-0"
    },
    {
        "id": 346,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22650&unique=1683187150000",
        "name": "Sticker Roll",
        "description": "DEPK-STI-GEN-0000-0-S4060"
    },
    {
        "id": 347,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21260&unique=1683187150000",
        "name": "Sticker Sheet",
        "description": "DEPK-STI-GEN-0000-S"
    },
    {
        "id": 348,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22651&unique=1683187150000",
        "name": "Sticky Notes - 3M",
        "description": "DES-STI-3M-0000-0-S33"
    },
    {
        "id": 349,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21261&unique=1683187150000",
        "name": "Sticky Notes - Generic",
        "description": "DES-STI-GEN-0000-0"
    },
    {
        "id": 350,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21259&unique=1683187150000",
        "name": "Stretch Wrap",
        "description": "DEPK-STR-GEN-0000-0"
    },
    {
        "id": 351,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22652&unique=1683187150000",
        "name": "Sugar",
        "description": "115.50 Rs."
    },
    {
        "id": 352,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22712&unique=1683187150000",
        "name": "Surface Disinfectant - Dettol - Lemon",
        "description": "630.00 Rs."
    },
    {
        "id": 353,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22653&unique=1683187150000",
        "name": "Surface Disinfectant - Generic",
        "description": "DEJ-SUR-GEN-0000-0-L5"
    },
    {
        "id": 354,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22713&unique=1683187150000",
        "name": "Surface Disinfectant - HiClean",
        "description": "0.00 Rs."
    },
    {
        "id": 355,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21109&unique=1683187150000",
        "name": "Sweetener - Splenda",
        "description": "DEJ-SWE-SPL-0000-0"
    },
    {
        "id": 356,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21263&unique=1683187150000",
        "name": "Table Spoon",
        "description": "ADOS-TAB-GEN-0000-0"
    },
    {
        "id": 357,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21208&unique=1683187150000",
        "name": "Tablet Medicine - Ansaid - 100mg",
        "description": "DEM-TAB-PFI-ANSA-1"
    },
    {
        "id": 358,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21209&unique=1683187150000",
        "name": "Tablet Medicine - Arinac - Forte",
        "description": "DEM-TAB-ABO-ARIN-F"
    },
    {
        "id": 359,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21210&unique=1683187150000",
        "name": "Tablet Medicine - Arinac - Plus",
        "description": "DEM-TAB-ABO-ARIN-P"
    },
    {
        "id": 360,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21211&unique=1683187150000",
        "name": "Tablet Medicine - Calci-100",
        "description": "DEM-TAB-GSK-CALC-0"
    },
    {
        "id": 361,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21212&unique=1683187150000",
        "name": "Tablet Medicine - Disprin",
        "description": "DEM-TAB-REC-DISP-0"
    },
    {
        "id": 362,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22753&unique=1683187150000",
        "name": "Tablet Medicine - Entox - 630gm",
        "description": "DEM-TAB-LCI-ENTO-6"
    },
    {
        "id": 363,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21214&unique=1683187150000",
        "name": "Tablet Medicine - Flagyl",
        "description": "DEM-TAB-SAN-FLAG-0"
    },
    {
        "id": 364,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21217&unique=1683187150000",
        "name": "Tablet Medicine - Panadol - 500mg",
        "description": "DEM-TAB-GSK-PANA-5"
    },
    {
        "id": 365,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21215&unique=1683187150000",
        "name": "Tablet Medicine - Panadol - Extra",
        "description": "DEM-TAB-GSK-PANA-E"
    },
    {
        "id": 366,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21216&unique=1683187150000",
        "name": "Tablet Medicine - Panadol - Xtend",
        "description": "DEM-TAB-GSK-PANA-X"
    },
    {
        "id": 367,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21219&unique=1683187150000",
        "name": "Tablet Medicine - Ponstan - 500mg",
        "description": "DEM-TAB-GSK-PONS-5"
    },
    {
        "id": 368,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21218&unique=1683187150000",
        "name": "Tablet Medicine - Ponstan - Forte",
        "description": "DEM-TAB-GSK-PONS-F"
    },
    {
        "id": 369,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21220&unique=1683187150000",
        "name": "Tablet Medicine - Pyodine",
        "description": "DEM-TAB-BRO-PYOD-0"
    },
    {
        "id": 370,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21221&unique=1683187150000",
        "name": "Tablet Medicine - Risek - 20mg",
        "description": "DEM-TAB-GET-RISE-2"
    },
    {
        "id": 371,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21223&unique=1683187150000",
        "name": "Tablet Medicine - Strepsils",
        "description": "DEM-TAB-REC-STRE-0"
    },
    {
        "id": 372,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21264&unique=1683187150000",
        "name": "Toilet Brush",
        "description": "ADH-TOI-GEN-0000-0"
    },
    {
        "id": 373,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22656&unique=1683187150000",
        "name": "Toilet Cleaner - Black Betty",
        "description": "0.00 Rs."
    },
    {
        "id": 374,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22657&unique=1683187150000",
        "name": "Toilet Cleaner - Fresco",
        "description": "DEJ-TOI-STA-FRES-0-M500"
    },
    {
        "id": 375,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22714&unique=1683187150000",
        "name": "Toilet Cleaner - Harpic - Blue",
        "description": "682.50 Rs."
    },
    {
        "id": 376,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22658&unique=1683187150000",
        "name": "Toilet Cleaner - Tyfon",
        "description": "DEJ-TOI-TYF-0000-0-L1"
    },
    {
        "id": 377,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21266&unique=1683187150000",
        "name": "Toilet Paper Dispenser",
        "description": "ADOS-TOI-GEN-0000-0"
    },
    {
        "id": 378,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22659&unique=1683187150000",
        "name": "Toilet Paper Roll - Fay",
        "description": "DEJ-TIS-FAY-0000-T-P10"
    },
    {
        "id": 379,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22660&unique=1683187150000",
        "name": "Toilet Paper Roll - Generic",
        "description": "DEJ-TIS-GEN-0000-T-P10"
    },
    {
        "id": 380,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22662&unique=1683187150000",
        "name": "Toilet Paper Roll - Rose Petal",
        "description": "DEJ-TIS-ROS-0000-T-P10"
    },
    {
        "id": 381,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22661&unique=1683187150000",
        "name": "Toilet Paper Roll - Rose Petal Maxorb",
        "description": "DEJ-TIS-ROS-MAXO-T-P10"
    },
    {
        "id": 382,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22663&unique=1683187150000",
        "name": "Toilet Paper Roll - Tulip",
        "description": "DEJ-TIS-TUL-0000-T-P10"
    },
    {
        "id": 383,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22664&unique=1683187150000",
        "name": "Toilet Paper Roll - Tux",
        "description": "525.00 Rs."
    },
    {
        "id": 384,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21265&unique=1683187150000",
        "name": "Toilet Plunger",
        "description": "DEJ-TOI-GEN-0000-0"
    },
    {
        "id": 385,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21267&unique=1683187150000",
        "name": "Tooth Pick",
        "description": "DEP-TOO-GEN-0000-0"
    },
    {
        "id": 386,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22666&unique=1683187150000",
        "name": "Towel",
        "description": "ADOS-TOW-GEN-0000-0-SM"
    },
    {
        "id": 387,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22667&unique=1683187150000",
        "name": "Transparent Tape",
        "description": "DEPK-TRA-GEN-0000-0-P6"
    },
    {
        "id": 388,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21268&unique=1683187150000",
        "name": "Transparent Tape - Magic",
        "description": "DEPK-TRA-GEN-MAGI-3"
    },
    {
        "id": 389,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22669&unique=1683187150000",
        "name": "Trash Bag - Generic",
        "description": "178.50 Rs."
    },
    {
        "id": 390,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22668&unique=1683187150000",
        "name": "Trash Bag - Home Sense",
        "description": "DEJ-TRA-HOM-0000-0-S3050"
    },
    {
        "id": 391,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22761&unique=1683187150000",
        "name": "Trash Bag - White",
        "description": "DEJ-TRA-GEN-WHIT-0-S1824"
    },
    {
        "id": 392,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22670&unique=1683187150000",
        "name": "Trash Can",
        "description": "ADOS-TRA-GEN-0000-0-SM"
    },
    {
        "id": 393,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21238&unique=1683187150000",
        "name": "UHU - Sticky Tac",
        "description": "DES-PAT-UHU-STIC-0"
    },
    {
        "id": 394,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21270&unique=1683187150000",
        "name": "USB - Card",
        "description": "CGGP-USB-GEN-CARD-0"
    },
    {
        "id": 395,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21269&unique=1683187150000",
        "name": "USB - Standard",
        "description": "CGGP-USB-GEN-STAN-0"
    },
    {
        "id": 396,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21271&unique=1683187150000",
        "name": "Voucher Book",
        "description": "DES-VOU-GEN-0000-0"
    },
    {
        "id": 397,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21272&unique=1683187150000",
        "name": "Water Bottle Brush",
        "description": "DEJ-WAT-GEN-0000-0"
    },
    {
        "id": 398,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22671&unique=1683187150000",
        "name": "Water Bucket",
        "description": "ADOS-WAT-GEN-0000-0-SM"
    },
    {
        "id": 399,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22672&unique=1683187150000",
        "name": "Wet Mop (Refill Only)",
        "description": "168.00 Rs."
    },
    {
        "id": 400,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21273&unique=1683187150000",
        "name": "Wet Mop with handle",
        "description": "DEJ-WET-GEN-0000-H"
    },
    {
        "id": 401,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22673&unique=1683187150000",
        "name": "Wet wipes - Cool n Cool",
        "description": "126.50 Rs."
    },
    {
        "id": 402,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21240&unique=1683187150000",
        "name": "Whito",
        "description": "DES-WHI-GEN-0000-P"
    },
    {
        "id": 403,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=21239&unique=1683187150000",
        "name": "Whito - Pen",
        "description": "DES-WHI-GEN-PEN-P"
    },
    {
        "id": 404,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22674&unique=1683187150000",
        "name": "Window Cleaner - Black Betty",
        "description": "DEJ-WIN-BLA-0000-O-M500"
    },
    {
        "id": 405,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22675&unique=1683187150000",
        "name": "Window Cleaner - Glint",
        "description": "DEJ-WIN-PER-GLIN-O-M500"
    },
    {
        "id": 406,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22676&unique=1683187150000",
        "name": "Window Cleaner - Scotch Brite",
        "description": "DEJ-WIN-3M-SCOT-O-M500"
    },
    {
        "id": 407,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22678&unique=1683187150000",
        "name": "Window Cleaner - Tyfon - Lavender",
        "description": "DEJ-WIN-TYF-0000-L-M500"
    },
    {
        "id": 408,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22677&unique=1683187150000",
        "name": "Window Cleaner - Tyfon - Original",
        "description": "DEJ-WIN-TYF-0000-O-M500"
    },
    {
        "id": 409,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22585&unique=1683187150000",
        "name": "Window Wiper",
        "description": "DEJ-WIP-GEN-WIND-0-SS"
    },
    {
        "id": 410,
        "pic": "https://haasil.dymaxel.com/web/image?model=product.template&field=image_128&id=22760&unique=1683187150000",
        "name": "tulip",
        "description": "0.00 Rs."
    }
]

export const formErrors = {
    emptyField: "Please fill this field!",
    confirmPassword: "Password did not match!",
    // emailPattern: "Enter pattern is not valid!"
}