import { Button, Col, Image, Modal, Row, Typography } from "antd";
import React from "react";
import { useState } from "react";
import { Images } from "../../Assets/Images/Images";
import { Colors } from "../../Assets/Colors";
import { useNavigate } from "react-router-dom";

const ConfirmationModal = ({ open, close }) => {
  const navigate = useNavigate()
  return (
    <Modal closeIcon={null} centered open={open} onCancel={close} footer={null} >
      <Row className="flex justify-center">
        <Col md={12} xs={19}>
          <div className="flex flex-col items-center">
            <div className="w-[150px] h-[150px] flex justify-center items-center  rounded-[50%] bg-[#061e3a]">
              <img
                className="w-[100px] h-[100px] my-auto mx-auto object-contain"
                preview={false}
                src={Images.RfqConfirmation}
              />

            </div>
            <Typography.Text
              className="bold-heading text-center mt-5 text-[20px]"
            >
              We have received your Request for Quotation.
            </Typography.Text>

            <Typography.Text
              className=" text-center mt-5 text-[12px]"
            >
              Our team will share a quotation with you shortly.
            </Typography.Text>
            <Button onClick={() => {
              // navigate('/quotes')
              close()
            }} className={`mt-2 w-[50%]  bg-[${Colors.darkBlue}] text-[white]`} >
              Ok
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfirmationModal;
