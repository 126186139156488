

import { CheckOutlined, DeleteOutlined, EyeOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Image, Radio, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Colors } from '../../../Assets/Colors';
import { Images } from '../../../Assets/Images/Images';
import { useNavigate } from 'react-router-dom';
import { getTimeRemainingFromDate } from '../../../helpers/getTimeFromDate';
import { getRemainingTimeInHours } from '../../../helpers/helper'
import PaginationComponent from '../../Pagination/PaginationComponent';




const ApprovedQuotes = ({ data, getApprovedQuotes, total }) => {
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [totalItems, setTotalItems] = useState([]);
    const [count, setCount] = useState(total)
    const [Search, setSearch] = useState('')

    useEffect(() => {
        setCount(total)
    }, [total])
    const columns = [
        {
            title: 'Quotation No.',
            dataIndex: 'orderId',
            render: (item, Data, index) => {
                return <Button onClick={() => { navigate(`/customer-quote/${item}?type=view`) }} className='font-[800]'>
                    AP-00{item}
                </Button>

            }
        },
        {
            title: 'Name',
            dataIndex: 'customername',
            render: (item, Data, index) => {
                return <p class="whitespace-normal">{Data.User?.firstname}</p>
            }
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            render: (item, Data, index) => {
                return <p class="whitespace-normal">{Data.User?.Company?.name}</p>
            }
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            render: (item, Data, index) => {
                return <p class="whitespace-normal">{Data.User?.emailAddress}</p>
            }
        },
        {
            title: 'Phone No.',
            dataIndex: 'Email',
            render: (item, Data, index) => {
                return <p class="whitespace-normal">{Data.User?.phoneNumber}</p>
            }
        },
        {
            title: 'Order Price',
            dataIndex: 'orderPrice',
            render: (item, Data, index) => {
                return <p class="whitespace-normal">{ "Rs. "+Data?.orderPrice }</p>
            }
        },
        {
            title: 'Payment Type',
            dataIndex: 'Payment Type',
            render: (item, Data, index) => {
                return <p class="whitespace-normal">{Data?.paymentType}</p>
            }
        },
        {
            title: 'Expiry Date',
            dataIndex: 'endDate',
            align: "center",
            render: (item, Data, index) => {

                let time = getRemainingTimeInHours(Data?.startDate, Data?.endDate)

                return <p style={{ fontWeight: "700", color: time !== 'Quote Expired' ? 'black' : 'red',whiteSpace:'wrap' }}>{time} </p>
            }

        },

    ];


    return (
        <div className='w-full'>
            <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                scroll={{y:400}}
                className='admin-customer-table overflow-x-scroll'
                
            />
            <Row className="flex justify-end">

                <PaginationComponent search={Search} currentPage={currentPage} itemsPerPage={itemsPerPage} totalItems={count} setCurrentPage={setCurrentPage} callProducts={getApprovedQuotes} />

            </Row>
        </div>
    );
};
export default ApprovedQuotes;