import React from "react";
import Layout from "../../Layout/Layout";
import Typography from "antd/es/typography/Typography";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  // RangePicker
} from "antd";

// import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Colors } from "../../Assets/Colors";
import { useState } from "react";
import { CaretLeftOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../Components/Modals/Confirmation";
import { useEffect } from "react";
import { GetAddressById } from "../../API-Interaction/api-functions/address.api";
import { useDispatch, useSelector } from "react-redux";
import { removeItemFromCart, updateOrder } from "../../Redux/features/cartSlice";
import { onCreateOrder } from "../../API-Interaction/api-functions/order.api";
import { resetCart } from "../../Redux/features/cartSlice";
import ViewOrderModal from "../../Components/Modals/ViewOrderModal";
import moment from "moment";
import { error } from "../../helpers/notifications";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const Checkout = () => {
  let token = localStorage.getItem('authTokenHaasil')
  const dispatch = useDispatch()
  const { pageLoader } = useSelector(state => state.utils.loaders)
  const navigate = useNavigate();
  const [Modal, setModal] = useState(false);
  const [value, setValue] = useState(1);
  const [Address, setAddress] = useState({})
  const [poDate, setPoDate] = useState()
  const cart = useSelector((state) => state?.cart);
  const UploadDocument = useSelector((state) => state?.cart?.uploadedDocuments);
  const [viewCart, setviewCart] = useState(false)
  const [isPaymentMethodSelected, setIsPaymentMethodSelected] = useState(false);
  const [isDeliveryDateSelected, setIsDeliveryDateSelected] = useState(false);


  // Function to disable dates before the minimum allowed date
  const disabledDate = current => {
    // Get today's date
    const today = new Date();

    // Calculate the minimum allowed date
    const minDate = new Date();
    minDate.setDate(today.getDate() + 2); // Minimum two days after today

    // Disable dates before the minimum allowed date
    return current && current < minDate;
  };
  // Handle Radio Button
  const onChange = (e) => {
    dispatch(updateOrder({ data: e.target.value, key: 'PaymentType' }))
    setValue(e.target.value);
  };

  // const HandleDate = (date, dateString, type) => {
  //   if (type === 'deliveryDate')
  //     return dispatch(updateOrder({ data: dateString, key: 'deliveryDate' }))
  //   setPoDate(dateString)
  // };
  const HandleDate = (date, dateString, type) => {
    const formattedDate = moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD'); // Format for submission
    if (type === 'deliveryDate')
      return dispatch(updateOrder({ data: formattedDate, key: 'deliveryDate' }));
    setPoDate(dateString); // Display in 'DD-MM-YYYY' format
  };
  const trackRFIDGeneration = (rfidValue) => {
    // Initialize gtag function
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    // Track the RFID generation event
    gtag('event', 'rfid_generation', {
      'event_category': 'rfid',
      'event_label': rfidValue,
    });
  };

  const HandleSubmit = async (value) => {
    if (cart?.order?.address) {
      let Data = {
        products: Object.values(cart.cartItems),
        paymentType: cart?.order?.PaymentType,
        preferredDeliveryDate: cart?.order?.deliveryDate,
        note: cart?.order?.notes,
        address: cart?.order?.address,
        orderDocuments: UploadDocument
      }
      let result = await onCreateOrder(dispatch, Data, token)
      if (result.success) {
        setModal(true)
      }
    } else {
      error('Kindly Select Address')
    }
  };

  const HandleModal = () => {
    dispatch(resetCart())
    navigate('/daily-essentials')
  };

  const HandleViewCart = () => {
    setviewCart(!viewCart)
  };


  const SetUserAddress = async () => {
    setAddress(cart?.order?.address)
  }
  useEffect(() => {
    window.scroll(0, 0);
    if (Object.keys(cart.cartItems).length == 0 && UploadDocument.length == 0) {
      navigate('/daily-essentials')
    } else {
      SetUserAddress()
    }

  }, []);
  return (
    <div>
      <Spin spinning={pageLoader.getaddressbyid || false}>
        <Form onFinish={HandleSubmit}>
          <Row>
            <Col md={17} xs={24}>
              <div>
                <Typography.Text className="bold-heading">
                  2. Choose Payment Method And PO Details
                </Typography.Text>
              </div>
              <div>
                <Form.Item
                  name={"paymentmethod"}
                  rules={[
                    {
                      required: true,
                      message: "kindly select payment method!",
                    },
                  ]}
                >
                  <Radio.Group className="mt-3" onChange={onChange} value={value}>
                    <Space direction="vertical">
                      <Radio value={"Net 15"}>Net 15</Radio>
                      {/* <Radio value={"Net 30"}>Net 30</Radio> */}
                      {/* <Radio value={"Net 45"}>Net 45</Radio> */}
                      <Radio value={"Cash on delivery"}>Cash on delivery</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>

              <Divider className="my-[5px] mx-[0px]" />
              {/* Select Date */}
              <div>
                {/* Address Section */}
                <Typography.Text
                  className={`bold-heading text-[${Colors.black}]`}
                >
                  3. Prefered Delivery Date
                </Typography.Text>
                <div className="mt-4">
                  <Form.Item name="date"

                    rules={[
                      {
                        required: true,
                        message: "Kindly Select  Delivery Date ",
                      },
                    ]}


                  >
                    {/* <DatePicker disabledDate={disabledDate} onChange={(date, dateString) => { HandleDate(date, dateString, 'deliveryDate') }} value={poDate} /> */}
                    <DatePicker
                      disabledDate={disabledDate}
                      onChange={(date, dateString) => { HandleDate(date, dateString, 'deliveryDate') }}
                      value={moment(poDate, 'DD-MM-YYYY')} // Display in 'DD-MM-YYYY' format
                      format="DD-MM-YYYY" // Set the desired date format for display
                    />
                  </Form.Item>
                </div>
                <div className="mt-4 flex gap-2">
                  {/* <Button loading={pageLoader.createOrder || false}
                    htmlType="submit"
                    className={`bg-[${Colors.darkBlue}] text-white py-[0px] px-[50px] `}
                  >
                    Submit
                  </Button> */}
                  {/* <Button
                  loading={pageLoader.createOrder || false}
                  htmlType="submit"
                  onClick={() => {
                    HandleSubmit(); // Call your submit function here
                    trackRFIDGeneration('GeneratedRFID123'); // Track RFID generation here
                  }}
                  className={`bg-[${Colors.darkBlue}] text-white py-[0px] px-[50px] `}
                >
                  Submit
                </Button> */}
                  <Button onClick={HandleViewCart} className={` flex items-center bg-[white]  text-[#0A789A] font-semibold text-[12px] rounded-md  text-center`}
                  >
                    <EyeOutlined />
                    View Order
                  </Button>
                  <Button
                    loading={pageLoader.createOrder || false}
                    htmlType="submit"
                    onClick={() => {
                      if (isPaymentMethodSelected && isDeliveryDateSelected) {
                        HandleSubmit(); // Call your submit function here
                        trackRFIDGeneration('GeneratedRFID123'); // Track RFID generation here
                      } else {
                        // Display an error message to the user or handle the case where the fields are not filled out.
                        console.log('Please select both payment method and delivery date');
                      }
                    }}
                    className={`bg-[${Colors.darkBlue}] text-white py-[0px] px-[50px] `}
                  >
                    Place Order
                  </Button>

                  {/* <Button
                  loading={pageLoader.createOrder || false}
                  htmlType="submit"
                  onClick={() => {
                    HandleViewCart();
                    trackRFIDGeneration('GeneratedRFID123'); // Track RFID generation here
                  }}
                  className={`bg-[${Colors.darkBlue}] text-white py-[0px] px-[50px] `}
                >
                  {viewCart ? <EyeOutlined /> : "Submit"}
                </Button> */}
                </div>
              </div>
            </Col>
            <ConfirmationModal open={Modal} close={HandleModal} />
            <ViewOrderModal open={viewCart} close={HandleViewCart} />

          </Row>
        </Form>
      </Spin >
    </div >
  );
};

export default Checkout;
