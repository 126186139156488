import { CheckOutlined, DeleteOutlined, EyeOutlined, PrinterOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Image, Modal, Radio, Row, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Colors } from '../../../Assets/Colors';
import { Images } from '../../../Assets/Images/Images';
import { useNavigate } from 'react-router-dom';
import { getDateInProperFormat, getDateInProperFormatInvoice } from '../../../helpers/helper';
import InvoiceGenerator from '../../InvoiceGenerator/InvoiceGenerator';
import { getInvoiceById, updatePaymentStatus } from '../../../API-Interaction/api-functions/invoice.api';
import { useDispatch } from 'react-redux';
import PaginationComponent from '../../Pagination/PaginationComponent';
import DeliveryChallanGenerator from '../../InvoiceGenerator/DeliveryChallanGenerator';
import ParkingSlipGenerator from '../../InvoiceGenerator/PakingSlipGenerator';




const AdminInvoiceTable = ({ data, total, getInvoices }) => {
  const [open, setopen] = useState(false)
  let dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1);
  const [Data, setData] = useState()
  const itemsPerPage = 10;
  const [count, setCount] = useState(total)
  const [Search, setSearch] = useState('')
  const [editPaymentModalVisible, setEditPaymentModalVisible] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [selectedInvoicePaymentStatus, setSelectedInvoicePaymentStatus] = useState('');
  const { Option } = Select;

  const HandleEditPayment = (invoiceId, paymentStatus) => {
    setSelectedInvoicePaymentStatus(paymentStatus);
    setSelectedInvoiceId(invoiceId);
    setEditPaymentModalVisible(true);
  };

  const HandleSavePayment = async () => {
    try {
      const response = await updatePaymentStatus(
        token,
        selectedInvoiceId,
        selectedInvoicePaymentStatus,
        dispatch,
          // Make sure you define and pass invoiceId here
    );
      if (response.success) {

        // If the API call is successful, close the modal and optionally update your data.
        setEditPaymentModalVisible(false);
        // You can update the data in your table or refetch the data to reflect the changes.
      } else {
        // Handle error cases if the API request fails or returns an error.
        console.error('API request failed');
      }
    } catch (error) {
      // Handle errors thrown by the API call.
      console.error('API request error:', error);
    }
  };


  useEffect(() => {
    setCount(total)
  }, [total])

  let token = localStorage.getItem('authTokenHaasil')
  const HandleModal = () => {
    setopen(!open)
  }
  const navigate = useNavigate()
  const columns = [
    {
      title: 'Invoice No#',
      dataIndex: 'invoiceId',
      align: "center",
      render: (item, Data, index) => {
        return (
          <Button onClick={() => { navigate(`/customer-quote/${Data?.Order?.orderId}?type=view`) }} className='font-[800]'>

            I-00{item}
          </Button>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'emailAddress',
      render: (item, Data, index) => {
        return <p class="whitespace-normal overflow-hidden truncate">{Data?.Order?.User?.emailAddress}</p>;
      },
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      render: (item, Data, index) => {
        return <p class="whitespace-normal overflow-hidden truncate">{Data?.Order?.User?.Company?.name ? Data?.Order?.User?.Company?.name : ' Liquid Technologies'}</p>;
      },
    },
    {
      title: 'POC Name',
      dataIndex: 'companyName',
      render: (item, Data, index) => {
        return <p class="whitespace-normal overflow-hidden truncate">{Data?.Order?.Address?.fullname ? Data?.Order?.Address?.fullname : ' POC'}</p>;
      },
    },
    {
      title: 'Delivery Date',
      dataIndex: 'companyName',
      render: (item, Data, index) => {
        let date = getDateInProperFormatInvoice(Data?.Order?.deliveryDate);
        return <p class="whitespace-normal overflow-hidden truncate">{date || 'N/A'}</p>;
      },
    },
    {
      title: 'Phone N.o',
      dataIndex: 'phone',
      render: (item, Data, index) => {
        return <p class="whitespace-normal overflow-hidden truncate">{Data?.Order?.Address?.number ? Data?.Order?.Address?.number : " POC NUMBER"}</p>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (item, Data, index) => {
        return <p class="whitespace-normal overflow-hidden truncate">Rs. {item}</p>;
      },
    },

    {
      title: 'Actions',
      key: 'address',
      dataIndex: 'address',
      render: (_, record) => {
        console.log(record)
        return (
          <div className='flex justify-center items-center gap-3'>
            <Button onClick={() => { HandlePrintInvoice(record.invoiceId) }} className='p-0 border-none  flex items-center justify-center ' >
              <PrinterOutlined className={`text-[20px] text-[${Colors.darkBlue}] shadow-none`} />
            </Button>
          </div>
        );
      },
      align: "center",
    },
    // {
    //   title: 'Payment',
    //   dataIndex: 'paymentRecieved',
    //   render: (paymentRecieved) => (
    //     <span>{paymentRecieved}</span>
    //   ),
    // },
    // {
    //   title: 'Edit',
    //   dataIndex: 'Edit',
    //   render: (_, record) => (
    //     <Button onClick={() => HandleEditPayment(record.invoiceId, record.paymentRecieved)}>
    //       Edit
    //     </Button>
    //   ),
    // },
  ];

  const HandlePrintInvoice = async (id) => {
    const response = await getInvoiceById(dispatch, token, id)

    if (response.success) {
      setData(response?.response?.order[0])
      HandleModal()
    }
  }
  return (
    <div className='w-full'>
      <Table
        pagination={false}
        columns={columns}
        dataSource={data}
        className='admin-customer-table overflow-x-scroll'
        scroll={{ y: 390, x: '100%' }}
      />
      <InvoiceGenerator open={open} close={HandleModal} data={Data} />
      {/* <DeliveryChallanGenerator open={open} close={HandleModal} data={Data} /> */}
      {/* <ParkingSlipGenerator open={open} close={HandleModal} data={Data} /> */}

      <Row className="flex justify-end">
        <PaginationComponent search={Search} currentPage={currentPage} itemsPerPage={itemsPerPage} totalItems={count} setCurrentPage={setCurrentPage} callProducts={getInvoices} />
      </Row>
      <Modal
        title="Edit Payment Received"
        visible={editPaymentModalVisible}
        okButtonProps={{ style: { background: Colors.darkBlue, color: "white" } }}

        onOk={HandleSavePayment}
        onCancel={() => setEditPaymentModalVisible(false)}
        style={{ height: '50px' }}
      >
        <Select
          style={{ width: '100%' }}
          value={selectedInvoicePaymentStatus}
          onChange={(value) => setSelectedInvoicePaymentStatus(value)}
        >
          <Option value={0}>Not Received</Option>
          <Option value={1}>Received</Option>
        </Select>
      </Modal>
    </div>
  );
};
export default AdminInvoiceTable;



