
import { CheckOutlined, DeleteOutlined, EyeOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Image, Input, Radio, Select, Spin, Switch, Table, message } from 'antd';
import { useEffect, useState } from 'react';
import { Colors } from '../../../Assets/Colors';
import { Images } from '../../../Assets/Images/Images';
import ProductImage from '../../../../Assets/productNestle.png'
import { useParams } from 'react-router-dom';
import { getQuotationById, updateOrderByOrderId, deleteOrderByOrderId, getProductById } from '../../../API-Interaction/api-functions/quotation.api';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrder, updateOrderItem, updateOrderRfq } from '../../../API-Interaction/api-functions/order.api';
import { success } from '../../../helpers/notifications';



const Quotestable = ({ callApiCheck, allOrderData, setorderRemarks, setClientRemarks, setOrderStatus, setDocument, setAddressDetail, setCompanyDetails }) => {
    const { id } = useParams()
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const [Status, setStatus] = useState('')
    const [editMode, setEditMode] = useState(false)
    const [productList, setProductList] = useState(false)

    const [Data, setData] = useState([])
    // Render the component as Data get Changed
    const getQuoteById = async (id) => {
        const result = await getQuotationById(dispatch, id, token)
        if (result.success) {
            setDocument(result?.response?.order[0]?.documents ? result?.response?.order[0]?.documents[0] : "")
            let OrderData = result?.response?.order[0]?.OrderItems?.map((o) => ({ ...o, GST: o?.GST || 0 }))
            setData(OrderData)
            setCompanyDetails(result?.response?.order[0]?.User)
            allOrderData(result?.response?.order[0])
            setAddressDetail(result?.response?.order[0].Address)
            setStatus(result?.response?.order[0]?.status)
            setOrderStatus(result?.response?.order[0]?.status)
            setClientRemarks(result?.response?.order[0]?.note)
            setorderRemarks(result?.response?.order[0]?.orderRemarks)
        }
        else {
            message.error("Please fill the forms")
        }

    }
    // const handleSelectChange = (productId, index) => {
    //             let product = productList?.filter((prod) => prod?.productId == productId)
    //             if (!product?.length) return
    //             product = product[0]
    //             let defaultObj = {

    //                 quantity: 0,
    //                 unitrate: 0,
    //                 GST: 0,
    //                 includeGst: true,   


    //             }
    //             const catagoriesToDelete = [
    //                 'productCategoryL1',
    //                 'productCategoryL2',
    //                 'productCategoryL3',
    //                 'productCategoryL4',
    //                 'productCategoryL5',
    //                 'productCategoryL6',
    //                 'productCategoryL10'
    //             ]
    //             catagoriesToDelete?.forEach((category) => delete product[category])
    //             const newData = Data.map((item, itemIndex) => {
    //                 if (itemIndex === index) {
    //                     return {
    //                         ...defaultObj,
    //                         Product: product,
    //                     };
    //                 }
    //                 return item;
    //             });
    //             setData(newData);
    //             console.log({ newData })
    //         };



    // const handleSelectChange = (productId, index) => {
    //     let product = productList?.filter((prod) => prod?.productId == productId)
    //     if (!product?.length) return
    //     product = product[0]
    //     let newData = Data
    //     newData[index] = {
    //         Product: product,
    //         productId: product?.productId,
    //         quantity: product?.quantity || 0,
    //         price: product?.price || 0,
    //         GST: product?.GST || 0,
    //         includeGst: product?.includeGst ? true : false,
    //         Mode: true,
    //         totalPrice: product?.totalPrice || 0
    //     }
    //     console.log({ newData })
    //     setData(newData)
    // }

    const handleSelectChange = (productId, index) => {
        let product = productList?.filter((prod) => prod?.productId == productId);
        if (!product?.length) return;
        product = product[0];

        let newData = [...Data]; // Create a copy of the existing Data

        // Check if the product already exists at the specified index
        if (index < newData.length) {
            // Update the existing product with the selected product's SKU
            newData[index] = {
                ...newData[index],
                Product: product,
                productId: product?.productId,
                quantity: product?.quantity || 0,
                price: product?.price || 0,
                GST: product?.GST || 0,
                includeGst: product?.includeGst ? true : false,
                Mode: true,
                totalPrice: product?.totalPrice || 0,
            };
        } else {
            // Add a new product if it doesn't exist at the specified index
            const newDataItem = {
                Sno: (Data?.length || 0) + 1,
                Product: product,
                quantity: product?.quantity || 0,
                price: product?.price || 0,
                GST: product?.GST || 0,
                includeGst: product?.includeGst ? true : false,
                Mode: true,
                totalPrice: product?.totalPrice || 0,
            };
            newData.push(newDataItem);
        }

        setData(newData);
    };

    const addNewProduct = () => {
        const newData = {
            Sno: (Data?.length || 0) + 1,
            Product: {},
            quantity: 0,
            price: 0,
            GST: 0,
            includeGst: false,
            Mode: true,
            totalPrice: 0
        };
        setData([...Data, newData]);
    };
    const fetchProductData = async () => {
        const result = await getProductById(dispatch, id, token);
        if (!result.success) return
        if (!result?.response?.products?.length) return
        setProductList(result?.response?.products)
    }
    useEffect(() => {
        fetchProductData(); // Fetch product data when the component mounts
    }, []);

    const handleIncludeGst = (index, checked) => {
        const newData = Data?.map((item, itemIndex) => {
            if (itemIndex == index) {
                console.log({
                    ...item,
                    includeGst: checked,
                    totalPrice: calculateTotal(item?.quantity, item?.price, item?.GST, checked),
                })
                return {
                    ...item,
                    includeGst: checked,
                    totalPrice: calculateTotal(item?.quantity, item?.price, item?.GST, checked),
                };
            }
            return item;
        });
        setData(newData);
    };
    const calculateTotal = (quantity, price, GST, includeGst) => {
        if (includeGst)
            return Math.ceil((quantity * price) + ((quantity * price) * (GST / 100)));
        else
            return Math.ceil(quantity * price);
    };



    const HandleQuantity = (action, index, newValue = null) => {
        // Make a copy of the data to avoid mutating the state directly
        const newData = [...Data];

        // Find the product item by index
        const product = newData[index];

        // Calculate the new quantity based on the action
        if (action === 'Increment') {
            console.log({ product })
            product.quantity += 1;
        } else if (action === 'Decrement') {
            if (product.quantity > 0) {
                product.quantity -= 1;
            }
        } else if (action === 'Input' && !isNaN(newValue)) {
            // Handle quantity input change
            product.quantity = +newValue
        }

        product.totalPrice = calculateTotal(product?.quantity, product?.price, product?.GST, product?.includeGst)

        // Update the state with the new data
        setData(newData);

    };
    const columns = [
        {
            title: 'Serial No',
            align: 'center',
            key: 'Sno',
            dataIndex: 'Sno',
            render: (_, record, index) => {
                return (
                    <p>{index + 1}</p>
                );
            },
        },
        {
            title: 'PRODUCTS',
            align: 'start',
            key: 'Sno',
            dataIndex: 'Sno',
            render: (_, record, index) => {
                return (
                    <div className='flex justify-start items-center'>
                        <div className='flex justify-s items-center gap-3 w-[300px]'>
                            <Avatar
                                style={{
                                    background: 'white',
                                    boxShadow:
                                        'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                                }}
                                size='large'
                                icon={
                                    <Image
                                        src={record?.Product?.imageURLs ? record?.Product?.imageURLs : Images.CameraUpload}
                                        style={{ color: Colors.darkBlue }}
                                    />
                                }
                            />
                            {record?.Product?.name ? ( // Check if product name exists
                                <p style={{ fontSize: '12px', fontWeight: '700' }}>{record?.Product?.name}</p>
                            ) : (
                                <Select
                                    showSearch
                                    value={record?.product}
                                    style={{ width: 400 }}
                                    onChange={(prodId) => handleSelectChange(prodId, index)}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!editMode}  // Disable the dropdown when not in edit mode

                                >
                                    {productList?.map(({ name, productId, productCategoryL8 }, i) => (
                                        <Select.Option key={i} value={productId}>
                                            {`${name}  ${productCategoryL8 ? productCategoryL8.name : ''}`}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'SKU',
            dataIndex: 'Sku',
            align: "center",
            render: (_, record, index) => {
                return <div className='flex justify-center items-center gap-3 '>
                    <div>
                        <p style={{ fontSize: "12px" }}>{record?.Product?.internalReference}</p>
                    </div>

                </div>
            },
        },
        {
            title: 'Unit Of Measure',
            dataIndex: 'UOM',
            align: "center",
            render: (_, record, index) => {
                return <div className='flex justify-center items-center gap-3 '>
                    <div>
                        <p style={{ fontSize: "12px" }}> {record?.Product?.productCategoryL10?.name ? record?.Product?.productCategoryL10?.name : "N/A"}</p>
                    </div>


                </div>
            },
        },
        {
            title: 'Variant',
            dataIndex: 'Variant',
            align: "center",
            render: (_, record, index) => {
                return <div className='flex justify-center items-center gap-3 '>
                    <div>
                        <p style={{ fontSize: "12px" }}> {record?.Product?.productCategoryL8?.name ? record?.Product?.productCategoryL8?.name : "N/A"}</p>
                    </div>


                </div>
            },
        },
        {
            title: 'QTY',
            dataIndex: 'quantity',
            align: "center",
            render: (_, record, index) => {
                return (
                    <div className='flex justify-center items-center gap-3'>
                        <div>
                            {editMode ? (
                                <Button
                                    className="flex gap-2 items-center w-[150px]"
                                    style={{
                                        background: Colors.darkBlue,
                                        color: "white",
                                        borderRadius: "20px",
                                        fontSize: "10px",
                                        padding: "0px",
                                    }}
                                >
                                    <p
                                        onClick={() => HandleQuantity('Decrement', index)}
                                        className="h-full w-full flex items-center justify-center"
                                    >
                                        -
                                    </p>
                                    <Input
                                        type='Number'
                                        onChange={(event) => {
                                            HandleQuantity('Input', index, event.target.value);
                                        }}
                                        id="quantityInput"
                                        value={record?.quantity}
                                        style={{
                                            background: Colors.darkBlue,
                                            color: "white",
                                            textAlign: "center",
                                            border: "none",
                                        }}
                                    />
                                    <p
                                        onClick={() => HandleQuantity('Increment', index)}
                                        className="h-full w-full flex items-center justify-center"
                                    >
                                        +
                                    </p>
                                </Button>
                            ) : (
                                record?.quantity
                            )}
                        </div>
                    </div>
                );
            },
        },

        {
            title: 'UNIT RATE ',
            dataIndex: 'unitrate',
            key: 'unitrate',
            align: "center",

            render: (_, record, index) => {
                return <div className='flex justify-center items-center '>
                    <div className='flex justify-center items-center ' style={{ width: "100px" }}>
                        {
                            editMode ? <Input value={record.price} onChange={(event) => { HandleUnitRate(index, event.target.value) }} type='Number'
                                className='w-full' /> : Math.ceil(record?.price)
                        }
                    </div>
                </div>
            },

        },


        {
            title: 'GST  %',
            key: 'GST',
            align: "center",
            dataIndex: 'gst',
            render: (_, record, index) => {
                return <div className='flex justify-center items-center '>
                    <div>
                        {
                            editMode ? <Input type='Number' value={record?.GST || 0} onChange={(event) => { HandleGst(index, event.target.value) }} style={{ width: "100px" }} />
                                : (Math.ceil(record?.GST) || 0)
                        }
                    </div>
                </div>
            },
        },
        Status !== "OC" && {
            title: 'Include GST',
            dataIndex: 'includeGst',
            render: (_, record, index) => (
                <div className='flex justify-center items-center'>
                    <Switch
                        checked={record.includeGst}
                        onChange={(checked) => handleIncludeGst(index, checked)}
                    />
                </div>
            ),
        },


        // {
        //     title: 'Total',
        //     key: "Total",
        //     render: (_, record, index) => {
        //         return <p>{Math.ceil((record?.quantity * (record?.price ? record?.price : 0)) + ((record?.quantity * (record?.price ? record?.price : 1)) * ((record?.GST ? record?.GST : 0) / 100)))}</p>
        //     }

        // },
        {
            title: 'Total',
            key: 'totalPrice',
            render: (_, record, index) => (
                <p>
                    {calculateTotal(record.quantity, record.price, record.GST, record.includeGst)}
                </p>
            ),
        },


        ...(Status !== "OR" && Status !== 'OC') ? [{

            title: 'EDIT',
            key: 'edit',
            dataIndex: 'edit',
            render: (_, record, index) => {
                return <div className='flex justify-center items-center gap-3'>
                    {
                        editMode ? <div className='flex items-center gap-2'>

                            <Button onClick={() => { HandleMode('delete', index, record) }} className='p-0 flex items-center border-none shadow-none'>

                                <DeleteOutlined style={{ color: "red" }} />
                            </Button>
                            {/* <Button onClick={() => { HandleMode('submit', index, record) }} className='p-0 flex items-center border-none shadow-none'>

                                <CheckOutlined style={{ color: "green" }} />
                            </Button> */}

                        </div> : <Button onClick={() => {
                            HandleMode('edit', index, record);
                            setEditMode(true)
                        }} className='p-0 border-none  flex items-center justify-center ' >
                            <img src={Images.EditPen} style={{ color: Colors.darkBlue, height: "20px" }} />
                        </Button>

                    }

                </div>
            },
            align: "center",
        },] : [],

    ].filter(Boolean);

    // Hanlde UnitChange
    const HandleUnitRate = (index, value) => {
        let UnitRateData;
        UnitRateData = Data.filter((data, dataindex) => {
            if (dataindex == index) {
                if (value > 0) {
                    data.price = Number(value)
                    data.totalPrice = calculateTotal(data?.quantity, Number(value), data?.GST, data?.includeGst)
                } else {
                    data.price = ""
                }
            }
            return data
        })
        setData(UnitRateData)
    }
    // Hanlde GST
    // const HandleGst = (index, value) => {
    //     let GstData;
    //     GstData = Data.filter((data, dataindex) => { if (dataindex === index) { if (value > -1) { data.GST = Number(value) } else { data.GST = 1 } } return data })
    //     setData(GstData)
    // }
    // const HandleGst = (index, value) => {
    //     let GstData;
    //     GstData = Data.map((data, dataIndex) => {
    //         if (dataIndex === index) {
    //             return {
    //                 ...data,
    //                 GST: Number(value),
    //                 totalPrice: calculateTotal(data?.quantity, data?.price, Number(value), data?.includeGst)
    //             };

    //         }
    //         return data;
    //     });
    //     setData(GstData);
    // };
    const HandleGst = (index, value) => {
        // Parse the user input to ensure it's treated as a number
        const parsedValue = parseFloat(value);

        // Check if the input is a valid number, and if not, set it to 0
        if (isNaN(parsedValue)) {
            parsedValue = 0;
        }

        let GstData = Data.map((data, dataIndex) => {
            if (dataIndex === index) {
                return {
                    ...data,
                    GST: parsedValue,
                    totalPrice: calculateTotal(data?.quantity, data?.price, parsedValue, data?.includeGst)
                };
            }
            return data;
        });

        setData(GstData);
    };

    // Handle Edit Mode Functionality
    const HandleMode = async (mode, index, record) => {
        let NewData;
        if (mode === 'edit') {
            NewData = Data.filter((item, key) => { if (key === index) { item.Mode = true } return item })
        }
        else if (mode === 'submit') {
            const result = await updateOrderByOrderId(dispatch, token, record?.orderItemId, record)
            NewData = Data.filter((item, key) => { if (key === index) { item.Mode = false } return item })
        }
        else if (mode === 'delete') {
            // const result = await deleteOrderByOrderId(dispatch, token, record?.orderItemId, record)
            NewData = Data.filter((item, key) => { if (key === index) { item.Mode = false } return item })
            NewData = Data.filter((item, key) => { if (key !== index) { return item } })

        }
        setData(NewData)
    }

    const UpdateOrder = async () => {
        //to exclude the inCludeGst from payload
        const dataWithoutIncludeGst = Data.map(item => {
            // Create a copy of each item without the includeGst field
            const { includeGst, ...itemWithoutIncludeGst } = item;
            return itemWithoutIncludeGst;
        });
        // const response = await updateOrder(dispatch, token, id,data)
        console.log({ dataWithoutIncludeGst })
        const response = await updateOrderItem(dispatch, token, id, dataWithoutIncludeGst)
        if (response.success) {
            success('Order Successfully Updated!')
            setEditMode(false)
            getQuoteById(id)
        }
        else {
            message.error("fill the form correctly")
        }
    }


    useEffect(() => {
        getQuoteById(id)
    }, [pageLoader.deleteOrderByOrderId, callApiCheck])


    return (
        <Spin spinning={pageLoader?.getQuotesById || false}>
            <div className='w-full'>
                <Table
                    style={{ overflowX: "scroll" }}
                    columns={columns}
                    dataSource={Data}
                    pagination={false}
                    className=' admin-customer-quotes-table'
                />
                {Status === 'RFQ' && (<div className='flex justify-center items-center gap-3 mt-3'>
                    <Button onClick={addNewProduct} className="bg-[#061E3A] text-[white] w-[20%]">
                        Add a Product
                    </Button>
                </div>
                )}
            </div>

            {
                editMode &&
                <div className='flex justify-end py-3 px-2 gap-3'>
                    <Button onClick={() => { setEditMode(false) }} className='bg-[white] text-[#061E3A] border-none'>
                        Cancel
                    </Button>
                    <Button loading={pageLoader?.updateOrder || false} onClick={UpdateOrder} className='bg-[#061E3A] text-[white]'>
                        Submit
                    </Button>

                </div>
            }

        </Spin>
    );
};
export default Quotestable;














