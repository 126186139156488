import React, { useDebugValue, useEffect, useState } from "react";
import { Button, Col, Divider, Row, Spin } from "antd";
import Layout from "../../../Layout/Layout";
import Appbar from '../../../Components/Appbar/Appbar'
import CustomerTable from "../../../Components/Admin/Table/Customertable";
import ViewCustomerModal from "../../../Components/Modals/ViewcustomerModal";
import { getAllCustomerList } from "../../../API-Interaction/api-functions/customer.api";
import { useDispatch, useSelector } from "react-redux";
import FileDownloadComponent from "./Admin-Product-Download";
import FilterableData from "./Button/FilterButton";
import CreateCustomerModal from "../../../Components/Modals/CreateCustomerModal";
import { Colors } from "../../../Assets/Colors";


const Customerslist = () => {
    const dispatch = useDispatch()
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const [Data, setData] = useState([])
    const token = localStorage.getItem('authTokenHaasil')
    const [total, settotal] = useState(0)
    const [open, setOpen] = useState();

    const showModal = () => {
        setOpen(true)
    }
    const handleOk = () => {
        setOpen(true)
    }
    const onCancel = () => {
        console.log('false')

        setOpen(false)
    }
    const close = () => {
        setOpen(false)
    }

    const getAllUsers = async () => {
        let response = await getAllCustomerList(dispatch, token)
        if (response.success) {
            settleResponse(response)
        }
    }

    const settleResponse = (response) => {
        settotal(response?.response?.userCount)
        setData(response?.response?.users)
    }

    useEffect(() => {
        getAllUsers()
    }, [pageLoader.ChangeUserStatus, pageLoader.updateCustomer])

    return (
        <Layout>
            <Row className="">
                <Col md={17} sm={16} className='flex  flex-col justify-end gap-3 '>
                    <Appbar Heading={'Customer List'} />
                </Col>

                <Col md={4} sm={6}>

                    {/* <Button onClick={showModal} style={{ background: Colors.darkBlue, color: "white" }}>
                        Create Customer
                    </Button> */}
                    <Spin spinning={pageLoader?.MainLoading || false}>
                        <FilterableData passData={settleResponse} getData={getAllUsers} />
                    </Spin>
                </Col>
                {/* <Col md={1} sm={1}></Col> */}
                <Col md={2} sm={3} className='flex  gap-4 '>
                    <div className='flex gap-3 '>
                        
                        <Button onClick={showModal} style={{ background: Colors.darkBlue, color: "white" }}>
                            Create Customer
                        </Button>
                    </div>
                </Col>
            </Row>
            <CreateCustomerModal open={open} onCancel={onCancel} close={close} />
            <Spin spinning={pageLoader?.getAllUsers || pageLoader?.getUserById || false}>
                <Divider className="mt-1 w-[full]" />
                <div className="w-full bg-[#f4f6fc]" >
                    <CustomerTable Data={Data} callProducts={getAllUsers} total={total} />
                </div>
            </Spin>
        </Layout >
    )
}

export default Customerslist