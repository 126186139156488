import { configureStore } from '@reduxjs/toolkit'
import authReducer from "../features/authSlice"
import CartSlicer from "../features/cartSlice"
import utilReducer from '../features/utilsSlice'
// Redux Persistance --------------->
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'root',
  storage,
}

const persistedCartReducer = persistReducer(persistConfig, CartSlicer)

export const store = configureStore({
  reducer: {
    auth: authReducer,
    cart: persistReducer(persistConfig, CartSlicer),
    utils: utilReducer,
  },
})
// Persist Data Here
export const persistor = persistStore(store);

