// import { error, success } from "@/helpers/notifications";
import { error, success } from "../../helpers/notifications"
import { login, verification } from "../../Redux/features/authSlice";
import { setPageLoader } from "../../Redux/features/utilsSlice";
// import { pageRoutes } from "@/routes/page.routes";
// import { useRouter } from "next/router";
import { deleteRequest, getRequest, postRequest, putRequest } from "../apiMethods";
import { setToLocalStorage, encryptPass } from "../../helpers/helper"
// import { apiRoutes } from "@/routes/api.routes";
import { apiRoutes } from "../apiRoutes";
const { address } = apiRoutes;




const onCreateAddress = async (dispatch, body, accessToken) => {

    dispatch(setPageLoader({ page: 'createAddress', flag: true }))

    let response = await postRequest({
        endPoint: address.create,
        body,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'createAddress', flag: false }))
    if (response.success === true) {
        success('Address has been added')
        return (response)
    }
    else {
        error(response.response)
    }

}

const onGetAllAddresses = async (dispatch, accessToken) => {

    dispatch(setPageLoader({ page: 'rfq', flag: true }))

    let response = await getRequest({
        endPoint: `${address.getAll}?limit=100`,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'rfq', flag: false }))
    if (response.success === true) {
        // success(response.response.message)
        return (response)

        // navigate(pageRoutes.Authentication.login)
    }
    else {
        error(response.response)
    }

}

const GetAddressById = async (dispatch, accessToken, id) => {

    dispatch(setPageLoader({ page: 'getaddressbyid', flag: true }))

    let response = await getRequest({
        endPoint: `${address.getById}/${id}`,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'getaddressbyid', flag: false }))
    if (response.success === true) {
        // success(response.response.message)
        return (response)

        // navigate(pageRoutes.Authentication.login)
    }
    else {
        error(response.response)
    }

}


const onDeleteAddress = async (dispatch, accessToken, AddressID) => {
    dispatch(setPageLoader({ page: `deletePage`, flag: true }))
    let response = await deleteRequest(
        {
            endPoint: apiRoutes.address.deleteByID + `/${AddressID}`,
            loggedInToken: accessToken,
        }
    )
    dispatch(setPageLoader({ page: `deletePage`, flag: false }))

    if (response.success === true) {
        success('Address Deleted Successfully')
        return (response)

    }
    else {
        error(response.response)
    }


}

const onUpdateAddress = async (dispatch, body, accessToken, id) => {

    dispatch(setPageLoader({ page: 'updateAddress', flag: true }))

    let response = await putRequest({
        endPoint: address.updateAddress + `/${id}`,
        body,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'updateAddress', flag: false }))
    if (response.success === true) {
        return (response)
    }
    else {
        error(response.response)
    }

}



export { onCreateAddress, onGetAllAddresses, onDeleteAddress, GetAddressById, onUpdateAddress }