import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import Typography from "@mui/material/Typography";
import { Colors } from "../../Assets/Colors";
import { Images } from "../../Assets/Images/Images";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Image, Input, Row, Space, Spin } from "antd";
import { Typography } from "antd";
import SessionLayout from "../../Components/Sessionlayout.js/SessionLayout";
import { onSendVerificationCodeSubmit } from "../../API-Interaction/api-functions/login.api"
import DynamicButton from "../../Layout/dynamics/button.dynamic";

export default function SignInSide() {

  const { pageLoader } = useSelector(state => state.utils.loaders)
  const dispatch = useDispatch()
  let navigate = useNavigate();

  const HandleResetPassword = async (value) => {
    let body = {
      emailAddress: value.email
    }
    await onSendVerificationCodeSubmit(dispatch, body, navigate)
  };



  const HandleResetFailed = () => { };
  return (
    <SessionLayout>
      <Spin spinning={pageLoader?.emailVerification || false} >
        <Form
          name="Reset Password"
          className="w-full"
          onFinish={HandleResetPassword}
          onFinishFailed={HandleResetFailed}
        >
          <Row className="w-full flex justify-center  ">
            <Row className="w-full flex justify-center">
              <Col
                lg={18}
                xs={22}
                className="text-center text-[30px]"
              >
                <Typography.Text
                  className="large-font header-heading font-[900] "
                >
                  Reset Password
                </Typography.Text>
              </Col>
              <Col
                lg={12} xs={20}
                className="text-center mb-4 text-[18px]"
              >
                <Typography.Text
                  className="text-xs "
                  style={{ color: Colors.grayTxt }}
                >
                  Enter the email associated with your account and we'll send you code to reset your password.
                </Typography.Text>
              </Col>
            </Row>
            <Row className="w-full flex justify-center">
              <Col lg={12} xs={20}>
                <Typography.Title level={5} className="font-black">
                  Email
                </Typography.Title>
              </Col>
            </Row>
            <Col lg={12} xs={20} className="w-full mb-0">
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email is required!",
                  },
                ]}
              >
                <Input
                  type="email"
                  className="p-3 border-transparent "
                  placeholder="Enter email"
                  style={{ background: `${Colors.inputBg} ` }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="w-full flex justify-center">
            <Col lg={12} xs={20}>
              <DynamicButton className="w-full" type={2} text="Next" />
            </Col>
          </Row>
        </Form>
      </Spin>
    </SessionLayout>
  );
}
