import { appConfig } from "../config/config"
import CryptoJS from "crypto-js";
import moment from 'moment';
import { error, success } from "./notifications";

export const setToLocalStorage = (data, where) => {
    let validateData = data || []
    localStorage.setItem(where, JSON.stringify(validateData))
}

export const getFromLocalStorage = (where) => {
    return JSON.parse(localStorage.getItem(where))
}

export const encryptPass = (value) => {
    const secretPass = appConfig?.encryptionKey
    return CryptoJS.AES.encrypt(
        value,
        secretPass
    )?.toString();
}

export const debounce = (cb, delay = 1500) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            cb(...args)
        }, delay);
    }
}

export const debounceImage = (cb, delay = 500) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            cb(...args)
        }, delay);
    }
}

export const getRemainingTimeInHours = (startDate, endDate) => {

    const start = new Date();
    const end = new Date(endDate);
    const diffInMs = end - start;
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    if (diffInHours < 1) {
        return 'Quote Expired'
    }
    else {
        return diffInHours + "h left";
    }
}

export const getDateInProperFormat = (Date) => {
    return moment(Date).format('DD-MM-YYYY');

}


export const getDateInProperFormatInvoice = (Date) => {
    if (Date) {
        return moment(Date).format('DD-MM-YYYY');
    } else {
        return 'N/A';
    }
};


export const inputValidations = {
    required: [{ required: true, message: <span className="text-[12px]">Please fill this field!</span> }],
    password: [
        { min: 7, message: <span className="text-[12px]">-Password should have atleast 7 charecters</span> },
        { pattern: /^\S*$/, message: <span className="text-[12px]">-Password must not contain Whitespaces!</span> },
        { pattern: /^(?=.*[0-9]).*$/, message: <span className="text-[12px]">-Password must contain at least one Digit!</span> },
        { pattern: /^(?=.*[A-Z]).*$/, message: <span className="text-[12px]">-Password must have at least one Uppercase Character!</span> },
        { pattern: /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/, message: <span className="text-[12px]">-Password must contain at least one Special Symbol!</span> },
        { required: true, message: <span className="text-[14px]">Please fill this field!</span> }
    ]

}

export const checkbeforeUpload = (info) => {
    let { file } = info
    const fileListTypes = ["image/jpeg", "image/png", "image/Jpeg",
        "image/PNG", "image/JPEG", "image/Png", 'application/pdf',
        'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        '.xlsx', 'text/csv', '.docs', '.docx', '.doc',
        'text/plain', 'text/docs', 'text/docx',
        'text/word', 'text/xlsx', 'text/CSV'];
    const checkFile = fileListTypes.includes(file.type);
    if (!checkFile) {
        error('You can only upload JPG/PNG/Excel/Pdf/word file!')
        return false
    }
    if (file.size > 2000000) {
        error('File must be smaller than 2MB!')
        return false
    }
    return true
}


export const EncryptCartItemData = (data) => {
    // var token = sign({ data: [{ name: "usman", age: 19 }, { name: "usman", age: 19 }] }, 'CartItems')
}
EncryptCartItemData('Hello world')

export const routeHistoryMaintainer = (route = '') => {
    let prevHistory = JSON.parse(localStorage.getItem('routeHistory'))
    if (!prevHistory || route == '/') return
    prevHistory = JSON.parse(localStorage.getItem('routeHistory'))
    prevHistory.push(route)
    localStorage.setItem('routeHistory', JSON.stringify(prevHistory))
}

export const routeHistoryInitializer = () => {
    let prevHistory = localStorage.getItem('routeHistory')
    if (!prevHistory) {
        const history = []
        localStorage.setItem('routeHistory', JSON.stringify(history))
    }
}

export const navigateToHistory = (navigate, arr = []) => {

    if (arr?.length == 1)
        return navigate(arr[0])
    if (arr?.length > 1)
        return navigate(arr[arr?.length - 1])

}