import React from 'react'
import { Button, Col, Dropdown, Row } from 'antd'
import { DownOutlined } from '@ant-design/icons';

const items = [
    {
        key: '1',
        label: (
            <a> Option one</a>
        ),
    },
    {
        key: '2',
        label: (
            <a> Option Two</a>
        ),
    },
    {
        key: '3',
        label: (
            <a> Option Three</a>

        ),
    },
];
const QuotesHeader = () => {

    return (
        <Row className='flex justify-between gap-2'>
            <Col xs={24} md={10}>
                <p className=" font-semibold ml-[10px] header-heading">Quotations </p>
            </Col>

        </Row>
    )
}

export default QuotesHeader