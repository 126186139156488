export async function ErrorHandling(result) {

    const resultJSON = await result.json()

    if (!resultJSON) return { success: false, response: "No data available" }

    if (result.status === 403 && resultJSON.message !== 'invalid_credentials') return sendToLogin()

    if (result.status !== 200) return { success: false, response: resultJSON.message }

    if (result.status === 200) return { success: true, response: resultJSON }

}

const sendToLogin = () => {
    // localStorage.removeItem('authTokenHaasil')
    // localStorage.clear()
    // window.location.href = '/'
    // return {
    //     success: false,
    //     response: "Kindly login again"
    // }
}


export const errorFactory = (error) => {

    // For checking internet connection
    if (!window.navigator.onLine) return { success: false, response: "Request failed! Please make sure you have a stable internet connection!" }

    // Normal error message
    return { success: false, response: error.message }
}