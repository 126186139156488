import { Button, Checkbox, Col, Divider, Form, Image, Input, Row, Spin, Typography, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../Layout/Layout";
import Nestle from "../../../Assets/Nestlesvg.svg";

import productNestle from "../../../Assets/Nestlesvg.svg";
import { useSelector } from "react-redux";
import {
  onAddToCart,
  removeFromCart,
  onResetCart
} from "../../API-Interaction/api-functions/cart.api";
import {
  onCreateOrder
} from "../../API-Interaction/api-functions/order.api";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Colors } from "../../Assets/Colors";
import { Images } from "../../Assets/Images/Images";
import { deleteSelectedDocument, removeItemFromCart, updateOrder } from "../../Redux/features/cartSlice";
import { DeleteOutlined, LeftOutlined, LinkOutlined } from "@ant-design/icons";
import { error, info } from "../../helpers/notifications";
import DraggerButton from "../../Components/Upload/DraggerButton";
import ProductDropdown from "../../helpers/ProductDropdown";


function AddtoCart() {

  const [selectedProduct, setSelectedProduct] = useState();
  const { id } = useParams()
  const { Option } = Select;
  const [rows, setRows] = useState([]);
  const [productList, setProductList] = useState([])

  const [uploadItems, setUploadItems] = useState([])
  const dispatch = useDispatch();
  const cart = useSelector((state) => state?.cart?.cartItems);
  const notes = useSelector((state) => state?.cart?.order);
  const token = localStorage.getItem("authTokenHaasil")
  let UploadDocument = useSelector((state) => state?.cart?.uploadedDocuments);
  // console.log("🚀 ~ file: AddtoCart.js:33 ~ AddtoCart ~ UploadDocument:", UploadDocument)
  const { pageLoader } = useSelector(state => state.utils.loaders)
  const navigate = useNavigate();

  const handleClick = (type, product, value) => {
    let { quantity } = product;
    if (type === "increment")
      onAddToCart(product, quantity + 1, dispatch, cart);
    if (type === "decrement" && quantity > 1)
      onAddToCart(product, quantity - 1, dispatch, cart);
    if (type === "decrement" && quantity === 1)
      removeItemFromCart(dispatch, product?.productId);
    if (type === "UserInput") {
      // if (value == 0) {
      // } else {
      onAddToCart(product, Number(value), dispatch, cart);
      // }
    }
  };

  const handleProductSelect = (product) => {
    console.log(product);
    // Update the selectedProduct state in the AddtoCart component
    setSelectedProduct(prev => prev = product);

  };

  const onFinish = async (values) => {
    navigate("/request-for-quotation");
  };
  const removeItem = (item) => {
    removeFromCart(dispatch, item?.productId)
  };



  const addRow = () => {
    if (selectedProduct) {
      console.log(selectedProduct, "lkjkljkl");
      // Create a new row with the selected product details
      const newRow = {
        productId: selectedProduct?.productId,
        name: selectedProduct?.name,
        imageURLs: selectedProduct?.imageURLs,
        quantity: 1, // You may set a default quantity here
      };
      console.log(newRow);

      // Update the product list by adding the new row
      setProductList((prevProductList) => [...prevProductList, newRow]);

      // Add the new row to the cart
      onAddToCart(newRow, 1, dispatch, cart);

      // Reset selectedProduct to null
      setSelectedProduct(null);
    } else {
      // Add the emptyRow when no product is selected

    }
  };


  useEffect(() => {
    // Convert the cart object to an array of products
    const productsArray = Object.values(cart).map((product) => ({
      productId: product.productId,
      product: product.name,
      sku: product.sku,
      Variant: product.variant,
      quantity: product.quantity,
      unitrate: product.unitrate,
      gst: product.gst,
      Total: product.total,
    }));

    setProductList(productsArray);

  }, [cart]);


  console.log("Array of products selected")
  console.log(productList)
  console.log("The next product we select")
  console.log(selectedProduct)

  return (
    <Layout>
      <Spin spinning={pageLoader.AddtoCart || false}>
        <>
          <Form
            name="basic"
            initialValues={{
              remember: false,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row className="flex justify-between items-center">
              <h1 className=" font-semibold text-gray-400 header-heading">
                <span>My Cart </span>(
                <span>{Object.keys(cart)?.length} Items</span>)
              </h1>
              <div className="flex gap-2">

                <Button onClick={() => { navigate('/daily-essentials') }}
                  className={`bg-[white] w-[100%] text-[#0A789A] font-semibold text-[12px] rounded-md  text-center`}>
                  Continue Shopping
                </Button>
                <Button
                  htmlType="submit"
                  disabled={Object.keys(cart)?.length ? false : UploadDocument.length !== 0 ? false : true}
                  loading={pageLoader?.addtocart || false}
                  style={{
                    cursor: Object.keys(cart)?.length ? 'pointer' : UploadDocument.length !== 0 ? 'pointer' : 'not-allowed'
                  }}
                  className="bg-[#061E3A] w-[100%] text-white text-[12px] rounded-md  text-center "
                >
                  Proceed to RFQ
                </Button>
              </div>
            </Row>
            <Divider className="mt-[10px] mb-[10px]" />


            <Form.Item
              name="items"
              rules={[
                {
                  required: false,
                  message: "Please input your username!",
                },
              ]}
            >
              <p
                className={`mb-[5px] w-full px-[4px] py-[5px]  support-heading text-[#44444f] 
                rounded-[5px] font-bold`}
                style={{ background: Colors.grayBg }}
              >
                ITEMS & DESCRIPTION
              </p>
            </Form.Item>

            {Object.values(cart)?.map(
              (product) => (
                <Form.Item
                  name="items"
                  rules={[
                    {
                      required: false,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Row className="flex justify-between">
                    <Col lg={18} xs={16}>
                      <div className="flex items-center gap-2">
                        <div
                          className="flex items-center border
                          -1 py-1 rounded-md"
                        >
                          <img
                            src={product?.imageURLs ? product?.imageURLs[0] : Images.CameraUpload}
                            className="w-[40px] h-[40px] object-contain"
                            preview={false}
                          />

                        </div>
                        <p className="text-[13px] text-[#171725]" >
                          {product?.name}
                        </p>
                      </div>
                    </Col>
                    <Col lg={5} xs={8}>
                      <div className="flex items-center gap-2 ">
                        <Button
                          className={`w-full flex gap-2 items-center text-[white] rounded-[20px] text-[10px] p-[0] bg-[${Colors.darkBlue}]`}

                        >
                          <p
                            onClick={() =>
                              handleClick("decrement", product)
                            }
                            className="h-full w-full flex items-center justify-center  "
                          >
                            -
                          </p>
                          <Input
                            type="Number"
                            onChange={(event) => {
                              handleClick(
                                "UserInput",
                                product,
                                event.target.value
                              );
                            }}
                            productId="quantityInput"
                            className={`capitalize text-[white] border-none text-center bg-[${Colors.darkBlue}]`}
                            value={

                              product?.quantity
                            }
                          />
                          <p
                            onClick={() =>
                              handleClick("increment", product)
                            }
                            className="h-full w-full flex items-center
                        justify-center"
                          >
                            {" "}
                            +
                          </p>
                        </Button>
                        <DeleteOutlined onClick={() => { removeItem(product) }} className="text-[red] text-[16px] cursor-pointer" />

                      </div>
                    </Col>
                  </Row>
                </Form.Item>
              )
            )}

            {/* Quotes Table  */}

            <div className='flex items-center justify-between gap-3 pr-3'>

              <ProductDropdown onProductSelect={handleProductSelect} />

              <Button
                onClick={addRow}
                style={{
                  background: Colors.darkBlue,
                  color: "white",
                  margin: '10px',
                  width: '20%',
                }}
              >
                Add a product
              </Button>

            </div>

            {/* Document Upload or FIle Upload Flow  */}
            <div className="my-10">
              <DraggerButton setUploadItems={setUploadItems} />
              {
                UploadDocument.length !== 0 &&
                <div className="flex flex-col gap-2">
                  {UploadDocument.map((item, index) => {
                    return (
                      <div className="flex justify-between ">
                        <div className="flex items-center gap-2">
                          <LinkOutlined className="text-[#1677ff]" />
                          <a className="text-[#1677ff] cursor-pointer" href={item} >{item}</a>
                        </div>
                        <div>
                          <Button className="shadow-none border-none" onClick={() => { dispatch(deleteSelectedDocument(item)) }}>
                            <DeleteOutlined className="text-[red] " />
                          </Button>
                        </div>
                      </div>

                    )
                  })}
                </div>}

            </div>

            <Form.Item
              name={'note'}
            >
              <Input.TextArea
                defaultValue={notes?.notes ? notes?.notes : ''}
                onChange={(event) => {
                  dispatch(updateOrder({ data: event.target.value, key: 'notes' }))
                }}
                maxLength={3000}
                className="border-none bg-[#E9EFF6] text-[12px] py-[20px] px-[15px]"

                rows={2}
                placeholder="Add Your Note"
              />
            </Form.Item>
            <Form.Item>
              <Row className="flex justify-end">
                <Col span={3}>

                </Col>

              </Row>

            </Form.Item>

          </Form>
        </>
      </Spin>
    </Layout>
  );
};

export default AddtoCart;
