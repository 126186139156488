import React, { useEffect, useState } from "react";
import { Divider, Row, Spin } from "antd";
import Layout from "../../Layout/Layout";
import QuotesTable from "../../Components/Quotes/Clientquotestable";
import QuotesHeader from "../../Components/Quotes/quotesHeader";
import { useDispatch, useSelector } from "react-redux";
import { getAllApprovedQuotes, getAllUserQuotes } from "../../API-Interaction/api-functions/quotation.api";

const Quotes = () => {
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const [data, setData] = useState([])
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const getUserQuotes = async () => {
        const respone = await getAllUserQuotes(dispatch, token)
        if (respone.success) {
            setData(respone?.response?.order)
        }
    }
    useEffect(() => {
        getUserQuotes()
    }, [])

    return (
        <Layout>
            <Row className="">
                <QuotesHeader />
            </Row>
            <Divider className="mt-1 w-[full]" />
            <Spin spinning={pageLoader?.getAllUserQuotes || false}>
                <Row className="w-[full] ">

                    <QuotesTable data={data} />

                </Row>
            </Spin>
        </Layout>)
}

export default Quotes