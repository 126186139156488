import { Button, Col, Image, Modal, Row, Typography } from "antd";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../Assets/Images/Images";
import { Colors } from "../../../Assets/Colors";

const ApprovedQuoteModal = ({ open, close }) => {
    const navigate = useNavigate()
    return (
        <Modal centered open={open} onCancel={close} footer={null} >
            <Row className="flex justify-center">
                <Col span={12}>
                    <div className="flex flex-col items-center">
                        <Image
                            width={"100%"}
                            height="80px"
                            preview={false}
                            style={{ objectFit: "contain" }}
                            src={Images.ConfirmCheckout}
                        />
                        <Typography.Text
                            className="bold-heading text-center mt-5"
                            style={{ fontSize: "20px" }}
                        >
                            Quotation Sent
                        </Typography.Text>

                        <Typography.Text
                            className=" text-center mt-2"
                            style={{ fontSize: "12px" }}
                        >
                            The quotation will be expired after soon.
                        </Typography.Text>
                        <Button onClick={() => {
                            navigate('/admin/approved-quotations')
                        }} className="mt-2 w-[50%]" style={{ background: Colors.darkBlue, color: "white" }}>
                            Ok
                        </Button>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};

export default ApprovedQuoteModal;
