import { CarryOutOutlined, CommentOutlined, ShoppingCartOutlined, UsergroupAddOutlined, CheckSquareOutlined, CheckCircleOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { Images } from "../Assets/Images/Images";

export const SideNavigation = {
  // Customer Routes--------------->
  CustomerRoutes: [
    {
      name: "Dashboard",
      path: "/dashboard",
      SelectedImage: Images.DashboardWhiteIcon,
      NonSelectedImage: Images.Dashboardblueicon,
      isDisabled: false,
      // close: true

    },
    {
      name: "Daily Essentials",
      path: "/daily-essentials",
      SelectedImage: Images.DailyEssentialsWhite,
      NonSelectedImage: Images.DailyEssentialsBlue,
      isDisabled: false,
    },
    {
      name: "Customised Goods",
      path: "/customisegoods",
      SelectedImage: Images.CustomiseGoodsWhite,
      NonSelectedImage: Images.CustomisegoodsBlue,
      isDisabled: false,
    },
    {
      name: "Ad-Hoc Supplies",
      path: "/adhoc",
      SelectedImage: Images.AdhocWhite,
      NonSelectedImage: Images.AdhocBlue,
      isDisabled: false,
    },
    {
      name: "User",
      path: "/userCreated",
      SelectedImage: Images.userWhite,
      NonSelectedImage: Images.userBlue,
      isDisabled: false,
    },
    {
      name: "Invoices",
      path: "/invoice",
      SelectedImage: Images.InvoiceWhite,
      NonSelectedImage: Images.InvoicesBlue,
      isDisabled: false,
    },
    {
      name: "Order Status",
      path: "/quotes",
      SelectedImage: Images.QuotationsWhite,
      NonSelectedImage: Images.QuotationsBlue,
      isDisabled: false,
    },
    {
      name: "Analytics",
      path: "",
      SelectedImage: Images.QuotationsWhite,
      NonSelectedImage: Images.QuotationsBlue,
      isDisabled: true,
    },


  ],

  AdminRoutes: [
    {
      name: "Customers List",
      path: "/admin/customers-list",
      Avatar: <UsergroupAddOutlined style={{ fontSize: "23px" }} />,
      SelectedImage: Images.DashboardWhiteIcon,
      NonSelectedImage: Images.Dashboardblueicon,
      isDisabled: false,
    },
    {
      name: "Orders",
      // path: "",
      Avatar: <CommentOutlined style={{ fontSize: "23px" }} />,
      SelectedImage: Images.DashboardWhiteIcon,
      NonSelectedImage: Images.Dashboardblueicon,
      isDisabled: false,
      child: [{
        label: "Pending RFQs",
        key: "/admin/quotations",
        icon: <FieldTimeOutlined style={{ fontSize: "23px" }} />,

      },
      {
        label: "Quotations",
        key: "/admin/approved-quotations",
        icon: <CarryOutOutlined style={{ fontSize: "23px" }} />,

      }, {
        label: "Confirmed Orders",
        key: "/admin/orders",
        icon: <CheckSquareOutlined style={{ fontSize: "23px" }} />,

      }, {
        label: "Invoiced & FulFilled",
        key: "/admin/invoice",
        icon: <CheckCircleOutlined style={{ fontSize: "23px" }} />,

      }]
    },

    {
      name: "Products",
      path: "/admin/products",
      Avatar: <ShoppingCartOutlined style={{ fontSize: "23px" }} />,
      SelectedImage: Images.DashboardWhiteIcon,
      NonSelectedImage: Images.Dashboardblueicon,
      isDisabled: false,
    },
    {
      name: "Product Category",
      path: "/admin/productCategory",
      Avatar: <ShoppingCartOutlined style={{ fontSize: "23px" }} />,
      SelectedImage: Images.DashboardWhiteIcon,
      NonSelectedImage: Images.Dashboardblueicon,
      isDisabled: false,
    },

  ]

};
