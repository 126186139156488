import React, { useEffect, useState } from 'react'
import Layout from '../../../Layout/Layout'
import Appbar from '../../../Components/Appbar/Appbar'
import { Divider, Row, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import ApprovedQuotationsTable from '../../../Components/Admin/Table/Quotationstable'
import AdminInvoiceTable from '../../../Components/Admin/Table/Admin-Invoice-Table'
import { getAllInvoices } from '../../../API-Interaction/api-functions/invoice.api'

const AdminInvoices = ({ sideKey }) => {
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const [data, setData] = useState([])
    const [total, settotal] = useState()
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const GetInvoices = async (offset) => {
        const response = await getAllInvoices(dispatch, token, offset ? offset-1 : 0)
        if (response.success) {
            settotal(response?.response?.invoiceCount)
            setData(response?.response?.order)
        }
    }

    useEffect(() => {
        GetInvoices()
    }, [])
    return (
        <Layout sideKey={sideKey}>
            <Row className="">
                <Appbar Heading={"Invoices & FullFilled"} />
            </Row>
            <Divider className="mt-1 w-[full]" />
            <Spin spinning={pageLoader?.getAllInvoice || pageLoader?.getInvoiceById || false}>
                <div className="w-full text-[#f4f6fc]" >
                    <AdminInvoiceTable data={data} getInvoices={GetInvoices} total={total} />
                </div>
            </Spin>
        </Layout>
    )
}

export default AdminInvoices