import { message, notification } from "antd"

const success = (txt) => {
    message.destroy()
    const args = {
        type: 'success',
        content: txt,
        duration: 2,
    };
    message.success(args)
}

const error = (txt) => {
    message.destroy()
    const args = {
        type: 'Error',
        content: txt,
        duration: 2,
    };
    message.error(args)
}

const info = (txt) => {
    message.destroy()
    const args = {
        type: 'Information',
        content: txt,
        duration: 2,
    };
    message.info(args)
}

export {
    success,
    error,
    info
}