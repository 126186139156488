// import { error, success } from "@/helpers/notifications";
import { error, success } from "../../helpers/notifications"
import { login, verification } from "../../Redux/features/authSlice";
import { setPageLoader } from "../../Redux/features/utilsSlice";
// import { pageRoutes } from "@/routes/page.routes";
// import { useRouter } from "next/router";
import { getRequest, postRequest, putRequest } from "../apiMethods";
import { setToLocalStorage, encryptPass } from "../../helpers/helper"
// import { apiRoutes } from "@/routes/api.routes";
import { apiRoutes } from "../apiRoutes";
const { profile } = apiRoutes;




const onFetchProfile = async (dispatch, accessToken) => {

    dispatch(setPageLoader({ page: 'profile', flag: true }))

    let response = await getRequest({
        endPoint: profile.get,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'profile', flag: false }))
    if (response.success === true) {
        return (response)
        // navigate(pageRoutes.Authentication.login)
    }
    else {
        error(response?.response)
    }

}

const onProfileSubmit = async (dispatch, body, accessToken) => {

    dispatch(setPageLoader({ page: 'profile', flag: true }))

    let response = await putRequest({
        endPoint: profile.update,
        body,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'profile', flag: false }))
    if (response.success === true) {
        success(response?.response?.message)
        return (response)
    }
    else {
        error(response?.response)
    }

}





export { onFetchProfile, onProfileSubmit }