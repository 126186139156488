import React, { useEffect, useState } from "react";
import { Divider, Row, Spin } from "antd";
import Layout from "../../../Layout/Layout";
import Appbar from '../../../Components/Appbar/Appbar'
import QuotationsTable from "../../../Components/Admin/Table/Quotationstable";
import { getAllApprovedQuotes, getAllQuotesAdmin, getAllUserQuotes } from "../../../API-Interaction/api-functions/quotation.api";
import { useDispatch, useSelector } from "react-redux";
import ApprovedQuotationsTable from "../../../Components/Admin/Table/Quotationstable";
import ApprovedQuotes from "../../../Components/Admin/Table/ApprovedquotesTable";


const ApprovedQuotation = ({ sideKey }) => {
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const [data, setData] = useState([])
    const [total, settotal] = useState()
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')


    const getApprovedQuotations = async (offset) => {
        const respone = await getAllApprovedQuotes(dispatch, token, offset ? offset - 1 : 0)
        if (respone.success) {
            settotal(respone?.response?.orderCount)
            setData(respone?.response?.order)
        }
    }
    useEffect(() => {
        getApprovedQuotations()
    }, [])

    return (
        <Layout sideKey={sideKey}>
            <Row className="">
                <Appbar Heading={"Quotations"} />
            </Row>
            <Divider className="mt-1 w-[full]" />
            <Spin spinning={pageLoader?.getAllAprovedQuotes || false}>
                <div className='bg-[#f4f6fc] w-full' >
                    <ApprovedQuotes data={data} total={total} getApprovedQuotes={getApprovedQuotations} />
                </div>
            </Spin>
        </Layout>
    )
}

export default ApprovedQuotation