import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import Typography from "antd/es/typography/Typography";
import { Button, Col, Divider, Form, Input, Row, Select, Space, Spin } from "antd";
import { Colors } from "../../Assets/Colors";
import AddressModal from "../../Components/Modals/Addressmodal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  onGetAllAddresses
} from "../../API-Interaction/api-functions/address.api";
import { formErrors } from "../../../constant";
import { updateOrder } from "../../Redux/features/cartSlice";
import { error } from "../../helpers/notifications";
import { CaretLeftOutlined, PlusOutlined } from "@ant-design/icons";
import zIndex from "@mui/material/styles/zIndex";
import FormItem from "antd/es/form/FormItem";
import Checkout from "./Checkout";

const RFQ = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state?.cart);
  const UploadDocument = useSelector((state) => state?.cart?.uploadedDocuments);


  // UseStates
  const [Modal, setModal] = useState(false);
  const { pageLoader } = useSelector(state => state.utils.loaders)
  const token = localStorage.getItem("authTokenHaasil")
  const [addressData, setAddressData] = useState({});
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false)
  const [userAddress, setUserAddress] = useState({})



  // Handle Address Change
  const handleChange = (value) => {
    const selectedAddress = addressData[value];
    setUserAddress(addressData[value])
    dispatch(updateOrder({ data: addressData[value], key: 'address' }))

    form.setFieldsValue({
      country: selectedAddress?.country,
      // CHange this
      title: selectedAddress?.addressTitle,
      // -------------------->
      address: selectedAddress?.address,
      fullname: selectedAddress?.fullname,
      number: selectedAddress?.number,
      city: selectedAddress?.city,
      zipcode: selectedAddress?.zipcode,
      state: selectedAddress?.state,

    });
  };
  //Handle modal
  const HandleModal = () => {
    setModal(!Modal);
  };

  // Handle Form Submits
  const HandleForm = (value) => {
    localStorage.setItem('userAddress', userAddress.addressId)
    navigate("/checkout");
  };

  const FetchAddressData = async () => {
    let response = await onGetAllAddresses(dispatch, token);
    setAddressData(response.response.addresses); // Set the profile data
  };

  const checkCartAddress = () => {

    let addressDetail = cart.order?.address
    if (addressDetail) {
      form.setFieldsValue({
        country: addressDetail?.country,
        // CHange this
        title: addressDetail?.addressTitle,
        // -------------------->
        address: addressDetail?.address,
        fullname: addressDetail?.fullname,
        number: addressDetail?.number,
        city: addressDetail?.city,
        zipcode: addressDetail?.zipcode,
        state: addressDetail?.state,

      });
      console.log(addressDetail)
    }
  }
  useEffect(() => {
    window.scroll(0, 0);
    if (Object.keys(cart.cartItems).length === 0 & UploadDocument.length === 0) {
      error('Oops! your cart is empty')
      navigate('/daily-essentials')
    } else {
      FetchAddressData()
      checkCartAddress()
    }
  }, [pageLoader?.createAddress]);

  return (
    <Layout>
      <Spin spinning={pageLoader?.rfq || false}>
        <Form name="shippingform" onFinish={HandleForm} form={form}>
          <Row>
            <Col lg={17} xs={24} >
              <div className="w-full flex justify-between items-center">

                <Typography.Text
                  className="large-font header-heading"
                >
                  Enter Shipping Details{" "}
                </Typography.Text>
                <Button onClick={() => { navigate('/add-to-cart') }} className={` flex items-center bg-[white]  text-[#0A789A] font-semibold text-[12px] rounded-md  text-center`}>
                  <CaretLeftOutlined />
                  Go Back
                </Button>
              </div>

              <Divider className="my-[1px] mx-[0px]" />
              {/* Form Section */}
              <div>
                {/* Address Section */}
                <Typography.Text className="bold-heading">
                  Select your address
                </Typography.Text>

                {/* <div>
                  <Select
                    size="middle"
                    placeholder="Select Your Addresss"
                    className="w-[100%] mt-[13px]"
                    style={{
                      zIndex: '11 !important'
                    }}
                    value={addressData?.addressTitle}
                    onChange={handleChange}
                    onClick={() => { setOpen(!open) }}
                    open={open}
                    options={Object.values(addressData).map((address, index) => ({
                      value: index,
                      label: address?.addressTitle,
                    }))}

                    dropdownRender={(menu) => (
                      <div className="w-full flex flex-col ">
                        {menu}
                        <Divider
                          style={{
                            margin: '8px 0',
                          }}
                        />
                        <div
                          className="w-full  flex justify-center "

                        >

                          <Button type="text" className="flex items-center gap-2 w-full" onClick={() => {
                            // setOpen(true);
                            HandleModal();
                          }}>
                            <PlusOutlined /> Add New Address
                          </Button>
                        </div>
                      </div>
                    )}
                  />

                </div> */}
                <div>
                  <Form.Item
                    name="title"
                    // label="Address Tittle"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a option and change input text above"
                      onChange={handleChange}
                      allowClear
                      options={Object.values(addressData).map((address, index) => ({
                        value: index,
                        label: address?.addressTitle,
                      }))}
                    >

                    </Select>
                  </Form.Item>
                </div>



                <p className="bold-heading  mt-[13px]">
                  Address
                </p>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: formErrors.emptyField

                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Enter address.."
                    rows={4}
                    disabled={true}
                    size="large"
                    className="mt-4 text-[12px] text-[black]"
                  />
                </Form.Item>
                <p className="bold-heading mt-[13px] "
                >
                  {"Full Name (First and Last name)"}
                </p>
                <Form.Item
                  name={"fullname"}
                  rules={[{
                    required: "true",
                    message: formErrors.emptyField

                  }]}
                >
                  <Input
                    placeholder="Enter name..."
                    disabled={true}
                    className={'text-[12px] text-[black] mt-4'}
                    size="large"
                  />
                </Form.Item>
                <p className="bold-heading mt-[13px]">
                  Phone Number
                </p>
                <Form.Item
                  name={"number"}
                  rules={[{
                    required: true,
                    message: formErrors.emptyField

                  }]}
                >
                  <Input
                    placeholder="Enter Number..."
                    max={11}
                    disabled={true}

                    size="large"
                    className=" mt-4 text-[12px] text-[black]"
                  />
                </Form.Item>
              </div>
              <div className="flex justify-between gap-3">
                <div>
                  <p className="bold-heading mt-[13px]">
                    City
                  </p>
                  <Form.Item
                    name="city"
                    rules={[{
                      required: true,
                      message: formErrors.emptyField

                    }]}
                  >
                    <Input
                      placeholder="Enter City..."
                      disabled={true}
                      size="large"
                      className="mt-4 text-[12px] text-[black]"
                    />
                  </Form.Item>
                </div>
                <div>
                  <p className="bold-heading mt-[13px] " >
                    State
                  </p>
                  <Form.Item
                    name={"state"}
                    rules={[{
                      // required: true,
                      // message: formErrors.emptyField
                    }]}>
                    <Select
                      placeholder='Select State...'
                      disabled={true}
                      className="mt-4 w-[200px] text-[12px] text-[black] "
                      style={{ width: 200 }}
                      defaultValue="State"
                      onChange={handleChange}
                      options={[
                        { value: "Sindh", label: "Sindh" },
                        { value: "Punjab", label: "Punjab" },
                        { value: "Balochistan", label: "Balochistan" },
                        { value: "NWFP", label: "NWFP", disabled: true },
                      ]}
                    />
                  </Form.Item>
                </div>
                <div>
                  <p className="bold-heading mt-[13px]">
                    ZIP Code
                  </p>
                  <Form.Item
                    name={"zipcode"}
                    rules={[{
                      // required: true,
                      // message: formErrors.emptyField

                    }]}
                  >
                    <Input
                      placeholder='Enter Zipcode...'
                      disabled={true}
                      size="large"
                      className="mt-4 text-[12px] text-[black]"
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
          {/* Add Dress modal */}
          <AddressModal open={addressData?.length == 0 ? true : Modal} close={HandleModal} fetchAddress={FetchAddressData} style={{ zIndex: '999999999999999' }} />
        </Form>
        <Checkout />
      </Spin>

    </Layout>
  );
};
export default RFQ;
