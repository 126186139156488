import React from 'react'
import { Button, Col, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons';

const items = [
    {
        key: '1',
        label: (
            <a> Option one</a>
        ),
    },
    {
        key: '2',
        label: (
            <a> Option Two</a>
        ),
    },
    {
        key: '3',
        label: (
            <a> Option Three</a>

        ),
    },
];
const InvoicesHeader = () => {

    return (
        <>
            <Col md={20}>
                <p className=" font-semibold ml-[10px] header-heading">Invoices </p>
            </Col>
            <Col md={3}>

            </Col>

        </>
    )
}

export default InvoicesHeader
