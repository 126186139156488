import { Button, Col, Form, Input, Row, Typography, Spin, InputNumber } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from "../../Assets/Colors";
import FormItem from "antd/es/form/FormItem";
import { useNavigate } from "react-router-dom";
import { onSignupSubmit } from "../../API-Interaction/api-functions/login.api";
import { error, success } from "../../helpers/notifications";
import SessionLayout from "../../Components/Sessionlayout.js/SessionLayout";
import DynamicButton from "../../Layout/dynamics/button.dynamic";
import { formErrors } from "../../../constant";
import { inputValidations } from "../../helpers/helper";

function Signup() {

  const dispatch = useDispatch()
  let navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisibleConfirm, setPasswordVisibleConfirm] = useState(false);

  const { pageLoader } = useSelector(state => state.utils.loaders)

  const HandleSignup = async (values) => {
    window.scroll(0, 0)
    if (values?.confirmPassword !== values?.password) return error("Password did not match")
    await onSignupSubmit(dispatch, values, navigate)
  };
  return (
    <SessionLayout pageName="signup">
      <div className="px-5 py-2">
        <Spin spinning={pageLoader?.signup || false}>
          <Form name="Signup" onFinish={HandleSignup}
            autoComplete="false"
          >
            <Row>
              <Col span={24} className="flex justify-start mb-5">
                <Typography.Text className="large-font text-center ">
                  Sign Up
                </Typography.Text>
              </Col>
              <div
                className={`w-full flex flex-col gap-2 rounded-md`}
              >
                <Row>
                  <Typography.Text
                    className="text-base mb-3 font-[900] text-[18px] flex-nowrap "
                    style={{
                      textShadow: "0.5px 0 #000000",
                    }}
                  >
                    Personal Information
                  </Typography.Text>
                </Row>
                <div className="w-full flex justify-between gap-7">
                  <div className="w-full">
                    <Typography.Text
                      className="font-[700] pb-[10px] mb-3"

                    >
                      First Name
                    </Typography.Text>
                    <Form.Item
                      name={"firstname"}
                      rules={[
                        {
                          required: true,
                          message: formErrors?.emptyField,
                        },
                      ]}
                    >
                      <Input className="p-2" placeholder="John" />
                    </Form.Item>
                  </div>
                  <div className="w-full">
                    <Typography.Text
                      className="font-[700] pb-[10px] "
                    >
                      Last Name
                    </Typography.Text>
                    <FormItem
                      name={"lastname"}
                      rules={[
                        {
                          required: true,
                          message: formErrors?.emptyField,
                        },
                      ]}
                    >
                      <Input className="p-2" placeholder="Trott" />
                    </FormItem>
                  </div>
                </div>
                <div className="w-full flex justify-between gap-7">
                  <div className="w-full ">
                    <Typography.Text
                      className="font-[700] pb-[10px] mb-3"
                    >
                      Phone Number
                    </Typography.Text>

                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          pattern: /^[0-9]+$/,
                          message: 'Only numeric values are allowed!',
                        },
                        {
                          required: true,
                          message: 'Please enter a phone number',
                        },
                        {
                          max: 13,
                          message: 'Phone number should not exceed 13 digits.',
                        },
                        {
                          min:11,
                          message: 'Phone number should minimum 11 digits.',
                        },
                      ]}
                    >
                      <Input  minLength={11} maxLength={13} className="p-2" placeholder="03342344523" />
                    </Form.Item>

                  </div>
                  <div className="w-full">
                    <Typography.Text
                      className="font-[700] pb-[10px] "
                    >
                      Email
                    </Typography.Text>
                    <Form.Item
                      name={"emailAddress"}
                      autoComplete="off"
                      rules={[
                        {
                          required: true,
                          message: formErrors?.emptyField,
                        },
                        {
                          pattern: new RegExp(/^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/),
                          message: 'Please enter a valid EmailAddress'
                        }
                      ]}
                    >
                      <Input type="email" className="p-2" placeholder="john@example.com" />
                    </Form.Item>
                  </div>
                </div>
                <div className="w-full flex justify-between gap-7">
                  <div className="w-full ">
                    <Typography.Text
                      className="font-[700] pb-[10px] mb-3"
                    >
                      Password
                    </Typography.Text>
                    <Form.Item
                    autoComplete='false'
                    name={'password'}
                      // name="password"
                      rules={inputValidations.password}

                    >
                      <Input.Password
                        className="p-3 border-transparent w-[100%]"
                        placeholder="Enter password"
                        style={{ background: `${Colors.inputBg} ` }}
                        visibilityToggle={{
                          visible: passwordVisible,
                          onVisibleChange: setPasswordVisible,
                        }}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  
                  </div>
                  <div className="w-full">
                    <Typography.Text
                      className="font-[700] pb-[10px] "
                    >
                      Confirm Password
                    </Typography.Text>
                    <Form.Item
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: formErrors?.emptyField,
                        },
                        {
                          min: 7,
                          message: "Password length should be atleast 7 charecters",
                        },
                      ]}
                    >
                      <Input.Password
                        className="p-3 border-transparent w-[100%]"
                        placeholder="Re-enter password"
                        style={{ background: `${Colors.inputBg} `, }}
                        visibilityToggle={{
                          visible: passwordVisibleConfirm,
                          onVisibleChange: setPasswordVisibleConfirm,
                        }}
                        autoComplete="false"
                      />
                    </Form.Item>
                  </div>
                </div>

                {/* Companies Information */}
                <div className="flex flex-col gap-2 mt-7">
                  <Typography.Text
                    className="text-base mb-7 font-[900] text-[18px] flex-nowrap  "
                    style={{
                      textShadow: "0.5px 0 #000000",
                    }}
                  >
                    Company Information
                  </Typography.Text>

                  <div className="w-full ">
                    <Typography.Text
                      className="font-[700] pb-[10px] mb-3"

                    >
                      Company Name
                    </Typography.Text>
                    <FormItem
                      name={"companyname"}
                      rules={[
                        {
                          required: true,
                          message: formErrors?.emptyField,
                        },
                      ]}
                    >
                      <Input className="p-2" placeholder="Enter your company name" />
                    </FormItem>
                  </div>
                  <div className="w-full">
                    <Typography.Text
                      className="font-[700] pb-[10px] "


                    >
                      Address
                    </Typography.Text>
                    <FormItem
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: formErrors?.emptyField,
                        },
                      ]}
                    >
                      <Input className="p-2" placeholder="Enter company address" />
                    </FormItem>
                  </div>
                  <div className="w-full">
                    <Typography.Text
                      className="font-[700] pb-[10px] "

                    >
                      City
                    </Typography.Text>
                    <Form.Item
                      name={"city"}
                      rules={[
                        {
                          required: true,
                          message: formErrors?.emptyField,
                        },
                      ]}
                    >
                      <Input className="p-2" placeholder="Enter city" />
                    </Form.Item>
                  </div>
                  <div className="flex gap-7 justify-end">
                    <div className="w-full">
                      <Typography.Text
                        className="font-[700] pb-[10px] "

                      >
                        NTN(Optional)
                      </Typography.Text>
                      <Form.Item
                        name="ntn"
                        rules={[
                          {
                            required: false,
                            message: formErrors?.emptyField,
                          },
                        ]}
                      >
                        <Input type="Number" className="p-2" placeholder="Enter National Tax Number" />
                      </Form.Item>
                    </div>
                    <div className="w-full">
                      <Typography.Text
                        className="font-[700] pb-[10px]"
                      >
                        STN(Optional)
                      </Typography.Text>
                      <Form.Item
                        name="stn"
                        rules={[
                          {
                            required: false,
                            message: formErrors?.emptyField,
                          },
                        ]}
                      >
                        <Input type="Number" className="p-2" placeholder="Enter STN" />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="flex items-start justify-end">
                  <DynamicButton className="w-[30%]" type={2} text="Create Account" />
                </div>
              </div>
            </Row>
          </Form>
        </Spin>
      </div>
    </SessionLayout>
  );
}

export default Signup;
