import { message } from "antd";
import { error, success } from "../../helpers/notifications"
import { setPageLoader } from "../../Redux/features/utilsSlice";
import { getRequest, postRequest, putRequest } from "../apiMethods";
import { apiRoutes } from "../apiRoutes";
const { order } = apiRoutes;

export const onCreateOrder = async (dispatch, body, accessToken) => {

    dispatch(setPageLoader({ page: 'createOrder', flag: true }))
    let response = await postRequest({
        endPoint: order.create,
        body,
        otherToken: accessToken
    })
    dispatch(setPageLoader({ page: 'createOrder', flag: false }))
    if (response.success === true) {
        return (response)
    }
    else {
        error(response?.response)
    }

}
export const UpdateStatusOrder = async (dispatch, token, orderId, data) => {
    dispatch(setPageLoader({ page: 'onUpdateCustomerOrderByOrderId', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Order.updateStatus + `/${orderId}`, loggedInToken: token, body: data })
    dispatch(setPageLoader({ page: 'onUpdateCustomerOrderByOrderId', flag: false }))
    if (response.success) {
        return response
    }
    else {
    }

}

export const onUpdateCustomerOrderByOrderId = async (dispatch, token, id, data) => {
    dispatch(setPageLoader({ page: 'onUpdateCustomerOrderByOrderId', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Order.updateCustomerOrderByOrderId + `/${id}`, loggedInToken: token, body: data })
    dispatch(setPageLoader({ page: 'onUpdateCustomerOrderByOrderId', flag: false }))
    if (response.success) {
        return response
    }
    else {
    }

}
export const SubmitOrder = async (dispatch, token, id, data) => {
    dispatch(setPageLoader({ page: 'SubmitOrder', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Order.submitOrder + `/${id}`, loggedInToken: token, body: data })
    dispatch(setPageLoader({ page: 'SubmitOrder', flag: false }))
    if (response.success) {
        success('Your Order Has Been Created!')
        return response
    }
    else {
    }

}

export const getAllOrders = async (dispatch, token, offset, status) => {
    dispatch(setPageLoader({ page: 'getAllOrders', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.Order.getAllOrders + `?offset=${offset}&limit=20${status ? `&status=${status}` : ''}`, loggedInToken: token, })
    dispatch(setPageLoader({ page: 'getAllOrders', flag: false }))
    if (response.success) {
        return response
    }
    else {
    }

}

export const onOrderDelivered = async (dispatch, token, id) => {
    dispatch(setPageLoader({ page: 'orderDelivered', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Order.updateOrder + `/${id}`, loggedInToken: token })
    dispatch(setPageLoader({ page: 'orderDelivered', flag: false }))
    if (response.success) {
        success(response?.response.message)
        return response
    }


}



export const updateOrder = async (dispatch, token, id, data) => {
    console.log({ data })
    dispatch(setPageLoader({ page: 'updateOrder', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Order.updateCompleteOrder + `/${id}`, loggedInToken: token, body: { OrderItems: data } })
    dispatch(setPageLoader({ page: 'updateOrder', flag: false }))
    console.log({ response })
    if (response.success) {
        success('Your Order Has Been Created!')
        return response
    }
    else {
        message.error("Please fill the fields")
    }

}

export const updateOrderRfq = async (dispatch, token, id, data) => {
    console.log({ data })
    data = data?.map((item) => ({ ...item, orderId: +id, productId: +item?.Product?.productId }))

    dispatch(setPageLoader({ page: 'updateOrder', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Order.updateCompleteOrderRfq + `/${id}`, loggedInToken: token, body: { OrderItems: data } })
    dispatch(setPageLoader({ page: 'updateOrder', flag: false }))
    console.log({ response })
    if (response.success) {
        success('Your Order Has Been Created!')
        return response
    }
    else {
        message.error("Please fill the fields")
    }

}
export const updateOrderItem = async (dispatch, token, id, data) => {
    console.log({ data })
    data = data?.map((item) => ({ ...item, orderId: +id, productId: +item?.Product?.productId }))

    dispatch(setPageLoader({ page: 'updateOrder', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Order.NewupadteCompleteRfqOrder + `/${id}`, loggedInToken: token, body: { OrderItems: data } })
    dispatch(setPageLoader({ page: 'updateOrder', flag: false }))
    console.log({ response })
    if (response.success) {
        success('Your Order Has Been Created!')
        return response
    }
    else {
        message.error("Please fill the fields")
    }

}



export const onUpdateAllCustomerOrder = async (dispatch, token, id, data) => {
    dispatch(setPageLoader({ page: 'onUpdateAllCustomerOrder', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Order.updateCompleteOrderCust + `/${id}`, loggedInToken: token, body: { OrderItems: data } })
    dispatch(setPageLoader({ page: 'onUpdateAllCustomerOrder', flag: false }))
    if (response.success) {
        return response
    }
    else {
    }

}



export const deleteBulkOrderItem = async (dispatch, token, id, data) => {
    dispatch(setPageLoader({ page: 'deleteBulkOrderItem', flag: true }))
    let response = await postRequest({ endPoint: apiRoutes.Order.bulkOrderItemDelete + `/${id}`, loggedInToken: token, body: { rejectedItems: data } })
    dispatch(setPageLoader({ page: 'deleteBulkOrderItem', flag: false }))
    if (response.success) {
        return response
    }
    else {
    }

}