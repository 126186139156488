import { CheckOutlined, DeleteOutlined, EyeOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Image, Radio, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Colors } from '../../../Assets/Colors';
import { Images } from '../../../Assets/Images/Images';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from '../../Pagination/PaginationComponent';
import { getDateInProperFormat, getDateInProperFormatInvoice } from '../../../helpers/helper';




const AdminOrdersTable = ({ data, confirmOrderApi, total }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [count, setCount] = useState(total)
    const [Search, setSearch] = useState('')
    const navigate = useNavigate()



    useEffect(() => {
        setCount(total)
    }, [total])
    
    const columns = [
        {
            title: 'Order No#',
            dataIndex: 'orderId',
            align: "center",
            render: (item, Data, index) => {
                // console.log(`${Data?.User?.firstname +" " + Data?.User?.LastName}`);
                return <Button onClick={() => { navigate(`/admin/quotation/${Data.orderId}`) }} className='font-[800] '>
                    O-00{item}
                </Button>

            }
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
            render: (item, Data, index) => {
                return <p class="whitespace-normal" >{Data.User?.emailAddress}</p>
            }
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            render: (item, Data, index) => {
                return <p class="whitespace-normal">{Data?.User?.Company?.name}</p>
            }
        },
        {
            title: 'POC Name',
            dataIndex: 'companyName',
            render: (item, Data, index) => {
                return <p class="whitespace-normal">{Data?.User?.firstname}</p>
            }
        },
        // {
        //     title: 'Delivery Date',
        //     dataIndex: 'companyName',
        //     render: (item, Data, index) => {
        //         const Date = getDateInProperFormat(Data?.deliveryDate )
        //         return <p class="whitespace-normal">{Date || 'N/A'}</p>
        //     }
        // },
        {
            title: 'Delivery Date',
            dataIndex: 'deliveryDate',  // Change dataIndex to 'deliveryDate'
            render: (deliveryDate, Data, index) => {
                const formattedDate = getDateInProperFormatInvoice(deliveryDate);
                return <p class="whitespace-normal">{formattedDate || 'N/A'}</p>;
            }
        },
        

        {
            title: 'Phone N.o',
            dataIndex: 'phone',
            render: (item, Data, index) => {
                return <p class="whitespace-normal">{Data.User?.phoneNumber}</p>
            }
        },
        {
            title: 'Price',
            dataIndex: 'price',
            render: (item, Data, index) => {
                // return <p>{Data?.orderPrice ? ` ${Data?.orderPrice} Rs` : 'N/A'}</p>
                return <p class="whitespace-normal">{Data?.orderPrice ? `Rs. ${Data?.orderPrice}` : 'N/A'}</p>

            }
        },
      
        {
            title: 'Actions',
            key: 'address',
            dataIndex: 'address',
            render: (_, record) => {
                return <div className='flex justify-center items-center gap-3'>
                    <Button onClick={() => { navigate(`/admin/quotation/${record.orderId}`) }} className='p-0 border-none  flex items-center justify-center ' >
                        <EyeOutlined className={`text-[20px] text-[${Colors.darkBlue}] shadow-none`} />
                    </Button>

                </div>
            },
            align: "center",
        },
    ];


    return (
        <div className='w-full'>
            <Table
                pagination={false}
                columns={columns}
                dataSource={data}
                className='admin-customer-table overflow-x-scroll'
                scroll={{y:360}}
            />
            <Row className="flex justify-end">
                <PaginationComponent search={Search} currentPage={currentPage} itemsPerPage={itemsPerPage} totalItems={count} setCurrentPage={setCurrentPage} callProducts={confirmOrderApi} />
            </Row>
        </div>
    );
};
export default AdminOrdersTable;



