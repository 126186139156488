import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from "../../Assets/Colors";
import { useNavigate } from "react-router-dom";
import { Col, Form, Input, Row, Spin } from "antd";
import { Typography } from "antd";
import SessionLayout from "../../Components/Sessionlayout.js/SessionLayout";
import { onLoginSubmit } from "../../API-Interaction/api-functions/login.api";
import { encryptPass, routeHistoryInitializer } from "../../helpers/helper"
import { formErrors } from "../../../constant";
import DynamicButton from "../../Layout/dynamics/button.dynamic";
import { pageRoutes } from "../../Routes/pageRoutes";
import { useEffect } from "react";

export default function SignInSide() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { pageLoader } = useSelector(state => state.utils.loaders)
  const dispatch = useDispatch()
  let navigate = useNavigate();

  const onFinishFailed = (errorInfo) => {
  };
  const onFinish = async (values) => {
    const hashedPassword = encryptPass(values?.password)
    let body = {
      emailAddress: values.email,
      password: hashedPassword
    }
    await onLoginSubmit(dispatch, body, navigate)

  };

  useEffect(() => {
    routeHistoryInitializer()
  }, [])

  return (
    <SessionLayout>
      <Spin spinning={pageLoader?.login || false}>
        <Form
          name="login"
          className="w-full"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row className="w-full flex justify-center ">
            <Row className="w-full flex justify-center">
              <Col lg={12} xs={20}>
                <Typography.Title level={5}>Login</Typography.Title>
              </Col>
            </Row>
            <Col lg={12} xs={20} className="w-full">
              <Form.Item
                name="email"
                autoComplete="off"
                rules={[
                  {
                    required: true,
                    message: formErrors?.emptyField,
                  },
                  {
                    pattern: new RegExp(/^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/),
                    message: 'Please enter a valid EmailAddress'
                  }
                ]}
              >
                <Input type="email" className="p-2" placeholder="john@example.com" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="w-full flex justify-center  ">
            <Row className="w-full flex justify-center">
              <Col lg={12} xs={20}>
                <Typography.Title level={5} className="font-black">
                  Password
                </Typography.Title>
              </Col>
            </Row>
            <Col lg={12} xs={20} className="w-full">
              <Form.Item
                className="w-full"
                name={"password"}
                rules={[
                  {
                    required: true,
                    message: formErrors?.emptyField,
                  },
                ]}
              >
                <Input.Password
                  className="p-3 border-transparent w-[100%]"
                  placeholder="Enter password"
                  style={{ background: `${Colors.inputBg} ` }}
                  visibilityToggle={{
                    visible: passwordVisible,
                    onVisibleChange: setPasswordVisible,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="w-full flex justify-center ">
            <Col lg={12} xs={20} className="text-end mb-3">
              <Typography.Text
                onClick={() => {
                  navigate(pageRoutes?.public?.forgetPassword);
                }}
                className="text-xs cursor-pointer"
                style={{ color: Colors.grayTxt }}
              >
                Forgot Password?
              </Typography.Text>
            </Col>
          </Row>
          <Row className="w-full flex justify-center">
            <Col lg={12} xs={20}>
              <DynamicButton className="w-full" text="Log in" type={2} />
            </Col>
            <Row className="w-full flex justify-center">
              <Col lg={12} xs={20} className="text-center mt-3">
                <Typography.Text
                  className="text-xs"

                  style={{ color: Colors.grayTxt }}
                >
                  Don't have an account?{" "}
                  <span
                    onClick={() => {
                      navigate("/signup");
                    }}
                    className="cursor-pointer font-bold"
                    style={{ color: Colors.blueLink }}
                  >
                    Sign Up
                  </span>
                </Typography.Text>
              </Col>
            </Row>
          </Row>
        </Form>
      </Spin>
    </SessionLayout>
  );
}
