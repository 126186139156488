import { Pagination } from 'antd'
import React, { useEffect } from 'react'
import { Colors } from '../../Assets/Colors'

const PaginationComponent = ({ search, currentPage, itemsPerPage, totalItems, setCurrentPage, callProducts }) => {
const onChange = (page) => {
    setCurrentPage(page);
    callProducts(page)
  };

  return (
    <Pagination
      className='flex justify-center items-center'
      size='small'
      current={currentPage}
      style={{ color: Colors.darkBlue }}
      pageSize={itemsPerPage}
      total={totalItems}
      onChange={onChange}
      showSizeChanger={false}
    />)
}

export default PaginationComponent