import React, { useState } from 'react';

function TestingPage() {
    const [inputValue, setInputValue] = useState('Hello world');

    const handleChange = (event) => {
        // setInputValue(event.target.value);
    };

    return (
        <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            className='bg-[lightgray]'
        />
    );
}

export default TestingPage