import { getMessaging, onMessage } from "firebase/messaging";
import RouteHandler from "./Routes/RouteHandler";
import { pushNotification } from "./Firebase/FirebaseNotification";
import app from "./Firebase/Firebase";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
    const messaging = getMessaging(app)
    onMessage(messaging, (payload) => {
        try {
            pushNotification(payload)
            console.log(payload)
            return payload
        }
        catch (exc) {
            console.log(exc)
        }
    })
    return <Router><RouteHandler /></Router>
}

export default App;
