import { error, success } from "../../helpers/notifications"
import { setPageLoader } from "../../Redux/features/utilsSlice";
import { getRequest, postRequest, putRequest } from "../apiMethods";
import { apiRoutes } from "../apiRoutes";

export const getAllQuotesAdmin = async (dispatch, accessToken, offSet) => {
    dispatch(setPageLoader({ page: 'getAllQuotes', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.Quotation.getAllQuotation + `?offset=${offSet}&limit=${20}`, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getAllQuotes', flag: false }))
    if (response.success) {
        return response
    } else {
    }

}


export const getQuotationById = async (dispatch, id, accessToken, offSet) => {
    dispatch(setPageLoader({ page: 'getQuotesById', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.Quotation.getQuoteById + `/${id}`, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getQuotesById', flag: false }))
    if (response.success) {
        return response
    } else {
    }

}
export const getProductById = async (dispatch, id, accessToken, offSet) => {
    dispatch(setPageLoader({ page: 'getQuotesById', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.products.get, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getQuotesById', flag: false }))
    if (response.success) {
        response.visibility = true;

        return response
    } else {
    }

}
export const AdminQuotationforCustomer = async (dispatch, token, id, data) => {

    dispatch(setPageLoader({ page: 'changeOrderStatus', flag: true }))
    let response = await postRequest({ endPoint: apiRoutes.Quotation.CreateOrderAdmin, loggedInToken: token, body: data })
    dispatch(setPageLoader({ page: 'changeOrderStatus', flag: false }))
    if (response.success) {
        success(response?.response?.message)
        return response
    }
    else {
        error(response?.response)
    }

}


export const ChangeQuotationStatusAdmin = async (dispatch, token, id, data) => {

    dispatch(setPageLoader({ page: 'changeOrderStatus', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Quotation.changeOrderStatus + `/${id}`, loggedInToken: token, body: data })
    dispatch(setPageLoader({ page: 'changeOrderStatus', flag: false }))
    if (response.success) {
        success(response?.response?.message)
        return response
    }
    else {
        error(response?.response)
    }

}
export const rejectClientQuotation = async (dispatch, token, id) => {

    dispatch(setPageLoader({ page: 'RejectQuotation', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Quotation.rejectClientQuotation + `/${id}`, loggedInToken: token, })
    dispatch(setPageLoader({ page: 'RejectQuotation', flag: false }))
    if (response.success) {
        success(response?.response?.message)
        return response
    }
    else {
        error(response?.response)
    }

}
export const getAllUserQuotes = async (dispatch, accessToken, offSet) => {
    dispatch(setPageLoader({ page: 'getAllUserQuotes', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.Quotation.getUserQuotation, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getAllUserQuotes', flag: false }))
    if (response.success) {
        return response
    } else {
    }

}

export const getAllApprovedQuotes = async (dispatch, accessToken, offSet,) => {
    dispatch(setPageLoader({ page: 'getAllAprovedQuotes', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.Quotation.getApprovedQuotes + `?offset=${offSet}&limit=100`, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getAllAprovedQuotes', flag: false }))
    if (response.success) {
        return response
    } else {
    }

}



// Order Apis-------------
export const updateOrderByOrderId = async (dispatch, token, id, data) => {

    dispatch(setPageLoader({ page: 'updateOrderByOrderId', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Order.updateOrderByOrderId + `/${id}`, loggedInToken: token, body: data })
    dispatch(setPageLoader({ page: 'updateOrderByOrderId', flag: false }))
    if (response.success) {
        return response
    }
    else {
    }

}


export const updatePendingRfqOrderByOrderId = async (dispatch, token, id, data) => {

    console.log({ data })
    dispatch(setPageLoader({ page: 'updateOrderByOrderId', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Order.updatePendingRfqOrderByOrderId + `/${id}`, loggedInToken: token, body: data })
    dispatch(setPageLoader({ page: 'updateOrderByOrderId', flag: false }))
    if (response.success) {
        return response
    }
    else {
    }

}

export const deleteOrderByOrderId = async (dispatch, token, id, data) => {

    dispatch(setPageLoader({ page: 'deleteOrderByOrderId', flag: true }))
    let response = await postRequest({ endPoint: apiRoutes.Order.deleteOrderByOrderId + `/${id}`, loggedInToken: token, body: data })
    dispatch(setPageLoader({ page: 'deleteOrderByOrderId', flag: false }))
    if (response.success) {
        return response
    }
    else {
    }

}

// export const getStatusData = async (dispatch, id, accessToken, orderId) => {
//     // console.log('it working fine')

//     dispatch(setPageLoader({ page: 'Quotes', flag: true }))
//     console.log('it working fine')
//     let response = await getRequest({ endPoint: apiRoutes.status.getStatus + `/${orderId}` , loggedInToken: accessToken })
//     dispatch(setPageLoader({ page: 'Quotes', flag: false }))
//     if (response.success) {
//         response.visibility = true;

//         return response
//     } else {
//     }

// }

