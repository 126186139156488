import React from 'react'

const DynamicButton = ({ type, text, style, className }) => {
    const buttons = {
        1: <SimpleButton text={text} style={style} className={className} />,
        2: <SubmitButton text={text} style={style} className={className} />
    }
    let Button = () => buttons[type]
    return <Button />
}

export default DynamicButton

const SimpleButton = ({ text, className }) => {
    return <button className={`w-full text-white text-sm font-ligh py-3 shadow-2xl shadow-indigo-500/40 rounded-lg bg-[#061E3A] ${className}`}
    >
        {text}
    </button>
}
const SubmitButton = ({ text, className }) => {
    return <button
        type="submit"
        className={`text-white text-sm font-ligh py-3 shadow-2xl shadow-indigo-500/40 rounded-lg bg-[#061E3A] ${className}`}
    >
        {text}
    </button>
}