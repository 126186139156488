import React, { useEffect, useState } from 'react'
import { Space, Table, Tag, Button, Input } from 'antd';
import { Colors } from '../../Assets/Colors';
import { onAddToCart, removeFromCart } from '../../API-Interaction/api-functions/cart.api';
import { useDispatch, useSelector } from 'react-redux';
import { removeItemFromCart } from '../../Redux/features/cartSlice';
import { DeleteOutlined, ShoppingCartOutlined } from '@ant-design/icons';



const ItemAndDescription = ({ recurringProducts }) => {
    const [data, setData] = useState([])
    const dispatch = useDispatch()
    const cart = useSelector((state) => state?.cart?.cartItems);

    useEffect(() => {
        setData(recurringProducts)
    }, [recurringProducts])



    const handleClick = (type, product, value) => {
        let quantity = cart[product?.productId]?.quantity;
        console.log(quantity)
        if (type === "increment")
            onAddToCart(product, quantity + 1, dispatch, cart);
        if (type === "decrement" && quantity > 1)
            onAddToCart(product, quantity - 1, dispatch, cart);
        if (type === "decrement" && quantity === 1)
            removeItemFromCart(dispatch, product?.productId);
        if (type === "UserInput") {
            // if (value == 0) {
            // } else {
            onAddToCart(product, Number(value), dispatch, cart);
            // }
        }
    };

    const handleAddToCart = async (product) => {
        onAddToCart(product, 1, dispatch, cart);

    };


    const removeItem = (product) => {
        removeFromCart(dispatch, product.productId)

    }


    const columns = [
        {
            title: <p style={{whiteSpace:'wrap'}}>Item & Description</p>,
            dataIndex: '1',
            key: '1',
            render: (item, data, index) => {
                return (
                    <p style={{whiteSpace:'wrap'}}>{data?.Product?.name}</p>
                )
            }
        },
        {
            title: 'Variant',
            dataIndex: '1',
            key: '1',
            align: "center",
            render: (item, data, index) => {
                return (
                    <p style={{whiteSpace:'wrap'}}>-</p>
                )
            }
        },
        {
            title: 'Brand',
            dataIndex: '1',
            key: '1',
            align: "center",
            render: (item, data, index) => {
                return (
                    <p style={{whiteSpace:'wrap'}}>-</p>
                )
            }
        },


        {
            title: 'Qty',
            dataIndex: 'age',
            key: 'age',
            align: "center",
            alignItems: "center",
            render: (item, data, index) => {
                return (

                    cart[data?.productId]?.quantity > 0 ? (
                        <div className="flex items-center justify-center  gap-2 w-[110px]" style={{
                            // boxShadow: " rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
                        }}>
                            <button
                                className="w-full flex  items-center"
                                style={{
                                    background: 'white',
                                    // border: "2px solid gray",
                                    color: "gray",
                                    padding: "3px",
                                    borderRadius: "7px",
                                    fontSize: "10px",
                                    boxShadow: " rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"


                                }}
                            >
                                <p
                                    onClick={() => handleClick("decrement", data)}
                                    className="h-full w-[100%] flex items-center justify-center font-black text-[16px]"

                                >
                                    -
                                </p>
                                <input
                                    type="Number"
                                    className='text-[14px]'

                                    style={{
                                        // border: "2px solid gray",
                                        color: "gray",
                                        textAlign: "center",
                                        width: "30px",
                                        fontWeight: '800'
                                    }}
                                    onChange={(event) => {
                                        handleClick("UserInput", data, event.target.value);
                                    }}
                                    id="quantityInput"
                                    value={
                                        cart[data?.productId]?.quantity
                                    }
                                />
                                <p
                                    onClick={() => handleClick("increment", data)}
                                    className="h-full w-[100%] flex items-center justify-center font-black text-[16px] "
                                >
                                    +
                                </p>
                            </button>
                            {/* Delete Icon */}
                            <DeleteOutlined onClick={() => { removeItem(data?.Product) }} className="text-[red] text-[16px] cursor-pointer" />
                        </div>
                    ) : (
                        <div className='w-full flex justify-center'>
                            <button
                                className="w-fit flex justify-center  items-center"
                                style={{
                                    background: 'white',
                                    // border: "2px solid gray",
                                    color: "gray",
                                    padding: "3px",
                                    borderRadius: "7px",
                                    fontSize: "10px",
                                    boxShadow: " rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"


                                }}
                            >
                                {/* <p
                                // onClick={() => handleClick("decrement", data)}
                                className="h-full w-[100%] flex items-center justify-center font-black text-[16px]"

                            >
                                -
                            </p> */}
                            
                                <input
                                    type="Number"
                                    className='text-[14px]'

                                    style={{
                                        // border: "2px solid gray",
                                        color: "gray",
                                        textAlign: "center",
                                        width: "30px",
                                        fontWeight: '800'
                                    }}
                                    // onChange={(event) => {
                                    //     handleClick("UserInput", data, event.target.value);
                                    // }}
                                    id="quantityInput"
                                    value={
                                        Math.floor(data?.ProductCount)
                                    }
                                />
                                {/* <p
                                // onClick={() => handleClick("increment", data)}
                                className="h-full w-[100%] flex items-center justify-center font-black text-[16px] "
                            >
                                +
                            </p> */}
                            </button>
                        </div>

                    )
                )
            }
        },
        // {
        //     title: 'Rate',
        //     dataIndex: '3',
        //     key: '3',
        //     render: (item, data, index) => {
        //         return (
        //             <p>{data?.price ? data?.price : 'N-A'}</p>

        //         )
        //     }
        // },
        // {
        //     title: 'Amount',
        //     key: 'tags',
        //     dataIndex: 'tags',
        //     align: "end",
        //     render: (_, data) => (
        //         <p>{data?.price ? data?.price : 'N-A'}</p>
        //     ),
        // },


    ];




    return (
        <div className='item-Description  h-full  '>
            <Table pagination={false} columns={columns} dataSource={data} className=' w-full h-[300px] overflow-y-scroll TableMain'  scroll={{y: 240,}} />
        </div>

    )
}

export default ItemAndDescription