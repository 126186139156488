import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { Colors } from '../../../../Assets/Colors';

const UpdateForm = ({ visible, onCreate, onCancel, data }) => {
  const [form] = Form.useForm();
  // const { password, ...formInitialValues } = data;

  return (
    <Modal
      visible={visible}
      title="Update User"
      okButtonProps={{ style: { background: Colors.darkBlue, color: "white" } }}
      okText="Update"
      cancelButtonProps={{ style: { background: Colors.darkBlue, color: "white" } }}
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((errorInfo) => {
            console.log('Validation failed:', errorInfo);
          });
      }}
    >
      <Form form={form} layout="vertical" initialValues={data}>
  
        <Form.Item
          name="userId"
          label="userId"
          hidden // Hide the userId field
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="firstname"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Please enter the user's First Name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastname"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Please enter the user's Last Name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          rules={[
            {
              required: true,
              message: "Please enter the user's Address!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="emailAddress"
          label="Email Address"
          rules={[
            {
              required: true,
              message: "Please enter the user's Email Address!",
            },
            {
              pattern: new RegExp(/^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/),
              message: 'Please enter a valid EmailAddress'
            }
          ]}
        >
          <Input disabled />
        </Form.Item>
      
        {/* <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please enter the user's password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: "Please confirm the user's password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Passwords do not match');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item> */}

        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please enter the user's Phone Number!",
            },
          ]}
        >
          <Input type='number' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateForm;
