import React from "react";
import { Colors } from "../../Assets/Colors";
import { Divider } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";

function Statusboxes({ Name, Number, Image, color, info }) {
  return (
    <div className="pl-2 h-full mt-[10px] "
      style={{

      }} >
      <div
        className={`flex flex-col gap-1 p-4 bg-[white] h-full rounded-[13px] `}
        style={{
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
          borderBottom: `5px solid ${color}`,
          boxShadow: " rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
        }}

      >

        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-3" >
            <span className={`rounded-[50%]  h-[10px] w-[10px] font-bold shadow-2xl`} style={{
              background: color,
              boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"
            }}></span>
            <p className="font-black text-[20px]">
              {Name}
            </p>

          </div>
          <Divider className="my-[1px]" />
          <div className="flex p-1 items-center justify-evenly"
            style={{ border: '2px solid #ecf1f9', borderStyle: "dashed", borderRadius: "10px" }}>
            <div className="bg-[#f1f5fd] p-2 rounded-lg">
              <img
                src={Image}
                style={{ height: "40px", width: "40px", objectFit: "contain" }}
              />
            </div>
            <div className="flex-1 justify-center flex flex-col items-center" style={{ flex: 1 }}>
              <p className="font-black text-[30px]"> {Number}</p>
              <p className="text-[#97aac5] font-bold text-[14px]">{info}</p>
            </div>

          </div>
          {/* <div className="flex gap-2 justify-center">
            <div>
              <p className="text-[#97aac5] flex gap-2">Total <SwapRightOutlined /></p>
              <Divider className="my-[2px]" />
              <p className="font-black">520</p>
            </div>
            <div>
              <div>
                <p className="text-[#97aac5]">Total</p>
                <Divider className="my-[2px]" />
                <p className="font-black">63</p>
              </div>
            </div>
          </div> */}
        </div>


      </div>
    </div >
  );
}

export default Statusboxes;
