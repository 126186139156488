import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from "../../Assets/Colors";
import { Images } from "../../Assets/Images/Images";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Image, Input, Row, Spin } from "antd";
import { Typography } from "antd";
import SessionLayout from "../../Components/Sessionlayout.js/SessionLayout";
import { onResetPasswordSubmit } from "../../API-Interaction/api-functions/login.api"
import { encryptPass, inputValidations } from "../../helpers/helper"
import DynamicButton from "../../Layout/dynamics/button.dynamic";
import { pageRoutes } from "../../Routes/pageRoutes";
import { error, success } from "../../helpers/notifications";
import { formErrors } from "../../../constant";

export default function NewPassword() {

  const dispatch = useDispatch()
  localStorage.removeItem('OTP')
  const { pageLoader } = useSelector(state => state.utils.loaders)
  const { token } = useSelector(state => state.auth.accessTokens)
  const [PasswordCheck, setpasswordCheck] = useState(true)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [passwordVisibleConfirm, setPasswordVisibleConfirm] = useState(false)



  let navigate = useNavigate();

  const onFinishFailed = (errorInfo) => {
  };
  const onFinish = async (values) => {
    if (values.password === values.confirmpassword) {
      const hashedPassword = encryptPass(values?.password)
      const hashedPasswordConfirm = encryptPass(values?.confirmpassword)
      let body = {
        password: hashedPassword,
        confirmPassword: hashedPasswordConfirm
      }
      await onResetPasswordSubmit(dispatch, body, navigate, token)
      setpasswordCheck(false)
      setTimeout(() => {
        navigate(pageRoutes?.public?.login)
      }, 3000);

    } else
      error("Passwords did not match")
  };

  return (
    <SessionLayout>
      <Spin spinning={pageLoader?.resetPassword || false} >
        {PasswordCheck ? (
          <Form
            name="abc"
            className="w-full"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row className="w-full flex justify-center ">
              <Row className="w-full flex justify-center mb-5">
                <Col
                  lg={12} xs={20}
                  className="text-center text-[30px]"
                >
                  <Typography.Text
                    className="header-heading text-[30px]  flex-nowrap font-black"
                    style={{
                      textShadow: "  1.5px 0 #000000",
                    }}
                  >
                    Set New Password
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="w-full flex justify-center">
                <Col lg={12} xs={20}>
                  <Typography.Text
                    className="font-black">New Password</Typography.Text>
                </Col>
              </Row>
              <Col lg={12} xs={20} className="w-full">
                <Form.Item
                  name="password"
                  rules={inputValidations.password}

                >
                  <Input.Password
                    className="p-3 border-transparent w-[100%]"
                    placeholder="Enter password"
                    style={{ background: `${Colors.inputBg} `, }}
                    visibilityToggle={{
                      visible: passwordVisible,
                      onVisibleChange: setPasswordVisible,
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="w-full flex justify-center  mb-5">
              <Row className="w-full flex justify-center">
                <Col lg={12} xs={20}>
                  <Typography.Text className="font-black">Confirm Password</Typography.Text>
                </Col>
              </Row>
              <Col lg={12} xs={20} className="w-full">
                <Form.Item
                  name={"confirmpassword"}
                  rules={[
                    {
                      required: true,
                      message: formErrors?.emptyField,
                    },
                    {
                      min: 7,
                      message: "Password length should be atleast 7 charecters",
                    },
                  ]}
                >
                  <Input.Password
                    className="p-3 border-transparent w-[100%]"
                    placeholder="Re-enter password"
                    style={{ background: `${Colors.inputBg} `, }}
                    visibilityToggle={{
                      visible: passwordVisibleConfirm,
                      onVisibleChange: setPasswordVisibleConfirm,
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="w-full flex justify-center">
              <Col lg={12} xs={20}>
                <DynamicButton className="w-full" type={2} text="Set Password" />
              </Col>
            </Row>
          </Form>
        ) : (
          <div className="flex flex-col items-center justify-center gap-2">
            <Typography.Text
              className="text-[34px]"
              style={{ textShadow: "2px 0 #000000" }}
            >
              Password Updated
            </Typography.Text>
            <Typography.Text className={`text-[${Colors?.grayTxt}] text-xs mb-5`}>
              Your password has been updated
            </Typography.Text>
            <Image
              preview={false}
              src={Images.UpdatePassBg}
            />
          </div>
        )}
      </Spin>
    </SessionLayout>
  );
}
