import { configureStore, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { error } from "../../helpers/notifications";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const initialState = {
  cartItems: {},
  order: {},
  uploadedDocuments: []
};
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    ADD_TO_CART: (state, { payload }) => {
      let { product, quantity } = payload;
      state.cartItems[product?.productId] = { ...product, quantity };
    },
    removeItemFromCart: (state, { payload }) => {
      let { productId } = payload;
      delete state.cartItems[productId];
    },
    resetCart: (state) => {
      state.cartItems = {};
      state.order = {}
      state.uploadedDocuments = []
    },
    updateOrder: (state, { payload }) => {
      let { data, key } = payload;
      state.order[key] = data
    },
    setCartFromApiData: (state, { payload }) => {
      state.cartItems = payload
    },
    setUploadDocument: (state, { payload }) => {
      const check = JSON.parse(JSON.stringify(state.uploadedDocuments));
      console.log(check)
      state.uploadedDocuments.push(payload)
    },
    deleteSelectedDocument: (state, { payload }) => {
      const check = JSON.parse(JSON.stringify(state.uploadedDocuments));
      console.log(check)
      const newItems = state.uploadedDocuments.filter((item, index) => item !== payload)
      state.uploadedDocuments = newItems
      console.log("🚀 ~ file: cartSlice.js:42 ~ newItems:", newItems)
      // state.uploadedDocuments.splice(index, 1)
    },
    addProductRecomendation: (state, { payload }) => {
      let stateItem = JSON.parse(JSON.stringify(state))
      let { product, quantity, Existing_Pid } = payload;
      let qty = stateItem?.cartItems[Existing_Pid]?.quantity
      delete state.cartItems[Existing_Pid];
      state.cartItems[product?.productId] = { ...product, quantity: qty ? qty : quantity };

    }

  }
});
export const { ADD_TO_CART, removeItemFromCart, resetCart, updateOrder, setCartFromApiData, setUploadDocument, deleteSelectedDocument, addProductRecomendation } = cartSlice.actions;
export default cartSlice.reducer;
