import React, { useState, useEffect } from 'react';
import { Button, Table, Input, message, Select, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { updateProductCategory, updateProductCategoryLevel } from '../../../API-Interaction/api-functions/product.api'; // Import your API function here
import { useDispatch, useSelector } from 'react-redux';
import ProductCategory from '../../../Views/Dashboard/Admin/Admin-Product-Category';

const ProductCategoryTable = ({ Data, callProducts, total, reload }) => {
    const [editingKey, setEditingKey] = useState('');
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [updatedData, setUpdatedData] = useState({ name: '', parentCategory: {} });
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const { pageLoader } = useSelector(state => state?.utils?.loaders)


    const isEditing = (record) => record.categoryId === editingKey;

    const edit = async (form) => {
        let response = await updateProductCategoryLevel(dispatch, token, form.categoryId);
        setEditingKey(form?.categoryId);
        setUpdatedData({ ...updatedData, parentCategory: form?.parentCategoryId })
        let options = response?.response?.productCategories?.map((option) =>
        ({
            value: option?.categoryId,
            label: option?.name
        }))
        setCategoryOptions(options)
    };




    const cancel = () => {
        setEditingKey('');
    };

    const save = async (form, record) => {

        try {
            await updateProductCategory(dispatch, token, updatedData, form?.categoryId, form); // 1 is the dataId
            cancel()
            await callProducts()
        } catch (error) {
            console.error(error.message);
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Input
                        defaultValue={text}
                        onChange={(e) => setUpdatedData({ ...updatedData, name: e.target.value })}
                        onPressEnter={(e) => save(record)}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: 'Level',
            dataIndex: 'level',
        },
        {
            title: 'Code',
            dataIndex: 'code',
        },

        // {
        //     title: 'Parent Category',
        //     dataIndex: 'parentCategoryId',
        // },
        {
            title: 'Parent Category',
            dataIndex: 'parentCategoryId',
            render: (text, record) => {
                const editable = isEditing(record);

                if (record?.level === 'L1') { // Assuming 'category' holds the category type
                    return record?.parentCategory?.level;
                }
                return editable ?
                    <Select
                        // value={record.parentCategory}
                        options={categoryOptions}
                        onChange={(value) => setUpdatedData({ ...updatedData, parentCategory: value })}
                        onPressEnter={() => save(record)}
                        value={updatedData?.parentCategory}
                        style={{ width: '200px', height: '40px' }}

                    />
                    :
                    record?.parentCategory?.name
            },
        },

        {
            title: 'Actions',
            render: (_, record) => {
                const editable = isEditing(record);
                return (
                    <div className='flex justify-center items-center gap-3'>
                        {editable ? (
                            <>
                                <Button onClick={() => save(record)} type='link'>
                                    Save
                                </Button>
                                <Button onClick={cancel} type='link'>
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={() => edit(record)} type='link'>
                                    <EditOutlined />
                                </Button>
                            </>
                        )}
                    </div>
                );
            },
            align: 'center',
        },
    ];

    return (

        <div className='w-full'>
            <Spin spinning={pageLoader?.getAllUsers || false}>

                <Table
                    pagination={true}
                    columns={columns}
                    dataSource={Data}
                    className='admin-customer-table overflow-x-scroll'
                    scroll={{y:360}}
                />
                </Spin>
        </div>
    );
};

export default ProductCategoryTable;