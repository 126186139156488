import { error, success } from "../../helpers/notifications"
import { login, verification } from "../../Redux/features/authSlice";
import { setPageLoader } from "../../Redux/features/utilsSlice";
import { postRequest, putRequest } from "../apiMethods";
import { navigateToHistory, setToLocalStorage } from "../../helpers/helper"
import { apiRoutes } from "../apiRoutes";
import { pageRoutes } from "../../Routes/pageRoutes";
import { setCartFromApiData } from "../../Redux/features/cartSlice";

const { auth } = apiRoutes;


const onLoginSubmit = async (dispatch, body, navigate) => {
    dispatch(setPageLoader({ page: 'login', flag: true }))
    let response = await postRequest({
        endPoint: auth.login,
        body
    })
    dispatch(setPageLoader({ page: 'login', flag: false }))
    if (response.success === true) {

        let userDetails = {
            username: body.username,
            token: response?.response?.accessToken
        }
        dispatch(login(userDetails))
        localStorage.setItem('authTokenHaasil', response?.response?.accessToken)
        if (response?.response?.user?.profilePicURL) {
            localStorage.setItem('HaasilUserImage', response?.response?.user?.profilePicURL)
        }
        if (response?.response?.user?.Company) {
            localStorage.setItem('CompanyDetails', response?.response?.user?.Company.name)
        }
        let CartItems = response?.response?.tokenItem?.items ? response?.response?.tokenItem?.items : false
        if (CartItems) {
            dispatch(setCartFromApiData(CartItems))
        }


        localStorage.setItem('Role', response?.response?.user?.role)
        localStorage.setItem('User', JSON.stringify(response?.response?.user))

        success("Logged In")
        if (response?.response?.user?.role == 2) {
            const routeHistory = localStorage.getItem('routeHistory')
            if (routeHistory)
                navigateToHistory(navigate, JSON.parse(routeHistory))
            else {
                navigate('/admin/customers-list')
            }
        }
        const routeHistory = localStorage.getItem('routeHistory')
        if (routeHistory && JSON.parse(routeHistory)?.length) {
            const emptyArr = []
            navigateToHistory(navigate, JSON.parse(routeHistory))
            localStorage.setItem('routeHistory', JSON.stringify(emptyArr))
        }
        else {
            navigate('/dashboard')
        }
    }
    else {
        error(response?.response)
    }
}
const onSendVerificationCodeSubmit = async (dispatch, body, navigate) => {
    dispatch(setPageLoader({ page: 'emailVerification', flag: true }))

    let response = await postRequest({
        endPoint: auth.forgotPassword.receiveOTP,
        body
    })

    dispatch(setPageLoader({ page: 'emailVerification', flag: false }))

    if (response.success === true) {
        success("OTP Sent")
        localStorage.setItem('OTP', response?.response?.OTP)
        setToLocalStorage(body.emailAddress, "verification email")
        dispatch(verification({ accessToken: response?.response?.accessToken, flag: true }))
        navigate(pageRoutes?.private?.verification);
    }
    else {
        error(response?.response)
    }

}
const onVerifyOTPSubmit = async (dispatch, body, navigate, accessToken) => {

    dispatch(setPageLoader({ page: 'otpVerify', flag: true }))

    let response = await postRequest({
        endPoint: auth.forgotPassword.verifyOTP,
        body,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'otpVerify', flag: false }))
    if (response.success === true) {
        success("OTP Verified")
        dispatch(verification({ accessToken: response?.response?.accessToken, flag: true }))
        navigate(pageRoutes?.private.newPassword)
    }
    else {
        error(response?.response)
    }
}
const onResetPasswordSubmit = async (dispatch, body, navigate, accessToken) => {

    dispatch(setPageLoader({ page: 'resetPassword', flag: true }))

    let response = await postRequest({
        endPoint: auth.forgotPassword.changePassword,
        body,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'resetPassword', flag: false }))
    if (response.success === true) {
        success(response?.response?.message)
    }
    else {
        error(response?.response)
    }

}
const onSendResendOTP = async (dispatch, accessToken) => {
    dispatch(setPageLoader({ page: 'resendOTP', flag: true }))

    let response = await putRequest({
        endPoint: auth.forgotPassword.resendOTP,
        otherToken: accessToken
    })
    dispatch(setPageLoader({ page: 'resendOTP', flag: false }))
    if (response.success === true) {
        success('OTP resend successfully')
        return (response)
    }
    else {
        error(response?.response)
    }
}
const onSignupSubmit = async (dispatch, body, navigate) => {

    dispatch(setPageLoader({ page: 'signup', flag: true }))

    let response = await postRequest({
        endPoint: auth.signup,
        body,
    })

    dispatch(setPageLoader({ page: 'signup', flag: false }))
    if (response.success === true) {
        success(`Your request has been received by the admin, please allow up to 24 hours for your request to be processed`)
        // navigate(pageRoutes?.public?.login)
        setTimeout(() => {
            navigate(pageRoutes?.public?.login);
        }, 2000);
    }
    else {
        error(response?.response)
    }

}



export { onLoginSubmit, onSendVerificationCodeSubmit, onVerifyOTPSubmit, onResetPasswordSubmit, onSendResendOTP, onSignupSubmit }