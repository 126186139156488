import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import { Col, Divider, Form, Input, Row, Spin, } from "antd";
import ProductSectionHeader from "../../Components/ProductSection/Product-Section-Header";
import ProductListing from "../../Components/ProductSection/Product-Listing-Client";
import { Colors } from "../../Assets/Colors";
import PaginationComponent from '../../Components/Pagination/PaginationComponent'
import {
  onFetchProducts, onGetProdcutByName
} from "../../API-Interaction/api-functions/product.api";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from '../../helpers/helper'
import { useNavigate } from "react-router-dom";

let Value = 1
let TotalProductCount = 0
let CurrentItems = 0
let Subcategory = ''


function ClientProducts({ type }) {
  const T_count = useRef('')
  const C_count = useRef('')
  let Search = useRef('')
  let parentCat = useRef('')
  const [View, setView] = useState('AmazaonGridView')
  const [form] = Form.useForm()
  const [categoryID, setcategoryID] = useState('')
  parentCat.current = type == 'Daily Essentials' ? 1 : type == 'Customise Goods' ? 2 : 3;
  Subcategory = categoryID;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState([]);
  const [count, setCount] = useState(0)
  const { pageLoader } = useSelector((state) => state.utils.loaders);
  const token = localStorage.getItem("authTokenHaasil")
  const dispatch = useDispatch();



  const FetchProdutsData = async (offset, Search, CategoryID) => {
    if (Search) {
      let response = await onGetProdcutByName(dispatch, token, Search, categoryID, offset, parentCat.current);

      setTotalItems(prev => { let items = [...prev, ...response?.response?.products]; CurrentItems = items.length; return [...prev, ...response?.response?.products] })
      setCount(response?.response.productCount)
      T_count.current = response?.response.productCount;
      C_count.current = totalItems.length
      TotalProductCount = response?.response.productCount

    }
    else {
      let response = await onFetchProducts(dispatch, token, offset, CategoryID ? CategoryID : '', parentCat.current);
      if (response?.success) {
        setTotalItems(prev => { let items = [...prev, ...response?.response?.products]; CurrentItems = items.length; C_count.current = items.length; return [...prev, ...response?.response?.products] })
        setCount(response?.response.productCount)
        T_count.current = response?.response.productCount;
        TotalProductCount = response?.response.productCount
        setCount(response?.response.productCount)

      }
    }
  };
  // We will set the Search State Here
  const getProductByName = debounce((e) => { setCurrentPage(1); setTotalItems([]); Value = 1; Search.current = e.target.value })
  // Calling API on Scroll 

  // const handleScroll = debounce(async (e) => {
  //   const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
  //   const totalHeight = Math.max(
  //     document.documentElement.scrollHeight,
  //     document.body.scrollHeight
  //   );
  //   const windowHeight = window.innerHeight;
  //   // Check if the user has reached the bottom of the page
  //   if (scrollTop + windowHeight === totalHeight || Math.ceil(scrollTop + windowHeight) === totalHeight || Math.floor(scrollTop + windowHeight) === totalHeight) {
  //     if (TotalProductCount > CurrentItems) {
  //       Value++;
  //       await FetchProdutsData(Value - 1, Search.current, Subcategory)
  //     }
  //   }
  // })
  const handleScroll = async (e) => {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const totalHeight = Math.max(
      document.documentElement.scrollHeight,
      document.body.scrollHeight
    );
    const windowHeight = window.innerHeight;
    // Check if the user has reached the bottom of the page
    if (scrollTop + windowHeight === totalHeight || Math.ceil(scrollTop + windowHeight) === totalHeight || Math.floor(scrollTop + windowHeight) === totalHeight) {
      if (TotalProductCount > CurrentItems) {
        Value++;
        await FetchProdutsData(Value - 1, Search.current, Subcategory)
      }
    }
  };



  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [])
  useEffect(() => {
    Search.current = ''
    form.setFieldsValue({ 'SearchInput': null })
  }, [type])

  useEffect(() => {
    setTotalItems([])
    Value = 1
  }, [type, categoryID])

  useEffect(() => {
    FetchProdutsData(currentPage - 1, Search.current, categoryID)
    setCurrentPage(1)
  }, [Search.current, categoryID, currentPage, type]);



  return (
    <Layout>
      {/* Header-----------> */}
      <Row>
        <ProductSectionHeader parentCategoryId={type == 'Daily Essentials' ? 1 : type == 'Customise Goods' ? 2 : 3} Heading={type} view={View} setView={setView} setcategoryID={setcategoryID} setCurrentPage={setCurrentPage} count={count} />
      </Row>
      <Divider className=" mb-[10px]" />
      <Row className="flex justify-between items-start   text-[#44444F] rounded-[2px]">
        <p
          className={`flex gap-[5px] items-center text-[14px] text-[${Colors.grayTxt}]  whitespace-nowrap`}
        >
          {count ? `${count} Products found` : 'No product found!'}
        </p>
        <Col lg={10} xs={24}>
          <Form form={form} >
            <Form.Item name={'SearchInput'} >
              <Input className="p-[5px] support-heading"
                value={Search.current}
                onChange={(event) => {
                  getProductByName(event)
                }} prefix={<SearchOutlined />} placeholder="Search Here... " />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>

        <Col className={`grid ${View === "Table" ? "grid-cols-1" : View === 'Grid' ? "min-[1100px]:grid-cols-6 min-[1000px]:grid-cols-5 min-[800px]:grid-cols-4 min-[700px]:grid-cols-3 min-[400px]:grid-cols-2" : "min-[1100px]:grid-cols-4 min-[1000px]:grid-cols-3 min-[800px]:grid-cols-2 min-[700px]:grid-cols-3 min-[400px]:grid-cols-1"} w-full gap-3 mt-3 mb-5`}>
          {totalItems?.map((product, index) => (
            <ProductListing key={index} product={product} view={View} setView={setView} />
          ))}
        </Col>
      </Row>
      <Row className="flex justify-center mb-[30px]">
        <Spin spinning={pageLoader?.Dailyessentials || pageLoader?.getProductByCategoryId || false}>
        </Spin>
      </Row>
    </Layout>
  );
}
export default ClientProducts;
