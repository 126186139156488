import * as React from "react";
import { Images } from "../../Assets/Images/Images";
import { Image } from "antd";

export default function SessionLayout({ children, pageName }) {


  return (
    <>
      <div
        className={`grid ${pageName === 'signup' ? "signup-res" : "login-res"}`}
        style={{
          height: `${pageName === 'signup' ? "100%" : "100vh"}`
        }}>
        {pageName === 'signup' ?
          <div className="signup-image"></div>
          :
          <Image
            width="100%"
            height="100vh"
            preview={false}
            style={{ objectFit: "cover",}}
            src={Images.LoginBg}
          />
        }
        <div className="flex items-center flex-col justify-center sessionlayout">
          <div className="w-full">
            {children}
          </div>
        </div>
      </div>

    </>
  );
}
