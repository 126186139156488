import { appConfig } from "../../config/config";
import { error, success } from "../../helpers/notifications"
import { setPageLoader } from "../../Redux/features/utilsSlice";
import { deleteRequest, getRequest, postRequest, putRequest, uploadRequest } from "../apiMethods";
import { apiRoutes } from "../apiRoutes";
const { products, profile } = apiRoutes;

const onFetchProducts = async (dispatch, accessToken, offset, categoryId, parentCategoryId, Visibility) => {
    let VisibilityData = Visibility ? Visibility : null
    dispatch(setPageLoader({ page: 'Dailyessentials', flag: true }))

    let response = await getRequest({
        endPoint: `${products.get}?limit=16&offset=${offset}&categoryId=${categoryId ? categoryId : ""}&parentCategoryId=${parentCategoryId ? parentCategoryId : ""}&visibility=${VisibilityData === 'Visible' ? true : VisibilityData === 'Hidden' ? false : null}`,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'Dailyessentials', flag: false }))
    if (response.success === true) {

        return (response)


    }
    else {
        error(response?.response)
    }

}
const onGetProdcutByName = async (dispatch, accessToken, name, categoryID, offset, parentCategoryId, visibility) => {
    let Visibility = visibility ? visibility : null

    dispatch(setPageLoader({ page: 'Dailyessentials', flag: true }))
    let response = await getRequest({
        endPoint: `${products.getProductByName}?query=${name}&categoryId=${categoryID ? categoryID : ''}&offset=${offset}&limit=${16}&parentCategoryId=${parentCategoryId ? parentCategoryId : ""}${!Visibility ? "&visibility=true" : Visibility == 'All' ? "" : Visibility == 'Visible' ? "&visibility=true" : '&visibility=false'}`,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'Dailyessentials', flag: false }))
    if (response.success === true) {
        return (response)
    }
    else {
        error(response?.response)
    }

}

const onCreateProduct = async (dispatch, accessToken, data) => {
    dispatch(setPageLoader({ page: 'createProduct', flag: true }))
    const response = await uploadRequest(
        {
            endPoint: apiRoutes?.products?.createProduct,
            loggedInToken: accessToken,
            body: data
        }
    )
    dispatch(setPageLoader({ page: 'createProduct', flag: false }))
    if (response.data.success) {
        success('Product Created Successfully!')
        return (response.data)
    }
    else {
        error(response?.response)
    }

}

const onDeleteProductById = async (dispatch, accessToken, id) => {
    dispatch(setPageLoader({ page: 'deleteProductById', flag: true }))
    const response = await deleteRequest({
        endPoint: apiRoutes.products.deleteProductById + `${id}`,
        loggedInToken: accessToken
    })
    dispatch(setPageLoader({ page: 'deleteProductById', flag: false }))
    if (response?.success) {
        success('Product Deleted Successfully')
        return (response)
    }
    else {
        error(response?.response)
    }

}


const onUpdateProductById = async (dispatch, accessToken, data, id) => {
    dispatch(setPageLoader({ page: 'updateProductById', flag: true }))
    const response = await uploadRequest(
        {
            endPoint: apiRoutes?.products?.updateProductById + `/${id}`,
            loggedInToken: accessToken,
            body: data
        }
    )
    dispatch(setPageLoader({ page: 'updateProductById', flag: false }))
    if (response.data.success) {
        success('Product Updated Successfully!')
        return (response.data)
    }
    else {
        error(response?.response)
    }

}

export const onPostReview = async (dispatch, token, data) => {
    dispatch(setPageLoader({ page: 'postReview', flag: true }))
    let response = await postRequest({
        endPoint: apiRoutes.products.giveReview,
        loggedInToken: token,
        body: data
    })
    dispatch(setPageLoader({ page: 'postReview', flag: false }))
    if (response.success) {
        success(response?.response.message)
        return response
    }
    else {
        error("Error Occure")
    }

}


export const ProductDownloadApi = async (dispatch, accessToken,) => {

    dispatch(setPageLoader({ page: 'FileDownloadComponent', flag: true }))
    let response = await
        fetch(`${appConfig?.apiUrl}${apiRoutes?.AdminProduct?.downloadFile}`,
            {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': accessToken
                },
            });
    dispatch(setPageLoader({ page: 'FileDownloadComponent', flag: false }))
    return response

}
export const ProductUploadApi = async (dispatch, body, accessToken) => {

    dispatch(setPageLoader({ page: 'FileDownloadComponent', flag: true }))
    const Form = new FormData()
    Form.append('products', body)
    let response = await uploadRequest({
        endPoint: apiRoutes.AdminProduct.uploadFile,
        loggedInToken: accessToken,
        body: Form,
    })
    dispatch(setPageLoader({ page: 'FileDownloadComponent', flag: false }))
    return response

}

export const getAllProductCategoryList = async (dispatch, accessToken, offSet = 0) => {
    dispatch(setPageLoader({ page: 'getAllUsers', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes?.products?.Category + `?offset=${offSet}&limit=10`, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getAllUsers', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }

}
export const getProductCategoryFilterList = async (accessToken, dispatch, query, offset = 0) => {

    dispatch(setPageLoader({ page: 'getAllUsers', flag: true }))
    let response = await getRequest({
        endPoint: apiRoutes.products.FilterCategory + `?offset=${offset}&limit=20&level=${query}`,
        loggedInToken: accessToken
    })
    dispatch(setPageLoader({ page: 'getAllUsers', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }

}


export const updateProductCategory = async (dispatch, accessToken, data, id, prevData) => {
    dispatch(setPageLoader({ page: 'updateCustomer', flag: true }))
    let payload = {
        name: data?.name,
        parentCategoryId: data?.parentCategory
    }
    if (!data?.parentCategory) payload.parentCategoryId =  prevData?.parentCategoryId
    if (!data?.name) payload.name = prevData?.name
    let respone = await putRequest({ endPoint: apiRoutes.products.UpdateCategory + id, loggedInToken: accessToken, body: payload })
    dispatch(setPageLoader({ page: 'updateCustomer', flag: false }))
    if (respone.success) {
        success("Product Category Updated!")
        return respone
    } else {
        error(respone?.response)
    }
}

export const updateProductCategoryLevel = async (dispatch, accessToken, categoryId) => {
    console.log({ categoryId });
    dispatch(setPageLoader({ page: 'updateCustomer', flag: true }));
    
    let response = await getRequest({
        endPoint: apiRoutes?.products?.UpdateCategorylevel + categoryId,
        loggedInToken: accessToken,
    });
    dispatch(setPageLoader({ page: 'updateCustomer', flag: false }));

    if (response.success) {
        // success("User Updated");
        return response;
    } else {
        error(response?.response);
    }
};



export { onFetchProducts, onGetProdcutByName, onCreateProduct, onDeleteProductById, onUpdateProductById }