import React, { useEffect, useState } from "react";
import { Divider, Row, Spin } from "antd";
import Layout from "../../../Layout/Layout";
import Appbar from '../../../Components/Appbar/Appbar'
import CustomerTable from "../../../Components/Admin/Table/Customertable";
import { getAllCustomerList } from "../../../API-Interaction/api-functions/customer.api";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../../../API-Interaction/api-functions/order.api";
import QuotationsTable from "../../../Components/Admin/Table/Quotationstable";
import AdminOrdersTable from "../../../Components/Admin/Table/Admin-Orders-Table";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { Colors } from "../../../Assets/Colors";



const Orders = ({ sideKey }) => {
    const dispatch = useDispatch()
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const [data, setData] = useState([])
    const [total, settotal] = useState()
    const [filterType, setFilterType] = useState({ name: 'All Orders', value: "" })
    const token = localStorage.getItem('authTokenHaasil')



    const items = [
        {
            label: <p onClick={() => { setFilterType({ name: 'All Orders', value: "" }) }}>All Orders</p>,
            key: '0',
        },
        {
            label: <p onClick={() => { setFilterType({ name: 'Confirmed Orders', value: "OC" }) }}>Confirmed Orders</p>,
            key: '1',
        },
        {
            label: <p onClick={() => { setFilterType({ name: 'Delivered Orders', value: "OR" }) }}>Delivered Orders</p>,
            key: '2',
        },

    ];

    const getAllConfirmedOrders = async (offset) => {
        let respone = await getAllOrders(dispatch, token, offset ? offset -1 : 0,filterType.value)
        if (respone.success) {
            settotal(respone?.response?.orderCount)
            setData(respone?.response?.order)
        }
    }

    useEffect(() => {
        getAllConfirmedOrders()
    }, [pageLoader.ChangeUserStatus, filterType])

    return (
        <Layout sideKey={sideKey}>
            <Row className="">
                <Appbar Heading={'Confirmed Orders'} />
                <div className="flex justify-end w-full items-center">
                    <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={['click']}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <p className={`flex justify-center items-center bg-[white] text-[gray] px-[20px] py-[5px] gap-3  border-2 border-[lightgray]`}>
                                Filter Order : {filterType.name}
                                <DownOutlined />
                            </p>
                        </a>
                    </Dropdown>
                </div>
            </Row>
            <Spin spinning={pageLoader?.getAllOrders || false}>
                <Divider className="mt-1 w-[full]" />
                <div className="w-full bg-[#f4f6fc]" >
                    <AdminOrdersTable data={data} confirmOrderApi={getAllConfirmedOrders} total={total} />
                </div>
            </Spin>
        </Layout >
    )
}

export default Orders