import React, { useState } from 'react';
import { Upload, Button, message, Modal } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { ProductUploadApi } from '../../../API-Interaction/api-functions/product.api';
import { useDispatch } from 'react-redux';
import { debounceImage } from '../../../helpers/helper';

const FileUploadComponent = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch()
  const token = localStorage.getItem('authTokenHaasil')
  // const handleFileChange = (event) => {
  //   if (event.fileList.length > 0) {
  //     setFile(event.fileList[0].originFileObj);
  //     window.onload = function () {window.location.reload()}

  //   } else {
  //     setFile(null);
  //   }
  // };
  const handleFileChange = (event) => {
    if (event.fileList.length > 0) {
      const selectedFile = event.fileList[0].originFileObj;
      if (selectedFile.name.endsWith('.csv')) {
        setFile(selectedFile);
      } else {
        message.error('Please select a .CSV file.');
      }
    } else {
      setFile(null);
    }
  };


  // const handleUpload = debounceImage(async (info) => {

  //   if (!file) {
  //     message.error('Please select a file to upload.');
  //     return;
  //   }
  //   console.log({ info, file })

  //   try {

  //     setLoading(true);
  //     const response = await ProductUploadApi(dispatch, file, token); // Assuming OnfetchProductUploadApi handles API request
  //     setModalVisible(false);
  //     console.log({ response })
  //     message.success('Product File uploaded successfully.');

  //   } catch (error) {
  //     console.error('An error occurred:', error);
  //     message.error('An error occurred while uploading the file.');
  //   } finally {
  //     setLoading(false);
  //   }
  // })
  const handleUpload = debounceImage(async (info) => {
    if (!file) {
      message.error('Please select a file to upload.');
      return;
    }
    console.log({ info, file });
  
    try {
      setLoading(true);
      const response = await ProductUploadApi(dispatch, file, token); // Assuming OnfetchProductUploadApi handles API request
      setModalVisible(false);
      console.log({ response });
      message.success('Product File uploaded successfully.');
  
      // Refresh the page after a successful upload
      window.location.reload();
  
    } catch (error) {
      console.error('An error occurred:', error);
      message.error('An error occurred while uploading the file.');
    } finally {
      setLoading(false);
    }
  });
  

  return (
    <div>
      <Button
        // type="primary"
        icon={<UploadOutlined />}
        onClick={() => setModalVisible(true)}
      >
        Upload Bulk Product File
      </Button>
      <Modal
        title="Upload Bulk Product File"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="upload"
            // type="primary"
            onClick={handleUpload}
            loading={loading}
            disabled={!file}
          >
            Upload
          </Button>,
        ]}
      >

        <Upload onChange={handleFileChange} disabled={loading} accept=".CSV"
          beforeUpload={() => false}
          fileList={file ? [{ uid: '1', name: file.name }] : []}>
          <Button icon={!loading && <UploadOutlined />} > {loading ? <LoadingOutlined /> : `Click to Upload `}</Button>
        </Upload>
      </Modal>
    </div>
  );
};

export default FileUploadComponent;
