import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Popconfirm, Select, Spin, Switch, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Colors } from '../../../Assets/Colors';
import { Images } from '../../../Assets/Images/Images';
import { useParams } from 'react-router-dom';
import { getProductById } from '../../../API-Interaction/api-functions/quotation.api';
import { useDispatch, useSelector } from 'react-redux';
import DraggerButton from '../../Upload/DraggerButton';
import { deleteSelectedDocument } from '../../../Redux/features/cartSlice';




const NewTable = ({ setRows, rows }) => {
    const { id } = useParams()
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const [Status, setStatus] = useState('')
    const [editMode, setEditMode] = useState(false)
    const [productList, setProductList] = useState([])
    const [includeGst, setIncludeGst] = useState(false)
    let UploadDocument = useSelector((state) => state?.cart?.uploadedDocuments);

    const [Data, setData] = useState([])
    const [updateOrder, setUpdatetOrder] = useState()
    const { Option } = Select;
    const [totalPrice, setTotalPrice] = useState(0);

    // const calculateRowTotal = (row) => {
    //     const quantity = row.quantity || 0;
    //     const unitRate = row.unitrate || 0;
    //     const gst = row.gst || 0;

    //     const total = includeGst ? quantity * unitRate * (1 + gst / 100) : quantity * unitRate;
    //     return Math.ceil(total);

    //     // return Math.ceil(quantity * unitRate + (quantity * unitRate * gst / 100));
    // };

    // const updateTotalPrice = () => {
    //     const newTotalPrice = rows.reduce((total, row) => {
    //         return total + calculateRowTotal(row);
    //     }, 0);
    //     setTotalPrice(newTotalPrice);
    // };

    // useEffect(() => {
    //     updateTotalPrice();
    // }, [rows,includeGst]);

    const calculateRowTotal = (row) => {
        const quantity = row.quantity || 0;
        const unitRate = row.unitrate || 0;
        const gst = row.gst || 0;
        const includeGst = row.includeGst; // Include GST value for the row

        // Calculate the total based on the includeGst value
        const total = includeGst ? quantity * unitRate * (1 + gst / 100) : quantity * unitRate;
        return Math.ceil(total);
    };

    const updateTotalPrice = () => {
        const newTotalPrice = rows.reduce((total, row) => {
            return total + calculateRowTotal(row);
        }, 0);
        setTotalPrice(newTotalPrice);
    };

    useEffect(() => {
        updateTotalPrice();
    }, [rows]);


    const emptyRow = {
        id: null, product: 'Select', sku: '', Variant: '', quantity: 0, unitrate: 0, gst: 0, Total: 0,
    }
    const handleSelectChange = (index, value, record) => {

        let productFound = productList?.filter((prod) => prod?.productId === value)
        if (!productFound?.length) return
        let newRows = rows.filter((data, dataindex) => {
            if (dataindex == index) {
                const { GST: gst, price: unitrate, name: product, internalReference: sku, productCategoryL8 = null, productId } = productFound[0]
                data.product = product
                data.sku = sku || 0
                data.unitrate = unitrate || 0
                data.gst = gst || 0
                data.Variant = productCategoryL8 ? productCategoryL8?.name : 'N/A'
                data.id = productId
            }
            return record
        })
        setRows(prev => { return prev = newRows })

    };

    const handleDelete = (index) => {
        // Filter out the record at the specified index and update the rows
        const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(updatedRows);
    };
    // Render the component as Data get Changed
    const fetchProductData = async () => {
        const result = await getProductById(dispatch, id, token);
        if (!result.success) return
        if (!result?.response?.products?.length) return
        setProductList(result?.response?.products)
    }
    const handleIncludeGstChange = (index) => {
        // Create a copy of the rows array and update the includeGst value for the specific row.
        const updatedRows = [...rows];
        updatedRows[index].includeGst = !updatedRows[index].includeGst;

        // Update the GST value to 0 if includeGst is false
        if (!updatedRows[index].includeGst) {
            updatedRows[index].gst = 0;
        }

        setRows(updatedRows);
    };


    useEffect(() => {
        fetchProductData(); // Fetch product data when the component mounts
    }, []);

    const columns = [


        // {
        //     title: 'PRODUCTS',
        //     dataIndex: 'product',
        //     render: (text, record, index) => (
        //         <Select
        //             showSearch
        //             value={record?.product}
        //             style={{ width: 300 }}
        //             onChange={(value) => handleSelectChange(index, value, record)}
        //             filterOption={(input, option) =>
        //                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        //             }
        //         >
        //             {productList.map(({ name, productId }, i) => (
        //                 <Select.Option key={i} value={productId}>
        //                     {name}
        //                 </Select.Option>
        //             ))}
        //         </Select>
        //     ),
        // },
        {
            title: 'PRODUCTS',
            dataIndex: 'product',
            render: (text, record, index) => (
                <Select
                    showSearch
                    value={record?.product}
                    style={{ width: 400 }}
                    onChange={(value) => handleSelectChange(index, value, record)}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {productList.map(({ name, productId, productCategoryL8 }, i) => (
                        <Select.Option key={i} value={productId}>
                            {`${name}  ${productCategoryL8 ? productCategoryL8.name : ''}`}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },

        {
            title: 'SKU',
            dataIndex: 'sku',
            align: "center",
            render: (_, record, index) => {
                return (
                    <div className='flex justify-center items-center gap-3 '>
                        <div>
                            <p style={{ fontSize: "12px" }}> {record?.sku}</p>
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Variant',
            dataIndex: 'Variant',
            align: "center",
            render: (_, record, index) => {
                return <div className='flex justify-center items-center gap-3 '>
                    <div>
                        <p style={{ fontSize: "12px" }}> {record?.Variant}</p>
                    </div>


                </div>
            },
        },
        {
            title: 'QTY',
            dataIndex: 'quantity',
            align: "center",
            render: (_, record, index) => {
                return <div className='flex justify-center items-center gap-3 '>
                    <div>
                        {/* <p> {record?.quantity}</p> */}
                        {
                            editMode ?
                                <Input
                                    value={record?.quantity}
                                    onChange={(event) => editCells(index, 'quantity', event.target.value)}
                                    type='Number'
                                    style={{ width: '100px' }} />
                                : Math?.ceil(record?.quantity)
                        }
                    </div>
                </div>
            },
        },
        {
            title: 'UNIT RATE',
            dataIndex: 'unitrate',
            key: 'unitrate',
            align: "center",

            render: (_, record, index) => {
                return <div className='flex justify-center items-center '>
                    <div className='flex justify-center items-center ' style={{ width: "100px" }}>
                        {
                            editMode ?
                                <Input
                                    value={record?.unitrate}
                                    onChange={(event) => editCells(index, 'unitrate', event.target.value)}
                                    type='Number'
                                    className='w-full' />
                                : Math.ceil(record?.unitrate)
                        }
                    </div>
                </div>
            },

        },
        {
            title: 'GST  %',
            key: 'gst',
            align: "center",
            dataIndex: 'gst',
            render: (_, record, index) => {
                return <div className='flex justify-center items-center '>
                    <div>
                        {
                            editMode ?
                                <Input
                                    type='Number'
                                    value={record?.gst}
                                    onChange={(event) => editCells(index, 'gst', event.target.value)}
                                    style={{ width: "100px" }} />
                                : Math.ceil(record?.gst)
                        }
                    </div>
                </div>
            },
        },
        // if you want to disable gst when the toggle button is false use this one 
        // {
        //     title: 'GST %',
        //     key: 'gst',
        //     align: "center",
        //     dataIndex: 'gst',
        //     render: (_, record, index) => {
        //         return (
        //             <div className='flex justify-center items-center '>
        //                 <div>
        //                     {
        //                         record.includeGst ? (
        //                             // Render the input field for GST only when includeGst is true
        //                             editMode ? (
        //                                 <Input
        //                                     type='Number'
        //                                     value={record.gst}
        //                                     onChange={(event) => editCells(index, 'gst', event.target.value)}
        //                                     style={{ width: "100px" }}
        //                                 />
        //                             ) : (
        //                                 Math.ceil(record.gst)
        //                             )
        //                         ) : (
        //                             // Render the text value (no input field) when includeGst is false
        //                             Math.ceil(0)
        //                         )
        //                     }
        //                 </div>
        //             </div>
        //         );
        //     },
        // },

        {
            title: 'Include GST',
            key: 'incl',
            align: "center",
            dataIndex: 'includegst',
            render: (_, record, index) => (
                <div className='flex justify-center items-center'>
                    <Switch
                        checked={record.includeGst}
                        onChange={() => handleIncludeGstChange(index)}
                    />
                </div>
            ),
        },

        {
            title: 'Total',
            key: 'totalPrice',
            render: (_, record, index) => {
                const rowTotal = calculateRowTotal(record);
                return <p>{rowTotal}</p>;
            },
        },
        ...(Status !== "OR" && Status !== 'OC') ? [{

            title: 'EDIT',
            key: 'edit',
            dataIndex: 'edit',
            render: (_, record, index) => {
                return (
                    <div className='flex justify-center items-center gap-3'>
                        {editMode ? (
                            <div className='flex items-center gap-2'>
                                <Popconfirm
                                    title="Are you sure you want to delete this record?"
                                    onConfirm={() => handleDelete(index)}
                                    okText={<span className="text-black hover:text-white"> Yes</span>}
                                    cancelText="No"
                                >
                                    <Button type="danger">
                                        <DeleteOutlined style={{ color: 'red' }} />
                                    </Button>
                                </Popconfirm>
                            </div>
                        ) :
                            <Button onClick={() => {
                                HandleMode('edit', index, record);
                                setEditMode(true)
                            }} className='p-0 border-none  flex items-center justify-center ' >
                                <img src={Images.EditPen} style={{ color: Colors.darkBlue, height: "20px" }} />
                            </Button>

                        }

                    </div>
                );
            },
            align: "center",
        },

        ] : [],

    ];

    // Handle Cells on Edit
    const editCells = (index, key, value) => {
        let data = rows.filter((data, dataindex) => {
            if (dataindex == index) {
                if (value > 0)
                    data[key] = Number(value)
                else
                    data[key] = ""
            }
            return data
        })
        setRows(prev => { return prev = data })
    }

    const HandleMode = async (mode, index, record) => {
        console.log('handleMode called with mode:', mode);
        console.log('handleMode called with index:', index);
        console.log('handleMode called with record:', record);

        let newData;
        if (mode === 'edit') {
            newData = Data.map((item, key) => (key === index ? { ...item, Mode: true } : item));
        } else if (mode === 'submit') {
            // Handle submission logic here if needed
            newData = Data.map((item, key) => (key === index ? { ...item, Mode: false } : item));
        } else if (mode === 'delete') {
            // Remove the item from Data array at the specified index
            newData = Data.filter((item, key) => key !== index);
        }
        setData(newData);
        console.log('Data after delete:', newData);

    };



    return (

        <>
            <div className='w-full' style={{ overflowX: "scroll" }}>
                <Table
                    columns={columns}
                    dataSource={rows}
                    pagination={false}
                />
                <div className='flex justify-end items-center mt-4'>
                    <p style={{ fontWeight: 'bold' }}>Total Price :<span style={{ color: 'blue' }}> {totalPrice}</span></p>
                </div>
                <Divider />

                <div className='flex justify-center items-center gap-3'>
                    <Button
                        onClick={() => setRows((prev) => [...prev, emptyRow])}
                        style={{
                            background: Colors.darkBlue,
                            color: "white",
                            margin: '10px',
                            width: '300px',
                        }}
                    >
                        Add a product
                    </Button>
                </div>

            </div></>
    );
};
export default NewTable;