import { message } from "antd"

const success = (txt) => {
    message.destroy()
    const args = {
        type: 'Success',
        content: txt,
        duration: 3,
    };
    message.success(args)
}

const error = (txt) => {
    message.destroy()
    const args = {
        type: 'Error',
        content: txt,
        duration: 3,
    };
    message.error(args)
}

const info = (txt) => {
    message.destroy()
    const args = {
        type: 'Information',
        content: txt,
        duration: 5,
    };
    message.info(args)
}

const pushNotification = (payload) => {
    message.destroy()
    const args = {
        type: 'Information',
        content: <>
            <span className="font-bold">{payload?.notification?.title}: </span>
            <span>{payload?.notification?.body}</span>
        </>,
        duration: 5,
    };
    message.info(args)

}

export {
    success,
    error,
    info,
    pushNotification
}