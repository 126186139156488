import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { error } from "../helpers/notifications";
import { pageRoutes } from "./pageRoutes";

const UniversalRoutes = ({ isAuth }) => {

    let token = localStorage?.getItem('authTokenHaasil')
    let Role = localStorage.getItem('Role')
    let authTokenHaasil = useSelector(state => state?.auth?.accessTokens?.token)
    useEffect(() => {
        if (!authTokenHaasil?.length || !token?.length) return
        if (isAuth) return !authTokenHaasil?.length && error("Please send email for verification and further process first!")
        !token?.length && error("Please login before continuing!")
    }, [])

    return (
        isAuth ? authTokenHaasil?.length ? <Outlet /> : <Navigate to={pageRoutes.public.login} /> : token?.length ? < Outlet /> : <Navigate to={pageRoutes.public.login} />
    )
}

export default UniversalRoutes