import { error, success } from "../../helpers/notifications"
import { login, verification } from "../../Redux/features/authSlice";
import { setPageLoader } from "../../Redux/features/utilsSlice";
import { deleteRequest, getRequest, postRequest, putRequest } from "../apiMethods";
import { setToLocalStorage } from "../../helpers/helper"
import { apiRoutes } from "../apiRoutes";
import { pageRoutes } from "../../Routes/pageRoutes";

export const getAllCustomerList = async (dispatch, accessToken, offSet = 0) => {
    dispatch(setPageLoader({ page: 'getAllUsers', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.customer.getAll + `?offset=${offSet}&limit=10`, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getAllUsers', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }

}



export const getCustomerById = async (dispatch, id, accessToken) => {
    dispatch(setPageLoader({ page: 'getUserById', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.customer.getUserById + `/${id}`, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getUserById', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }

}


export const changeUserStatus = async (dispatch, id, accessToken, status) => {
    dispatch(setPageLoader({ page: 'ChangeUserStatus', flag: true }))
    let respone = await putRequest({ endPoint: apiRoutes.customer.changeUserStatus + `/${id}`, loggedInToken: accessToken, body: { status: status } })
    dispatch(setPageLoader({ page: 'ChangeUserStatus', flag: false }))
    if (respone.success) {
        if (status === 3) {
            error('User Rejected')
        } else {
            success("User Verified")
        }
        return respone
    } else {
        error(respone?.response)
    }
}

export const updateCustomerAdmin = async (dispatch, accessToken, data) => {
    dispatch(setPageLoader({ page: 'updateCustomer', flag: true }))
    let respone = await putRequest({ endPoint: apiRoutes.customer.updateCustomer, loggedInToken: accessToken, body: data })
    dispatch(setPageLoader({ page: 'updateCustomer', flag: false }))
    if (respone.success) {
        success("User Updated")
        return respone
    } else {
        error(respone?.response)
    }
}

// export const CreateCustomerAdmin = async (dispatch, accessToken, data) => {
//     dispatch(setPageLoader({ page: 'updateCustomer', flag: true }))
//     let respone = await postRequest({ endPoint: apiRoutes.customer.createCustomer, loggedInToken: accessToken, body: data })
//     dispatch(setPageLoader({ page: 'updateCustomer', flag: false }))
//     if (respone.success) {
//         success("User Updated")
//         return respone
//     } else {
//         error(respone?.response)
//     }

    
// }
export const CreateCustomerAdmin = async (dispatch, accessToken, data) => {
    try {
      console.log('CreateCustomerAdmin: Request data', data);
      
      dispatch(setPageLoader({ page: 'updateCustomer', flag: true }));
      let respone = await postRequest({ endPoint: apiRoutes.customer.createCustomer, loggedInToken: accessToken, body: data });
      dispatch(setPageLoader({ page: 'updateCustomer', flag: false }));
  
      console.log('CreateCustomerAdmin: Response', respone);
  
      if (respone.success) {
        success("User Updated");
        return respone;
      } else {
        error(respone?.response);
      }
    } catch (error) {
      console.error('CreateCustomerAdmin: Error', error);
    }
  }
  
export const CreateUserBySuperAdmin = async (dispatch, accessToken, data) => {
    dispatch(setPageLoader({ page: 'updateCustomer', flag: true }))
    let respone = await postRequest({ endPoint: apiRoutes.customer.createUser, loggedInToken: accessToken, body: data })
    dispatch(setPageLoader({ page: 'updateCustomer', flag: false }))
    if (respone.success) {
        success("User Updated")
        return respone
    } else {
        error(respone?.response)
    }

    
}
// export const UpdateUser = async (dispatch, accessToken, data) => {
//     dispatch(setPageLoader({ page: 'updateCustomer', flag: true }))
//     let respone = await putRequest({ endPoint: apiRoutes.customer.UpdateUser, loggedInToken: accessToken, body: data })
//     dispatch(setPageLoader({ page: 'updateCustomer', flag: false }))
//     if (respone.success) {
//         success("User Updated")
//         return respone
//     } else {
//         error(respone?.response)
//     }

    
// }
export const UpdateUser = async (dispatch, accessToken, data) => {
    dispatch(setPageLoader({ page: 'updateCustomer', flag: true }))
    let respone = await putRequest({ endPoint: apiRoutes.customer.UpdateUser, loggedInToken: accessToken, body: data })
    dispatch(setPageLoader({ page: 'updateCustomer', flag: false }))
    if (respone.success) {
        success("User Updated")
        return respone
    } else {
        error(respone?.response)
    }
}

// export const getAllUsers = async (accessToken, dispatch, query, offset = 0) => {

//     dispatch(setPageLoader({ page: 'getAllUsers', flag: true }))
//     let response = await getRequest({
//         endPoint: apiRoutes.customer.getusers + `?offset=${offset}&limit=20`,

//         loggedInToken: accessToken
//     })
//     dispatch(setPageLoader({ page: '', flag: false }))
//     if (response.success) {
//         return response
//     } else {
//         error(response?.response)
//     }

// }
// customer.api.js

// Assume you have getRequest, setPageLoader, and error functions defined elsewhere.

export const getAllUsers = async (accessToken, dispatch, query, offset = 0) => {
    dispatch(setPageLoader({ page: 'getAllUsers', flag: true }));
  
    try {
      const response = await getRequest({
        endPoint: apiRoutes.customer.getusers + `?offset=${offset}&limit=20`,
        loggedInToken: accessToken,
      });
  
      dispatch(setPageLoader({ page: '', flag: false }));
  
      if (response.success) {
        return response;
      } else {
        error(response?.response);
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error fetching users:", error);
      error(error.message);
    }
  };
  
export const getCompanyList = async (accessToken, dispatch, query, offset = 0) => {

    dispatch(setPageLoader({ page: 'getAllUsers', flag: true }))
    let response = await getRequest({
        endPoint: apiRoutes.company.getAllCompany + `?offset=${offset}&limit=20`,

        loggedInToken: accessToken
    })
    dispatch(setPageLoader({ page: '', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }

}
export const getFilterProductCustomerList = async (accessToken, dispatch, query, offset = 0) => {

    dispatch(setPageLoader({ page: 'getAllUsers', flag: true }))
    let response = await getRequest({
        endPoint: apiRoutes.customer.getAll + `?offset=${offset}&limit=20&status=2`,

        loggedInToken: accessToken
    })
    dispatch(setPageLoader({ page: 'getAllUsers', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }

}
export const getFilterCustomerList = async (accessToken, dispatch, query, offset = 0) => {

    dispatch(setPageLoader({ page: 'getAllUsers', flag: true }))
    let response = await getRequest({
        endPoint: apiRoutes.customer.getAll + `?offset=${offset}&limit=20&status=${query}`,

        loggedInToken: accessToken
    })
    dispatch(setPageLoader({ page: 'getAllUsers', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }

}

// export const DeleteUser = async (dispatch, token, id, data) => {

//     dispatch(setPageLoader({ page: 'deleteOrderByOrderId', flag: true }))
//     let response = await deleteRequest({ endPoint: apiRoutes.customer.deleteUser + `/${id}`, loggedInToken: token, body: data })
//     dispatch(setPageLoader({ page: 'deleteOrderByOrderId', flag: false }))
//     if (response.success) {
//         return response
//     }
//     else {
//     }

// }
// export const DeleteUser = async (dispatch, token, userId) => {
//     dispatch(setPageLoader({ page: 'deleteOrderByOrderId', flag: true }));
//     try {
//       const response = await deleteRequest({
//         endPoint: `${apiRoutes.customer.deleteUser}?userId=${userId}`,
//         loggedInToken: token,
//       });
  
//       dispatch(setPageLoader({ page: 'deleteOrderByOrderId', flag: false }));
  
//       if (response.success) {
//         return response;
//       } else {
//         // Handle the error here if needed
//         return response;
//       }
//     } catch (error) {
//       // Handle unexpected errors
//       console.error('Error deleting user:', error);
//       return { success: false, error: 'An error occurred while deleting the user' };
//     }
//   };
export const DeleteUser = async (dispatch, token, userId, companyId) => {
    dispatch(setPageLoader({ page: 'deleteOrderByOrderId', flag: true }));
    try {
      const response = await deleteRequest({
        endPoint: `${apiRoutes.customer.deleteUser}?userId=${userId}&companyId=${companyId}`,
        loggedInToken: token,
      });
  
      dispatch(setPageLoader({ page: 'deleteOrderByOrderId', flag: false }));
  
      if (response.success) {
        return response;
      } else {
        // Handle the error here if needed
        return response;
      }
    } catch (error) {
      // Handle unexpected errors
      console.error('Error deleting user:', error);
      return { success: false, error: 'An error occurred while deleting the user' };
    }
  };
  
  





