import AdminCreateOrder from "../Views/Dashboard/Admin/Admin-Create-Order";
import TestingPage from "../Views/Dashboard/TestingPage";
import Pagenotfound from "../Views/Pagenotfound";
import ForgetPassword from "../Views/Session/Forgetpassword";
import Login from "../Views/Session/Login";
import Signup from "../Views/Session/Signup";

export const PublicRoutes = [
  {
    path: "/",
    component: <Login />,
  },
  {
    path: "/forget-password",
    component: <ForgetPassword />,
  },

  {
    path: "/signup",
    component: <Signup />,
  },

  { path: "*", component: <Pagenotfound /> },
  {
    path: "test",
    component: <TestingPage />
  },



];
