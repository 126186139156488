import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Divider, Form, Image, Input, Row, Spin, Typography, Select } from "antd";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';

import { getProductById } from '../API-Interaction/api-functions/quotation.api';

const ProductDropdown = ({ onProductSelect }) => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')

    const [allProductList, setAllProductList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductsArray, setSelectedProductsArray] = useState([]);

    const fetchProductData = async () => {
        const result = await getProductById(dispatch, id, token);
        if (!result.success) return
        if (!result?.response?.products?.length) return
        setAllProductList(result?.response?.products)
    }

    const handleProductSelect = (value) => {
        // Find the selected product from the allProductList array
        const selectedProductDetails = allProductList.find(product => product.productId === value);

        // Update the selected product and add it to the selectedProductsArray
        setSelectedProduct(selectedProductDetails);
        setSelectedProductsArray(prevArray => [...prevArray, selectedProductDetails]);

        // Call the callback function to pass the selectedProduct to the parent component
        onProductSelect(selectedProductDetails);

    }

    useEffect(() => {
        fetchProductData();
    }, []);


    return (
        <div>
            <Select
                style={{ width: 500 }}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleProductSelect} // This function will be called when a product is selected

            >
                {allProductList.map(({ name, productId, productCategoryL8 }, i) => (
                    <Select.Option key={i} value={productId}>
                        {`${name}  ${productCategoryL8 ? productCategoryL8.name : ''}`}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default ProductDropdown;