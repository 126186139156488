import { setPageLoader } from "../../Redux/features/utilsSlice"
import { error } from "../../helpers/notifications"
import { getRequest, postRequest } from "../apiMethods"
import { apiRoutes } from "../apiRoutes"

export const getProductCategory = async (dispatch, accessToken, parentCategoryId, level) => {
    let Level = level ? level : ""
    let ParentCategoryId = parentCategoryId ? parentCategoryId : ""
    if (ParentCategoryId) {
        dispatch(setPageLoader({ page: 'getProductCategory', flag: true }))
    } else {
        dispatch(setPageLoader({ page: 'MainLoading', flag: true }))
    }
    let response = await getRequest({
        endPoint: apiRoutes.Categories.getAllProductCategory + `${ParentCategoryId && `?parentCategoryId=${ParentCategoryId}`}${ParentCategoryId && Level && `&`}${Level && `?level=${Level}`}`,
        otherToken: accessToken
    })

    if (ParentCategoryId) {
        dispatch(setPageLoader({ page: 'getProductCategory', flag: false }))
    } else {
        dispatch(setPageLoader({ page: 'MainLoading', flag: false }))

    }


    if (response.success === true) {
        return (response)
    }
    else {
        error(response?.response)
    }

}
export const getProductByCategoryId = async (dispatch, accessToken, id) => {

    dispatch(setPageLoader({ page: 'getProductByCategoryId', flag: true }))

    let response = await getRequest({
        endPoint: apiRoutes.Categories.getProductByCategoryId + `?query=${id}&offset=0&limit=100`,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'getProductByCategoryId', flag: false }))
    if (response.success === true) {
        return (response)
    }
    else {
        error(response?.response)
    }

}

export const getAllProductCategoryWithLevel = async (dispatch, accessToken) => {

    dispatch(setPageLoader({ page: 'GetCategoryWithLevel', flag: true }))
    let response = await getRequest({
        endPoint: apiRoutes.Categories.getAllProductCategoriesWithLevel,
        otherToken: accessToken
    })
    dispatch(setPageLoader({ page: 'GetCategoryWithLevel', flag: false }))
    if (response.success === true) {
        return (response)
    }
    else {
        error(response?.response)
    }

}

export const createCategory = async (dispatch, accessToken, data) => {

    dispatch(setPageLoader({ page: 'createCategory', flag: true }))

    let response = await postRequest({
        endPoint: apiRoutes.Categories.createCategory,
        body: data,
        otherToken: accessToken
    })

    dispatch(setPageLoader({ page: 'createCategory', flag: false }))
    if (response.success === true) {
        return (response)
    }
    else {
        error(response?.response)
    }

}