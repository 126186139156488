import { Modal, Upload } from 'antd';
import { Button, Checkbox, Col, Divider, Form, Image, Input, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import Nestle from "../../../Assets/Nestlesvg.svg";

import productNestle from "../../../Assets/Nestlesvg.svg";
import { useSelector } from "react-redux";
import {
    onAddToCart,
    removeFromCart,
    onResetCart
} from "../../API-Interaction/api-functions/cart.api";
import {
    onCreateOrder
} from "../../API-Interaction/api-functions/order.api";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Colors } from "../../Assets/Colors";
import { Images } from "../../Assets/Images/Images";
import { deleteSelectedDocument, updateOrder } from "../../Redux/features/cartSlice";
import { DeleteOutlined, LeftOutlined, LinkOutlined } from "@ant-design/icons";


const ViewOrderModal = ({ open, close }) => {
    const UploadDocument = useSelector((state) => state?.cart?.uploadedDocuments);
    const dispatch = useDispatch();
    const cart = useSelector((state) => state?.cart?.cartItems);
    const notes = useSelector((state) => state?.cart?.order);
    const token = localStorage.getItem("authTokenHaasil")
    const { pageLoader } = useSelector(state => state.utils.loaders)
    const navigate = useNavigate();

    const handleClick = (type, product, value) => {
        let { quantity } = product;
        if (type === "increment")
            onAddToCart(product, quantity + 1, dispatch, cart);
        if (type === "decrement" && quantity > 1)
            onAddToCart(product, quantity - 1, dispatch, cart);
        if (type === "decrement" && quantity === 1)
            removeFromCart(dispatch, product?.productId);
        if (type === "UserInput") {
            if (value == 0) {
            } else {
                onAddToCart(product, Number(value), dispatch, cart);
            }
        }
    };
    const onFinish = async (values) => {
        // dispatch(updateOrder({ data: note, key: 'notes' }))
        navigate("/request-for-quotation");
    };
    const removeItem = (item) => {
        removeFromCart(dispatch, item?.productId)
    }

    const CheckCart = () => {
        if (Object.keys(cart)?.length || UploadDocument.length !== 0) {

        } else {
            navigate('/daily-essentials')
        }
    }
    useEffect(() => {
        CheckCart()
    })
    return (
        <>
            <Modal open={open} footer={null} onCancel={close} className='h-400px overflow-hidden' style={{
                top: 20,
            }} >
                <Spin spinning={pageLoader.AddtoCart || false}>


                    <Row className="flex justify-between items-center">
                        <p className=" font-semibold text-gray-400 ">
                            <span>My Cart </span>(
                            <span>{Object.keys(cart)?.length} Items</span>)
                        </p>

                    </Row>
                    <Divider className="mt-[10px] mb-[10px]" />
                    <Form
                        name="basic"
                        initialValues={{
                            remember: false,
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="items"
                            rules={[
                                {
                                    required: false,
                                    message: "Please input your username!",
                                },
                            ]}
                        >
                            <p
                                className={`mb-[5px] w-full px-[4px] py-[5px]  support-heading text-[#44444f] 
                rounded-[5px] font-bold`}
                                style={{ background: Colors.grayBg }}
                            >
                                ITEMS & DESCRIPTION
                            </p>
                        </Form.Item>
                        <div className='max-h-[250px] overflow-y-scroll mt-[30px] px-[5px] mb-2'>

                            {Object.values(cart)?.map(
                                (product) => (
                                    <div className="flex flex-col gap-2 my-2 mb-2"
                                    >
                                        <Form.Item
                                            name="items"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Please input your username!",
                                                },
                                            ]}
                                        >
                                            <Row className="flex justify-between">
                                                <Col lg={13} xs={16}>
                                                    <div className="flex items-center gap-2">
                                                        <div
                                                            className="flex items-center border -1 py-1 rounded-md"
                                                        >
                                                            <img
                                                                src={product?.imageURLs ? product?.imageURLs[0] : Images.CameraUpload}
                                                                className="w-[40px] h-[40px] object-contain"
                                                                preview={false}
                                                            />

                                                        </div>
                                                        <p className="text-[13px] text-[#171725]" >
                                                            {product?.name}
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col lg={9} xs={8}>
                                                    <div className="flex items-center  ">
                                                        <Button
                                                            className={`w-full flex  items-center text-[white] rounded-[20px] text-[10px] p-[0] bg-[${Colors.darkBlue}]`}

                                                        >
                                                            <p
                                                                onClick={() =>
                                                                    handleClick("decrement", product)
                                                                }
                                                                className="h-full w-full flex items-center justify-center  "
                                                            >
                                                                -
                                                            </p>
                                                            <Input
                                                                type="Number"
                                                                onChange={(event) => {
                                                                    handleClick(
                                                                        "UserInput",
                                                                        product,
                                                                        event.target.value
                                                                    );
                                                                }}
                                                                productId="quantityInput"
                                                                className={`capitalize text-[white] border-none text-center bg-[${Colors.darkBlue}]`}
                                                                value={

                                                                    product?.quantity
                                                                }

                                                            />
                                                            <p
                                                                onClick={() =>
                                                                    handleClick("increment", product)
                                                                }
                                                                className="h-full w-full flex items-center  justify-center"
                                                            >
                                                                {" "}
                                                                +
                                                            </p>
                                                        </Button>
                                                        <DeleteOutlined onClick={() => { removeItem(product) }} className="text-[red] text-[16px] cursor-pointer" />

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </div>
                                )
                            )}
                            <p className='font-black text-[20px] my-[10px]'>Documents Uploaded</p>
                            {
                                UploadDocument.length !== 0 &&
                                <div
                                    className="flex flex-col gap-2  "
                                >
                                    {UploadDocument.map((item, index) => {
                                        return (
                                            <div className="flex justify-between px-[4px] box-border overflow-hidden">
                                                <div className="flex items-center gap-2  ">
                                                    <LinkOutlined className="text-[#1677ff]" />
                                                    <a className="text-[#1677ff] cursor-pointer w-[300px] whitespace-nowrap overflow-hidden text-ellipsis " href={item} >{item}</a>
                                                </div>
                                                <div>
                                                    <Button className="shadow-none border-none" onClick={() => { dispatch(deleteSelectedDocument(item)) }}>
                                                        <DeleteOutlined className="text-[red] " />
                                                    </Button>
                                                </div>
                                            </div>

                                        )
                                    })}
                                </div>}
                        </div>


                        <Form.Item
                            name={'note'}
                        >
                            <Input.TextArea
                                maxLength={255}
                                defaultValue={notes?.notes ? notes?.notes : ''}
                                onChange={(event) => {
                                    dispatch(updateOrder({ data: event.target.value, key: 'notes' }))
                                }}
                                className="border-none bg-[#E9EFF6] text-[12px] py-[20px] px-[15px]"

                                rows={3}
                                placeholder="Add Your Note"
                            />
                        </Form.Item>

                    </Form>
                </Spin>
            </Modal>
        </>
    );
};
export default ViewOrderModal;