import { Button, Table } from 'antd';
import { useState } from 'react';
import { getDateInProperFormat } from '../../helpers/helper';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../Assets/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { PrinterOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { getInvoiceById } from '../../API-Interaction/api-functions/invoice.api';
import InvoiceGenerator from '../InvoiceGenerator/InvoiceGenerator';
import { onAddToCart } from '../../API-Interaction/api-functions/cart.api';
import { getQuotationById } from '../../API-Interaction/api-functions/quotation.api';
import { success } from '../../helpers/notifications';



const InvoicesTable = ({ data, open, setOpen, setInvoiceData, HandleModal }) => {
  const [Data, setData] = useState()
  let dispatch = useDispatch()
  let token = localStorage.getItem('authTokenHaasil')
  const cart = useSelector((state) => state?.cart?.cartItems);



  const getOrderItem = async (id) => {
    const result = await getQuotationById(dispatch, id, token)
    if (result?.response.success) {
      let orderItems = result?.response?.order[0].OrderItems
      orderItems.forEach((item, index) => {
        onAddToCart(item?.Product, item?.quantity, dispatch, cart);

      })
      success('Order Items successfully added to cart')
    }
  }

  const navigate = useNavigate()
  const columns = [
    {
      title: 'Invoice ID',
      dataIndex: 'invoiceId',
      align: "center",
      render: (text, item) => <Button className='font-[800]' onClick={() => {  navigate(`/customer-quote/${item?.Order?.orderId}?type=view`)
      }} >
        I-00{text}
      </Button>, // Apply inline style

    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (data, item, index) => { let date = getDateInProperFormat(data); return <p className='font-[800]'>{date}</p> }
    },

    {
      title: 'Remarks',
      align: 'center',
      dataIndex: 'remarks',
      render: (text, data) => <span className='text-black font-[900]' >{data?.Order?.remarks ? data?.Order?.remarks : 'N-A'}</span>,

    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'center',
      render: (text) => <span className=' font-[900]'>Rs. {text ? text : "N-A"}</span>,

    },

    {
      title: 'Status',
      key: 'actions',
      dataIndex: 'TotalStatus',
      align: "center",
      render: () => (
        <>
          <Button
            className='font-[900] rounded-[14px] h-[32px] bg-[#0080001a] cursor-default text-[limegreen] ml-[10px]'
            onClick={() => { }}>Order Confirmed</Button>
        </>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'InvoiceGenerator',
      align: "center",
      render: (_, record) => {
        return <div className='flex justify-center gap-1'>
          <Button onClick={() => { HandlePrintInvoice(record.invoiceId) }} className='p-0 border-none  flex items-center justify-center w-fit' >
            <PrinterOutlined className={`text-[20px] text-[${Colors.darkBlue}] shadow-none`} />
          </Button>
          <Button className='flex items-center ' style={{ background: Colors.darkBlue, color: "white" }} loading={false} onClick={() => { getOrderItem(record.orderId) }}>
            Reorder  <ShoppingCartOutlined />
          </Button>
        </div>
      }
    }
  ];
  const HandlePrintInvoice = async (id) => {
    const response = await getInvoiceById(dispatch, token, id)
    if (response.success) {
      setInvoiceData(response?.response?.order[0])
      setData(response?.response?.order[0])
      HandleModal()
    }
  }
  return (
    <>

      <Table columns={columns} dataSource={data} className='w-[100%] overflow-x-scroll'  scroll={{y: 360}} />
      {/* Invoice Modal */}

    </>
  )
}

export default InvoicesTable