import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Input, Rate, Row } from "antd";
import {
  onAddToCart,
  removeFromCart,
} from "../../API-Interaction/api-functions/cart.api";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../Assets/Colors";
import { Images } from "../../Assets/Images/Images";
import { DeleteOutlined } from "@ant-design/icons";

const ProductListing = ({ product, view, setView, scroll }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state?.cart?.cartItems);
  const [count, setCount] = useState(0);
  const [userQuantity, setuserQuantity] = useState('false');

  //   Add to cart Item
  const handleAddToCart = async () => {
    setuserQuantity(1);
    onAddToCart(product, 1, dispatch, cart);
    setIsSubmitted(true);
    setCount((prev) => {
      return prev + 1;
    });
  };

  const handleClick = (type, value) => {
    let quantity = cart[product?.productId]?.quantity;
    if (type === "increment") {
      onAddToCart(product, Number(quantity + 1), dispatch, cart);
      setuserQuantity(quantity + 1);
    }
    if (type === "decrement" && Number(quantity) > 1) {
      onAddToCart(product, Number(quantity) - 1, dispatch, cart);
      setuserQuantity(Number(quantity) - 1);
    }

    if (type === "decrement" && quantity === 1) {
      removeFromCart(dispatch, product?.productId);
      setIsSubmitted(false);
    }
    if (type === "UserInput") {
      let userdefinedquantity = Number(value);
      setuserQuantity(userdefinedquantity);
      if (userdefinedquantity === 0) {
        setuserQuantity(userdefinedquantity);
      } else {
        onAddToCart(product, userdefinedquantity, dispatch, cart);
      }
    }
  };

  const removeItem = () => {
    removeFromCart(dispatch, product.productId)

  }

  return (
    <>
      {
        view === 'Grid' ?

          <div
            className="w-full flex flex-col gap-0"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
              borderRadius: "20px",
              overflow: "hidden"

            }}
          >
            <div
              className=" flex flex-col justify-between"
              style={{
                border: "1px solid lightgray",
                width: "100%",
                height: "200px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px"
              }}
            >
              <div className="flex justify-center h-full items-center">
                <Image
                  className="h-[70px] w-[70px]"
                  src={product?.imageURLs ? product?.imageURLs[0] : Images.HasilInvoiceLogo}
                  // src={Images?.CameraUpload}
                  style={{ objectFit: "contain", width: "90px" }}
                />
              </div>
              <div className="flex flex-col p-2 w-[100%] gap-1">


                <p className="font-bold " style={{
                  fontSize: "12px", whiteSpace: 'nowrap',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: "ellipsis",
                }}>
                  {product?.name}
                </p>

                {/* <p style={{
                  fontSize: "10px", whiteSpace: 'nowrap',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: "ellipsis",
                  color: "gray"
                }}>{product?.internalReference ? product?.internalReference : "SKU"}</p> */}

                <p style={{
                  fontSize: "9px", whiteSpace: 'nowrap',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: "ellipsis",
                  color: "gray"
                }}>
                  {product?.productCategoryL8?.name ? product?.productCategoryL8?.name : ""}
                </p>



                <p className="p-0" style={{
                  fontSize: "9px", whiteSpace: 'nowrap',
                  width: 'fit-content',
                  overflow: 'hidden',
                  textOverflow: "ellipsis",
                  color: "gray",
                  fontWeight: "700",
                  textAlign: 'center',
                  borderRadius: "10px",
                  color: Colors.Lightblue

                }}>{product?.productCategoryL5?.name ? product?.productCategoryL5?.name : "Manufacturer : N-A"}</p>
              </div>
            </div>
            <div
              className=" px-[15px] py-[10px]"
              style={{
                borderRadius: "5px",
              }}
            >
              {cart[product?.productId]?.quantity > 0 ? (
                <div className="flex items-center gap-2">

                  <Button
                    className="w-full flex gap-2 items-center"
                    style={{
                      background: Colors.darkBlue,
                      color: "white",
                      borderRadius: "20px",
                      fontSize: "10px",
                      padding: "0px",
                    }}
                  >
                    <p
                      onClick={() => handleClick("decrement")}
                      className="h-full w-full flex items-center justify-center  "
                    >-
                    </p>
                    <Input
                      type="Number"
                      onChange={(event) => {
                        handleClick("UserInput", event.target.value);
                      }}
                      id="quantityInput"
                      value={
                        cart[product.productId]?.quantity
                      }
                      style={{
                        background: Colors.darkBlue,
                        color: "white",
                        textAlign: "center",
                        border: "none",
                      }}
                    />


                    <p
                      onClick={() => handleClick("increment")}
                      className="h-full w-full flex items-center justify-center"
                    >
                      +
                    </p>
                  </Button>
                  {/* Delete Icon */}
                  <DeleteOutlined onClick={() => { removeItem() }} className="text-[red] text-[16px] cursor-pointer" />
                </div>
              ) : (
                <Button
                  onClick={handleAddToCart}
                  className="w-full"
                  style={{
                    background: Colors.darkBlue,
                    color: "white",
                    borderRadius: "20px",
                    fontSize: "11px",
                  }}
                >
                  Add to cart
                </Button>
              )}
            </div>
          </div >
          : view === 'Table' ?

            <Row className="w-full flex py-1 items-center justify-between gap-2"
              style={{
                // boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                borderRadius: "5px",
                borderBottom: "1px solid lightgray"
              }}
            >
              <Col md={15} xs={24} >
                <div className="flex  items-center w-full gap-2">
                  <div className="p-[1px] overflow-hidden rounded-[15px] h-[60px] w-[60px] flex justify-center items-center" style={{
                    // border: "1px solid black",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                  }}>
                    <Image
                      className="h-[30px] w-[30px] object-contain"
                      src={product?.imageURLs ? product?.imageURLs[0] : Images.HasilInvoiceLogo}
                    // src={Images?.CameraUpload}
                    />
                  </div>
                  <div className="flex flex-col " >

                    <p className="font-bold whitespace-nowrap overflow-hidden w-[250px] text-ellipsis" style={{ fontSize: "12px" }}>
                      {product?.name}
                    </p>

                    <p style={{
                      fontSize: "9px", whiteSpace: 'nowrap',
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: "ellipsis",
                      color: "gray"
                    }}><span style={{ fontWeight: "700" }}>Variant : </span> {product?.productCategoryL8?.name ? product?.productCategoryL8
                      ?.name : "N/A"}</p>
                    <p className="p-0" style={{
                      fontSize: "9px", whiteSpace: 'nowrap',
                      width: 'fit-content',
                      overflow: 'hidden',
                      textOverflow: "ellipsis",
                      color: "gray",
                      fontWeight: "700",
                      textAlign: 'center',
                      borderRadius: "10px",
                      color: Colors.Lightblue

                    }}>{product?.productCategoryL5?.name ? product?.productCategoryL5?.name : "Manufacturer : N-A"}</p></div>

                </div>
              </Col>




              <Col xs={24} md={8} className="flex  justify-end">
                {cart[product.productId]?.quantity > 0 ? (
                  <div className="flex items-center gap-2">

                    <Button
                      className="w-full flex gap-2 items-center"
                      style={{
                        background: Colors.darkBlue,
                        color: "white",
                        borderRadius: "20px",
                        fontSize: "10px",
                        padding: "0px",
                      }}
                    >
                      <p
                        onClick={() => handleClick("decrement")}
                        className="h-full w-full flex items-center justify-center  "
                      >
                        -
                      </p>
                      <Input
                        type="Number"
                        onChange={(event) => {
                          handleClick("UserInput", event.target.value);
                        }}
                        id="quantityInput"
                        value={
                          userQuantity !== "false"
                            ? userQuantity
                            : cart[product.productId]?.quantity
                        }
                        style={{
                          background: Colors.darkBlue,
                          color: "white",
                          textAlign: "center",
                          border: "none",
                        }}
                      />
                      <p
                        onClick={() => handleClick("increment")}
                        className="h-full w-full flex items-center justify-center"
                      >
                        +
                      </p>
                    </Button>
                    {/* Delete Icon */}
                    <DeleteOutlined onClick={() => { removeItem() }} className="text-[red] text-[16px] cursor-pointer" />
                  </div>
                ) : (
                  <Button
                    onClick={handleAddToCart}
                    className="w-[50%]"
                    style={{
                      background: Colors.darkBlue,
                      color: "white",
                      borderRadius: "20px",
                      fontSize: "10px",
                    }}
                  >
                    Add to cart
                  </Button>
                )}
              </Col>
            </Row>
            :
            // Amazon Grid View
            <div
              className="w-full flex flex-col gap-0"
              style={{
                // boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                borderRadius: "20px",
                overflow: "hidden"

              }}
            >
              <div
                className=" flex flex-col justify-between"
                style={{
                  width: "100%",
                  height: "fit-content",
                }}
              >
                <div className="flex justify-center h-full items-center bg-[#f7f7f7]">
                  <Image
                    src={product?.imageURLs ? product?.imageURLs[0] : Images.HasilInvoiceLogo}
                    // src={Images?.CameraUpload}
                    style={{ objectFit: "contain", height: "200px", width: "250px" }}
                  />
                </div>
                <div className="flex flex-col p-2 w-[100%] gap-1">
                  <p className="text-[16px] w-[100%] h-[50px] overflow-hidden" >
                    {product?.name}
                  </p>

                  {/* <p className="text-[14px] whitespace-nowrap w-[100%] overflow-hidden text-ellipsis text-[gray]"  >{product?.internalReference ? product?.internalReference : "SKU"}</p> */}
                  <p style={{
                    fontSize: "13px", whiteSpace: 'nowrap',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: "ellipsis",
                    height: "20px",
                    color: "gray"
                  }}>
                    {product?.productCategoryL8?.name ? product?.productCategoryL8?.name : ""}
                  </p>


                  {/* <Rate defaultValue={5} color={'orange'} className="text-[orange]" /> */}

                  <p className={`p-0 text-[16px] whitespace-nowrap  overflow-hidden text-ellipsis  w-fit text-[#0A789A] font-[900]`} >{product?.productCategoryL5?.name ? product?.productCategoryL5?.name : "Manufacturer : N-A"}</p>
                </div>
              </div>
              <div
                className=" px-[15px] py-[10px]"
                style={{
                  borderRadius: "5px",
                }}
              >
                {cart[product?.productId]?.quantity > 0 ? (
                  <div className="flex items-center gap-2">

                    <Button
                      className="w-full flex gap-2 items-center"
                      style={{
                        background: Colors.darkBlue,
                        color: "white",
                        borderRadius: "20px",
                        fontSize: "10px",
                        padding: "0px",
                      }}
                    >
                      <p
                        onClick={() => handleClick("decrement")}
                        className="h-full w-full flex items-center justify-center  "
                      >
                        -
                      </p>
                      <Input
                        type="Number"
                        onChange={(event) => {
                          handleClick("UserInput", event.target.value);
                        }}
                        id="quantityInput"
                        value={
                          userQuantity !== "false"
                            ? userQuantity
                            : cart[product.productId]?.quantity
                        }
                        style={{
                          background: Colors.darkBlue,
                          color: "white",
                          textAlign: "center",
                          border: "none",
                        }}
                      />
                      <p
                        onClick={() => handleClick("increment")}
                        className="h-full w-full flex items-center justify-center"
                      >
                        +
                      </p>
                    </Button>
                    {/* Delete Icon */}
                    <DeleteOutlined onClick={() => { removeItem() }} className="text-[red] text-[16px] cursor-pointer" />
                  </div>

                ) : (
                  <Button
                    onClick={handleAddToCart}
                    className={`w-full bg-[${Colors.darkBlue}] text-[white] rounded-[20px] text-[14px] `}>
                    Add to cart
                  </Button>
                )}
              </div>
            </div >
      }
    </>
  );
};

export default ProductListing;

