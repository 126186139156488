import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Upload } from 'antd';
import React, { useState } from 'react'
import { Images } from '../../../Assets/Images/Images';
import { message, Spin } from "antd";
import { CloseCircleOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Colors } from '../../../Assets/Colors';
import { useEffect } from 'react';
import { getAllProductCategoryWithLevel, getProductByCategoryId, getProductCategory } from '../../../API-Interaction/api-functions/category.api';
import { useDispatch, useSelector } from 'react-redux';
import SpinFC from 'antd/es/spin';
import { formErrors } from '../../../../constant';
import FormItem from 'antd/es/form/FormItem';
import { error } from '../../../helpers/notifications';
import { onCreateProduct, onUpdateProductById } from '../../../API-Interaction/api-functions/product.api';
import { Cancel } from '@mui/icons-material';












const ManageProductModal = ({ open, close, type, data }) => {
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const { pageLoader } = useSelector((state) => state.utils.loaders);
    const [images, setImages] = useState('')
    const [level1, setlevel1] = useState([])
    const [level2, setlevel2] = useState([])
    const [level3, setlevel3] = useState([])
    const [level4, setlevel4] = useState([])
    const [level5, setlevel5] = useState([])
    const [level6, setlevel6] = useState([])
    const [level7, setlevel7] = useState([])
    const [level8, setlevel8] = useState([])
    const [level9, setlevel9] = useState([])
    const [level10, setlevel10] = useState([])
    const [visibility, setVisibility] = useState(true)
    const [form] = Form.useForm()


    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (file) => {

        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("File must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    };

    // Handle Image Change
    const handleChange = (info) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        console.log('hello world ', info)
        setImages(info.file.originFileObj)

        getBase64(info.file.originFileObj, (url) => {
            setLoading(false);
            setImageUrl(url);
        });
    };

    // Custom Upload Button
    const uploadButton = (
        <div className='flex justify-center items-center h-full flex-col'>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const HandleCategoryChange = (value, type) => {
        form.setFieldsValue({
            'L2': null
        })
        getCategory('L2', value)

    };

    const getCategory = async (level, MainCategoryId) => {
        const result = await getProductCategory(dispatch, token, MainCategoryId, level)
        if (result?.success) {
            let filterData = result?.response?.productCategory.map((item, index) => {
                return {
                    key: `${item?.categoryId}`,
                    value: item?.categoryId,
                    label: item?.name
                }
            })
            setlevel2(filterData)
        }
    }
    const getCategoryWithLevels = async (level) => {
        const response = await getAllProductCategoryWithLevel(dispatch, token)
        if (response?.success) {
            setlevel1(response?.response?.products[0])
            setlevel3(response?.response?.products[2])
            setlevel4(response?.response?.products[3])
            setlevel5(response?.response?.products[4])
            setlevel6(response?.response?.products[5])
            setlevel7(response?.response?.products[6])
            setlevel8(response?.response?.products[7])
            setlevel9(response?.response?.products[8])
            setlevel10(response?.response?.products[9])
        }
    }

    const HandleProduct = async (values) => {
        let formData = new FormData()
        formData.append('images', images)
        formData.append('name', values.name)
        formData.append('description', values.description ? values.description : null)
        formData.append('price', values.price ? values.price : null)
        formData.append('GST', values.GST ? values.GST : null)
        formData.append('visibility', values.visibility)
        formData.append('L1', values.L1)
        formData.append('L2', values.L2)
        formData.append('L3', null)
        formData.append('L4', values.L4)
        formData.append('L5', values.L5)
        formData.append('L6', values.L6 || 'null')
        formData.append('L7', values.L7 || 'null')
        formData.append('L8', values.L8 || 'null')
        formData.append('L9', values.L9 || 'null')
        formData.append('L10', values.L10 || 'null')
        if (type === 'Update') {
            const response = await onUpdateProductById(dispatch, token, formData, data?.productId)
            if (response?.success) {
                close('closeMOdal')
            }
        }
        if (type === 'Create') {

            const response = await onCreateProduct(dispatch, token, formData)
            if (response?.success) {
                close()
            }
        }
        if (type === 'Duplicate') {

            const response = await onCreateProduct(dispatch, token, formData)
            if (response?.success) {
                close()
            }
        }
    }


    useEffect(() => {
        if (open && type === 'Create') {
            getCategoryWithLevels("")
        }
        else {
            getCategoryWithLevels("")
            getCategory('L2', data?.productCategoryL1?.categoryId)
            form.setFieldsValue({
                'name': data?.name,
                'description': data?.description,
                'price': data?.price ? data?.price : null,
                'GST': data?.GST ? data?.GST : null,
                'visibility': data?.visibility ? data.visibility : false,
                'L1': data?.L1 ? level1?.find(obj => obj.value == data?.L1)?.value : null,
                'L2': data?.L2 ? level2?.find(obj => obj.value === data?.L2)?.value : null,
                'L3': data?.L3 ? level3?.find(obj => obj.value === data?.L3)?.value : null,
                'L4': data?.L4 ? level4?.find(obj => obj.value === data?.L4)?.value : null,
                'L5': data?.L5 ? level5?.find(obj => obj.value === data?.L5)?.value : null,
                'L6': data?.L6 ? level6?.find(obj => obj.value === data?.L6)?.value : null,
                'L7': data?.L7 ? level7?.find(obj => obj.value === data?.L7)?.value : null,
                'L8': data?.L8 ? level8?.find(obj => obj.value === data?.L8)?.value : null,
                'L9': data?.L9 ? level9?.find(obj => obj.value === data?.L9)?.value : null,
                'L10': data?.L10 ? level10?.find(obj => obj.value === data?.L10)?.value : null,
            });
            if (data?.imageURLs) {
                setImageUrl(data?.imageURLs[0])
                setImages(data?.imageURLs[0])
            } else {
                setImageUrl('')
                setImages('')

            }
        }
    }, [open])
    return (

        <div >
            <Modal
                centered
                open={open}
                style={{ margin: "0px", padding: "10px", }}
                closeIcon={null}
                // onCancel={close}
                footer={null}
                width={600}
            >
                <div className='absolute top-0 right-0 p-5  bg-white z-[99999]' >
                    <CloseCircleOutlined onClick={close} className='text-[black] cursor-pointer text-[20px] bg-[white] ' />
                </div>

                <Spin spinning={pageLoader?.GetCategoryWithLevel || false}>

                    {/* Upload Image Button */}
                    <div className='w-full flex justify-center '>
                        <div style={{}}>
                            <div className="view-profile-cont">
                                <Upload
                                    name="avatar"
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                    style={{
                                        overflow: "hidden",
                                        height: "50px !important",
                                    }}
                                >
                                    <div
                                        className="h-full w-full"
                                        style={{ position: "relative" }}
                                    >
                                        {imageUrl ? (
                                            <div
                                                className="h-full "
                                                style={{ position: "relative" }}
                                            >
                                                <img
                                                    preview={false}
                                                    src={imageUrl ? imageUrl : Images.DisplayPic}
                                                    alt="avatar"
                                                    style={{
                                                        objectFit: "fill",
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        overflow: "hidden",
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            uploadButton
                                        )}

                                        <div
                                            style={{
                                                position: "absolute",
                                                right: "2px",
                                                bottom: "5px",
                                                zIndex: "99999",
                                                height: "30px",
                                                width: "30px",
                                                background: "#F3EBEB",
                                                padding: "5px",
                                                borderRadius: "50%  ",
                                            }}
                                        >
                                            {" "}
                                            <img
                                                preview={false}
                                                src={Images.CameraUpload}
                                                alt="avatar"
                                                style={{
                                                    objectFit: "contain",
                                                    width: "100%",
                                                    height: "100%",
                                                    overflow: "hidden",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Upload>
                            </div>
                        </div>
                    </div>


                    <Divider style={{ margin: "3px" }} />
                    {/* Select Category  */}
                    <div className='p-2 w-full' style={{ borderRadius: '5px', position: "relative", overflow: "hidden" }}>
                        <Form
                            name='productData'
                            onFinish={HandleProduct}
                            form={form}


                        >
                            <div style={{ zIndex: "111111", display: 'flex', position: "relative", width: '100%' }}

                            >

                                <Row className='w-full' style={{ zIndex: '999999' }} >
                                    <Col span={24} className='flex flex-col gap-1 mt-2'>
                                        <p className='font-bold' style={{ fontSize: "12px" }}>Name:</p>
                                        <Form.Item name={'name'} rules={[
                                            {
                                                required: true,
                                                message: formErrors?.emptyField,
                                            }

                                        ]
                                        }>

                                            <Input placeholder='Enter Name' style={{ background: "white", boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }} />
                                        </Form.Item>
                                    </Col>
                                    {/* <Col span={24} className='flex flex-col gap-1 mt-2'>
                                        <p className='font-bold' style={{ fontSize: "12px" }}>Description</p>
                                        <Form.Item name={'description'} rules={[
                                            {
                                                required: false,
                                                message: formErrors?.emptyField
                                            }
                                        ]}>

                                            <Input.TextArea placeholder='Enter Description' rows={2} style={{ background: "white", boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }} />
                                        </Form.Item>
                                    </Col> */}
                                    <Col span={24} className='flex flex-col gap-1 mt-2'>
                                        <p className='font-bold' style={{ fontSize: "12px" }}>Description</p>
                                        <Form.Item name={'description'} rules={[{ required: false, message: formErrors?.emptyField }]}>
                                            <Input.TextArea placeholder='Description' rows={2} style={{ background: "white", boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }} maxLength={250} />
                                        </Form.Item>
                                    </Col>

                                    {/* Visibility --------------------> */}

                                    <Col span={8} className='flex flex-col  p-2'>
                                        <p className='font-bold' style={{ fontSize: "12px" }}>visibility</p>
                                        <Form.Item name={'visibility'} rules={[
                                            {
                                                required: false,
                                                message: formErrors?.emptyField
                                            }
                                        ]}>

                                            <Select
                                                placeholder="Set Visibility"

                                                size='middle'
                                                style={{
                                                    width: "100%",
                                                    fontSize: "12px"
                                                }}
                                                options={[

                                                    {
                                                        value: true,
                                                        label: 'Visible',
                                                    },
                                                    {
                                                        value: false,
                                                        label: 'Hidden',
                                                    }]}
                                            />
                                        </Form.Item>
                                    </Col>

                                    {/* Price and GsT------------> */}


                                    <Col span={8} className='flex flex-col  p-2'>
                                        <p className='font-bold' style={{ fontSize: "12px" }}>Price</p>
                                        <Form.Item name={'price'} rules={[
                                            {
                                                required: false,
                                                message: formErrors?.emptyField
                                            }
                                        ]}>

                                            <Input type='number' placeholder='Enter Price' style={{ background: "white", boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className='flex flex-col p-2'>
                                        <p className='font-bold' style={{ fontSize: "12px" }}>GST</p>
                                        <Form.Item name={'GST'} rules={[
                                            {
                                                required: false,
                                                message: formErrors?.emptyField,
                                            }
                                        ]}>

                                            <Input type='number' placeholder='GST %' style={{ background: "white", boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }} />
                                        </Form.Item>
                                    </Col>

                                    {/* {type === 'Create' &&  */}
                                    {
                                        <Row className='w-full'>
                                            <Col span={12} className='flex flex-col gap-1 mt-2 p-2'>
                                                <p className='font-bold' style={{ fontSize: "12px" }}>L1 - Category <strong style={{ color: 'red' }}>*</strong></p>
                                                <Form.Item name={'L1'} rules={[
                                                    {
                                                        required: true,
                                                        message: formErrors?.emptyField
                                                    }
                                                ]}>

                                                    <Select
                                                        showSearch
                                                        placeholder="Select Main Category"
                                                        size='middle'
                                                        style={{
                                                            width: "100%",
                                                            fontSize: "12px"
                                                        }}
                                                        onChange={(event) => { HandleCategoryChange(event, "Main Category") }}
                                                        optionFilterProp="children"

                                                        filterOption={(input, option) => (
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        )}
                                                        filterSort={(optionA, optionB) => (
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        )}
                                                        options={level1}
                                                    />
                                                </Form.Item>

                                            </Col>
                                            <Col span={12} className='flex flex-col gap-1 mt-2 p-2'>
                                                <p className='font-bold' style={{ fontSize: "12px" }}>L2 - Deep Category <strong style={{ color: 'red' }}>*</strong></p>
                                                <Spin spinning={pageLoader?.getProductCategory || false}>
                                                </Spin>
                                                <Form.Item name={'L2'} rules={[
                                                    {
                                                        required: true,
                                                        message: formErrors?.emptyField
                                                    }
                                                ]}>
                                                    <Select
                                                        showSearch
                                                        placeholder="Select Sub Category"
                                                        size='middle'
                                                        style={{
                                                            width: "100%",
                                                            fontSize: "12px"
                                                        }}
                                                        // onChange={(event) => {  }}
                                                        optionFilterProp="children"

                                                        filterOption={(input, option) => (
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        )}
                                                        filterSort={(optionA, optionB) => (
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        )}
                                                        options={level2}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            {/* <Col lg={12} xs={24} className='flex flex-col  mt-1 p-2'>

                                                <p className='font-bold' style={{ fontSize: "12px" }}>Storablee</p>
                                                <Form.Item name={'L3'} rules={[{
                                                    required: false,
                                                    message: formErrors?.emptyField
                                                }]}>

                                                    <Select
                                                        showSearch
                                                        size='middle'
                                                        className='w-full'
                                                        style={{ fontSize: "6px" }}

                                                        optionFilterProp="children"
                                                        // filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                        // filterSort={(optionA, optionB) =>
                                                        //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        // }
                                                        filterOption={(input, option) => (
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        )}
                                                        filterSort={(optionA, optionB) => (
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        )}
                                                        options={level3}
                                                    />
                                                </Form.Item>

                                            </Col> */}
                                            <Col lg={12} xs={24} className='flex flex-col  mt-1 p-2'>

                                                <p className='font-bold' style={{ fontSize: "12px" }}>L3 - Product Type <strong style={{ color: 'red' }}>*</strong></p>
                                                <Form.Item name={'L4'} rules={[
                                                    {
                                                        required: true,
                                                        message: formErrors?.emptyField
                                                    }
                                                ]}>

                                                    <Select
                                                        showSearch
                                                        size='middle'
                                                        className='w-full'
                                                        style={{ fontSize: "6px" }}

                                                        optionFilterProp="children"

                                                        filterOption={(input, option) => (
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        )}
                                                        filterSort={(optionA, optionB) => (
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        )}
                                                        options={level4}
                                                    />
                                                </Form.Item>

                                            </Col>
                                            <Col lg={12} xs={24} className='flex flex-col  mt-1 p-2'>

                                                <p className='font-bold' style={{ fontSize: "12px" }}>L4 - Manufacturer <strong style={{ color: 'red' }}>*</strong></p>
                                                <Form.Item name={'L5'} rules={[
                                                    {
                                                        required: true,
                                                        message: formErrors?.emptyField
                                                    }
                                                ]}>

                                                    <Select
                                                        showSearch
                                                        size='middle'
                                                        className='w-full'
                                                        style={{ fontSize: "6px" }}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => (
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        )}
                                                        filterSort={(optionA, optionB) => (
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        )}
                                                        options={level5}
                                                    />

                                                </Form.Item>

                                            </Col>
                                            <Col lg={12} xs={24} className='flex flex-col  mt-1 p-2'>

                                                <p className='font-bold' style={{ fontSize: "12px" }}>L5 - Brand <strong style={{ color: 'red' }}>*</strong></p>
                                                <Form.Item name={'L6'} rules={[
                                                    {
                                                        required: false,
                                                        message: formErrors?.emptyField
                                                    }
                                                ]}>

                                                    <Select
                                                        showSearch
                                                        size='middle'
                                                        className='w-full'
                                                        style={{ fontSize: "6px" }}

                                                        optionFilterProp="children"

                                                        filterOption={(input, option) => (
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        )}
                                                        filterSort={(optionA, optionB) => (
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        )}
                                                        options={level6}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} xs={24} className='flex flex-col  mt-1 p-2'>

                                                <p className='font-bold' style={{ fontSize: "12px" }}>L6 - Sub-Brand</p>
                                                <Form.Item name={'L7'} rules={[
                                                    {
                                                        required: false,
                                                        message: formErrors?.emptyField
                                                    }
                                                ]}>

                                                    <Select
                                                        showSearch
                                                        size='middle'
                                                        className='w-full'
                                                        style={{ fontSize: "6px" }}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => (
                                                            option && option.label.toLowerCase().includes(input.toLowerCase())
                                                        )}
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                                                        }
                                                        options={level7}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} xs={24} className='flex flex-col  mt-1 p-2'>

                                                <p className='font-bold' style={{ fontSize: "12px" }}>L7 - Variant</p>
                                                <Form.Item name={'L8'} rules={[
                                                    {
                                                        required: false,
                                                        message: formErrors?.emptyField
                                                    }
                                                ]}>

                                                    <Select
                                                        showSearch
                                                        size='middle'
                                                        className='w-full'
                                                        style={{ fontSize: "6px" }}

                                                        optionFilterProp="children"

                                                        filterOption={(input, option) => (
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        )}
                                                        filterSort={(optionA, optionB) => (
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        )}
                                                        options={level8}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} xs={24} className='flex flex-col  mt-1 p-2'>

                                                <p className='font-bold' style={{ fontSize: "12px" }}>UOM</p>
                                                <Form.Item name={'L9'} rules={[
                                                    {
                                                        required: false,
                                                        message: formErrors?.emptyField
                                                    }
                                                ]}>

                                                    <Select
                                                        showSearch
                                                        size='middle'
                                                        className='w-full'
                                                        style={{ fontSize: "6px" }}

                                                        optionFilterProp="children"

                                                        filterOption={(input, option) => (
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        )}
                                                        filterSort={(optionA, optionB) => (
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        )}
                                                        options={level9}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} xs={24} className='flex flex-col  mt-1 p-2'>

                                                <p className='font-bold' style={{ fontSize: "12px" }}>Tier</p>
                                                <Form.Item name={'L10'} rules={[
                                                    {
                                                        required: false,
                                                        message: formErrors?.emptyField
                                                    }
                                                ]}>

                                                    <Select
                                                        showSearch
                                                        size='middle'
                                                        className='w-full'
                                                        style={{ fontSize: "6px" }}

                                                        optionFilterProp="children"

                                                        filterOption={(input, option) => (
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        )}
                                                        filterSort={(optionA, optionB) => (
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        )}
                                                        options={level10}
                                                    />
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                    }



                                </Row>
                            </div>
                            {/* <div className='flex justify-end'> */}
                            {
                                type === 'Create' ? (
                                    <div className='flex gap-2'>

                                        <Button loading={pageLoader?.createProduct || false} htmlType="submit"
                                            style={{ background: Colors?.darkBlue, color: "white", fontWeight: "800" }}>
                                            Create Product
                                        </Button>
                                        <Button onClick={close}
                                            className='shadow-md'
                                            style={{ color: Colors?.darkBlue, fontWeight: "800" }}>
                                            Cancel
                                        </Button>
                                    </div>
                                ) : (type === 'Update' ? (
                                    <div className='flex gap-2 items-center'>

                                        <Button loading={pageLoader?.updateProductById || false} htmlType="submit"
                                            style={{ background: Colors?.darkBlue, color: "white", fontWeight: "800" }}>
                                            Update Product
                                        </Button>
                                        <Button onClick={close}
                                            className='shadow-md'
                                            style={{ color: Colors?.darkBlue, fontWeight: "800" }}>
                                            Cancel
                                        </Button>
                                    </div>
                                ) : (
                                    <div className='flex gap-2'>

                                        <Button loading={pageLoader?.createProduct || false} htmlType="submit"
                                            style={{ background: Colors?.darkBlue, color: "white", fontWeight: "800" }}>
                                            Create Product
                                        </Button>
                                        <Button onClick={close}
                                            className='shadow-md'
                                            style={{ color: Colors?.darkBlue, fontWeight: "800" }}>
                                            Cancel
                                        </Button>
                                    </div>
                                ))

                            }
                            {/* </div>   */}

                        </Form>

                        <div style={{ position: "absolute", bottom: "-30px", height: "200px", width: "200px", right: "-50px", background: 'rgb(6 30 58 / 8%)', zIndex: '1', borderRadius: "50%", }}>

                        </div>
                    </div>


                </Spin >


            </Modal >
        </div >
    )
}

export default ManageProductModal