import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import Typography from "@mui/material/Typography";
import { Colors } from "../../Assets/Colors";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row, Spin } from "antd";
import { Typography } from "antd";
import SessionLayout from "../../Components/Sessionlayout.js/SessionLayout";
import { onVerifyOTPSubmit, onSendResendOTP } from "../../API-Interaction/api-functions/login.api"
import DynamicButton from "../../Layout/dynamics/button.dynamic";
import OtpInput from "react-otp-input";
import { error } from "../../helpers/notifications";
import { useEffect } from "react";

export default function Verifyotp() {

  const { pageLoader } = useSelector(state => state.utils.loaders)
  const { token } = useSelector(state => state.auth.accessTokens)
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const HandleVerification = async (value) => {
    if (otp.length == 5) {

      let body = {
        OTP: otp
      }
      await onVerifyOTPSubmit(dispatch, body, navigate, token)
    } else {
      error('Kindly enter valid otp!')
    }

  };

  const resendOtp = async (value) => {
    await onSendResendOTP(dispatch, token, navigate)
  };

  useEffect(() => {
    const c_OTP = localStorage.getItem('OTP')
    if (!c_OTP) navigate('/forget-password')
  })

  return (
    <SessionLayout>
      <Spin spinning={pageLoader?.otpVerify || pageLoader?.resentOtp || false} >
        <Form name="verification" onFinish={HandleVerification}>
          <Row className="w-full flex justify-center  ">
            <Row className="w-full flex justify-center mb-10">
              <Col
                span={18}
                className="text-center"
                style={{ fontSize: "30px !important" }}
              >
                <Typography.Text
                  className="large-font header-heading"
                  style={{ fontWeight: "900" }}
                >
                  Verification
                </Typography.Text>
              </Col>
              <Col
                span={12}
                className="text-center "
                style={{ fontSize: "30px !important" }}
              >
                <Typography.Text
                  className="text-xs support-heading"
                  style={{ color: Colors.grayTxt }}
                >
                  Enter the code from the email we just sent you
                </Typography.Text>
              </Col>
            </Row>
            <Row className="w-full flex justify-center gap-3">
              <Col lg={12} xs={20} className="mb-5">

                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={5}
                  containerStyle={{ gap: "10px", justifyContent: "flex-start" }}
                  renderSeparator={false}
                  inputType={'tel'}
                  inputStyle={{ width: "100%", height: "3.9em", borderRadius: "1px", border: "1px solid lightgray", background: '#E9EFF6', padding: "4px 3px", color: "#0B779C" }}
                  renderInput={(props) => <input
                    {...props} />}
                />
              </Col>
            </Row>

            {/* <Row className="w-full flex justify-center">
              <Col lg={12} xs={20} className="mb-5">
                <Row className="w-full grid grid-cols-5 gap-4">
                  <Form.Item
                    name={"Otp1"}
                    rules={[{ required: true, message: false }]}
                  >
                    <Input
                      maxLength={1}
                      // type="Number"
                      className={`px-3 py-4 font-bold text-[#0B779C] text-center border-[${Colors.borderColor}] rounded-sm mb-0 bg-[${Colors.inputBg}]`}
                    />
                  </Form.Item>
                  <Form.Item
                    name={"Otp2"}
                    rules={[{ required: true, message: false }]}
                  >
                    <Input
                      maxLength={1}
                      type="Number"
                      className={`px-3 py-4 font-bold text-[#0B779C] text-center border-[${Colors.borderColor}] rounded-sm mb-0 bg-[${Colors.inputBg}]`}
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={"Otp3"}
                    rules={[{ required: true, message: false }]}
                  >
                    <Input
                      maxLength={1}
                      type="Number"
                      className={`px-3 py-4 font-bold text-[#0B779C] text-center border-[${Colors.borderColor}] rounded-sm mb-0 bg-[${Colors.inputBg}]`}
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={"Otp4"}
                    rules={[{ required: true, message: false }]}
                  >
                    <Input
                      maxLength={1}
                      type="Number"
                      className={`px-3 py-4 font-bold text-[#0B779C] text-center border-[${Colors.borderColor}] rounded-sm mb-0 bg-[${Colors.inputBg}]`}
                    />
                  </Form.Item>
                  <Form.Item
                    name={"Otp5"}
                    rules={[{ required: true, message: false }]}
                  >
                    <Input
                      maxLength={1}
                      type="Number"
                      className={`px-3 py-4 font-bold text-[#0B779C] text-center border-[${Colors.borderColor}] rounded-sm mb-0 bg-[${Colors.inputBg}]`}
                    />
                  </Form.Item>
                </Row>
              </Col>
            </Row> */}
          </Row>

          {/* Next Button */}

          <Row className="w-full flex justify-center">
            <Col lg={12} xs={20}>
              <DynamicButton className="w-full" type={2} text="Next" />
            </Col>
            <Row className="w-full flex justify-center">
              <Col lg={12} xs={20} className="text-center mt-3">
                <Typography.Text
                  className="text-xs"
                  style={{ color: Colors.grayTxt }}
                >
                  Didn't receive a code?{" "}
                  <span
                    className="cursor-pointer"
                    style={{ color: Colors.brightBlue }}
                    onClick={resendOtp}
                  >
                    resend
                  </span>
                </Typography.Text>
              </Col>
            </Row>
          </Row>
        </Form>
      </Spin>
    </SessionLayout>
  );
}
