
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBc6fH5S2EfUt76zhUzE6Up76T6P0NEywI",
  authDomain: "haasil-app.firebaseapp.com",
  projectId: "haasil-app",
  storageBucket: "haasil-app.appspot.com",
  messagingSenderId: "809828395416",
  appId: "1:809828395416:web:28ac067488851291cf63ed",
  measurementId: "G-4HP9WW1S8W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app;
