import React, { useEffect, useState } from 'react'
import Layout from '../../../Layout/Layout'
import Appbar from '../../../Components/Appbar/Appbar'
import { Button, Col, Divider, Dropdown, Input, Row, Space, Spin, Typography } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import ProductListing from '../../../Components/Admin/ProductListing/ProductListing';
import ManageProductModal from '../../../Components/Admin/Modals/Manage-Product-Modal';
import { Colors } from '../../../Assets/Colors';
import { onFetchProducts, onGetProdcutByName } from '../../../API-Interaction/api-functions/product.api';
import { useDispatch, useSelector } from 'react-redux';
import PaginationComponent from '../../../Components/Pagination/PaginationComponent';
import { debounce } from '../../../helpers/helper';
import { DownOutlined } from '@ant-design/icons';
import { getProductCategory } from '../../../API-Interaction/api-functions/category.api';
import ManageCategoriesModal from '../../../Components/Modals/ManageCategoriesModal';
import FileDownloadComponent from './Admin-Product-Download';
import FileUploadComponent from './Admin-product-upload';


const Product = () => {
    const dispatch = useDispatch()
    const token = localStorage.getItem('authTokenHaasil')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 16;
    const { pageLoader } = useSelector((state) => state.utils.loaders);
    const [View, setView] = useState('Grid')
    const [Modal, setModal] = useState(false)
    const [count, setCount] = useState('')
    const [Search, setSearch] = useState('')
    const [TotalItems, setTotalItems] = useState([])
    const [MainCategory, setMainCategory] = useState([{ key: '-1', label: 'No Data......' }])
    const [MainCategoryID, setMainCategoryID] = useState()
    const [SubCategoryId, setSubCategoryId] = useState()
    const [AllMainData, setAllMainData] = useState([])
    const [AllSubData, setAllSubData] = useState([])
    const [MainCategoryName, setMainCategoryName] = useState("")
    const [SubCategory, setSubCategory] = useState([{ key: '', label: 'Select Category First. ' }])
    const [SubCategoryName, setSubCategoryName] = useState('')
    const [UpdateData, setUpdateData] = useState([])
    const [Visibility, setVisibility] = useState('All')
    const [Type, setType] = useState('Create')
    const [CategoriesModal, setCategoriesModal] = useState(false)





    const HandleModal = (type, data) => {
        if (type !== 'Create') {
            setUpdateData(data)
        }
        setType(type)
        setModal(!Modal)
    }
    const HandleView = (view) => {
        setView(view)
    }



    const FetchProdutsData = async (offset,) => {
        if (Search) {
            let response = await onGetProdcutByName(dispatch, token, Search, SubCategoryId, offset - 1, MainCategoryID, Visibility);
            setTotalItems(response?.response?.products)
            setCount(Math.ceil(response?.response.productCount))
        }
        else {
            let response = await onFetchProducts(dispatch, token, offset - 1, SubCategoryId, MainCategoryID, Visibility);
            if (response?.success) {
                setTotalItems(response?.response?.products)
                setCount(Math.ceil(response?.response.productCount))
            }
        }

    };

    const handleSubCategory = (key) => {
        setSubCategoryId(key.key)
        let SubCatname = AllSubData.filter((e) => {
            if (key?.key == e.categoryId) {
                return e
            }
        })
        setSubCategoryName(SubCatname[0].name)
    }

    const handleMainCategory = (key) => {
        getCategory('L2', key?.key)
        setMainCategoryID(key.key)
        setSubCategoryId('')
        setSubCategoryName('')
        let MainCatName = AllMainData.filter((e) => {
            if (key?.key == e.categoryId) {
                return e
            }
        })
        setMainCategoryName(MainCatName[0].name)
    }
    // We will set the Search State Here
    const getProductByName = debounce((e) => {
        setSearch(e.target.value)
    })

    const getCategory = async (level, MainCategoryId) => {
        const result = await getProductCategory(dispatch, token, MainCategoryId, level)
        if (result?.success) {
            setSearch('')
            level === 'L1' ? setAllMainData(result?.response?.productCategory) : setAllSubData(result?.response?.productCategory)
            let filterData = result?.response?.productCategory.map((item, index) => {
                return {
                    key: `${item?.categoryId}`,
                    index: index,
                    label: item?.name
                }
            })
            level === 'L1' ? setMainCategory(filterData) : setSubCategory(filterData)
        }
    }

    const handleVisibility = (key) => {
        setVisibility(key.key)
    }

    const HandleManageCategoirsModal = () => {
        setCategoriesModal(!CategoriesModal)
    }


    useEffect(() => {

        if (AllMainData.length === 0) {
            getCategory('L1')
        }
        if (Search) {
            FetchProdutsData(1)
            setCurrentPage(1)
        } else {
            FetchProdutsData(1)
            setCurrentPage(1)
        }

    }, [MainCategoryID, SubCategoryId, Search, pageLoader?.createProduct, pageLoader?.deleteProductById, pageLoader?.updateProductById, Visibility])




    return (
        <Layout>
            <Row className="">
                <Col span={13} className='flex  flex-col justify-end gap-3 '>
                    <Appbar Heading={'Products'} view={View} setView={HandleView} ExtraDetail={true} />
                </Col>
                <Col span={11} className='flex  flex-col  gap-3 '>
                    <div className='flex gap-3 justify-end'>

                        <Spin spinning={pageLoader?.MainLoading || false}>

                            <FileDownloadComponent />
                        </Spin>
                        <Spin spinning={pageLoader?.MainLoading || false}>

                            <FileUploadComponent />
                        </Spin>
                    </div>
                </Col>
            </Row>
            <Divider className="mt-1 w-[full]" />
            {/* Create product and Filter */}
            <Row>
                <div className='flex justify-between w-full align-'>
                    <Col span={8} className={`flex flex-col gap-4 overflow-hidden py-[30px] px-[10px] bg-[#ecf2ff] rounded-[10px] relative `}>
                        <p className='bold-heading text-[#0c1830] whitespace-nowrap ' style={{ fontSize: "18px" }}>Create New Product Or Manage Categories </p>

                        <div className='gap-[10px] flex '>

                            <button onClick={() => { HandleModal('Create') }} className='bg-[#0c1830] text-[white] p-[10px] rounded-[10px] w-fit text-[12px] font-[700]'>
                                Create Now
                            </button>
                            <button onClick={() => { HandleManageCategoirsModal() }} className='bg-[white] text-[#0c1830] p-[10px] rounded-[10px] w-fit text-[12px] font-[700] border-2 border-[#0c1830]'>
                                Manage Categories
                            </button>
                        </div>
                        <div className='absolute top-[-10px] right-[-30px] bg-[#061e3a38] rounded-[100%] w-[140px] h-[140px]' >

                        </div>
                    </Col>
                    {/* Product Searching */}

                    <Col span={13} className='flex  flex-col justify-end gap-3 '>

                        <div className='flex gap-3 justify-end'>


                            <Spin spinning={pageLoader?.MainLoading || false}>
                                <Button className='text-[black]' >
                                    <Dropdown
                                        menu={{
                                            items: [{ key: 'Visible', label: 'Visibile' }, { key: 'Hidden', label: 'Hidden' }, { key: 'All', label: 'All' }],
                                            onClick: handleVisibility,
                                            selectable: true,
                                        }}
                                        placement="bottomCenter"
                                        trigger={['click']}
                                    >
                                        <p className='flex items-center gap-3'>

                                            Visibility: {Visibility}
                                            <DownOutlined />
                                        </p>
                                    </Dropdown>
                                </Button>
                            </Spin>

                            <Spin spinning={pageLoader?.MainLoading || false}>

                                <Button className='text-[black]' >
                                    <Dropdown
                                        menu={{
                                            items: MainCategory,
                                            onClick: handleMainCategory,
                                            selectable: true,
                                        }}
                                        placement="bottomCenter"
                                        trigger={['click']}
                                    >
                                        <p className='flex items-center gap-3'>

                                            Category: {MainCategoryName}
                                            <DownOutlined />
                                        </p>
                                    </Dropdown>
                                </Button>
                            </Spin>


                            {/* Sub Category-----------------> */}
                            <Spin spinning={pageLoader?.getProductCategory || false}>
                                <Button className='text-[black]'>
                                    <Dropdown
                                        menu={{
                                            items: SubCategory,
                                            onClick: handleSubCategory,
                                            selectable: true,
                                        }}
                                        trigger={['click']}
                                    >
                                        <p className='flex items-center gap-3'>
                                            SubCategory:{SubCategoryName}
                                            <DownOutlined />
                                        </p>

                                    </Dropdown>
                                </Button>
                            </Spin>

                        </div>

                        {/* Product Searching  ---------------------> */}
                        <div className='flex flex-col gap-2'>
                            <div className={`bg-[${Colors.darkBlue}] text-[white] py-[10px] px-[20px] rounded-tr-[40px] rounded-bl-[40px]`}>

                                <p className='font-bold text-[23px]' >Search Product Here..</p>

                            </div>
                            <Space.Compact className='w-[100%]' >
                                <Input prefix={<SearchOutlined />} onChange={(event) => getProductByName(event)} placeholder='Search...'
                                    className={`text-[10px] p-[10px] z-0`} />
                            </Space.Compact>
                        </div>

                    </Col>
                </div>

            </Row >
            {/* All Products--------------> */}
            <Spin Spin spinning={pageLoader?.Dailyessentials || false}>

                <Row>
                    {
                        TotalItems?.map((data, index) => <ProductListing
                            HandleModal={HandleModal}
                            variant={data?.variant}
                            category={data?.productCategory?.name}
                            key={index}
                            name={data?.name}
                            imageUrl={data?.imageURLs}
                            data={data} />)
                    }

                </Row>
                <div className='mt-10'>
                    {
                        TotalItems.length !== 0 && <PaginationComponent search={Search} currentPage={currentPage} itemsPerPage={itemsPerPage} totalItems={count} setCurrentPage={setCurrentPage} callProducts={FetchProdutsData} />

                    }

                </div>

            </Spin >

            <ManageProductModal open={Modal} close={HandleModal} type={Type} data={UpdateData} />
            <ManageCategoriesModal open={CategoriesModal} close={HandleManageCategoirsModal} />

        </Layout >
    )
}

export default Product