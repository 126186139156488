import { setPageLoader } from "../../Redux/features/utilsSlice"
import { error } from "../../helpers/notifications"
import { getRequest, putRequest } from "../apiMethods"
import { apiRoutes } from "../apiRoutes"

export const getAllCustomerInvoices = async (dispatch, accessToken) => {
    dispatch(setPageLoader({ page: 'getAllInvoice', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.Invoices.getAllCustomerInvoices, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getAllInvoice', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }
}



export const getAllInvoices = async (dispatch, accessToken, offset) => {
    dispatch(setPageLoader({ page: 'getAllInvoice', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.Invoices.getAllInvoice+`?offset=${offset}&limit=10`, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getAllInvoice', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }
}


export const getInvoiceById = async (dispatch, accessToken, id) => {
    dispatch(setPageLoader({ page: 'getInvoiceById', flag: true }))
    let response = await getRequest({ endPoint: apiRoutes.Invoices.getInvoiceById + `/${id}`, loggedInToken: accessToken })
    dispatch(setPageLoader({ page: 'getInvoiceById', flag: false }))
    if (response.success) {
        return response
    } else {
        error(response?.response)
    }
}

export const updatePaymentStatus = async ( token, id, paymentStatus,dispatch ,invoiceId) => {
    const data = { paymentStatus: paymentStatus}; 
    // Assuming 'paymentStatus' should be sent as a field in your request data
    

    dispatch(setPageLoader({ page: 'onUpdateCustomerOrderByOrderId', flag: true }))
    let response = await putRequest({ endPoint: apiRoutes.Invoices.getPayment  + `/${id}`, loggedInToken: token, body: data })
    dispatch(setPageLoader({ page: 'onUpdateCustomerOrderByOrderId', flag: false }))
    if (response.success) {
        return response
    }
    else {
    }

}
// export const updatePaymentStatus = async (token, id, paymentStatus) => {
//     const data = { paymentStatus: paymentStatus }; // Assuming 'paymentStatus' should be sent as a field in your request data
  
//     try {
//       const response = await putRequest(`/your-api-endpoint/${id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(data),
//       });
  
//       if (response.success) {
//         return response;
//       } else {
//         // Handle the error case if the request was not successful
//         return response;
//       }
//     } catch (error) {
//       // Handle errors thrown by the API call
//       console.error('API request error:', error);
//       throw error; // Re-throw the error for the calling code to handle
//     }
//   };